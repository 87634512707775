import React from 'react';

import { Card, Collapse, VerticalGroup } from '@grafana/ui';

import { AnalysisEvent, SiftModalData } from 'types';

const cloudProviders = ['aws', 'azure', 'google-cloud', 'oracle'] as const;
type CloudProvider = (typeof cloudProviders)[number];

interface StatusPageDetails {
  statusByProvider: Record<CloudProvider, boolean>;
}

interface StatusPageEventDetails {
  description: string;
  updated: string;
  link: string;
  provider?: string;
}

function providerFromEvent({ name, details }: AnalysisEvent): CloudProvider | undefined {
  if (details == null) {
    return undefined;
  }
  const d = details as unknown as StatusPageEventDetails;
  return (d.provider ?? name.split(':').at(0)) as CloudProvider | undefined;
}

export default function StatusPageOutages({ analysis }: SiftModalData): JSX.Element {
  const statusByProvider = ((analysis.result?.details as unknown as StatusPageDetails) ?? {}).statusByProvider ?? {};
  const events = analysis.result?.events ?? [];
  const byProvider = new Map(cloudProviders.map((p) => [p, [] as AnalysisEvent[]]));
  for (const event of events) {
    const provider = providerFromEvent(event);
    if (provider === undefined) {
      continue;
    }
    const current = byProvider.get(provider) ?? [];
    current.push(event);
    byProvider.set(provider, current);
  }
  const interestingProviders = new Map();
  const failedProviders = new Map();
  const uninterestingProviders = new Map();
  for (const [provider, events] of byProvider.entries()) {
    if (!statusByProvider[provider]) {
      failedProviders.set(provider, events);
    } else if (events.length === 0) {
      uninterestingProviders.set(provider, events);
    } else {
      interestingProviders.set(provider, events);
    }
  }
  return (
    <VerticalGroup>
      <h3>Interesting</h3>
      <EventByProvider eventsByProvider={interestingProviders} />
      <br />
      <h3>Failed</h3>
      <br />
      <EventByProvider eventsByProvider={failedProviders} />
      <h3>Uninteresting</h3>
      <EventByProvider eventsByProvider={uninterestingProviders} />
    </VerticalGroup>
  );
}

interface EventByProviderProps {
  eventsByProvider: Map<CloudProvider, AnalysisEvent[]>;
}

function EventByProvider({ eventsByProvider }: EventByProviderProps): JSX.Element {
  return (
    <div>
      {eventsByProvider.size > 0 ? (
        <div>
          {Array.from(eventsByProvider.entries()).map(([provider, events]) => (
            <Collapse key={provider} label={provider} isOpen={events.length > 0}>
              <ul>
                {events.map((event, i) => (
                  <li key={`event-${provider}-${i}`}>
                    <Event key={`event-${provider}-${i}`} event={event} />
                  </li>
                ))}
              </ul>
            </Collapse>
          ))}
        </div>
      ) : (
        <div>No providers with this status.</div>
      )}
    </div>
  );
}

function titleFromEventName(name: string): string {
  return name.split(':').at(1) ?? name;
}

function Event({ event }: { event: AnalysisEvent }): JSX.Element {
  const details = event.details as unknown as StatusPageEventDetails;
  return (
    <Card href={details.link}>
      <Card.Heading>{titleFromEventName(event.name)}</Card.Heading>
      <Card.Meta>{details.updated}</Card.Meta>
      <Card.Description>{details.description}</Card.Description>
    </Card>
  );
}
