import React from 'react';

import { DashboardCursorSync, TimeRange } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeCompare,
} from '@grafana/scenes';
import { AxisPlacement, Icon } from '@grafana/ui';

import { SceneDrilldown } from 'components/SceneDrillDown/SceneDrillDown';
import { useScene } from 'hooks';
import { LabelInput, SiftModalData } from 'types';
import { filterByLabelsTransformation } from 'utils';

interface TargetSeries {
  selector: string;
  labels: Record<string, string>;
  correlations: CandidateSeries[];
}

interface CandidateSeries {
  score: number;
  query: string;
}

interface CorrelatedSeriesDetails {
  series: Record<string, TargetSeries>;
  query: string;
  datasourceUid: string;
}

export function CorrelatedSeries({ analysis, investigation }: SiftModalData): React.ReactElement {
  if (!analysis.result.interesting) {
    return <div>No correlated series found.</div>;
  }
  const details = analysis.result.details as unknown as CorrelatedSeriesDetails;

  if (Object.keys(details.series).length === 0) {
    return <div>No correlated series found.</div>;
  }

  return <CorrelatedSeriesInner analysisId={analysis.id} details={details} timeRange={investigation.timeRange} />;
}

interface CorrelatedSeriesInnerProps {
  analysisId: string;
  details: CorrelatedSeriesDetails;
  timeRange: TimeRange;
}

function CorrelatedSeriesInner({ analysisId, details, timeRange }: CorrelatedSeriesInnerProps): React.ReactElement {
  const getScene = () => {
    const series = Object.values(details.series);
    return new EmbeddedScene({
      $behaviors: [new behaviors.CursorSync({ key: 'correlated-series', sync: DashboardCursorSync.Crosshair })],
      $timeRange: new SceneTimeRange({
        to: timeRange.to.format(),
        from: timeRange.from.format(),
      }),
      controls: [new SceneControlsSpacer(), new SceneTimePicker({}), new SceneTimeRangeCompare({})],
      body: new SceneDrilldown({
        // Outer view.
        outerLayout: new SceneFlexLayout({
          direction: 'column',
          // This will be computed by the getOuterChildren function.
          children: [],
        }),
        getOuterChildren: (setDrilldownIdx) =>
          getOuterChildren(details.query, series, details.datasourceUid, setDrilldownIdx),
        drilldownLayout: new SceneGridLayout({
          isDraggable: true,
          isResizable: true,
          isLazy: true,
          // This will be computed by the getDrilldownChildren function.
          children: [],
        }),
        getDrilldownChildren: (idx) => getDrilldownChildren(details.query, series[idx], details.datasourceUid),
        getDrilldownTitle: (idx) => series[idx].selector,
      }),
    });
  };
  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}

function getOuterRow(
  name: string,
  idx: number,
  expr: string,
  labels: Record<string, string>,
  datasourceUid: string,
  setDrilldownIdx: (idx: number) => void
): SceneFlexItem {
  const queryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: datasourceUid,
    },
    queries: [
      {
        refId: name,
        expr,
      },
    ],
  });
  const labelInputs: LabelInput[] = Object.entries(labels).map(([name, value]) => ({
    type: 'label',
    label: { name, value },
  }));
  const filterByLabels = filterByLabelsTransformation({ [name]: labelInputs });
  const transformed = new SceneDataTransformer({ $data: queryRunner, transformations: [filterByLabels] });
  const body = PanelBuilders.timeseries()
    .setTitle(name)
    .setData(transformed)
    .setHeaderActions(
      <a
        className="external-link"
        onClick={(e) => {
          e.preventDefault();
          setDrilldownIdx(idx);
        }}
      >
        <Icon name="arrow-right" />
        Drill down
      </a>
    )
    .build();
  return new SceneFlexItem({
    minHeight: '300px',
    body,
  });
}

function getOuterChildren(
  expr: string,
  series: TargetSeries[],
  datasourceUid: string,
  setDrilldownIdx: (idx: number) => void
): SceneFlexItem[] {
  return series.map(({ selector, labels }, idx) =>
    getOuterRow(selector, idx, expr, labels, datasourceUid, setDrilldownIdx)
  );
}

function getTargetDrilldownItem(query: string, target: TargetSeries, datasourceUid: string): SceneGridItem {
  const title = `Target: ${target.selector}`;
  const queryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: datasourceUid,
    },
    queries: [
      {
        refId: 'A',
        expr: query,
      },
    ],
  });
  const labelInputs: LabelInput[] = Object.entries(target.labels).map(([name, value]) => ({
    type: 'label',
    label: { name, value },
  }));
  const filterByLabels = filterByLabelsTransformation({ A: labelInputs });
  const transformed = new SceneDataTransformer({ $data: queryRunner, transformations: [filterByLabels] });
  return new SceneGridItem({
    x: 0,
    y: 0,
    width: 24,
    height: 8,
    body: PanelBuilders.timeseries().setTitle(title).setData(transformed).build(),
  });
}

function getCandidateDrilldownItem(
  idx: number,
  targetQuery: string,
  targetLabels: Record<string, string>,
  candidateQuery: string,
  score: number,
  datasourceUid: string
): SceneGridItem {
  const title = `${candidateQuery} (score: ${score.toFixed(2)})`;
  const queryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: datasourceUid,
    },
    queries: [
      {
        refId: 'TARGET',
        expr: targetQuery,
      },
      {
        refId: 'CANDIDATE',
        expr: candidateQuery,
      },
    ],
  });
  const targetLabelInputs: LabelInput[] = Object.entries(targetLabels).map(([name, value]) => ({
    type: 'label',
    label: { name, value },
  }));
  const filterByLabels = filterByLabelsTransformation({ TARGET: targetLabelInputs });
  const transformed = new SceneDataTransformer({ $data: queryRunner, transformations: [filterByLabels] });
  return new SceneGridItem({
    x: 8 * (idx % 3),
    y: 8 * Math.floor(idx / 3),
    width: 8,
    height: 8,
    body: PanelBuilders.timeseries()
      .setTitle(title)
      .setData(transformed)
      .setOverrides((b) =>
        b
          .matchFieldsByQuery('TARGET')
          .overrideColor({ mode: 'fixed', fixedColor: 'red' })
          .overrideCustomFieldConfig('axisPlacement', AxisPlacement.Left)
          .matchFieldsByQuery('CANDIDATE')
          .overrideColor({ mode: 'fixed', fixedColor: 'green' })
          .overrideCustomFieldConfig('axisPlacement', AxisPlacement.Right)
      )
      .build(),
  });
}

function getDrilldownChildren(targetQuery: string, target: TargetSeries, datasourceUid: string): SceneGridItem[] {
  const targetItem = getTargetDrilldownItem(targetQuery, target, datasourceUid);
  const candidateItems = target.correlations.map(({ score, query }, idx) =>
    getCandidateDrilldownItem(idx, targetQuery, target.labels, query, score, datasourceUid)
  );
  return [targetItem, ...candidateItems];
}
