import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { HorizontalGroup, LinkButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Paging } from 'components/Paging';
import { Filter, SearchBar } from 'components/SearchBar';
import { PLUGIN_ROOT } from 'consts';
import { useIsEditor } from 'hooks';
import { PlaceholderJobListItem } from 'projects/Forecasting/Tabs/List/JobListItem';
import { Holiday } from 'types';

import { HolidayItem } from './HolidayItem';

interface HolidaysProps {
  items: Holiday[];
  isLoading: boolean;
  isFetching: boolean;
}

export default function HolidayList({ items, isLoading }: HolidaysProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();
  const isEditor = useIsEditor();
  const [searchFilters, setSearchFilters] = useState<Filter[]>([]);
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [pageSize, setPageSize] = useQueryParam('pageSize', NumberParam);

  const filter = (_dataToFilter: Holiday[]) => {
    if (searchFilters.length === 0) {
      return items;
    }

    return items?.filter((item) => {
      return searchFilters.every((filter) => {
        if (filter.type === 'match') {
          return item.name.toLowerCase().includes(filter.value.toLowerCase());
        }

        return true;
      });
    });
  };

  const filteredData = items !== undefined ? filter(items) : items;

  const totalItems = filteredData?.length ?? 0;
  const currentPage = page ?? 1;
  const itemsPerPage = pageSize ?? 10;
  const pagedData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) ?? [];

  return (
    <main className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div>
          <div className={styles.toolbarFlex}>
            <SearchBar
              filters={[]}
              onFilter={(filters) => {
                setSearchFilters(filters);
              }}
            />
            <HorizontalGroup justify="flex-end">
              {isEditor ? (
                <LinkButton
                  className={styles.addHolidayButton}
                  variant="primary"
                  size="md"
                  onClick={() => navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/create`)}
                  icon="plus"
                >
                  New holiday
                </LinkButton>
              ) : null}
            </HorizontalGroup>
          </div>
        </div>
        <div>
          {isLoading ? <PlaceholderJobListItem /> : pagedData.map((item) => <HolidayItem key={item.id} item={item} />)}
        </div>
        {totalItems > 0 ? (
          <Paging
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            onItemsPerPageChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
          />
        ) : null}
      </div>
    </main>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    adjacentDiv: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    outerContainer: css``,
    innerContainer: css``,
    toolbarFlex: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 28px 0px;
      gap: 4px;
    `,
    createHoliday: css`
      margin-left: ${theme.spacing(1)}px;
    `,
    addHolidayButton: css`
      width: 160px;
    `,
  };
};
