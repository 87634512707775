import React, { useState } from 'react';

import { GrafanaTheme } from '@grafana/data';
import { IconName, Tab, TabContent, TabsBar, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

export interface TabItem<P> {
  id: string;
  text: string;
  icon: IconName;
  component: React.ComponentType<P & { tabId: string }>;
}

interface Props<P> {
  tabs: Array<TabItem<P>>;
  tabProps?: P;
}

export function TabView<P>({ tabs, tabProps }: Props<P>): JSX.Element {
  const styles = useStyles(getStyles);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className={styles.wrapper}>
      <TabsBar className={styles.tabBar}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.text}
            active={activeTab?.id === tab.id}
            onChangeTab={() => setActiveTab(tab)}
            icon={tab.icon}
          />
        ))}
      </TabsBar>
      <TabContent className={styles.tabContent}>
        {tabs.map((SomeTab) => (
          <aside key={SomeTab.id} className={SomeTab.id === activeTab?.id ? styles.activeTab : styles.inactiveTab}>
            <SomeTab.component {...(tabProps ?? ({} as P))} tabId={SomeTab.id} />
          </aside>
        ))}
      </TabContent>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme) => {
  return {
    activeTab: css`
      display: block;
    `,
    inactiveTab: css`
      display: none;
    `,
    wrapper: css`
      display: flex;
      flex-direction: column;
    `,
    tabBar: css`
      padding-left: ${theme.spacing.md};
    `,
    tabContent: css`
      padding: ${theme.spacing.md};
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 0;
      background: ${theme.colors.panelBg};
      border-right: 1px solid ${theme.colors.pageHeaderBorder};

      .toolbar {
        background: transparent;
      }
    `,
  };
};
