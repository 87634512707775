/* eslint-disable camelcase */
import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { config as CONFIG } from '@grafana/runtime';

import { PLUGIN_ROOT } from 'consts';

import { adminApiDelete, adminApiGet, adminApiPost } from './core.api';
import {
  Outlier,
  OutlierParams,
  OutlierParamsToSaveOutlier,
  RunOutlierPayload,
  SaveOutlierPayload,
  SaveOutlierResponse,
} from './types';

export const getOutlierPayload = ({
  queryParams,
  algorithm,
  config,
  sensitivity,
  startTime,
  endTime,
  interval,
  datasource,
  responseType,
}: OutlierParams): RunOutlierPayload => ({
  data: {
    attributes: {
      grafana_url: CONFIG.appUrl,
      datasource_id: datasource.id,
      datasource_uid: datasource.uid,
      datasource_type: datasource.type,
      query_params: queryParams,
      start_end_attributes: {
        start: startTime,
        end: endTime,
        interval: interval,
      },
      response_type: responseType,
      algorithm: {
        name: algorithm,
        config,
        sensitivity,
      },
    },
  },
});

export const getSaveOutlierPayload = ({
  id,
  queryParams,
  algorithm,
  sensitivity,
  config,
  datasource,
  name,
  description,
  metric,
}: OutlierParamsToSaveOutlier): SaveOutlierPayload => {
  return {
    id,
    name,
    description,
    metric,
    grafanaUrl: CONFIG.appUrl,
    datasourceType: datasource.type,
    datasourceId: datasource.id,
    datasourceUid: datasource.uid,
    queryParams: { ...queryParams, refId: 'A' },
    interval: 300,
    algorithm: {
      name: algorithm,
      sensitivity,
      config,
    },
  };
};

export function useSaveOutlier(
  id?: string | null
): UseMutationResult<SaveOutlierResponse, unknown, OutlierParamsToSaveOutlier, unknown> {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    async (outlierSaveParams: OutlierParamsToSaveOutlier) => {
      const url = `/manage/api/v1/outliers${id !== undefined ? `/${id!}` : ''}`;
      const response = await adminApiPost(url, {
        data: getSaveOutlierPayload(outlierSaveParams),
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as SaveOutlierResponse;
    },
    {
      onSuccess() {
        navigate(`${PLUGIN_ROOT}/outlier-detector`);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries('outlier-list');
      },
    }
  );
}

export function useRemoveOutlier(): UseMutationResult<unknown, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const response = await adminApiDelete(`manage/api/v1/outliers/${id}`);
      return response;
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('outlier_list');
      },
      onSettled: async () => {
        await queryClient.invalidateQueries('outlier_list');
      },
    }
  );
}

export function useGetOutlierList(): QueryObserverResult<Outlier[], Error> {
  return useQuery<Outlier[], Error>('outlier_list', async () => {
    const response = await adminApiGet('/manage/api/v1/outliers');
    if (!response.ok) {
      throw response.data;
    }
    return response.data.data as Outlier[];
  });
}

export function useGetOutlier(id: string): QueryObserverResult<Outlier, Error> {
  return useQuery<Outlier, Error>(['outlier', id], async () => {
    const response = await adminApiGet(`/manage/api/v1/outliers/${id}`);
    if (!response.ok) {
      throw response.data;
    }
    return response.data.data as Outlier;
  });
}
