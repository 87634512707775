import React from 'react';

import { Modal, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import {
  CheckConfig,
  ConfigFormProps,
  ConfigModalState,
  correlatedSeriesConfig,
  ErrorPatternLogsConfig,
  HTTPErrorSeriesConfig,
  LogQueryConfig,
  MetricQueryConfig,
  NoisyNeighborsConfig,
  SlowRequestsConfig,
  StatusPageOutagesConfig,
} from 'types';

import { getAnalysisDisplayTitle } from '../../../../utils/utils.sift';
import { LabelWithHelpText } from './Forms/Components/LabelWithHelpText';
import { CorrelatedSeriesForm } from './Forms/CorrelatedSeriesForm';
import { ErrorPatternLogsForm } from './Forms/ErrorPatternLogsForm';
import { HttpErrorSeriesForm } from './Forms/HttpErrorSeriesForm';
import { LogQueryForm } from './Forms/LogQueryForm';
import { MetricQueryForm } from './Forms/MetricQueryForm';
import { NoisyNeighborsForm } from './Forms/NoisyNeighborsForm';
import { SlowRequestsForm } from './Forms/SlowRequestsForm';
import { StatusPageOutagesForm } from './Forms/StatusPageOutagesForm';

function ModalTitle({ title, checkName }: { title: string; checkName: string }): JSX.Element {
  return (
    <div>
      {title} <LabelWithHelpText checkName={checkName} />
    </div>
  );
}

function ConfigurationModal({
  show,
  check,
  defaultConfig,
  onClose,
}: ConfigModalState & { onClose: () => void }): JSX.Element | null {
  const styles = useStyles2(getStyles);

  if (show === false || check === undefined) {
    return null;
  }

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      onDismiss={onClose}
      onClickBackdrop={onClose}
      title={<ModalTitle title={check.title ?? getAnalysisDisplayTitle(check.name)} checkName={check.name} />}
    >
      <ConfigForm check={check} defaultConfig={defaultConfig} onClose={onClose} />
    </Modal>
  );
}

// ConfigForm is the contents of the config modal.
// It dispatches to the config form of the supplied check.
// The type-erased config is cast to the concrete config type
// here.
function ConfigForm({ check, defaultConfig, onClose }: Omit<ConfigFormProps, 'show'>): JSX.Element | null {
  switch (check?.name) {
    case 'CorrelatedSeries':
      return (
        <CorrelatedSeriesForm
          check={check as CheckConfig<correlatedSeriesConfig>}
          defaultConfig={defaultConfig as correlatedSeriesConfig}
          onClose={onClose}
        />
      );
    case 'ErrorPatternLogs':
      return (
        <ErrorPatternLogsForm
          check={check as CheckConfig<ErrorPatternLogsConfig>}
          defaultConfig={defaultConfig as ErrorPatternLogsConfig}
          onClose={onClose}
        />
      );
    case 'HTTPErrorSeries':
      return (
        <HttpErrorSeriesForm
          check={check as CheckConfig<HTTPErrorSeriesConfig>}
          defaultConfig={defaultConfig as HTTPErrorSeriesConfig}
          onClose={onClose}
        />
      );
    case 'LogQuery':
      return (
        <LogQueryForm
          check={check as CheckConfig<LogQueryConfig>}
          defaultConfig={defaultConfig as LogQueryConfig}
          onClose={onClose}
        />
      );
    case 'MetricQuery':
      return (
        <MetricQueryForm
          check={check as CheckConfig<MetricQueryConfig>}
          defaultConfig={defaultConfig as MetricQueryConfig}
          onClose={onClose}
        />
      );
    case 'NoisyNeighbors':
      return (
        <NoisyNeighborsForm
          check={check as CheckConfig<NoisyNeighborsConfig>}
          defaultConfig={defaultConfig as NoisyNeighborsConfig}
          onClose={onClose}
        />
      );
    case 'SlowRequests':
      return (
        <SlowRequestsForm
          check={check as CheckConfig<SlowRequestsConfig>}
          defaultConfig={defaultConfig as SlowRequestsConfig}
          onClose={onClose}
        />
      );
    case 'StatusPageOutages':
      return (
        <StatusPageOutagesForm
          check={check as CheckConfig<StatusPageOutagesConfig>}
          defaultConfig={defaultConfig as StatusPageOutagesConfig}
          onClose={onClose}
        />
      );
    default:
      return <div>No form</div>;
  }
}

const getStyles = () => {
  return {
    modal: css`
      width: 600px;
    `,
  };
};

export { ConfigurationModal };
