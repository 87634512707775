import React from 'react';

import { PluginPage } from '@grafana/runtime';
import { Alert, Spinner } from '@grafana/ui';

import { useAllHolidays } from 'api';

import { EmptyList } from './List/EmptyList';
import HolidayList from './List/HolidayList';

const Holidays = () => {
  const { isError, isFetching, data, isLoading } = useAllHolidays();

  if (isError) {
    return (
      <PluginPage>
        <Alert title="Failed loading">Could not load holidays</Alert>
      </PluginPage>
    );
  }

  if (isLoading) {
    return (
      <PluginPage>
        <Spinner />
      </PluginPage>
    );
  }
  return (
    <>
      {data == null || data?.length === 0 ? (
        <EmptyList />
      ) : (
        <HolidayList items={data} isLoading={isLoading} isFetching={isFetching} />
      )}
    </>
  );
};

export default Holidays;
