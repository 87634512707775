import React, { ReactElement } from 'react';

import { config } from '@grafana/runtime';
import { Alert, Button, Modal } from '@grafana/ui';

type Props = {
  onDismiss: (() => void) | undefined;
};

export function InitializeApp(props: Props): ReactElement {
  const { onDismiss } = props;
  const openInTab = () => global.open(`${config.appUrl}a/grafana-ml-app`, '_blank');

  return (
    <>
      <Alert title="" severity="info">
        You need to initialize the Grafana ML app to unlock this feature. Please use the button below to open the
        initialization page in a new tab. This is a one time action that needs to be performed by a user with
        administration permissions.
        <br /> <br />
        Please refresh the page when the app is initialized.
      </Alert>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button icon="external-link-alt" onClick={openInTab}>
          Open ML app
        </Button>
      </Modal.ButtonRow>
    </>
  );
}
