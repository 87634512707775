import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppEvents, DataQueryError, DataSourceInstanceSettings, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents, locationService } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';
import { Icon, InlineField, Input, PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { CreateAlertButton } from 'components/CreateAlertButton';
import { JobDetailsDrawer } from 'components/JobDetailsDrawer';
import { JobGraph } from 'components/JobGraph';
import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { ViewAlertsButton } from 'components/ViewAlertsButton';
import { PLUGIN_ROOT } from 'consts';
import { useIsEditor, useQueryResult, useSupportedDatasources, useTimeRange } from 'hooks';
import { useMlDataSource } from 'hooks/useSupportedDatasources';
import { Job, RuleInstance } from 'types';
import { defaultViewJobTimeRange, onHandler } from 'utils';
import { getAlertStateCounts } from 'utils/utils.alerts';
import {
  getJobMetricQueries,
  getJobQueryString,
  getJobSeriesInfo,
  getMinimumUniqueUnderlyingSeriesLabels,
} from 'utils/utils.jobs';

import { CopyAsPanel } from './CopyAsPanel';
import { filterPanelSeriesData } from './filterPanelSeriesData';
import { getExploreUrl } from './getExploreUrl';
import { getPanelOptions } from './getPanelOptions';
import { ViewJobSeriesSelect } from './ViewJobSeriesSelect';

// This isn't exported from Grafana core yet, but its presence
// in the window's localStorage allows the 'paste panel' functionality
// to work.
const LS_PANEL_COPY_KEY = 'panel-copy';
function copyPanel(panelJson: string) {
  window.localStorage[LS_PANEL_COPY_KEY] = panelJson;
  getAppEvents().publish({
    type: AppEvents.alertSuccess.name,
    payload: ['Panel copied. Open Add Panel in a dashboard to paste.'],
  });
}

interface ViewJobProps {
  job: Job;
  alertingEnabled: boolean;
  alertRules: RuleInstance[];
}

export function ViewJobContent({ job, alertingEnabled, alertRules }: ViewJobProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const isEditor = useIsEditor();
  const navigate = useNavigate();

  const [{ maxDataPoints, maxDataPointsUpdated }, setMaxDataPoints] = useState({
    maxDataPoints: 100,
    maxDataPointsUpdated: false,
  });

  // because we require a render to identify the size of the chart, and thus to calculate
  // the max data points, we're tracking maxDataPointsUpdated so we know to wait to make
  // an api call (via the useQueryResult hook) until we've updated the data points at least once.
  const onChangeMaxDataPoints = (maxPoints: number) => {
    if (maxPoints !== maxDataPoints) {
      setMaxDataPoints({
        maxDataPoints: maxPoints,
        maxDataPointsUpdated: true,
      });
    }
  };

  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultViewJobTimeRange()
  );

  const jobMetricQueries = useMemo(() => getJobMetricQueries(job), [job]);
  const queries: DataQuery[] = useMemo(
    () => [
      {
        refId: 'P',
        expr: jobMetricQueries.predicted,
        queryType: 'metric',
      },
      {
        refId: 'A',
        expr: jobMetricQueries.actual,
        queryType: 'metric',
      },
    ],
    [jobMetricQueries.predicted, jobMetricQueries.actual]
  );

  const mlDatasourceUid = useMlDataSource()?.uid ?? '';

  const [data, isRefreshing, refresh, cancelQuery] = useQueryResult(
    queries,
    maxDataPoints,
    timeRange,
    timeZone,
    mlDatasourceUid,
    maxDataPointsUpdated
  );

  const [errors, setErrors] = useState<DataQueryError[] | undefined>(undefined);

  useEffect(() => {
    setErrors(data?.errors);
  }, [data]);

  useEffect(() => {
    // NotStarted = "NotStarted",
    // Loading = "Loading",
    // Streaming = "Streaming",
    // Done = "Done",
    // Error = "Error"

    if (errors !== undefined) {
      errors.forEach((error: DataQueryError) => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error during query: ${error.message?.toString() ?? ''}`],
        });
        console.error('ERROR', error);
      });
    }
  }, [errors]);

  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const openDetails = () => setIsDetailsDrawerOpen(true);
  const hideDetails = () => setIsDetailsDrawerOpen(false);

  const [copyAsPanelState, setCopyAsPanelState] = useState('');
  const copyJobAsPanel = () => {
    copyPanel(copyAsPanelState);
  };
  const canCopyAsPanel = copyAsPanelState !== '';

  const [selectedSeries, selectSeries] = useState<number[]>([]);
  const [dataStructureRev, setDataStructureRev] = useState(data?.structureRev ?? 2);
  const handleSelectSeries = useCallback((series: number[]) => {
    // The structure of the data has changed, as far as Grafana's graph
    // components are concerned, so increment the structureRev counter.
    // See https://github.com/grafana/grafana-ml-app/issues/170 for details.
    setDataStructureRev((ds) => ds + 1);
    selectSeries(series);
  }, []);
  const options = getMinimumUniqueUnderlyingSeriesLabels(data);
  const selectedData =
    selectedSeries.length > 0 ? filterPanelSeriesData(data, options, selectedSeries, dataStructureRev) : data;

  // Get the original job series info of _all_ data.
  // We can't use the `selectedData` here because we'll lose information
  // about the deselected series. This is important if only one series is selected,
  // because we'll end up not including the relevant selectors.
  const jobSeriesInfo = data?.series != null && data.series.length !== 0 ? getJobSeriesInfo(data) : [];
  // If the user hasn't selected anything, consider everything selected.
  const selectedJobSeriesInfo =
    selectedSeries.length === 0 ? jobSeriesInfo : selectedSeries.map((i) => jobSeriesInfo[i]!);

  const datasources = useSupportedDatasources();

  useEffect(() => {
    if (job != null && selectedJobSeriesInfo != null && selectedJobSeriesInfo.length > 0) {
      const args = {
        job,
        jobSeriesInfo: selectedJobSeriesInfo,
        anySelected: selectedSeries.length > 0,
      };
      setCopyAsPanelState(JSON.stringify(getPanelOptions(args)));
    }
  }, [job, selectedJobSeriesInfo, selectedSeries.length]);

  const datasourceName = useMemo(
    () => datasources.find((ds: DataSourceInstanceSettings) => ds.id === job.datasourceId)?.name ?? 'unknown',
    [datasources, job.datasourceId]
  );

  const navigateToExplore = useCallback(async () => {
    const panelOptions = getPanelOptions({
      job,
      jobSeriesInfo: selectedJobSeriesInfo,
      anySelected: selectedSeries.length > 0,
    });
    const url = await getExploreUrl(panelOptions.targets, timeRange, mlDatasourceUid);

    locationService.push(url);
  }, [job, mlDatasourceUid, timeRange, selectedJobSeriesInfo, selectedSeries.length]);

  const alertStateCounts = getAlertStateCounts(alertRules);

  return (
    <div className={styles.wrapper}>
      <PageToolbar className={styles.pageToolbar}>
        <ToolbarTimeRange
          onChangeTimeRange={onChangeTimeRange}
          onZoomTimeRange={onZoomTimeRange}
          onChangeTimeZone={onChangeTimeZone}
          timeRange={timeRange}
          timeZone={timeZone}
          onRefresh={refresh}
          isRefreshing={isRefreshing}
        />
        {isEditor ? (
          <ToolbarButton key="copy" variant="default" disabled={!canCopyAsPanel} onClick={onHandler(navigateToExplore)}>
            <Icon name="compass" size="lg" />
            &nbsp;Explore
          </ToolbarButton>
        ) : null}
        <CreateAlertButton
          job={job}
          alertingEnabled={alertingEnabled}
          jobReady={true}
          jobDisabled={job.disabled ?? false}
          returnTo={`metric-forecast/${job.id}`}
          variant="primary"
          kind="button"
        />
        <ViewAlertsButton
          kind="text"
          job={job}
          alertingEnabled={alertingEnabled}
          alertStateCounts={alertStateCounts}
          jobReady={true}
          jobDisabled={job.disabled ?? false}
          returnTo={`metric-forecast/${job.id}`}
        />
        <CopyAsPanel canCopyAsPanel={canCopyAsPanel} copyJobAsPanel={copyJobAsPanel} />
        <ToolbarButton onClick={openDetails} title="View forecast details" key="info">
          Details
        </ToolbarButton>
        {isEditor ? (
          <ToolbarButton
            onClick={() => navigate(`${PLUGIN_ROOT}/metric-forecast/${job.id}/edit`)}
            title="Edit forecast"
            key="edit"
          >
            Edit
          </ToolbarButton>
        ) : null}
      </PageToolbar>
      <ViewJobSeriesSelect
        jobName={job.name}
        data={data}
        value={selectedSeries}
        options={options}
        selectSeries={handleSelectSeries}
      />
      <div className={styles.contentContainer}>
        <div className={styles.queryContainer}>
          <InlineField labelWidth={20} grow={true} label="Original query">
            <Input disabled readOnly value={getJobQueryString(job)} onChange={() => {}} />
          </InlineField>
          <InlineField labelWidth={20} grow={true} label="Predictions query">
            <Input disabled readOnly value={jobMetricQueries.predicted} onChange={() => {}} />
          </InlineField>
          <InlineField labelWidth={20} grow={true} label="Actual query">
            <Input disabled readOnly value={jobMetricQueries.actual} onChange={() => {}} />
          </InlineField>
        </div>
        <div className={styles.chartContainer}>
          <JobGraph
            job={job}
            data={selectedData}
            selectedJobSeriesInfo={selectedJobSeriesInfo}
            timeZone={timeZone}
            timeRange={timeRange}
            onChangeTimeRange={onChangeTimeRange}
            onRunQuery={refresh}
            isRefreshing={isRefreshing}
            onCancelQuery={cancelQuery}
            maxDataPoints={maxDataPoints}
            onChangeMaxDataPoints={onChangeMaxDataPoints}
          />
        </div>
      </div>
      {/* Job Details drawer */}
      {isDetailsDrawerOpen && <JobDetailsDrawer item={job} datasourceName={datasourceName} onClose={hideDetails} />}
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      .slate-query-field {
        max-height: 100px;
      }
    `,
    bottomPaneWrapper: css``,
    verticalSplitPanesWrapper: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      position: relative;
    `,
    seriesSelector: css`
      display: flex;
      padding: 8px;
      justify-content: space-between;
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
    `,
    queryContainer: css`
      background-color: ${theme.colors.background.primary};
      margin-bottom: 18px;
    `,
    chartContainer: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      background-color: ${theme.colors.background.primary};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 3px;
      height: calc(100vh - 400px);
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: theme.spacing(2),
    }),
  };
};
