import React, { FC } from 'react';

import { ToolbarButton } from '@grafana/ui';

interface CopyAsPanelProps {
  canCopyAsPanel: boolean;
  copyJobAsPanel: () => void;
}

export const CopyAsPanel: FC<CopyAsPanelProps> = ({ canCopyAsPanel, copyJobAsPanel }) => {
  return (
    <ToolbarButton variant="default" key="copyPanel" disabled={!canCopyAsPanel} onClick={copyJobAsPanel}>
      Copy as panel
    </ToolbarButton>
  );
};
