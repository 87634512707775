import React from 'react';

import { PanelData } from '@grafana/data';
import { Alert } from '@grafana/ui';

interface OutlierStatusbarProps {
  data?: PanelData;
  outlierCount: number;
}

export function OutlierStatusbar({ data, outlierCount }: OutlierStatusbarProps): JSX.Element | null {
  if (data != null && data.state === 'Done' && data.series.length > 0) {
    const valueFieldCount = data.series[0].fields.length - 1; // remove the time field
    if (outlierCount < 1) {
      return (
        <Alert topSpacing={0} severity="info" title="No outliers were identified for the current time period."></Alert>
      );
    } else if (outlierCount === 1) {
      return (
        <Alert
          topSpacing={0}
          severity="warning"
          title={`1 of ${valueFieldCount} series contains at least 1 outlier datapoint.`}
        ></Alert>
      );
    } else {
      return (
        <Alert
          topSpacing={0}
          severity="warning"
          title={`${outlierCount} of ${valueFieldCount} series contain at least 1 outlier datapoint.`}
        ></Alert>
      );
    }
  }

  return <></>;
}
