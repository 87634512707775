import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useCreateHoliday } from 'api';
import { PLUGIN_ROOT } from 'consts';
import { Holiday, NewHoliday } from 'types';

import { CreateUpdateHolidayContent } from './CreateUpdateHolidayContent';

export const CreateHoliday = () => {
  const navigate = useNavigate();
  const createHoliday = useCreateHoliday();
  const styles = useStyles2(getStyles);

  const onCreateHoliday = async (newHoliday: NewHoliday) => {
    await createHoliday.mutateAsync(newHoliday, {
      onSuccess(addedHoliday: Holiday) {
        navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${addedHoliday.id}`);
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Holiday added', `Holiday '${addedHoliday.name}' has been added.`],
        });
      },
      onError(e) {
        console.error(e);
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: ['An error happened while creating the holiday'],
        });
      },
    });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.tabPanel}>
          <CreateUpdateHolidayContent onSubmitForm={onCreateHoliday} />
        </div>
      </div>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    pageContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
    `,
    tabPanel: css`
      background-color: ${theme.colors.background.primary};
      margin: 0px 10px 10px 20px;
    `,
  };
}
