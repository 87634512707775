import { useEffect, useMemo, useState } from 'react';

import { PanelData, TimeRange } from '@grafana/data';
import { config, createQueryRunner } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { tryGte } from 'utils/utils.general';

/// Perform a set of data queries, returns the resulting PanelData
// Note - unlike `useQueryResult` this does not use a shared QueryRunner and
// avoid some race conditions we see
export function useOwnQueryResult(
  queries: Array<DataQuery & { expr?: string | undefined }>,
  maxDataPoints: number,
  timeRange: TimeRange,
  timeZone: string,
  datasourceUid: string
): [PanelData | undefined] {
  const runner = useMemo(() => createQueryRunner(), []);

  useEffect(() => {
    const toDestroy = runner;
    return () => {
      return toDestroy.destroy();
    };
  }, [runner]);

  const [data, setData] = useState<PanelData | undefined>(undefined);

  useEffect(() => {
    // This lint seems to be spurious since this signature hasn't been
    // deprecated.
    // eslint-disable-next-line deprecation/deprecation
    const s = runner.get().subscribe(setData);
    return () => s.unsubscribe();
  }, [runner, setData]);

  // Workaround for API change in Grafana 8.3 where need to specify datasource as DataSourceRef
  // instead of a string. https://github.com/grafana/machine-learning/issues/899
  const workaround84 = tryGte(config.buildInfo.version, '8.3.0');

  // start a new query if certain parameters change.
  useEffect(() => {
    runner.run({
      timeRange,
      queries,
      // @ts-expect-error
      datasource: workaround84 ? { uid: datasourceUid } : datasourceUid,
      maxDataPoints,
      minInterval: null,
      timezone: timeZone,
      panelId: Math.ceil(Math.random() * 10000),
      dashboardId: Math.ceil(Math.random() * 10000),
    });
    // don't include maxDataPoints in deps, as we dont want this to re-run constantly as browser resizes
  }, [datasourceUid, queries, runner, timeRange, timeZone, workaround84]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data];
}
