import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Dropdown, IconButton, LinkButton, Menu, Spinner } from '@grafana/ui';

import { css } from '@emotion/css';
import { formatDuration, formatRelative, intervalToDuration } from 'date-fns';

import { useRemoveMetricJob, useRetrainMetricJob } from 'api';
import { Badge, BadgeType } from 'components/Badge';
import Card from 'components/Card';
import { CreateAlertButton } from 'components/CreateAlertButton';
import { ViewAlertsButton } from 'components/ViewAlertsButton';
import { PLUGIN_ROOT } from 'consts';
import { useConfirmModal, useIsEditor } from 'hooks';
import { useForecastingState } from 'projects/Forecasting/useForecastingState';
import { AlertStateCounts, ErrorKind, Job } from 'types';
import { onHandler, useUtilityStyles } from 'utils';
import { alertStateCountsEq } from 'utils/utils.alerts';

interface JobListItemProps {
  item: Job;
  datasourceName: string;
  alertingEnabled: boolean;
  alertStateCounts: AlertStateCounts;
}

function messageForStatus(
  jobStatus: string,
  disabled: boolean,
  hasWarnings: boolean,
  warningText: string,
  errorKind?: ErrorKind
): string | null {
  if (disabled) {
    return null;
  }
  if (hasWarnings) {
    return warningText;
  }
  switch (jobStatus) {
    case 'error':
      switch (errorKind) {
        case 'user':
          return 'Configuration error';
        case 'timeout':
          return 'Training timed out';
        default:
          return null;
      }
    case 'initial training':
      return 'First Training';
    case 'training':
      return 'Updating';
  }
  return null;
}

function getBadgeType(jobStatus: string, hasWarnings: boolean, disabled: boolean): BadgeType {
  if (disabled) {
    // The only `BadgeColor` we haven't used yet...
    return 'disabled';
  }
  switch (jobStatus) {
    case 'success':
    case 'ready':
      return hasWarnings ? 'warning' : 'ready';

    case 'error':
      return 'error';

    case 'initial training':
    case 'pending':
    case 'training':
      return 'pending';
  }
  return 'error';
}

// Only allow the user to retrain a job if:
//
// - the job has failed
// - the job isn't waiting to be scheduled (this can happen in between the user
//   clicking the button, and the scheduler loop firing).
// - the job isn't disabled
function canRetrainJob(job: Job): boolean {
  if (job.disabled ?? false) {
    return false;
  }
  if (job.status !== 'error') {
    return false;
  }
  if (job.nextTrainingAt == null) {
    return false;
  }
  const now = new Date();
  const next = new Date(job.nextTrainingAt);
  return next > now;
}

const JobListItemInner: FC<JobListItemProps> = ({ item, datasourceName, alertingEnabled, alertStateCounts }) => {
  const navigate = useNavigate();
  const canRetrain = canRetrainJob(item);
  const doRetrain = useRetrainMetricJob();
  const onRetrain = useCallback(() => {
    doRetrain.mutate(item.id);
    getAppEvents().publish({
      type: AppEvents.alertSuccess.name,
      payload: ['Training scheduled', `Forecast '${item.name}' will be retrained shortly.`],
    });
  }, [item.id, item.name, doRetrain]);
  const doDelete = useRemoveMetricJob();
  const onDelete = useCallback(
    (metricJobId: string) => {
      doDelete.mutate(metricJobId);
    },
    [doDelete]
  );
  const s = useUtilityStyles();
  const { setForecastDrawer } = useForecastingState();

  const [confirmDelete, ConfirmDeleteModal] = useConfirmModal(
    async () => {
      onDelete(item.id);
    },
    { confirmOnNavigate: false }
  );

  const openDetails = useCallback(
    () => setForecastDrawer(item, datasourceName),
    [setForecastDrawer, item, datasourceName]
  );

  const isEditor = useIsEditor();

  const parts = useMemo(() => {
    const result = [];
    if (item.status === 'pending') {
      result.push(<span key="status">Initial Training scheduled</span>);
    } else if (item.status === 'initial training') {
      result.push(<span key="status">Initial Training in progress</span>);
    } else if (item.status === 'training') {
      result.push(<span key="status">Training in progress</span>);
    } else if (item.trainingCompletedAt == null) {
      result.push(<span key="status">Never trained</span>);
    } else {
      result.push(
        <span key="last-trained">Last trained {formatRelative(new Date(item.trainingCompletedAt), new Date())}</span>
      );
    }

    if (item.trainingFrequency != null) {
      result.push(
        <span key="training-freq">
          Train every {formatDuration(intervalToDuration({ start: 0, end: item.trainingFrequency * 1000 }))}
        </span>
      );
    }

    if (item.trainingWindow != null) {
      result.push(
        <span key="training-window">
          Training window {formatDuration(intervalToDuration({ start: 0, end: item.trainingWindow * 1000 }))}
        </span>
      );
    }
    return result;
  }, [item.status, item.trainingFrequency, item.trainingWindow, item.trainingCompletedAt]);

  const hasWarnings = (item.lastTrainingStatus?.warnings ?? []).length > 0;
  const warningText =
    item.lastTrainingStatus?.warnings?.reduce((tooltip, warning) => {
      return `${tooltip}${warning} `;
    }, '') ?? '';

  const badgeType = getBadgeType(item.status ?? '', hasWarnings, item.disabled ?? false);
  const message = messageForStatus(
    item.status ?? '',
    item.disabled ?? false,
    hasWarnings,
    warningText,
    item.lastTrainingStatus?.errorKind
  );
  const jobReady =
    item.status !== 'error' &&
    item.status !== 'pending' &&
    item.trainingCompletedAt != null &&
    !((item as { isOptimistic?: boolean })?.isOptimistic === true);
  const canViewJob = jobReady && !item.disabled;

  const onViewJob = useCallback(() => {
    if (canViewJob) {
      navigate(`${PLUGIN_ROOT}/metric-forecast/${item.id}`, { replace: true });
    }
  }, [item.id, canViewJob, navigate]);

  const onEditJob = useCallback(() => {
    if (isEditor) {
      navigate(`${PLUGIN_ROOT}/metric-forecast/${item.id}/edit`, { replace: true });
    }
  }, [item.id, isEditor, navigate]);

  const OptionsMenu = useMemo(
    () => (
      <Menu>
        <CreateAlertButton
          job={item}
          alertingEnabled={alertingEnabled}
          jobReady={jobReady}
          jobDisabled={item.disabled ?? false}
          returnTo="/metric-forecast"
          icon="bell"
          kind="menu-item"
        />
        <ViewAlertsButton
          kind="menu-item"
          job={item}
          alertingEnabled={alertingEnabled}
          jobReady={jobReady}
          jobDisabled={item.disabled ?? false}
          alertStateCounts={alertStateCounts}
          returnTo="/metric-forecast"
        />
        <Menu.Divider />
        <Menu.Item label="View forecast" icon="eye" disabled={!canViewJob} onClick={onViewJob} />
        <Menu.Item label="Retrain forecast" icon="repeat" disabled={!canRetrain} onClick={onRetrain} />
        <Menu.Item label="Edit forecast" icon="edit" disabled={!isEditor} onClick={onEditJob} />
        <Menu.Item label="Delete forecast" icon="trash-alt" onClick={onHandler(confirmDelete)} destructive />
      </Menu>
    ),
    [
      item,
      alertingEnabled,
      alertStateCounts,
      canViewJob,
      canRetrain,
      onRetrain,
      onViewJob,
      onEditJob,
      confirmDelete,
      isEditor,
      jobReady,
    ]
  );

  return (
    <div key={item.id}>
      <Card height="subtle" variant="secondary-gray" className={styles.card}>
        <a href={`${PLUGIN_ROOT}/metric-forecast/${item.id}`} className={styles.heading}>
          <h5>{item.name}</h5>
          {item.description != null ? <div>{item.description}</div> : null}
        </a>

        <aside className={styles.tags}>
          {badgeType === 'disabled' ? (
            <div onClick={openDetails}>
              <Badge
                badgeType={badgeType}
                tooltipText={message ?? 'This job has been manually disabled. Click to view details.'}
              />
            </div>
          ) : (
            <Badge badgeType={badgeType} tooltipText={message ?? ''} />
          )}
        </aside>
        <aside className={styles.status}>{parts}</aside>

        <main className={styles.actions}>
          <IconButton
            key="view"
            name="eye"
            tooltip="View forecast"
            disabled={!canViewJob}
            title={'View forecast'}
            onClick={onViewJob}
          />
          <IconButton
            key="edit"
            name="edit"
            tooltip="Edit forecast"
            disabled={!isEditor}
            title={'Edit forecast'}
            onClick={onEditJob}
          />
          <IconButton
            key="details"
            name="document-info"
            tooltip="View details"
            title={'View details'}
            onClick={openDetails}
          />
          <ViewAlertsButton
            kind="icon"
            job={item}
            alertingEnabled={alertingEnabled}
            jobReady={jobReady}
            jobDisabled={item.disabled ?? false}
            alertStateCounts={alertStateCounts}
            returnTo="/metric-forecast"
          />
          <IconButton
            key="retrain"
            name="repeat"
            tooltip="Retrain this forecast"
            title={canRetrain ? undefined : 'Retraining is disabled for successful forecasts.'}
            disabled={!canRetrain}
            onClick={onRetrain}
          />
          <Dropdown overlay={OptionsMenu}>
            <IconButton name="ellipsis-h" aria-label="Additional options" tooltip="Additional options" />
          </Dropdown>
        </main>
      </Card>
      <ConfirmDeleteModal icon="trash-alt" title="Delete forecast" actionText="Delete">
        <div className={s.textMd}>Are you sure you want to delete &quot;{item.name}&quot;?</div>
      </ConfirmDeleteModal>
    </div>
  );
};

export const JobListItem = React.memo(
  JobListItemInner,
  (prev, next) =>
    prev.item === next.item &&
    prev.datasourceName === next.datasourceName &&
    prev.alertingEnabled === next.alertingEnabled &&
    alertStateCountsEq(prev.alertStateCounts, next.alertStateCounts)
);

export function PlaceholderJobListItem(): JSX.Element {
  return (
    <Card height="flat" className={styles.card}>
      <header className={styles.heading}>
        <h5>Loading</h5>
      </header>

      <aside className={styles.tags}>
        <div className={styles.statusWithMessage}>
          <Badge badgeType="pending" tooltipText="Pending" />
          <Spinner />
        </div>
      </aside>

      <aside className={styles.status}>Loading...</aside>

      <main className={styles.actions}>
        <LinkButton
          key="view"
          variant="secondary"
          href={`${PLUGIN_ROOT}/metric-forecast`}
          disabled
          className={styles.disabled}
        >
          View
        </LinkButton>
        <Button key="details" variant="secondary" disabled>
          Details
        </Button>
        <IconButton key="retrain" name="repeat" tooltip="Retrain this forecast" disabled />
      </main>
    </Card>
  );
}

const styles = {
  empty: css``,

  card: css`
    display: flex;
    justify-content: flex-end;
    padding: 18px;
  `,

  statusWithMessage: css`
    display: flex;
    align-items: center;
    gap: 12px;
    flex-flow: row nowrap;
    cursor: default;
  `,

  status: css`
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    font-size: 12px;
    opacity: 0.7;
    min-width: 520px;
    padding-right: 20px;

    > span:not(:last-child):after {
      content: '|';
      padding-left: 6px;
    }

    @media (max-width: 1200px) {
      display: none;
      min-width: none;
    }
  `,

  tags: css`
    align-self: center;
    justify-self: flex-end;
    margin-right: 12px;
  `,

  heading: css`
    display: flex;
    gap: 12px;
    margin: 0px auto 0px 0px;
    cursor: pointer;

    > h5 {
      font-size: 14px;
      font-weight: 500;
      white-space: no-wrap;
    }
    > div {
      opacity: 0.7;
      min-width: 150px;
      text-overflow: ellipsis;
    }

    @media (max-width: 1300px) {
      > div {
        display: none;
      }
    }
  `,

  actions: css`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  `,

  jobDisabled: css`
    grid-area: jobDisabled;
  `,

  disabled: css`
    opacity: 0.6;
    cursor: not-allowed;
  `,
};
