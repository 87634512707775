import React, { FC } from 'react';

import { Badge, BadgeColor } from '@grafana/ui';

import { css } from '@emotion/css';
import { formatDuration, intervalToDuration } from 'date-fns';

import { TrainingHistoryItem } from 'types';

import { Field } from './Field';
import { duration } from './JobDetailsDrawer';
import { ObjectField } from './ObjectField';

function colorForStatus(color: string): BadgeColor {
  switch (color) {
    case 'Training in Progress':
      return 'blue';
    case 'Error':
      return 'red';
    case 'Success':
      return 'green';
  }
  return 'purple';
}

export const ShowTrainingHistoryItem: FC<{ item: TrainingHistoryItem }> = ({ item }) => {
  const startDate = item.started == null ? null : new Date(item.started);
  const endDate = item.finished == null ? null : new Date(item.finished);
  const status = item.error == null ? (item.finished == null ? 'Training in Progress' : 'Success') : 'Error';

  return (
    <>
      <h5 className={styles.headerStyle}>
        <span>{startDate?.toLocaleString() ?? 'New Training Run'}</span>
        <Badge text={status} color={colorForStatus(status)} />
      </h5>
      {item.error == null ? null : <Field label="Error" value={item.error} />}
      <Field label="Lookback window" value={duration(item.trainingWindow)} />
      <Field label="Data interval" value={duration(item.interval)} />
      <Field
        label="Duration of training"
        value={
          startDate != null && endDate != null
            ? formatDuration(intervalToDuration({ end: endDate, start: startDate }))
            : ''
        }
      />
      <ObjectField label="Hyperparameters used" value={item.hyperParams ?? undefined} />
    </>
  );
};

const styles = {
  headerStyle: css`
    margin: 30px 0 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
};
