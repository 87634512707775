import React, { useMemo } from 'react';

import { PluginPage } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { StringParam, useQueryParam } from 'use-query-params';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';
import { useLastSelectedStorage, useSupportedDatasources } from 'hooks';

import { CreateOutlierContent, OutlierToolbar } from './';

export default function CreateOutlier(): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const datasources = useSupportedDatasources();
  const [qsDatasourceId] = useQueryParam('ds', StringParam);
  const [editId] = useQueryParam('id', StringParam);
  const isEdit = editId !== undefined;

  const [lastSelected] = useLastSelectedStorage();

  const initialDataSource = useMemo(() => {
    if (qsDatasourceId !== null && qsDatasourceId !== undefined) {
      const found = datasources.find((x) => x.uid === qsDatasourceId);
      if (found != null) {
        return found;
      }
    }
    if (lastSelected != null) {
      const found = datasources.find((x) => x.uid === lastSelected);
      if (found != null) {
        return found;
      }
    }
    return datasources[datasources.length - 1];
  }, [lastSelected, qsDatasourceId, datasources]);

  const title = `${isEdit ? 'Update' : 'New'} detector`;

  if (initialDataSource == null) {
    return (
      <PluginPage pageNav={{ text: title }}>
        <div className={styles.pageContainer}>
          <OutlierToolbar hasDataSource={false} />
          <div className={styles.loadingContainer}>
            <LoadingIndicator />
          </div>
        </div>
      </PluginPage>
    );
  }

  return (
    <PluginPage pageNav={{ text: title }}>
      <QueryRunnerProvider>
        <CreateOutlierContent initialDataSource={initialDataSource} />
      </QueryRunnerProvider>
    </PluginPage>
  );
}

function getStyles() {
  return {
    pageContainer: css`
      top: 0;
      bottom: 0;
      right: 0;
      left: 60px;
      display: flex;
      flex-direction: column;
    `,
    loadingContainer: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      height: calc(100vh - 350px);
    `,
  };
}
