import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input, TextArea } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import { CheckConfig, CheckConfigWithDatasource, CheckFormProps, LogQueryConfig } from 'types';
import { getAnalysisDisplayTitle, parseInputToInt, parseInputToString } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithExample } from './Components/LabelWithExample';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

const queryPlaceholder = `{cluster="$cluster", namespace="$namespace", job="api"} |~ "ERROR|error"`;
const templatePlaceholder = `Found error logs for API:
{{ range .streams }}
- {{ .Labels }}
{{ end }}`;

function LogQueryForm({ check, defaultConfig, onClose }: CheckFormProps<LogQueryConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { lokiFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<LogQueryConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues: CheckConfigWithDatasource<LogQueryConfig> = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
    autoDetectDatasource: (check.config.lokiDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData = {
          id: check.id ?? undefined,
          name: check.name,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            lokiDatasourceUid: autoDetectDatasource ? '' : config.lokiDatasourceUid,
          },
          title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, formState, setValue, watch }) => {
        const expr = watch('config.expr');
        const message = watch('config.message');
        const lokiDatasourceUid = watch('config.lokiDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} />
            </Field>

            <Field
              label={
                <LabelWithExample
                  label={<LabelWithHelpText label="Query" checkName="logQuery" fieldName="expression" />}
                  tooltip="Use example query"
                  value={expr}
                  setValue={() => setValue('config.expr', queryPlaceholder)}
                />
              }
            >
              <TextArea
                rows={1}
                {...register('config.expr', { setValueAs: parseInputToString })}
                placeholder={queryPlaceholder}
              />
            </Field>

            <Field
              label={
                <LabelWithExample
                  label={<LabelWithHelpText label="Message template" checkName="logQuery" fieldName="message" />}
                  tooltip="Use example template"
                  value={message}
                  setValue={() => setValue('config.message', templatePlaceholder)}
                />
              }
            >
              <TextArea
                rows={4}
                {...register('config.message', { setValueAs: parseInputToString })}
                placeholder={templatePlaceholder}
              />
            </Field>

            <Field
              label={<LabelWithHelpText label="Maximum log lines" checkName="logQuery" fieldName="maxLogLines" />}
              error="must be between 0 and 20"
            >
              <Input
                type="number"
                {...register('config.maxLogLines', { min: 0, max: 20, setValueAs: parseInputToInt })}
                placeholder={(defaultConfig.maxLogLines ?? '').toString()}
              />
            </Field>

            <DataSourceSelector
              title="Loki Datasource"
              dsType="loki"
              dsUid={lokiDatasourceUid ?? ''}
              autoDetectDatasource={autoDetectDatasource}
              filter={lokiFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.lokiDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { LogQueryForm };
