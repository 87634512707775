import React from 'react';

import { DataSourceInstanceSettings } from '@grafana/data';
import { DataSourcePicker } from '@grafana/runtime';
import { Checkbox, Field } from '@grafana/ui';

// Datasource types that Sift supports querying from.
type SupportedDatasourceType = 'prometheus' | 'loki' | 'tempo';

interface DataSourceSelectorProps<T> {
  // The current value for the 'auto detect datasource' checkbox.
  autoDetectDatasource: boolean;
  // The type of the currently selected datasource
  dsType?: SupportedDatasourceType;
  // The UID of the currently selected datasource.
  dsUid?: string;
  // Title of the field.
  title: string;
  // Function to filter the available datasources.
  filter: (ds: DataSourceInstanceSettings) => boolean;
  // Function to register the inputs with the form, provided
  // by `@grafana/ui`'s `Form` component.
  register: any;
  // Callback called when the selected datasource is updated.
  setValue: (configKey: T, uid: string) => void;
  // The key to be used as the first argument to `setValue`.
  valueKey: T;
}

function DataSourceSelector<T>({
  autoDetectDatasource,
  dsType,
  dsUid,
  title,
  filter,
  register,
  setValue,
  valueKey,
}: DataSourceSelectorProps<T>): JSX.Element | null {
  return (
    <Field label={title}>
      <>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '8px 0px', gap: '8px', alignItems: 'center' }}>
          <Checkbox {...register('autoDetectDatasource')} checked={autoDetectDatasource} />
          <div style={{ fontSize: '11px', opacity: 0.7 }}>Automatically detect the datasource.</div>
        </div>

        <DataSourcePicker
          hideTextValue={autoDetectDatasource || dsUid === undefined || dsUid === ''}
          placeholder="Select a datasource"
          disabled={autoDetectDatasource}
          filter={filter}
          current={{ type: dsType, uid: dsUid }}
          onChange={(ds) => setValue(valueKey, ds.uid)}
        />
      </>
    </Field>
  );
}

export { DataSourceSelector };
