import React from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { FullFormModel, ModelId } from 'types';
import { neverCase } from 'utils';

import { ProphetHyperparametersForm } from './ProphetHyperparametersForm';
import { SarimaHyperparametersForm } from './SarimaHyperparametersForm';

/// Show one of the forms depending on a selector
/// This seems complicated but it lets typescript prove all the types for the subforms statically
export default function ShowHyperparameterForm(props: HyperparametersFormProps): JSX.Element | null {
  const { control, errors, register, holidays } = props;
  switch (props.id) {
    case 'grafana_sarima_1_8_0': {
      return <SarimaHyperparametersForm register={register} control={control} errors={errors} />;
    }
    case 'grafana_prophet_0_7_0':
    case 'grafana_prophet_1_0_1': {
      return <ProphetHyperparametersForm control={control} errors={errors} holidayIds={holidays} />;
    }
    case 'grafana_augurs_mstl_0_1_0':
    case 'philwinder_averaging_model_0_0_1': {
      return null;
    }
    default:
      return neverCase(props.id);
  }
}

interface HyperparametersFormProps {
  id: ModelId;
  control: Control<FullFormModel>;
  errors: FieldErrors<FullFormModel> | undefined;
  register: UseFormRegister<FullFormModel>;
  holidays: string[];
}
