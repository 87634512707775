import { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'react-use';

export function useLastSelectedStorage(): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>,
  () => void
] {
  return useLocalStorage<string>('grafana.ml.last-selected-ds');
}
