import { DataQuery } from '@grafana/schema';

import { DATASOURCE_NAME } from 'consts';
import { Job, JobSeriesInfo } from 'types';
import { createFieldOverrides, getJobMetricQueries, getSelectedJobQueries } from 'utils/utils.jobs';

interface JobPanelOptions {
  job: Job;
  jobSeriesInfo: JobSeriesInfo[];
  anySelected: boolean;
}

/**
 * Get an object representing a panel which can be copied into a dashboard.
 *
 * If any series have been explicitly selected in the series dropdown, then we only add
 * queries for these specific series to the panel, which can result in many different queries
 * being added to the panel.
 *
 * If no series have been explicitly selected, we add a single `predicted` and `actual`
 * query to the panel, which will include all underlying series.
 *
 * Either way, both field and series overrides are generated for the series' in
 * `jobSeriesInfo`, to provide the correct formatting for the generated panel. The
 * implication of this is that if the query later returns _new_ series (e.g. if a new
 * underlying series appears), the overrides will not be present for that series.
 * This is a limitation of how field overrides currently work in Grafana -
 * see https://github.com/grafana/grafana-ml-app/issues/146 for more details.
 *
 * TODO:
 * Ideally we could do away with almost all of this and instead get the 'save model' from
 * the actual graph we're rendering on this page, then simply add in a few alerting rules.
 * Otherwise it seems likely that the graph we're displaying in the JobGraphContent component
 * will differ subtley from the graph represented by this object.
 *
 */
export const getPanelOptions = ({ job, jobSeriesInfo, anySelected }: JobPanelOptions): { targets: DataQuery[] } => {
  const queries = getJobMetricQueries(job);
  const targets = anySelected
    ? getSelectedJobQueries(job, jobSeriesInfo)
    : [
        { refId: 'P', expr: queries.predicted, queryType: 'metric' } as DataQuery,
        { refId: 'A', expr: queries.actual, queryType: 'metric' } as DataQuery,
      ];
  const options = {
    datasource: DATASOURCE_NAME,
    fieldConfig: {
      defaults: {
        custom: {
          spanNulls: true,
        },
      },
      overrides: jobSeriesInfo.flatMap(createFieldOverrides),
    },
    gridPos: {
      h: 9,
      w: 12,
      x: 0,
      y: 0,
    },
    targets,
    title: job.name,
    type: 'timeseries',
  };
  return options;
};
