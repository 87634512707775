import React from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { Service } from 'types';

import { ServiceField } from './ServiceField';

interface ServiceProps {
  values: Service[];
  validation: ServiceValidation;
  showErrors: boolean;
  onChange: (values: Service[]) => void;
  onStartChange?: () => void;
  onEndChange?: () => void;
}

export interface ServiceValidation {
  valid: boolean; // all services are valid
  messages: string[]; // messages for services that are not valid
}

export const Services = ({ values, validation, showErrors, onChange, onStartChange, onEndChange }: ServiceProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      {values.map((label, i) => (
        <div key={`field${i}`} className={styles.serviceRow}>
          <ServiceField
            name={label.name}
            namespace={label.namespace ?? ''}
            errorMessage={showErrors ? validation.messages[i] : ''}
            onChange={(name, namespace) => {
              const changed = [...values];
              changed[i].name = name;
              changed[i].namespace = namespace;
              onChange(changed);
            }}
            onRemove={() => {
              const changed = [...values];
              changed.splice(i, 1);
              onChange(changed);
            }}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
          />
        </div>
      ))}
      <Button
        size="sm"
        variant="secondary"
        className={styles.addServiceButton}
        disabled={values.length > 0 && values[values.length - 1].name === ''}
        onClick={() => onChange([...values, { name: '', namespace: '' }])}
      >
        + Add service
      </Button>
    </div>
  );
};

const getStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
  serviceRow: css`
    display: flex;
    gap: 8px;
    padding: 4px 0px;
  `,
  addServiceButton: css`
    width: 100px;
    margin: 8px 28px 0px auto;
  `,
});
