import React, { useLayoutEffect } from 'react';

import { colorManipulator, DataFrame, FieldType } from '@grafana/data';
import { buildScaleKey, UPlotConfigBuilder, useStyles2 } from '@grafana/ui';

import { TypedArray } from 'uplot';

import { OutlierField, OutlierIntervals } from 'api/types';
import { getOutlierColors } from 'utils';

interface OutliersPluginProps {
  config: UPlotConfigBuilder;
  alignedDataFrame: DataFrame;
  selectedIndex: number | undefined;
}

/***
 * OutlierPlugin - this is a plugin for a TimeSeries graph, which applies different coloring to intervals of
 * each timeseries plot designated as 'outlier'. Additionally it can draw the 'normal' band.
 *
 * The outlier intervals are supplied as an additional property `outlierIntervals` on each field in the
 * `alignedDataFrame`.
 *
 * The coloring is applied by manipulating the uPlot context directly. This has the unfortunate side-effect
 * of not respecting Grafana's method of coloring timeseries using the `overrides` specified in the FieldConfig.
 *
 * Coloring is performed by applying a suitable canvas linear gradient to the timeseries.
 *
 * The 'normal' band is drawn if series 1 & 2 (series 0 being `time` axis) both have a label
 * with key `normal`. A fill operation will be performed between them.
 */
export const OutlierPlugin: React.FC<OutliersPluginProps> = ({ config, alignedDataFrame, selectedIndex }) => {
  const { isDark } = useStyles2((theme) => theme);
  const colors = getOutlierColors(isDark);

  useLayoutEffect(() => {
    config.addHook('setData', (uplotData) => {
      // This hook is called after the plot `data` is updated, allowing us to make any changes to the uPlot context.
      const timeArray = uplotData.data[0];
      if (timeArray.length === 0) {
        return;
      }

      // Check if normal band has been supplied
      let normalBand = false;
      if (
        alignedDataFrame.fields.length > 3 &&
        alignedDataFrame.fields[1]?.labels !== undefined &&
        alignedDataFrame.fields[2]?.labels !== undefined &&
        'normal' in alignedDataFrame.fields[1].labels &&
        'normal' in alignedDataFrame.fields[2].labels
      ) {
        normalBand = true;
      }

      // increase transparency as the number of series increases, so that the non-outliers are less prominent
      const transparency = 1 / Math.sqrt(1 + (uplotData.data?.length ?? 0) / 2);
      const notOutlierColor = colorManipulator.alpha(colors.notOutlier, transparency);

      const startIndex = normalBand ? 2 : 0; // deal with normal band later
      for (let i = startIndex; i < uplotData.series.length; i++) {
        const s = uplotData.series[i]!;
        s.stroke = (u, sidx) => {
          const field = alignedDataFrame.fields[sidx] as OutlierField<number>;

          let outlierColor = colors.isOutlier;
          let normalColor = notOutlierColor;
          let lineWidth = 1;

          // Series has been selected, draw differently
          if (selectedIndex !== undefined && field !== undefined && field?.index === selectedIndex) {
            outlierColor = colors.selectedIsOutlier;
            normalColor = colors.selectedNotOutlier;
            lineWidth = 3;
          }

          // set line width (selected width increased)
          u.ctx.lineWidth = lineWidth;

          // never anomalous
          if (field === undefined || !('outlierIntervals' in field)) {
            return normalColor;
          }
          const thisSeriesOutlierIntervals = field['outlierIntervals'] as OutlierIntervals;
          if (thisSeriesOutlierIntervals === undefined || thisSeriesOutlierIntervals.length === 0) {
            return normalColor;
          }

          const startTime: number = u.scales['x']!.min as number;
          const endTime: number = u.scales['x']!.max as number;

          // always anomalous
          if (
            thisSeriesOutlierIntervals.length === 2 &&
            (thisSeriesOutlierIntervals[0] as number) <= startTime &&
            (thisSeriesOutlierIntervals[1] as number) >= endTime
          ) {
            return outlierColor;
          }

          let isOutlying = false;

          // Unfortunately these need to be calculated in this scope, so repeated calc for each series :(
          const minStopPos = u.valToPos(startTime, 'x', true);
          const maxStopPos = u.valToPos(endTime, 'x', true);
          const range = maxStopPos - minStopPos;
          const plotData = u.data[sidx] as TypedArray;
          const stepSize = range / plotData.length;

          // Create linear gradient to specify the line coloring. Has x geometry map to the plot geometry,
          // so that can use 0 for the first pixel in the plot, and 1 as the last.
          const grd = u.ctx.createLinearGradient(minStopPos, 0, maxStopPos, 0);

          for (const changePoint of thisSeriesOutlierIntervals) {
            const pos = u.valToPos(changePoint, 'x', true);

            // calculate position of the color change, offsetting to center the transition between points
            let timeFraction = (pos - minStopPos - stepSize / 2) / range;
            if (timeFraction < 0) {
              timeFraction = 0;
            } else if (timeFraction > 1) {
              timeFraction = 1;
            }

            grd.addColorStop(timeFraction, isOutlying ? outlierColor : normalColor);
            isOutlying = !isOutlying;
            grd.addColorStop(timeFraction, isOutlying ? outlierColor : normalColor);
          }

          return grd;
        };
      }

      // Step 2: Draw normal band, if it exists. The 2 series identifying the band upper & lower are
      // the first 2 series supplied (so drawn as background). They should have labels to identify themselves too.
      if (normalBand) {
        const lowerSeries = uplotData.series[1]!;
        lowerSeries.stroke = () => colors.notOutlierRegion;
        lowerSeries.spanGaps = false;
        lowerSeries.auto = false; // so y axis calculation ignores this series

        const upperSeries = uplotData.series[2]!;
        upperSeries.stroke = () => colors.notOutlierRegion;
        upperSeries.spanGaps = false;
        upperSeries.auto = false;

        uplotData.addBand({ series: [1, 2], fill: colors.notOutlierRegion, dir: 1 });

        // Add upper/lower padding to the plot so the cluster region has room to expand
        addToUpperLowerRange(uplotData, config, 0.1, 0.1);
      }
    });
  }, [
    config,
    alignedDataFrame.fields,
    selectedIndex,
    colors.isOutlier,
    colors.notOutlier,
    colors.selectedIsOutlier,
    colors.selectedNotOutlier,
    colors.notOutlierRegion,
  ]);

  return null;
};

// Add more padding to the upper & lower range of the plot.
function addToUpperLowerRange(uplotData: uPlot, config: UPlotConfigBuilder, upperMult: number, lowerMult: number) {
  // uPlot calculates the min & max of the data to find the data range, which it uses to decide how to
  // to render the data so it all displayed on screen. Grafana additionally specifies a function to uPlot
  // that takes in the data min & max and adds custom spacing.
  //
  // Unfortunately we cannot influence this function to add more spacing. To work around this limitation
  // grab the 'range' function (i.e. the Grafana one), and wrap it in such a way it adjusts the data min & max.
  // This will then influence how the data is plotted.
  //
  // Padding is specified as multipliers applied to the span of the data.
  //
  // This is messing directly with uPlot's state, so the most relevant docs are
  // https://github.com/leeoniya/uPlot/tree/master/docs#series-scales-axes-grid
  // but it's quite light on detail. The Grafana implementation is more revealing:
  // https://github.com/grafana/grafana/blob/v9.2.2/packages/grafana-ui/src/components/uPlot/config/UPlotScaleBuilder.ts#L86

  // Grafana sets the name of the y-axis differently depending on the Grafana version
  //  - before version 10.0.3:  '__fixed/na-na/na-na/auto/linear/na'
  //  - 10.0.3 and after:       '__fixed/na-na/na-na/auto/linear/na/number'
  // The follwoing Grafana internal function generates that string. Before 10.0.3 it only accepted 1 argument, after it takes 2.
  // Due to Javascript oddness the below is universally compatible, as for <10.0.3 the second argument is ignored!
  // @ts-ignore
  const scaleKey = buildScaleKey(config, FieldType.number);

  // This will fetch the range function that Grafana specified for the y-axis.
  const defaultRangeFn = uplotData.scales[scaleKey]!.range as uPlot.Range.Function | undefined;

  const wrappedRangeFn = (
    u: uPlot,
    dataMin: number | null,
    dataMax: number | null,
    scaleKey: string
  ): uPlot.Scale.Range => {
    // Intercepts the default `range` implementation Grafana provides and pads the values and then runs it
    if (dataMin != null && dataMax != null) {
      const spread = dataMax - dataMin;

      dataMin -= spread * lowerMult;
      dataMax += spread * upperMult;
    }

    return defaultRangeFn!(u, dataMin!, dataMax!, scaleKey);
  };

  // Highly unlikely this is undefined, but doing this check to keep Typescript happy
  if (defaultRangeFn !== undefined) {
    uplotData.scales[scaleKey]!.range = wrappedRangeFn as uPlot.Scale.Range;
  }
}
