import React, { useState } from 'react';

import { DataSourceInstanceSettings, GrafanaTheme2 } from '@grafana/data';
import { config, PluginPage } from '@grafana/runtime';
import { Alert, HorizontalGroup, LinkButton, Spinner, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { NumberParam, useQueryParam } from 'use-query-params';

import { useAlertRuleInstances, useGetOutlierList } from 'api';
import { Outlier } from 'api/types';
import Card from 'components/Card';
import { Paging } from 'components/Paging';
import { Filter, SearchBar } from 'components/SearchBar';
import { PLUGIN_ROOT } from 'consts';
import { useIsEditor, useSupportedDatasources } from 'hooks';
import { RuleInstance } from 'types';
import { getAlertStateCounts } from 'utils/utils.alerts';

import { OutlierListItem, PlaceholderOutlierListItem } from './OutlierListItem';

export default function OutlierList(): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const isEditor = useIsEditor();
  const { isError, isFetching, data, isLoading } = useGetOutlierList();
  const { data: alertRules, isLoading: isAlertsLoading } = useAlertRuleInstances();
  const supportedDatasources = useSupportedDatasources();
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [searchFilters, setSearchFilters] = useState<Filter[]>([]);
  const [pageSize, setPageSize] = useQueryParam('pageSize', NumberParam);

  if (isError) {
    return (
      <PluginPage>
        <Alert title="Failed loading">Could not load outliers.</Alert>
      </PluginPage>
    );
  }

  if (isLoading || isAlertsLoading) {
    return (
      <PluginPage>
        <Spinner />
      </PluginPage>
    );
  }

  const alertRulesByJob = (alertRules ?? []).reduce((rulesByOutlierId, rule) => {
    const outlierId = rule.annotations?.['grafana-ml-outlier-detector-id'];
    if (outlierId !== undefined) {
      const rules = rulesByOutlierId.get(outlierId) ?? [];
      rules.push(rule);
      rulesByOutlierId.set(outlierId, rules);
    }
    return rulesByOutlierId;
  }, new Map<string, RuleInstance[]>());

  const datasources = Object.fromEntries(
    supportedDatasources.map((ds: DataSourceInstanceSettings) => [ds.id, ds]) ?? []
  );

  const getDatasourceName = (item: Outlier): string =>
    item.datasourceId != null ? datasources[item.datasourceId]?.name ?? 'unknown' : 'unknown';

  const filter = (_dataToFilter: Outlier[]) => {
    if (searchFilters.length === 0) {
      return data;
    }

    return data?.filter((item) => {
      return searchFilters.every((filter) => {
        if (filter.type === 'match') {
          return item.name.toLowerCase().includes(filter.value.toLowerCase());
        }

        return true;
      });
    });
  };

  const filteredData = data !== undefined ? filter(data) : data;
  const totalItems = filteredData?.length ?? 0;
  const currentPage = page ?? 1;
  const itemsPerPage = pageSize ?? 10;
  const pagedData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) ?? [];

  return (
    <PluginPage>
      <main className={styles.viewContainer}>
        <div className={styles.headerBar}>
          <div className={styles.controls}>
            <SearchBar
              filters={[]}
              onFilter={(filters) => {
                setSearchFilters(filters);
              }}
            />
          </div>
          <HorizontalGroup justify="flex-end">
            {isEditor ? (
              <div className={styles.rightAlign}>
                <LinkButton
                  className={styles.buttonDrop}
                  size="md"
                  icon="plus"
                  disabled={!isEditor}
                  variant="primary"
                  href={isEditor ? `${PLUGIN_ROOT}/outlier-detector/create` : ''}
                >
                  Outlier detector
                </LinkButton>
              </div>
            ) : null}
          </HorizontalGroup>
        </div>

        {isLoading ? (
          <>
            <Spinner
              className={styles.bgLoadingSpinner}
              style={{
                opacity: isFetching || isLoading ? '0.7' : '0',
              }}
            />
            <PlaceholderOutlierListItem />
          </>
        ) : data == null || data?.length === 0 ? (
          <Card height="elevated">
            <div className={styles.noForecastBox}>
              There are no outlier detectors setup. To set up a new outlier detector, click the{' '}
              <strong>Create an outlier detector</strong> button in the upper right corner.
            </div>
          </Card>
        ) : (
          pagedData.map((item) => (
            <div key={`outlieritem${item.id}`} className={styles.jobItem}>
              <OutlierListItem
                key={item.id}
                item={item}
                datasourceName={getDatasourceName(item)}
                alertingEnabled={config.unifiedAlertingEnabled}
                alertStateCounts={getAlertStateCounts(alertRulesByJob.get(item.id) ?? [])}
              />
            </div>
          ))
        )}
        {totalItems > 0 ? (
          <Paging
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            onItemsPerPageChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
          />
        ) : null}
      </main>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    viewContainer: css``,
    gradientBox: css`
      z-index: ${theme.zIndex.navbarFixed};
      top: 0;
      bottom: 0;
      right: 0;
      left: 60px;
      display: flex;
      flex-direction: column;
      margin: 20px;
    `,
    blueLink: css`
      color: ${theme.colors.text.link};
    `,
    infoCard: css`
      padding: 18px;
      margin-right: 16px;
      margin-bottom: 16px;
    `,
    infoLayout: css`
      display: flex;
      padding: 16px;
    `,
    bgLoadingSpinner: css`
      width: 32px;
      height: 32px;
      transition: 0.15s opacity ease-in-out;
    `,
    buttonDrop: css`
      filter: drop-shadow(rgba(19, 2, 43, 0.15) 2px 4px 6px);
      margin: 0px 0px 0px 8px;
      width: 170px;
    `,
    rightAlign: css`
      margin-left: auto;
    `,
    headerBar: css`
      margin-bottom: ${theme.spacing(1)};
      width: 100%;
      display: flex;
    `,
    noForecastBox: css`
      padding: 18px;
      border: ;
    `,
    jobItem: css`
      margin-top: ${theme.spacing(1)};
    `,
    controls: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 20px 0px;
      gap: 4px;
    `,
  };
};
