import React, { ReactElement, useCallback, useState } from 'react';

import { dateMath, GrafanaTheme2, rangeUtil, TimeOption, TimeRange } from '@grafana/data';
import { TimeZone } from '@grafana/schema';
import { Dropdown, HorizontalGroup, ToolbarButton, ToolbarButtonRow, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { orange } from './colors';
import { TimeOptionMenu } from './TimeOptionMenu';
import { TimeRangeTooltip } from './TimeRangeTooltip';

type Props = {
  timeRange: TimeRange;
  timeZone: TimeZone;
  options: TimeOption[];
  onChangeTimeRange: (timeRange: TimeRange) => void;
};

export function FutureTimeRangePicker(props: Props): ReactElement {
  const { timeRange, timeZone, options, onChangeTimeRange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles2(getStyles);
  const onChangeOption = useCallback(
    (option: TimeOption) => onChangeTimeRange(rangeUtil.convertRawToRange(option, timeZone)),
    [onChangeTimeRange, timeZone]
  );

  return (
    <HorizontalGroup justify="flex-end" spacing="none">
      <ToolbarButtonRow>
        <Dropdown
          offset={[0, 2]}
          placement="bottom-end"
          onVisibleChange={setIsOpen}
          overlay={<TimeOptionMenu options={options} onChange={onChangeOption} />}
        >
          <Tooltip content={<TimeRangeTooltip timeRange={timeRange} timeZone={timeZone} />}>
            <ToolbarButton className={styles.button} icon="clock-nine" isOpen={isOpen}>
              <span className={styles.container}>
                <span>{formattedRange(options, timeRange, timeZone)}</span>
                <span className={styles.utc}>{rangeUtil.describeTimeRangeAbbreviation(timeRange, timeZone)}</span>
              </span>
            </ToolbarButton>
          </Tooltip>
        </Dropdown>
      </ToolbarButtonRow>
    </HorizontalGroup>
  );
}

function formattedRange(options: TimeOption[], timeRange: TimeRange, timeZone?: TimeZone) {
  const option = options.find((o) => o.from === timeRange.raw.from && o.to === timeRange.raw.to);

  if (option) {
    return option.display;
  }

  const adjustedTimeRange = {
    to: dateMath.isMathString(timeRange.raw.to) ? timeRange.raw.to : timeRange.to,
    from: dateMath.isMathString(timeRange.raw.from) ? timeRange.raw.from : timeRange.from,
  };

  return rangeUtil.describeTimeRange(adjustedTimeRange, timeZone);
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    button: css({
      border: `1px solid ${theme.colors.border.weak}`,
    }),
    container: css({
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    }),
    utc: css({
      color: orange,
      fontSize: theme.typography.bodySmall.fontSize,
      paddingLeft: '6px',
      lineHeight: '28px',
      verticalAlign: 'bottom',
      fontWeight: theme.typography.fontWeightMedium,
    }),
  };
};
