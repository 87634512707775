import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';

import { EventInput } from 'types';

import './month-view-calendar.css';

interface MonthViewCalendarProps {
  formattedHolidays?: EventInput[];
}

export const MonthViewCalendar: FC<MonthViewCalendarProps> = ({ formattedHolidays }) => {
  const { container } = useStyles2(getStyles);

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      headerToolbar={{
        left: 'prev today next',
        center: 'title',
        right: '',
      }}
      initialView="dayGridMonth"
      selectMirror={true}
      dayMaxEvents={true}
      initialEvents={formattedHolidays}
      displayEventTime={false}
      initialDate={new Date()}
      viewClassNames={container}
      showNonCurrentDates
    />
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      background-color: ${theme.colors.background.canvas};
    `,
  };
}
