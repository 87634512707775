import React from 'react';

import { GrafanaTheme, Labels, PanelData, SelectableValue } from '@grafana/data';
import { MultiSelect, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

import { getSeriesSelector } from 'utils/utils.jobs';

interface Props {
  jobName: string;
  data?: PanelData;
  options: Array<SelectableValue<number>>;
  value: number[];
  selectSeries: (series: number[]) => void;
}

/**
 * Get the label for a select option in Prometheus format.
 *
 * This formats a series using the format:
 *
 *   `jobName{prometheusSelectors}`
 *
 * e.g.
 *
 *   go_goroutines{job="prometheus"}
 *   go_goroutines{job="prometheus", instance="demo.robustperception.io:9090"}
 *
 */
const getSelectLabel = (jobName: string, seriesLabels: Labels): string => {
  const selector = getSeriesSelector(seriesLabels);
  return `${jobName}${selector}`;
};

export const ViewJobSeriesSelect: React.FC<Props> = ({ jobName, data, options, value, selectSeries }) => {
  const styles = useStyles(getStyles);
  const hasData = (data?.series?.length ?? 0) !== 0;
  const enabled = options.length > 1;
  const seriesOptions = options.map((s, i) => ({ label: getSelectLabel(jobName, s), value: i }));

  const placeholder = hasData && options.length > 1 ? 'Choose a series' : '';

  return (
    <div className={styles.seriesSelector}>
      <MultiSelect
        disabled={!enabled}
        options={seriesOptions}
        value={value}
        onChange={(s) => selectSeries(s.filter((x) => x.value !== undefined).map((x) => x.value!))}
        placeholder={placeholder}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  seriesSelector: css`
    display: flex;
    padding: ${theme.spacing.md} 0;
    justify-content: space-between;
  `,
});
