import React, { useCallback, useEffect, useRef } from 'react';

import { DataSourceInstanceSettings, TimeRange } from '@grafana/data';
import { DataSourcePicker } from '@grafana/runtime';
import { Icon, InlineField } from '@grafana/ui';

import { css } from '@emotion/css';
import { StringParam, useQueryParam } from 'use-query-params';

import { QueryEditor } from 'components/QueryEditor';
import { useQueryData } from 'components/QueryRunner';
import { RowField } from 'components/RowField';
import { useLastSelectedStorage } from 'hooks';
import { useQueryContext } from 'hooks/useQueryContext';
import { supportedDatasource } from 'hooks/useSupportedDatasources';
import { DataQueryWithExpression } from 'types';

import { InfluxQueryEditor } from './InfluxQueryEditor';

export interface DatasourceQueryEditorProps {
  datasource?: DataSourceInstanceSettings;
  onUpdateDatasource: (ds: DataSourceInstanceSettings) => void;
  onRunQuery: (query: DataQueryWithExpression) => void;
  onQueryChange?: (query: DataQueryWithExpression) => void;
  timeRange: TimeRange;
}

// this is neccessary to prevent the query editor from running with a prior value
let referenceQuery: DataQueryWithExpression | undefined = undefined;

export function DatasourceQueryEditor({
  datasource: datasourceSettings,
  onUpdateDatasource,
  onRunQuery,
  onQueryChange,
  timeRange,
}: DatasourceQueryEditorProps): JSX.Element {
  const [qsDatasourceUid, setQsDatasourceUid] = useQueryParam('ds', StringParam);
  const [, setLastSelected] = useLastSelectedStorage();
  const { queryParams, updateQuery, clearQuery } = useQueryContext();

  // we do not want to update url state or execute query until user clicks run query
  // so we hold temporary state in localQuery. Using a ref is synchronous, unlike a state.
  const localQuery = useRef<DataQueryWithExpression | undefined>(queryParams);
  referenceQuery = localQuery.current;
  // using the above ref however means that switching between Builder & Code views does not
  // cause a rerender, since there's no change in queryParams. Workaround with an empty state.
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const data = useQueryData();

  const handleChangeDatasource = useCallback(
    (ds: DataSourceInstanceSettings) => {
      setLastSelected(ds.uid);
      onUpdateDatasource(ds);
      setQsDatasourceUid(ds.uid);
      clearQuery();
    },
    [onUpdateDatasource, setLastSelected, setQsDatasourceUid, clearQuery]
  );

  useEffect(() => {
    if (datasourceSettings !== null && datasourceSettings !== undefined) {
      setQsDatasourceUid(datasourceSettings.uid);
    }
  }, [datasourceSettings, datasourceSettings?.uid, setQsDatasourceUid]);

  const onChange = (q: DataQueryWithExpression) => {
    if (onQueryChange !== undefined) {
      onQueryChange(q);
    }
    localQuery.current = q;
  };

  const runQuery = (q?: DataQueryWithExpression) => {
    const query = q ?? referenceQuery;
    if (query !== undefined) {
      onRunQuery(query);
    }
    updateQuery(query);
  };

  if (datasourceSettings == null) {
    return (
      <div className={styles.root}>
        <InlineField labelWidth={15} label="Datasource">
          <DataSourcePicker filter={supportedDatasource} onChange={handleChangeDatasource} />
        </InlineField>
        <div className={styles.notSupported}>
          <Icon className={styles.icon} size="lg" name="exclamation-triangle" /> Not supported
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <InlineField labelWidth={15} label="Datasource">
        <DataSourcePicker
          noDefault
          filter={supportedDatasource}
          current={qsDatasourceUid ?? datasourceSettings.uid}
          onChange={handleChangeDatasource}
        />
      </InlineField>

      <RowField>
        {datasourceSettings.type === 'influxdb' ? (
          // limit impact of influx db changes to a separate component
          <InfluxQueryEditor
            data={data}
            datasource={datasourceSettings}
            query={localQuery.current!}
            onRunQuery={(q) => {
              runQuery(q);
            }}
            onChange={onChange}
            timeRange={timeRange}
          />
        ) : (
          <>
            {localQuery === undefined ? null : (
              <QueryEditor
                data={data}
                name={datasourceSettings.name}
                timeRange={timeRange}
                query={localQuery.current!}
                onRunQuery={() => {
                  // wish this returned the query to run. Instead we are guaranteed that onChange was
                  // just called with the query about to be run.
                  runQuery(localQuery.current);
                }}
                onChange={(q) => {
                  if (onQueryChange !== undefined) {
                    onQueryChange(q);
                  }
                  localQuery.current = q;
                  forceUpdate();
                }}
              />
            )}
          </>
        )}
      </RowField>
    </div>
  );
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
  `,
  notSupported: css`
    display: flex;
    align-items: center;
  `,
  icon: css`
    color: orange;
    margin: -3px 5px 0 8px;
  `,
};
