import { config } from '@grafana/runtime';

import { adminApiPost } from './core.api';
import { ApiKey, createApiKey, deleteApiKey, getApiKeys } from './grafana.api';

const apiKeyName = 'grafana-ml';

async function checkKeyIsValid(key: ApiKey): Promise<boolean> {
  // Short-circuit if the key is not admin - we'll definitely need to recreate it.
  if (key.role !== 'Admin') {
    return false;
  }
  // Ask the backend to check whether the key works.
  // If anything goes wrong or the status isn't OK, return false.
  try {
    const response = await adminApiPost('/check-key', { data: { grafanaUrl: config.appUrl } });
    if (!response.ok) {
      return false;
    }
    return response.data.status === 200;
  } catch {}
  return false;
}

// Checks if an existing API key held by the plugin backend is valid, and creates a new one if not.
//
// This function returns a string containing the new key, or undefined if the existing key is already valid.
async function getOrCreateApiKey(): Promise<string | undefined> {
  const keys = await getApiKeys();

  let key;
  const oldKey = keys.find((k) => k.name === apiKeyName);
  if (oldKey !== undefined) {
    const isValid = await checkKeyIsValid(oldKey);
    if (isValid) {
      key = oldKey;
    } else {
      // Remove the old API key; it's no longer valid.
      await deleteApiKey(oldKey.id);
    }
  }
  if (key === undefined) {
    // Get a new admin API key that the plugin backend can use to create the datasource
    // and inject into jobs in future.
    key = await createApiKey(apiKeyName, 'Admin');
  }
  return key?.key;
}

export async function setupPlugin(): Promise<void> {
  const key = await getOrCreateApiKey();

  // Request that the plugin backend initializes the plugin.
  await adminApiPost('/setup', {
    data: {
      grafanaUrl: config.appUrl,
      apiKey: key,
    },
  });

  // Reload the page so the changes made here are propagated to the actual plugin.
  // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
  window.location.reload();
}

// Request that the plugin be disabled. This is handled by the plugin backend
// so that 1) we don't overwrite the plugin's secret settings, and 2) settings are
// propagated to grafana.com for Hosted Grafana instances.
export async function disablePlugin(): Promise<void> {
  // We need to check if the key is valid and create a new one if not, in case the
  // user has accidentally deleted the existing Grafana API key, which would stop
  // the backend being able to make the request to update the plugin's settings.

  const key = await getOrCreateApiKey();
  await adminApiPost('/disable', {
    data: {
      grafanaUrl: config.appUrl,
      apiKey: key,
    },
  });

  // Reload the page so the changes made here are propagated to the actual plugin.
  // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
  window.location.reload();
}
