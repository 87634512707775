import { DataFrame, FieldType } from '@grafana/data';

import { clone, slice } from 'lodash';

import { Band, DataFrameOutlierIntervals, OutlierResults } from 'api/types';

export interface DBScanData {
  rawData: number[][];
  timestamps: number[];
  sortedData: SortedData[];
}
export abstract class DBScan {
  /*
   * DBSCAN - a simplified version that is 1 dimensional, i.e. compares series values *at each timestamp*.
   * It is much faster than multi-dimensional dbscan, but less robust to noisy data.
   */
  static preprocess(alignedDataFrame: DataFrame): DBScanData {
    // Get data into most useful format here
    const rawData = [];
    let timestamps = [];
    for (const field of alignedDataFrame.fields) {
      if (field.type === FieldType.number) {
        rawData.push(field.values);
      } else if (field.type === FieldType.time) {
        timestamps = field.values;
      }
    }

    // By pre-sorting the datapoints at each timestamp, 1-dim
    const sortedData = Array(timestamps.length);
    for (let i = 0; i < timestamps.length; i++) {
      const dataWindow = rawData.map((field) => slice<number | undefined>(field, i, i + 1)).flat(); // slice is a copy :(
      sortedData[i] = sortWithIndicesDroppingNulls(dataWindow);
    }

    // Check sufficient datapoints to run DBSCAN
    let sufficientDataForOutliers = false; // if each timestamp has <= 2 points, outliers cannot work
    for (let i = 0; i < timestamps.length; i++) {
      if (sortedData[i].sorted.length > 2) {
        sufficientDataForOutliers = true;
        break;
      }
    }
    if (!sufficientDataForOutliers) {
      throw new Error('Not enough data: DBSCAN requires 3 or more data points at each timestamp to function');
    }

    return {
      rawData: rawData,
      timestamps: timestamps,
      sortedData: sortedData,
    };
  }

  static run(data: DBScanData, eps: number): OutlierResults {
    // console.time(`DBSCAN {eps}`);

    // moving window dbscan with window of size 1
    const timeStampCount = data.timestamps?.length ?? 0;
    const normalBand: Band = [Array(timeStampCount).fill(undefined), Array(timeStampCount).fill(undefined)];
    const outlierIntervals: DataFrameOutlierIntervals = {};
    let outliersSoFar: number[] = [];

    for (let i = 0; i < data.sortedData.length; i++) {
      const sortedTimeStampData = data.sortedData[i] as SortedData;

      // Apply 1d DBSCAN to the sorted values at this timestamp
      const results = doDBSCAN1d(sortedTimeStampData, eps);

      // Construct the normal band, if found.
      if (results.clusterMin !== undefined && results.clusterMax !== undefined) {
        normalBand[0][i] = results.clusterMin - eps / 2;
        normalBand[1][i] = results.clusterMax + eps / 2;
      }

      const outliers = results.outlierIndices;
      const ts = data.timestamps[i] as number;
      // For each series that has outliers, find the intervals where they are outliers.
      if (outliers.length > 0) {
        // Compare with outliersSoFar
        // What was in previous outliers, but now not
        const stoppedBeingOutlier = outliersSoFar.filter((x) => !outliers.includes(x));
        for (const stoppedIndex of stoppedBeingOutlier) {
          outlierIntervals[stoppedIndex]!.push(ts);
        }

        // What has started being outlier
        const startedBeingOutlier = outliers.filter((x) => !outliersSoFar.includes(x));
        for (const startedIndex of startedBeingOutlier) {
          if (startedIndex in outlierIntervals) {
            outlierIntervals[startedIndex]!.push(ts);
          } else {
            outlierIntervals[startedIndex] = [ts];
          }
        }

        outliersSoFar = clone(outliers);
      } else {
        // all series considered normal at this timestamp, so take all outliersSoFar entries,
        // mark them as stopped and empty it
        for (const stoppedIndex of outliersSoFar) {
          outlierIntervals[stoppedIndex]!.push(ts);
        }
        outliersSoFar = [];
      }
    }

    // console.timeEnd('DBSCAN {eps}');
    return {
      outlierIntervals: outlierIntervals,
      normalBand: normalBand,
    };
  }

  static getDataSpan(series: DataFrame[]): number | undefined {
    // find data span: difference between max & min datapoint in whole dataframe
    let max, min;

    for (const frame of series) {
      let values = frame.fields[1]?.values;
      if (values === undefined) {
        continue;
      }
      // Ensure to remove undefined, nulls and NaN
      values = values.filter(Number.isFinite);
      if (min === undefined) {
        min = Math.min(...values);
      } else {
        min = Math.min(min, Math.min(...values));
      }
      if (max === undefined) {
        max = Math.max(...values);
      } else {
        max = Math.max(max, Math.max(...values));
      }
    }

    if (min === undefined || max === undefined) {
      return undefined;
    }
    return Math.max(max - min, 0.1); // if the span = 0, nothing interesting in the data, so hard-code small span.
  }
}

// 1-dim DBScan implementation. Requires sorted list of datapoints to function, and identifies only
// a single cluster of the majority of the datapoints (minClusterSize = data.length // 2)
interface DBSCAN1dResults {
  clusterMin: number | undefined;
  clusterMax: number | undefined;
  outlierIndices: number[];
}

// Following impl inspired by https://github.com/d-chambers/dbscan1d
//
// Main idea: as the array is sorted, compare the distance between each neighbour. If
// distance less than epsilon, is candidate for cluster. Try next neighbour to see if
// it close enough to join cluster. If cluster size grows to half of all points, that
// is the main/final cluster. If not, the points are outliers.
function doDBSCAN1d(sortedData: SortedData, eps: number): DBSCAN1dResults {
  const sorted = sortedData.sorted;
  const sortIndices = sortedData.sortIndices;

  // if <=2 series, can return quickly as no anomaly
  if (sorted.length <= 2) {
    return { clusterMin: undefined, clusterMax: undefined, outlierIndices: [] };
  }

  // Below DBSCAN impl relies on the fact we mandate the cluster contains at least 50% of all values
  const minClusterSize = Math.floor(sorted.length / 2) + 1;

  let thisClusterBottom;
  let thisClusterTop;
  let thisClusterSize = 1;
  let inCluster = false;
  let finalClusterFound = false;
  let clusterMin: number | undefined, clusterMax: number | undefined;
  let outlierIndices: number[] = [];

  for (let i = 0; i < sorted.length - 1; i++) {
    if (Math.abs(sorted[i + 1]! - sorted[i]!) <= eps) {
      if (!inCluster) {
        thisClusterBottom = sorted[i];
      }
      inCluster = true;
      thisClusterTop = sorted[i + 1];
      thisClusterSize += 1;

      if (thisClusterSize >= minClusterSize) {
        finalClusterFound = true;
        clusterMin = thisClusterBottom;
        clusterMax = thisClusterTop;
      }
    } else {
      if (inCluster) {
        thisClusterTop = sorted[i];
      }
      thisClusterSize = 1;
      inCluster = false;
    }
  }

  if (finalClusterFound) {
    for (let i = 0; i < sorted.length; i++) {
      const val = sorted[i] as number;
      const originalIndex = sortIndices[i] as number;
      if (val < clusterMin! || val > clusterMax!) {
        outlierIndices.push(originalIndex);
      }
    }
  } else {
    // everything is an outlier
    outlierIndices = sortIndices;
  }

  return {
    clusterMin: clusterMin,
    clusterMax: clusterMax,
    outlierIndices: outlierIndices,
  };
}

// Utilities to sort a dataset while keeping track of the original indices
interface SortedData {
  sorted: number[];
  sortIndices: number[];
}

// Take in an array, return `SortedData` which comprises of the sorted array with nulls, NaNs
// and undefineds removed, plus an array of the original element locations in the input array.
function sortWithIndicesDroppingNulls(data: Array<number | undefined>): SortedData {
  type Tuple = [number, number];

  const toSort: Tuple[] = [];
  for (let i = 0; i < data.length; i++) {
    const val = data[i];
    if (Number.isFinite(val)) {
      toSort.push([val!, i]);
    }
  }
  toSort.sort(function (left, right) {
    return left[0] < right[0] ? -1 : 1;
  });

  const sortIndices = Array(toSort.length);
  const sorted = Array(toSort.length);
  for (let j = 0; j < toSort.length; j++) {
    const valAndPos: Tuple = toSort[j]!;
    sortIndices[j] = valAndPos[1];
    sorted[j] = valAndPos[0];
  }

  return {
    sorted: sorted,
    sortIndices: sortIndices,
  };
}
