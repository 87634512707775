import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, LinkButton, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import AppHeader from 'components/AppHeader';
import Card from 'components/Card';
import { TenantInfo } from 'types';

import GettingStartedCard from '../Home/GettingStartedCard';
import MlHowItWorks from '../Home/MlHowItWorks';
import Features from './Features';

export default function FreeUser({ orgInfo }: { orgInfo: TenantInfo }): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <main className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <AppHeader intro />
        <div className={styles.alertContainer}>
          <Alert title={`The plan for ${orgInfo.name} does not include Grafana ML.`} />
        </div>
        <div className={styles.upgradeContainer}>
          <div className={styles.upgradeBox}>
            <LinkButton size="lg" href="https://grafana.com/products/cloud/pricing/">
              Upgrade your Grafana Cloud subscription.
            </LinkButton>
            <a className={styles.blueLink} href="https://grafana.com/docs/grafana-cloud/machine-learning">
              Read more in the Machine Learning Docs ❯
            </a>
          </div>
        </div>
        <main className={cx('page-container', styles.grid)}>
          <Card id="features" variant="gradient">
            <Features enableActions={false} />
          </Card>
          <div id="billing">
            <Card className={styles.action}>
              <MlHowItWorks />
            </Card>
          </div>
          <div id="aside">
            <Card className={styles.action}>
              <a target="_blank" href="/public/plugins/grafana-ml-app/assets/screenshot.png">
                <img src="/public/plugins/grafana-ml-app/assets/screenshot.png" width="100%" />
              </a>
            </Card>
          </div>
          <div id="getting-started">
            <GettingStartedCard />
          </div>
        </main>
      </div>
    </main>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    grid: css`
      display: grid;
      margin: 12px;
      grid-gap: 24px;
      grid-template-areas: 'howto' 'billing' 'aside' 'gettingstarted';
      @media only screen and (min-width: 890px) {
        grid-template-areas: 'howto howto' 'billing aside' 'gettingstarted aside';
        grid-template-columns: 2fr 3fr;
      }
      #features {
        grid-area: howto;
      }
      #billing {
        grid-area: billing;
      }
      #aside {
        grid-area: aside;
      }
      #getting-started {
        grid-area: gettingstarted;
      }
    `,
    blueLink: css`
      color: ${theme.colors.text.link};
    `,
    alertContainer: css`
      max-width: 1436px;
      padding: 0px 16px;
      margin: 0px auto;
    `,
    upgradeContainer: css`
      max-width: 1436px;
      padding: 20px 16px;
      margin: 0px auto;
      background-color: ${theme.colors.background.canvas};
    `,
    upgradeBox: css`
      display: flex;
      flex-direction: column;
      max-width: 400px;
      margin: auto;
      text-align: center;
    `,
    action: css`
      padding: 18px;
    `,
    outerContainer: css`
      min-height: calc(100vh - 80px);
    `,
    innerContainer: css`
      margin-top: 28px;
      > div.page-body {
        min-height: calc(100vh - 250px);
      }
    `,
  };
}
