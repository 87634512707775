import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import {
  CheckConfig,
  CheckConfigWithDatasource,
  CheckFormProps,
  HTTPErrorSeriesConfig,
  HTTPErrorSeriesConfigSchema,
} from 'types';
import { getAnalysisDisplayTitle, parseInputToFloat, parseInputToInt } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

function HttpErrorSeriesForm({
  check,
  defaultConfig,
  onClose,
}: CheckFormProps<HTTPErrorSeriesConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { prometheusFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<HTTPErrorSeriesConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  // The default values for the form.
  const defaultValues: CheckConfigWithDatasource<HTTPErrorSeriesConfig> = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
    autoDetectDatasource: (check.config.prometheusDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData = {
          id: check.id ?? undefined,
          name: check.name,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            prometheusDatasourceUid: autoDetectDatasource ? '' : config.prometheusDatasourceUid,
          },
          title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, errors, formState, setValue, watch }) => {
        const prometheusDatasourceUid = watch('config.prometheusDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} placeholder={check.name} />
            </Field>

            <Field
              label={
                <LabelWithHelpText label="Cut off time (minutes)" checkName="httpErrorSeries" fieldName="cutoffTime" />
              }
              invalid={!!errors.config?.cutOffTimeMinutes}
              error="must be between 20 and 120"
            >
              <Input
                type="number"
                {...register('config.cutOffTimeMinutes', {
                  min: Number(HTTPErrorSeriesConfigSchema.shape.cutOffTimeMinutes.minValue),
                  max: Number(HTTPErrorSeriesConfigSchema.shape.cutOffTimeMinutes.maxValue),
                  setValueAs: parseInputToInt,
                })}
                placeholder={(defaultConfig.cutOffTimeMinutes ?? '').toString()}
              />
            </Field>

            <Field
              label={
                <LabelWithHelpText
                  label="Percentage Threshold"
                  checkName="httpErrorSeries"
                  fieldName="percentageThreshold"
                />
              }
              invalid={!!errors.config?.percentageThreshold}
              error="must be above 50"
            >
              <Input
                type="number"
                step="0.1"
                {...register('config.percentageThreshold', {
                  min: Number(HTTPErrorSeriesConfigSchema.shape.percentageThreshold.minValue),
                  setValueAs: parseInputToFloat,
                })}
                placeholder={(defaultConfig.percentageThreshold ?? '').toString()}
              />
            </Field>

            <DataSourceSelector
              title="Prometheus Datasource"
              dsType="prometheus"
              dsUid={prometheusDatasourceUid ?? ''}
              autoDetectDatasource={autoDetectDatasource}
              filter={prometheusFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.prometheusDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { HttpErrorSeriesForm };
