import React from 'react';

import { Alert, TextLink } from '@grafana/ui';

interface Props {
  onRemove: () => void;
}

// Define a shared local storage key so that the help message can be hidden
// everywhere based on the user's previous preferences.
export const helpLocalStorageKey = 'grafana.ml.sift.newInvestigation.showHelp';

export default function HelpMessage({ onRemove }: Props): React.ReactElement {
  return (
    <Alert title="Sift investigations" severity="info" onRemove={onRemove}>
      <div>Investigations help automate the process of diagnosing and resolving incidents.</div>
      <div>
        When you create an investigation Sift will run a series of checks using your Grafana datasources, then surface
        them as part of the investigation results.
      </div>
      <div>
        <TextLink external href="https://grafana.com/docs/grafana-cloud/alerting-and-irm/machine-learning/sift/">
          Visit the docs to find out more.
        </TextLink>
      </div>
    </Alert>
  );
}
