import React, { FC } from 'react';
import { Range } from 'react-date-range';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { DateRange } from '@iroomit/react-date-range';

import './quarter-view-calendar.css';

interface QuarterViewCalendarProps {
  formattedHolidays: Range[] | undefined;
  numberOfMonths: number;
}

export const QuarterViewCalendar: FC<QuarterViewCalendarProps> = ({ formattedHolidays, numberOfMonths }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.calendarContainer}>
      <DateRange
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        showPreview={false}
        // @ts-ignore - Range[] is a subtype of the internal "DateRange" type of the "DateRange" component
        ranges={formattedHolidays}
        months={numberOfMonths}
        direction="horizontal"
        onChange={() => {}}
        className={styles.container}
        rangeColors={['#3D71D9']}
        color="#3D71D9"
      />
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    calendarContainer: css`
      border: solid 1px rgba(204, 204, 220, 0.3);
      margin-top: 29px;
    `,
    container: css`
      background-color: ${theme.colors.background.canvas};
    `,
  };
}
