import React from 'react';

import { HorizontalGroup, IconButton, Label } from '@grafana/ui';

interface Props {
  label: string | React.ReactNode;
  tooltip: string;
  value?: string;
  setValue: () => void;
}

export function LabelWithExample({ label, tooltip, value, setValue }: Props): React.ReactElement {
  return (
    <Label>
      <HorizontalGroup>
        {label}
        <IconButton
          onClick={() => setValue()}
          disabled={value !== undefined && value !== ''}
          name="copy"
          tooltip={tooltip}
          aria-label={tooltip}
          size="sm"
        />
      </HorizontalGroup>
    </Label>
  );
}
