import React from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { FieldSet, InlineField, InlineSwitch, VerticalGroup } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { InlineEnumField } from 'components/FormFields/InlineEnumField';
import { FullFormModel } from 'types';

interface SarimaHyperparametersFormProps {
  control: Control<FullFormModel>;
  errors: FieldErrors<FullFormModel> | undefined;
  register: UseFormRegister<FullFormModel>;
}

export function SarimaHyperparametersForm({
  control,
  errors,
  register,
}: SarimaHyperparametersFormProps): JSX.Element | null {
  return (
    <FieldSet>
      <aside className={cx('grafana-info-box span8', asideStyles)}>
        Seasonal Autoregressive Integrated Moving Average, or SARIMA, model is suitable for modeling time series data
        that may contain trend and seasonal components. Ordinarily it exposes many hyperparameters that need configuring
        to get good model performance, This implementation internally performs a hyperparameter search to get best fit.
      </aside>

      <InlineEnumField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_sarima_1_8_0?.information_criterion}
        name="parameters.hyperparameters.grafana_sarima_1_8_0.information_criterion"
        label="Information Criterion"
        tooltip="The information criterion used to select the best ARIMA model."
        options={[
          {
            label: 'AIC',
            value: 'aic',
          },
          {
            label: 'BIC',
            value: 'bic',
          },
          {
            label: 'HQIC',
            value: 'hqic',
          },
          {
            label: 'OOB',
            value: 'oob',
          },
        ]}
        defaultValue={'aic'}
      />

      <InlineField
        grow
        labelWidth={35}
        label="Enable Seasonality"
        tooltip="Whether to fit a seasonal ARIMA. Disable if you know your data has no seasonal impacts."
      >
        <VerticalGroup>
          <InlineSwitch {...register('parameters.hyperparameters.grafana_sarima_1_8_0.seasonal')} type="checkbox" />
        </VerticalGroup>
      </InlineField>
    </FieldSet>
  );
}

const asideStyles = css`
  margin: 0 0 25px 0;
`;
