import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

import { PluginMeta } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

import { getApiBaseUrl } from 'api/core.api';
import { MlAppSettings } from 'types';

let cache: PluginMeta<MlAppSettings> | undefined;

// We need to fetch the app settings because the modal
// displayed by the ML app is rendered outside of the
// PluginMetaContext.
export function usePluginMeta(): AsyncState<PluginMeta<MlAppSettings>> {
  return useAsync(async () => {
    if (cache) {
      return cache;
    }

    const url = getApiBaseUrl('settings');
    const meta = await getBackendSrv().get<PluginMeta<MlAppSettings>>(url);
    cache = meta;
    return meta;
  }, []);
}
