// Code generated by mlapi/cmd/gen-sift-schemas/main.go. Do not modify!
import { z } from 'zod';

export const PrometheusConfigSchema = z.object({
  prometheusDatasourceUid: z.string().optional(),
});
export type PrometheusConfig = z.infer<typeof PrometheusConfigSchema>;

export const topologyConfigSchema = z.object({
  edges: z.string().array().length(2).array().optional(),
});
export type topologyConfig = z.infer<typeof topologyConfigSchema>;

export const labelPermuterConfigSchema = z.object({
  keepLabels: z.string().array().optional(),
  ignoreLabels: z.string().array().optional(),
  labelAliases: z.string().array().array().optional(),
  maxRemovedLabels: z.number().optional(),
});
export type labelPermuterConfig = z.infer<typeof labelPermuterConfigSchema>;

export const kubernetesAggregatorConfigSchema = z.object({
  removeLabels: z.string().array().optional(),
});
export type kubernetesAggregatorConfig = z.infer<typeof kubernetesAggregatorConfigSchema>;

export const typeAugmentorConfigSchema = z.object({
  range: z.number().gte(60).lte(3600),
});
export type typeAugmentorConfig = z.infer<typeof typeAugmentorConfigSchema>;

export const correlatedSeriesConfigSchema = z
  .object({
    topology: topologyConfigSchema,
    labelPermuter: labelPermuterConfigSchema,
    kubernetesAggregator: kubernetesAggregatorConfigSchema,
    typeAugmentor: typeAugmentorConfigSchema,
    limit: z.number().gte(1).lte(20),
    lookBehindFactor: z.number().gte(0.0).lte(3.0),
    timeoutSeconds: z.number().gte(10.0).lte(120.0),
  })
  .merge(PrometheusConfigSchema);
export type correlatedSeriesConfig = z.infer<typeof correlatedSeriesConfigSchema>;

export const LokiConfigSchema = z.object({
  lokiDatasourceUid: z.string().optional(),
});
export type LokiConfig = z.infer<typeof LokiConfigSchema>;

export const ErrorPatternLogsConfigSchema = z
  .object({
    maxExamples: z.number().gte(1).lte(10),
    minCount: z.number().gte(1).lte(10),
    initialQuery: z.string(),
  })
  .merge(LokiConfigSchema);
export type ErrorPatternLogsConfig = z.infer<typeof ErrorPatternLogsConfigSchema>;

export const TempoConfigSchema = z.object({
  tempoDatasourceUid: z.string().optional(),
});
export type TempoConfig = z.infer<typeof TempoConfigSchema>;

export const ErrorSpansConfigSchema = z.object({}).merge(TempoConfigSchema);
export type ErrorSpansConfig = z.infer<typeof ErrorSpansConfigSchema>;

export const HTTPErrorSeriesConfigSchema = z
  .object({
    cutOffTimeMinutes: z.number().gte(20).lte(120),
    percentageThreshold: z.number().gte(50),
  })
  .merge(PrometheusConfigSchema);
export type HTTPErrorSeriesConfig = z.infer<typeof HTTPErrorSeriesConfigSchema>;

export const PyroscopeConfigSchema = z.object({
  pyroscopeDatasourceUid: z.string().optional(),
});
export type PyroscopeConfig = z.infer<typeof PyroscopeConfigSchema>;

export const KubeCrashesConfigSchema = z
  .object({})
  .merge(PrometheusConfigSchema)
  .merge(LokiConfigSchema)
  .merge(PyroscopeConfigSchema);
export type KubeCrashesConfig = z.infer<typeof KubeCrashesConfigSchema>;

export const LogQueryConfigSchema = z
  .object({
    expr: z.string(),
    message: z.string(),
    maxLogLines: z.number(),
  })
  .merge(LokiConfigSchema);
export type LogQueryConfig = z.infer<typeof LogQueryConfigSchema>;

export const MetricQueryConfigSchema = z
  .object({
    expr: z.string(),
    message: z.string(),
  })
  .merge(PrometheusConfigSchema);
export type MetricQueryConfig = z.infer<typeof MetricQueryConfigSchema>;

export const NoisyNeighborsConfigSchema = z
  .object({
    loadThreshold: z.number().gte(0.3).lte(1.0),
    usageQuantile: z.number().gte(0.5).lte(0.99),
  })
  .merge(PrometheusConfigSchema);
export type NoisyNeighborsConfig = z.infer<typeof NoisyNeighborsConfigSchema>;

export const RecentDeploymentsConfigSchema = z.object({}).merge(PrometheusConfigSchema);
export type RecentDeploymentsConfig = z.infer<typeof RecentDeploymentsConfigSchema>;

export const RelatedAlertsConfigSchema = z.object({}).merge(PrometheusConfigSchema);
export type RelatedAlertsConfig = z.infer<typeof RelatedAlertsConfigSchema>;

export const ResourceContentionsConfigSchema = z.object({}).merge(PrometheusConfigSchema).merge(PyroscopeConfigSchema);
export type ResourceContentionsConfig = z.infer<typeof ResourceContentionsConfigSchema>;

export const SLEConfigSchema = z.object({});
export type SLEConfig = z.infer<typeof SLEConfigSchema>;

export const SlowRequestsConfigSchema = z
  .object({
    threshold: z.string(),
  })
  .merge(TempoConfigSchema);
export type SlowRequestsConfig = z.infer<typeof SlowRequestsConfigSchema>;

export const StatusPageOutagesConfigSchema = z.object({
  cloudProviders: z.string().array().nullable(),
});
export type StatusPageOutagesConfig = z.infer<typeof StatusPageOutagesConfigSchema>;
