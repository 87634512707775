import React from 'react';
import { ToggleFeatures } from 'react-enable';
import { QueryClient } from 'react-query';
import { Navigate, Route, Routes } from 'react-router-dom';

import { IS_DEV, PLUGIN_ROOT } from 'consts';
import { useIsEditor } from 'hooks';
import CreateJob from 'projects/Forecasting/Create';
import EditJob from 'projects/Forecasting/Edit';
import { ForecastingTabs } from 'projects/Forecasting/Tabs/ForecastingTabs';
import ViewJob from 'projects/Forecasting/View';
import Home from 'projects/Home';
import CreateOutlier from 'projects/Outliers/Create';
import OutlierList from 'projects/Outliers/List/OutlierList';
import ViewOutlier from 'projects/Outliers/View';
import { InvestigationTabs } from 'projects/Sift/Tabs/InvestigationTabs';
import ViewInvestigation from 'projects/Sift/View';

export const queryClient = new QueryClient();

export function AppContent({ tenantData }: { tenantData: any }): JSX.Element {
  const isEditor = useIsEditor();

  return (
    <>
      <Routes>
        <Route path={`home`} element={<Home />} />

        <Route path={`metric-forecast`} element={<ForecastingTabs view={'list'} />} />
        <Route path={`metric-forecast/holiday`} element={<ForecastingTabs view={'holiday'} />} />
        <Route path={`metric-forecast/holiday/create`} element={<ForecastingTabs view={'holiday-create'} />} />
        <Route path={`metric-forecast/holiday/:id/edit`} element={<ForecastingTabs view={'holiday-edit'} />} />
        <Route path={`metric-forecast/holiday/:id`} element={<ForecastingTabs view={'holiday-details'} />} />

        <Route path={`metric-forecast/create`} element={<CreateJob tenantInfo={tenantData} />} />
        <Route path={`metric-forecast/:id/edit`} element={<EditJob tenantInfo={tenantData} />} />
        <Route path={`metric-forecast/:id`} element={<ViewJob />} />

        {/* keeping these holiday routesfor backwards compatability */}
        <Route path={`holiday`} element={<ForecastingTabs view={'holiday'} />} />
        <Route path={`holiday/create`} element={<ForecastingTabs view={'holiday-create'} />} />
        <Route path={`holiday/:id/edit`} element={<ForecastingTabs view={'holiday-edit'} />} />
        <Route path={`holiday/:id`} element={<ForecastingTabs view={'holiday-details'} />} />

        <Route path={`outlier-detector`} element={<OutlierList />} />
        <Route path={`outlier-detector/create`} element={<CreateOutlier />} />
        <Route path={`outlier-detector/:id`} element={<ViewOutlier />} />

        <Route path={`investigations`} element={<InvestigationTabs view="list" />} />
        <Route
          path={`investigations/configuration`}
          element={<InvestigationTabs view={isEditor ? 'config' : 'list'} />}
        />
        <Route path={`investigations/create`} element={<InvestigationTabs view={isEditor ? 'create' : 'list'} />} />
        <Route path={`investigations/:id`} element={<ViewInvestigation />} />

        <Route path={`*`} element={<Navigate to={`${PLUGIN_ROOT}/home`} />} />
      </Routes>
      {IS_DEV ? <ToggleFeatures /> : null}
    </>
  );
}
