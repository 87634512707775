import { useMemo } from 'react';

import { Analysis } from 'types';

export const useFilteredAnalysesByStatus = (
  analyses: Analysis[],
  namesToInclude: string[]
): {
  defaultAnalysis: string;
  interesting: Analysis[];
  passed: Analysis[];
  failed: Analysis[];
  running: Analysis[];
  skipped: Analysis[];
} =>
  useMemo(() => {
    const filteredAnalyses = analyses
      .filter((a) => namesToInclude.includes(a.name))
      .sort((a, b) => {
        const isInterestingA = Boolean(a.result?.interesting);
        const isInterestingB = Boolean(b.result?.interesting);
        const failedResultA = a.result === undefined || !a.result.successful;
        const failedResultB = a.result === undefined || !b.result.successful;
        const AOrder = failedResultA ? 2 : isInterestingA ? 3 : 1;
        const BOrder = failedResultB ? 2 : isInterestingB ? 3 : 1;

        if (AOrder > BOrder) {
          return -1;
        } else if (AOrder < BOrder) {
          return 1;
        }

        return 0;
      });

    const defaultAnalysis = filteredAnalyses[0]?.id ?? '';
    const interesting = filteredAnalyses.filter((a) => a.status !== 'running' && a.result?.interesting);
    const passed = filteredAnalyses.filter(
      (a) => a.status !== 'running' && a.result?.successful && !a.result?.interesting
    );
    const failed = filteredAnalyses.filter(
      (a) => a.status !== 'running' && a.status !== 'skipped' && !a.result?.successful
    );
    const running = filteredAnalyses.filter((a) => a.status === 'running');
    const skipped = filteredAnalyses.filter((a) => a.status === 'skipped');

    return {
      defaultAnalysis,
      interesting,
      passed,
      failed,
      running,
      skipped,
    };
  }, [analyses, namesToInclude]);
