import React, { useState } from 'react';

import { Button, ButtonVariant } from '@grafana/ui';

import useRudderStack, { RudderstackEvents } from 'hooks/useRudderstack';
import { RunSiftModal } from 'projects/Sift/RunSiftModal';
import {
  DataSourceInput,
  Investigation,
  InvestigationFormModel,
  InvestigationSourceType,
  LabelInput,
  MetricQueryInput,
  ServiceInput,
} from 'types';

interface Props {
  investigation?: Investigation;
  variant?: ButtonVariant;
}

function defaults(investigation: Investigation): InvestigationFormModel {
  return {
    name: `Rerun of ${investigation?.name}`,
    start: investigation.requestData.start,
    end: investigation.requestData.end,
    labels:
      investigation.inputs
        ?.filter((input): input is LabelInput => input.type === 'label')
        .map((input) => ({ name: input.label.name, value: input.label.value, type: input.label.type })) ?? [],
    investigationSource: {
      type: InvestigationSourceType.Rerun,
      id: investigation.id,
      url: location.href,
    },
    queries:
      investigation.inputs
        ?.filter((input): input is MetricQueryInput => input.type === 'metric-query')
        .map(({ metric }) => metric) ?? [],
    services:
      investigation.inputs
        ?.filter((input): input is ServiceInput => input.type === 'service')
        .map(({ service }) => service) ?? [],
    datasources:
      investigation.inputs
        ?.filter((input): input is DataSourceInput => input.type === 'datasource')
        .map(({ datasource }) => datasource) ?? [],
  };
}

export function RerunInvestigationButton({ investigation, variant = 'secondary' }: Props): JSX.Element {
  const trackRudderStackEvent = useRudderStack();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          trackRudderStackEvent(RudderstackEvents.RerunInvestigationModalOpened, {
            investigationSourceType: investigation?.requestData.investigationSource.type,
          });
          setShowModal(true);
        }}
        variant={variant}
      >
        Rerun
      </Button>
      {investigation !== undefined && (
        <RunSiftModal
          show={showModal}
          showHelp={false}
          defaults={defaults(investigation)}
          title="Rerun investigation"
          onClose={() => setShowModal(false)}
          onSubmit={() => setShowModal(false)}
        />
      )}
    </>
  );
}
