import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import Card from 'components/Card';

export default function GettingStartedCard({ className = '' }: { className?: string }): JSX.Element {
  const { helpCard, blueLink } = useStyles2(getCardStyles);
  return (
    <Card height="subtle" className={cx(helpCard, className)}>
      <h3>Getting started</h3>
      <ul>
        <li>
          See our{' '}
          <a href="https://grafana.com/docs/grafana-cloud/machine-learning/tutorial">getting started tutorial</a> to see
          metric predictions in action.
        </li>
        <li>
          <a href="https://grafana.com/docs/grafana-cloud/machine-learning/querying">Querying</a> will help you craft
          queries using the machine learning Prometheus datasource.
        </li>
        <li>
          <a href="https://grafana.com/docs/grafana-cloud/machine-learning/models">Model configuration</a> documents how
          you can tune models to improve predictions.
        </li>
      </ul>
      <a className={blueLink} href="https://grafana.com/docs/grafana-cloud/machine-learning">
        Read the Grafana ML docs ❯
      </a>
    </Card>
  );
}

function getCardStyles(theme: GrafanaTheme2) {
  return {
    blueLink: css`
      color: ${theme.colors.text.link};
    `,
    helpCard: css`
      padding: 18px;
      > ul {
        margin: 18px;
      }
      a {
        color: ${theme.colors.text.link};
      }
    `,
  };
}
