import React from 'react';

import { css } from '@emotion/css';

import LogoLine from './LogoLine';

export default function AppHeader({ intro = false }: { intro?: boolean }): JSX.Element {
  return (
    <header className={headerStyles.header}>
      <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.46 118.24" className={headerStyles.logoSvg}>
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="6.73"
            y1="137.67"
            x2="67.73"
            y2="-0.38"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f9ea1c" />
            <stop offset="1" stopColor="#ed5a29" />
          </linearGradient>
        </defs>
        <LogoLine />
      </svg>
      {intro ? (
        <>
          <h1>Welcome to Grafana Machine Learning</h1>
          <h2>Train ML models to forecast time series metrics into the future</h2>
        </>
      ) : (
        <>
          <h1>Grafana Machine Learning</h1>
          <h2>Train ML models to forecast time series metrics into the future</h2>
        </>
      )}
    </header>
  );
}
const headerStyles = {
  logoSvg: css`
    width: 100%;
    height: 46px;
  `,
  header: css`
    display: grid;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1440px;
    padding: 0px 16px 0px 16px;
    grid-template-columns: 75px 1fr;
    grid-template-rows: 70px 70px;
    svg#logo {
      grid-column: 1;
      grid-row: 1 / 3;
      margin-top: -12px;
      align-self: center;
    }
    h1 {
      grid-column: 2;
      margin: 0;
      align-self: flex-end;
    }
    h2 {
      grid-column: 2;
      margin: 0;
      opacity: 0.8;
      align-self: flex-start;
      font-size: 14px;
      font-weight: normal;
    }
  `,
};
