import React from 'react';

import { Icon, Tooltip } from '@grafana/ui';

const CheckTooltips = {
  HTTPErrorSeries:
    'Surfaces HTTP-related metrics from the same cluster and namespace that have elevated error series during the investigation window.',
};

interface AnalysisTooltipProps {
  name?: string;
}

const AnalysisTooltip: React.FC<AnalysisTooltipProps> = ({ name }) => {
  const description = CheckTooltips[name as keyof typeof CheckTooltips];
  return description !== undefined ? (
    <Tooltip placement="top" content={description} theme="info">
      <Icon tabIndex={0} name="info-circle" size="sm" />
    </Tooltip>
  ) : null;
};

export { AnalysisTooltip };
