import React from 'react';

import { TimeRange } from '@grafana/data';
import { HorizontalGroup, RefreshPicker, TimeRangePicker } from '@grafana/ui';

import { noop } from 'lodash';

interface Props {
  timeRange: TimeRange;
  timeZone: string;
  isRefreshing: boolean;
  onRefresh: () => void;
  onChangeTimeRange: (timeRange: TimeRange) => void;
  onZoomTimeRange: () => void;
  onChangeTimeZone: (timeZone: string) => void;
}

export function ToolbarTimeRange({
  timeRange,
  timeZone,
  onRefresh,
  isRefreshing,
  onChangeTimeZone,
  onChangeTimeRange,
  onZoomTimeRange,
}: Props): JSX.Element {
  return (
    <HorizontalGroup justify="flex-end" spacing="none">
      <TimeRangePicker
        value={timeRange}
        onChange={onChangeTimeRange}
        onChangeTimeZone={onChangeTimeZone}
        onMoveBackward={noop}
        onMoveForward={noop}
        onZoom={onZoomTimeRange}
        timeZone={timeZone}
      />
      <RefreshPicker
        onRefresh={onRefresh}
        onIntervalChanged={noop}
        noIntervalPicker={true}
        tooltip="Refresh"
        isLive={isRefreshing}
        isLoading={isRefreshing}
        width="36"
      />
    </HorizontalGroup>
  );
}
