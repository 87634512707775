import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Badge, HorizontalGroup, LinkButton, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { SiftModalData } from 'types';
import {
  getKubernetesMonitoringUrl,
  getPrometheusExploreUrl,
  getPyroscopeExploreUrl,
  isWorkloadKind,
  ProfileType,
} from 'utils/utils.url';

type ContentionType = 'CPUThrottling' | 'NetworkSendPacketsDropped';

interface ResourceContentionDetails {
  CPUThrottling: Contention[];
  NetworkSendPacketsDropped: Contention[];
}

interface Contention {
  container: Container;
  type: ContentionType;
  hasProfilingData?: boolean;
}

interface Container {
  cluster: string;
  namespace: string;
  pod: string;
  name: string;
  workload?: string;
  workloadKind?: string;
}

function ResourceContentions({ analysis, investigation, datasources }: SiftModalData): React.ReactElement {
  const styles = useStyles2(getStyles);
  const details = (analysis.result?.details || {
    CPUThrottling: [],
    NetworkSendPacketsDropped: [],
  }) as unknown as ResourceContentionDetails;
  const promUid = datasources.prometheusDatasource.uid;
  const lokiUid = datasources.lokiDatasource.uid;
  const timeRange = investigation.timeRange;

  if (!analysis.result.successful) {
    return <div>Error running analysis: {analysis.result.message}</div>;
  }

  if (!analysis.result.interesting) {
    return <div>No Resource Contention Found</div>;
  }

  const contentions = [...details.CPUThrottling, ...details.NetworkSendPacketsDropped];

  return (
    <div>
      <table className={styles.results}>
        <thead>
          <tr>
            <th>Cluster</th>
            <th>Namespace</th>
            <th>Workload</th>
            <th>Container</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contentions.map(({ container, type, hasProfilingData: profilingData }) => {
            const key = `${container.cluster}:${container.namespace}:${container.pod}:${container.name}`;
            const queries = getQueries({ container, type });
            const k8sUrl =
              isWorkloadKind(container.workloadKind) && container.workload !== undefined
                ? getKubernetesMonitoringUrl({
                    from: timeRange.from,
                    to: timeRange.to,
                    prometheusDatasourceUid: promUid,
                    lokiDatasourceUid: lokiUid,
                    cluster: container.cluster,
                    namespace: container.namespace,
                    workloadKind: container.workloadKind,
                    workload: container.workload,
                    pod: container.pod,
                  })
                : undefined;
            return (
              <tr key={key} className={styles.row}>
                <td>{container.cluster}</td>
                <td>{container.namespace}</td>
                <td>{container.pod}</td>
                <td>{container.name}</td>
                <td>
                  <ResourceContentionBadge type={type} />
                </td>
                <td>
                  <HorizontalGroup>
                    {k8sUrl && (
                      <LinkButton variant="secondary" target="_blank" size="sm" icon="external-link-alt" href={k8sUrl}>
                        Open in Kubernetes Monitoring
                      </LinkButton>
                    )}
                    <LinkButton
                      variant="secondary"
                      target="_blank"
                      size="sm"
                      icon="compass"
                      href={getPrometheusExploreUrl(promUid, queries, timeRange)}
                    >
                      Metrics
                    </LinkButton>
                    {type === 'CPUThrottling' && profilingData && (
                      <LinkButton
                        variant="secondary"
                        target="_blank"
                        size="sm"
                        icon="fire"
                        href={getPyroscopeExploreUrl(`{${selectors(container)}}`, ProfileType.CPUProfile, timeRange)}
                      >
                        CPU Profile
                      </LinkButton>
                    )}
                  </HorizontalGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function ResourceContentionBadge({ type }: { type: ContentionType }) {
  const styles = useStyles2(getStyles);
  const tooltip = type === 'CPUThrottling' ? 'Throttled for more than 25% of the time' : 'More than 1% packet loss';
  const text = type === 'CPUThrottling' ? 'High CPU Throttling' : 'High Network Packet Loss';
  return (
    <Tooltip placement="top" content={tooltip}>
      <div>
        <Badge color="red" text={text} className={styles.badge} />
      </div>
    </Tooltip>
  );
}

// Note: network metrics don't include the container label, so set withContainer to false
function selectors(container: Container, withContainer = true): string {
  const { cluster, namespace, pod, name } = container;
  return `cluster="${cluster}", namespace="${namespace}", pod="${pod}"${withContainer ? `, container="${name}"` : ''}`;
}

function getQueries({ container, type }: Contention): string[] {
  const selector = selectors(container, true);
  const networkSelector = selectors(container, false);
  return type === 'CPUThrottling'
    ? [
        `rate(container_cpu_usage_seconds_total{${selector}}[5m])`,
        `kube_pod_container_resource_limits{${selector}, resource="cpu"}`,
        `rate(container_cpu_cfs_throttled_periods_total{${selector}}[5m]) / rate(container_cpu_cfs_periods_total{${selector}}[5m])`,
      ]
    : [
        `rate(container_network_transmit_packets_total{${networkSelector}}[5m])`,
        `rate(container_network_transmit_packets_dropped_total{${networkSelector}}[5m])`,
      ];
}

function getStyles(theme: GrafanaTheme2) {
  return {
    badge: css`
      margin-right: 5px;
    `,
    card: css`
      display: block !important;
    `,
    results: css`
      width: 100%;
      td,
      th {
        padding: 10px;
        border-bottom: solid 1px ${theme.colors.secondary.border};
      }
    `,
    message: css`
      padding-bottom: 20px;
      font-weight: bold;
    `,
    header: css`
      font-weight: bold;
      padding: 5px 0px 5px 0px;
      border-bottom: solid 1px ${theme.colors.secondary.border};
    `,
    row: css`
      cursor: default;
    `,

    heading: css`
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
    `,
    buttonRow: css`
      margin-top: 10px;
      clear: both;
      justify-content: space-between;
    `,
    icon: css`
      height: 20px;
      font-size: 18px;
    `,
  };
}

export { ResourceContentions };
