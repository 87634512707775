import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useHoliday, useUpdateHoliday } from 'api';
import { PLUGIN_ROOT } from 'consts';
import { Holiday, NewHoliday } from 'types';

import { CreateUpdateHolidayContent } from '../Create/CreateUpdateHolidayContent';

export const EditHoliday = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const styles = useStyles2(getStyles);
  const { data: originalHoliday, isLoading } = useHoliday(id!);
  const updatedHoliday = useUpdateHoliday();

  const onEditHoliday = async (holiday: NewHoliday) => {
    await updatedHoliday.mutateAsync(
      { id: originalHoliday?.id ?? '', updatedHoliday: holiday },
      {
        onSuccess(addedHoliday: Holiday) {
          navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${addedHoliday.id}`);
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: ['Holiday updated', `Holiday '${holiday.name}' has been updated.`],
          });
        },
        onError(e) {
          console.error(e);
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: ['An error happened while creating the holiday'],
          });
        },
      }
    );
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.tabPanel}>
          {!isLoading && originalHoliday !== undefined ? (
            <CreateUpdateHolidayContent originalHoliday={originalHoliday} onSubmitForm={onEditHoliday} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    pageContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
    `,
    tabPanel: css`
      background-color: ${theme.colors.background.primary};
      margin: 0px 10px 10px 20px;
    `,
  };
}
