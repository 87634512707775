import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoSizer, { HorizontalSize } from 'react-virtualized-auto-sizer';

import {
  AppEvents,
  DataFrame,
  dataFrameFromJSON,
  DataFrameJSON,
  DataSourceInstanceSettings,
  FieldMatcherID,
  fieldMatchers,
  GrafanaTheme2,
  LoadingState,
  outerJoinDataFrames,
  PanelData,
  rangeUtil,
} from '@grafana/data';
import { FetchResponse, getAppEvents } from '@grafana/runtime';
import { Alert, Collapse, InlineField, Input, PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { adminApiPost, getOutlierPayload } from 'api';
import { DBScanConfig, Outlier, OutlierField, OutlierIntervals, OutlierResponseType } from 'api/types';
import { CappedDataDisclaimer } from 'components/CappedDataDisclaimer';
import { CreateOutlierAlertButton } from 'components/CreateOutlierAlertButton';
import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { OUTLIER_RENDER_CAP_LIMIT, PLUGIN_ROOT } from 'consts';
import { useIsEditor, useSupportedDatasources, useTimeRange } from 'hooks';
import { useCapLimit } from 'hooks/useCapLimit';
import { DataQueryWithExpression, RuleInstance } from 'types';
import {
  defaultCreateJobTimeRange,
  filterDataKeepingOutliersOnly,
  getlabelColumnValues,
  getQueryString,
  getSparkRange,
  sampleOutlierSeries,
  sortedOutliersToEndAndReindex,
  timeRangeToUtc,
} from 'utils';

import { OutlierSparklines, OutlierStatusbar, OutlierTable } from '../Create';
import { ViewOutlierGraph } from './ViewOutlierGraph';

interface ViewOutlierContentProps {
  outlier: Outlier;
  alertingEnabled: boolean;
  alertRules: RuleInstance[];
}

export const ViewOutlierContent: React.FC<ViewOutlierContentProps> = ({ outlier, alertingEnabled }) => {
  const styles = useStyles2(getStyles);
  const isEditor = useIsEditor();
  const navigate = useNavigate();

  const [data, setData] = useState<PanelData>();
  const [selectedSeries, setSelectedSeries] = useState<number | undefined>(undefined);
  const [algorithmError, setAlgorithmError] = useState<string | undefined>(undefined);

  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultCreateJobTimeRange()
  );

  // set a default resolution of 500 datapoints
  // resolution will change when the user resizes their window
  const [resolution, setResolution] = useState(500);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const refresh = () => {
    setRefreshCount(refreshCount + 1);
  };

  const runOutlierQuery = async ({
    queryParams,
    queryAlgorithm,
    queryConfig,
    querySensitivity,
    queryStartTime,
    queryEndTime,
    queryInterval,
    queryDatasource,
  }: {
    queryParams: DataQueryWithExpression;
    queryAlgorithm: string;
    queryConfig: DBScanConfig | undefined;
    querySensitivity: number;
    queryStartTime: string;
    queryEndTime: string;
    queryInterval: number;
    queryDatasource: DataSourceInstanceSettings;
  }) => {
    try {
      // if we're currently refreshing, dont do anything
      if (!isRefreshing) {
        setIsRefreshing(true);

        // run label result
        const labelResult = await adminApiPost('/proxy/api/v1/outlier', {
          data: getOutlierPayload({
            queryParams: queryParams,
            algorithm: queryAlgorithm,
            config: queryConfig,
            sensitivity: querySensitivity,
            startTime: queryStartTime,
            endTime: queryEndTime,
            interval: queryInterval,
            datasource: queryDatasource,
            responseType: OutlierResponseType.Label,
          }),
        });

        // backend algorithm errors transmitted as error string
        setAlgorithmError(labelResult.data.data.results.A?.error);

        // format series as data frames
        const frames = labelResult.data.data.results.A.frames; // can be undefined
        const series =
          frames === undefined
            ? []
            : frames.map((f: DataFrameJSON, index: number) => {
                const seriesFrame = dataFrameFromJSON(f);
                return {
                  ...seriesFrame,
                  index,
                };
              });

        if (series.length > 0) {
          // setup panel data
          const panelData: PanelData = {
            state: LoadingState.Done,
            series,
            timeRange: timeRange,
          };

          // generate list of series that have outliers
          const outlierSeries = [];
          for (let i = 0; i < series.length; i++) {
            if (series[i].fields !== undefined && series[i].fields[1]?.labels?.isOutlier === 'True') {
              outlierSeries.push(i);
            }
          }

          if (outlierSeries.length > 0) {
            const binaryResult: FetchResponse = await adminApiPost('/proxy/api/v1/outlier', {
              data: getOutlierPayload({
                queryParams: queryParams,
                algorithm: queryAlgorithm,
                config: queryConfig,
                sensitivity: querySensitivity,
                startTime: queryStartTime,
                endTime: queryEndTime,
                interval: queryInterval,
                datasource: queryDatasource,
                responseType: OutlierResponseType.Binary,
              }),
            });

            for (const seriesIndex of outlierSeries) {
              const seriesFrames = (binaryResult.data.data.results.A ?? { frames: [] }).frames;
              const theSeries = seriesFrames !== undefined ? seriesFrames[seriesIndex] : { data: { values: [] } };
              const seriesValues = theSeries?.data?.values ?? [];
              const timestamps = seriesValues[0] ?? [];
              const values = seriesValues[1] ?? [];

              const outlierIntervals: OutlierIntervals = [];
              let previousOutlierState = 0; // assume start non-outlier, as only want to find outlier intervals

              for (let j = 0; j < timestamps.length; j++) {
                const ts = timestamps[j] as number;
                const outlierState = values[j] as number; // 0=normal, 1=outlier

                if (outlierState !== previousOutlierState) {
                  // something changed, so take note of timestamp where change happened
                  outlierIntervals.push(ts);
                  previousOutlierState = outlierState;
                }
              }

              // Attach the OutlierIntervals to the Field as an additional property
              const seriesRef = panelData.series[seriesIndex] as DataFrame;
              const field = seriesRef.fields[1] as OutlierField<number>;
              field.outlierIntervals = outlierIntervals;
            }
          }
          setData(panelData);
        } else {
          setData(undefined);
        }

        setIsRefreshing(false);
      }
    } catch (e) {
      setData(undefined);
      if (e instanceof Error) {
        getAppEvents().publish({ type: AppEvents.alertError.name, payload: [`Error: ${e?.message ?? ''}`] });
      }
    }
  };

  const alignedPanelData = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    // setQueryError(data.error?.message);
    if (data.state !== 'Done') {
      return undefined;
    }

    // `data` is the raw PanelData returned by  the datasource, which can have several different
    // formats. Perform the following to standardize it.
    const alignedFrame = outerJoinDataFrames({
      frames: data.series,
      joinBy: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
      keep: fieldMatchers.get(FieldMatcherID.numeric).get({}),
      keepOriginIndices: true,
    });
    if (alignedFrame === undefined) {
      return data;
    }

    // convert back to panel data format
    const panelData: PanelData = {
      ...data,
      series: [alignedFrame],
    };
    return panelData;
  }, [data]);

  const supportedDatasources = useSupportedDatasources();

  const datasource = useMemo(
    () => supportedDatasources.find((ds: DataSourceInstanceSettings) => ds.id === outlier.datasourceId),
    [supportedDatasources, outlier.datasourceId]
  );

  useEffect(() => {
    if (datasource !== undefined) {
      const utcTimeRange = timeRangeToUtc(timeRange);
      runOutlierQuery({
        queryParams: outlier.queryParams,
        queryAlgorithm: outlier.algorithm.name,
        queryConfig: outlier.algorithm.config,
        querySensitivity: outlier.algorithm.sensitivity,
        queryStartTime: utcTimeRange.from,
        queryEndTime: utcTimeRange.to,
        queryInterval: rangeUtil.calculateInterval(timeRange, resolution, '1m').intervalMs / 1000,
        queryDatasource: datasource,
      }).catch((e) => {
        setData(undefined);
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: ${(e?.message as string) ?? ''}`],
        });
      });
    }
    // don't include resolution in deps, as we dont want this to re-run constantly as browser resizes
  }, [outlier, timeRange, refreshCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSeriesSelection = (seriesIndex: number | undefined) => {
    setSelectedSeries(seriesIndex);
  };

  const sortedData = sortedOutliersToEndAndReindex(alignedPanelData);
  const outlierOnlyData = filterDataKeepingOutliersOnly(sortedData);
  const labelColumnValues = getlabelColumnValues(outlierOnlyData);
  const sparkRange = getSparkRange(alignedPanelData);
  const outlierCount = data?.series.filter((o) => o.fields[1]?.labels?.isOutlier === 'True').length ?? 0;

  const capLimit = OUTLIER_RENDER_CAP_LIMIT; // maximum number of series to show initially
  const [cappedData, didCap, originalCount, setShouldCap] = useCapLimit(sortedData, capLimit, sampleOutlierSeries);

  const linkToOutlier = (outlierData: Outlier, edit: boolean) => {
    const editDefaults = encodeURIComponent(
      JSON.stringify({
        name: outlierData.name,
        description: outlierData.description,
        metric: outlierData.metric,
      })
    );
    const link =
      `${PLUGIN_ROOT}/outlier-detector/create?` +
      `query_params=${encodeURIComponent(JSON.stringify(outlierData.queryParams))}` +
      `&algorithm=${outlierData.algorithm.name}` +
      `&ds=${datasource?.uid ?? ''}` +
      `&sensitivity=${outlierData.algorithm.sensitivity.toString()}` +
      (edit ? `&id=${outlierData.id}&edit=${editDefaults}` : ``);
    return link;
  };

  const queryString = getQueryString(outlier.datasourceType, outlier.queryParams);

  return (
    <div className={styles.pageContainer}>
      <PageToolbar className={styles.pageToolbar}>
        <ToolbarTimeRange
          onChangeTimeRange={onChangeTimeRange}
          onZoomTimeRange={onZoomTimeRange}
          onChangeTimeZone={onChangeTimeZone}
          timeRange={timeRange}
          timeZone={timeZone}
          onRefresh={refresh}
          isRefreshing={isRefreshing}
        />
        <CreateOutlierAlertButton
          outlier={outlier}
          alertingEnabled={alertingEnabled && algorithmError === undefined && data !== undefined}
          returnTo="/outlier-detector"
          variant="primary"
        />
        {isEditor ? (
          <ToolbarButton onClick={() => navigate(linkToOutlier(outlier, true))} title="Edit outlier" key="edit">
            Edit
          </ToolbarButton>
        ) : null}
      </PageToolbar>

      <div className={styles.contentContainer}>
        <div className={styles.queryContainer}>
          <InlineField labelWidth={20} grow={true} label="Original query">
            <Input disabled readOnly value={queryString} onChange={() => {}} />
          </InlineField>
          <div className={styles.algorithmContainer}>
            <InlineField labelWidth={20} grow={true} label="Algorithm">
              <Input disabled readOnly value={outlier.algorithm.name} onChange={() => {}} />
            </InlineField>
            <InlineField labelWidth={20} grow={true} label="Sensitivity">
              <Input disabled readOnly value={outlier.algorithm.sensitivity} onChange={() => {}} />
            </InlineField>
            {outlier.algorithm.name === 'dbscan' && outlier.algorithm.config?.epsilon !== undefined && (
              <InlineField labelWidth={20} grow={true} label="Epsilon">
                <Input disabled readOnly value={outlier.algorithm.config.epsilon} onChange={() => {}} />
              </InlineField>
            )}
          </div>
        </div>

        {algorithmError && <Alert topSpacing={0} severity="error" title={algorithmError}></Alert>}

        <Collapse label="Graph" loading={isRefreshing} isOpen={true}>
          {didCap && (
            <CappedDataDisclaimer originalCount={originalCount} capLimit={capLimit} setShouldCap={setShouldCap} />
          )}
          <AutoSizer disableHeight>
            {({ width }: HorizontalSize) => {
              if (width === undefined || width < 3) {
                return <div>Window is too small to render graph, please increase size.</div>;
              }

              setResolution(width);

              return (
                <div style={{ width }}>
                  <ViewOutlierGraph
                    data={cappedData}
                    onChangeTimeRange={onChangeTimeRange}
                    timeZone={timeZone}
                    width={width}
                    selectedIndex={selectedSeries}
                  />
                </div>
              );
            }}
          </AutoSizer>
        </Collapse>

        {!algorithmError && (
          <Collapse label="Summary" loading={isRefreshing} isOpen={true}>
            <OutlierStatusbar data={alignedPanelData} outlierCount={outlierCount} />
            {outlierCount > 0 ? (
              <>
                <OutlierSparklines
                  alignedData={cappedData}
                  sparkRange={sparkRange}
                  onSeriesSelection={onSeriesSelection}
                  selectedIndex={selectedSeries}
                />
                <OutlierTable
                  alignedData={outlierOnlyData}
                  sparkRange={sparkRange}
                  labelColumnValues={labelColumnValues}
                  onSeriesSelection={onSeriesSelection}
                  selectedIndex={selectedSeries}
                />
              </>
            ) : (
              <></>
            )}
          </Collapse>
        )}
      </div>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    pageContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
    `,
    loadingContainer: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      height: calc(100vh - 350px);
    `,
    queryContainer: css`
      background-color: ${theme.colors.background.primary};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 3px;
      padding: ${theme.spacing(1)};
      margin-bottom: 30px;
    `,
    algorithmContainer: css`
      display: flex;
    `,
    feedbackText: css`
      font-size: 14px;
      color: #ddd;
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: space-between;
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: theme.spacing(2),
    }),
  };
}
