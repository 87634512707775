import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export default function MlHowItWorks(): JSX.Element {
  const { list, blueLink, description } = useStyles2(getStyles);
  return (
    <>
      <h3>How it works at a glance</h3>
      <ul className={list}>
        <li>Grafana ML will learn the patterns in your data so it can predict your time series.</li>
        <li>This model forecasts your data into the future, including confidence in those predictions.</li>
        <li>
          You can use these forecasts to create alerts, predict capacity requirements, or detect anomalous activity.
        </li>
        <li>
          Grafana ML currently supports these datasources: Prometheus, Graphite, Loki (metric queries only), Postgres,
          InfluxDB, Snowflake, Splunk, Elasticsearch, BigQuery, MongoDB and Datadog.
        </li>
      </ul>
      <p className={description}>
        <a className={blueLink} href="https://grafana.com/docs/grafana-cloud/machine-learning">
          Learn more about Grafana ML ❯
        </a>
      </p>
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    blueLink: css`
      color: ${theme.colors.text.link};
    `,
    description: css`
      margin-top: 12px;
      margin-bottom: 0;
      .blueLink {
      }
    `,
    list: css`
      margin: 18px;
    `,
  };
}
