import React from 'react';

import { Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { Investigation } from 'types';

interface LabelTooltipProps {
  item: Investigation;
}

const colors = [
  { name: 'green', labelBg: 'rgb(86, 166, 75)', labelText: 'white', valueBG: 'rgb(55, 135, 45)', valueText: 'white' },
  { name: 'blue', labelBg: 'rgb(87, 148, 242)', labelText: 'white', valueBG: 'rgb(31, 96, 196)', valueText: 'white' },
  {
    name: 'purple',
    labelBg: 'rgb(184, 119, 217)',
    labelText: 'white',
    valueBG: 'rgb(143, 59, 184)',
    valueText: 'white',
  },
  { name: 'red', labelBg: 'rgb(242, 73, 92)', labelText: 'white', valueBG: 'rgb(196, 22, 42)', valueText: 'white' },
  { name: 'orange', labelBg: 'rgb(255, 120, 10)', labelText: 'white', valueBG: 'rgb(250, 100, 0)', valueText: 'white' },
  { name: 'yellow', labelBg: 'rgb(250, 222, 42)', labelText: 'black', valueBG: 'rgb(224, 180, 0)', valueText: 'black' },
];
const labelStyles = colors.map(
  ({ labelBg, labelText }) => css`
    color: ${labelText};
    background-color: ${labelBg};
  `
);
const valueStyles = colors.map(
  ({ valueBG, valueText }) => css`
    color: ${valueText};
    background-color: ${valueBG};
  `
);

export const LabelTooltip = ({ item }: LabelTooltipProps) => {
  const styles = useStyles2(getStyles);
  const labels = Object.entries(item.requestData?.labels || []);
  const numLabels = labels.length;

  return (
    <Tooltip
      content={
        <div>
          {labels.map(([key, value], i) => (
            <LabelItem key={key} value={value} name={key} set={i % colors.length} />
          ))}
        </div>
      }
    >
      <div className={styles.labels}>
        <Icon className={styles.iconSpacing} name="tag-alt" />
        {numLabels}
      </div>
    </Tooltip>
  );
};

const LabelItem = ({ name, value, set }: { name: string; value: string; set: number }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.labelItem}>
      <div className={cx(styles.labelKey, labelStyles[set])}>{name}</div>
      <div className={cx(styles.labelValue, valueStyles[set])}>{value}</div>
    </div>
  );
};

const getStyles = () => ({
  labelItem: css`
    display: flex;
    justify-content: space-between;
    margin: 4px 0px;
    border-radius: 4px;
    color: white;
  `,

  labelKey: css`
    flex: 1;
    padding: 4px 6px;
  `,

  labelValue: css`
    flex: 1;
    padding: 4px 6px;
  `,

  iconSpacing: css`
    margin-right: 2px;
  `,

  labels: css`
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 4px 6px;
    cursor: default;
    width: 40px;
  `,
});
