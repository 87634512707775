import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AppEvents, DataSourceInstanceSettings } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Input, Modal, TextArea, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

import { useUpdateMetricJob } from 'api';
import { CustomLabels } from 'components/FormFields/CustomLabels';
import { validateLabel } from 'components/FormFields/validators';
import { PLUGIN_ROOT } from 'consts';
import { DataQueryWithExpression, ForecastLabels, FullFormModel } from 'types';
import { editJobRequestFromFormModel, onFormSubmit } from 'utils';

import { useForecastingState } from '../useForecastingState';

interface EditJobModalProps {
  id: string;
  isOpen: boolean;
  onDismiss: () => void;
  datasource: DataSourceInstanceSettings;
  modelForm: FullFormModel;
  queryParams?: DataQueryWithExpression;
  attachedHolidays: string[];
}

export function EditJobModal({
  id,
  datasource,
  isOpen,
  onDismiss,
  queryParams,
  modelForm,
  attachedHolidays,
}: EditJobModalProps): JSX.Element | null {
  const { mutateAsync: updateJob, isLoading } = useUpdateMetricJob();
  const { data, setShowEditModal } = useForecastingState();
  const navigate = useNavigate();
  const styles = useStyles(getStyles);

  const initialLabels = Object.entries(modelForm.customLabels || {}).map(([prop, value]) => ({
    prop,
    value,
  })) as ForecastLabels[];

  const {
    control,
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isValidating, isSubmitted, isSubmitting },
  } = useForm<FullFormModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      ...modelForm,
      labels: initialLabels,
    },
  });

  const labelWatch = watch('labels');

  const labelsAreValid =
    labelWatch?.find((label: ForecastLabels) => {
      const validation = validateLabel(label.prop);
      if (validation.invalid) {
        return true;
      }
      return false;
    }) === undefined;

  const onSubmit = useCallback(async () => {
    const submitForm = data.modelForm;
    submitForm.holidays = attachedHolidays;
    submitForm.query.value = queryParams;
    void updateJob(editJobRequestFromFormModel(id, submitForm, datasource), {
      onSuccess() {
        setShowEditModal(false);
        navigate(`${PLUGIN_ROOT}/metric-forecast`);
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Forecast edited', `Forecast '${submitForm.name}' has been edited and will be retrained shortly.`],
        });
      },
    });
  }, [setShowEditModal, datasource, navigate, id, updateJob, attachedHolidays, queryParams, data]);

  return (
    <Modal
      className={styles.container}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onClickBackdrop={onDismiss}
      title="Edit forecast"
    >
      <form onSubmit={onFormSubmit(handleSubmit(onSubmit))}>
        <Field label="Name" invalid={Boolean(errors.name)} error={errors.name?.message}>
          <Input
            readOnly
            {...register('name', {
              required: 'name must be provided',
            })}
          />
        </Field>
        <Field label="Metric" invalid={Boolean(errors.metric)} error={errors.metric?.message}>
          <Input
            readOnly
            {...register('metric', {
              required: 'metric is required',
            })}
          />
        </Field>
        <Field label="Description" invalid={Boolean(errors.description)} error={errors.description?.message}>
          <TextArea {...register('description')} placeholder="Add a description for your forecast..." autoFocus />
        </Field>
        <CustomLabels getValues={getValues} control={control} register={register} />
        <div style={{ display: 'flex', gap: '4px' }} data-element="forecasting-confirm-edit-buttons">
          <Button type="submit" disabled={!labelsAreValid || !isValid || isValidating || isSubmitted || isSubmitting}>
            Confirm
          </Button>
          <Button variant="secondary" disabled={isLoading} onClick={onDismiss}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}

const getStyles = () => {
  return {
    container: css`
      width: 500px;
    `,
  };
};
