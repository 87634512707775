import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

interface CappedDataDisclaimerProps {
  originalCount: number;
  capLimit: number;
  setShouldCap: (a: boolean) => void;
}

export function CappedDataDisclaimer({
  originalCount,
  capLimit,
  setShouldCap,
}: CappedDataDisclaimerProps): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <>
      <div className={styles.cappedContainer}>
        <div>
          <Icon name="exclamation-triangle" className={styles.capWarningIcon} size="lg" /> Showing only {capLimit} time
          series.{' '}
          <span onClick={() => setShouldCap(false)} className={styles.linkShowAll}>
            Show all {originalCount}
          </span>
        </div>
        <div className={styles.capSubWarning}>
          <strong>Note:</strong> Displaying many series could cause performance issues with your browser.
        </div>
      </div>
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    capSubWarning: css`
      opacity: 0.8;
      font-size: ${theme.typography.bodySmall.fontSize}; // 12px
    `,
    cappedContainer: css`
      text-align: center;
      margin: ${theme.spacing(2)} auto; // 16px auto
    `,
    capWarningIcon: css`
      color: orange;
      margin: -3px 5px 0 0;
    `,
    linkShowAll: css`
      font-weight: 700;
      cursor: pointer;
      color: ${theme.colors.primary.text}; //rgb(110, 159, 255);
    `,
  };
}
