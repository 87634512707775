interface OutlierColors {
  isOutlier: string;
  notOutlier: string;
  selectedNotOutlier: string;
  selectedIsOutlier: string;
  notOutlierRegion: string;
}

// Specific Colors for outlier graph in light mode
const OutlierColorsLight = {
  isOutlier: '#F5793A',
  notOutlier: '#777777',
  selectedNotOutlier: '#f5b73d',
  selectedIsOutlier: '#f5b73d',
  notOutlierRegion: '#b7cef2',
};

// Specific Colors for outlier graph in dark mode
const OutlierColorsDark = {
  isOutlier: '#f5b73d',
  notOutlier: '#777777',
  selectedNotOutlier: '#ffffff',
  selectedIsOutlier: '#ffffff',
  notOutlierRegion: '#2e4668',
};

// util function to get the correct color set
export const getOutlierColors = (isDark: boolean): OutlierColors => {
  return isDark ? OutlierColorsDark : OutlierColorsLight;
};

// accepts current color constant and returns line color
export const getLineColor = (colors: OutlierColors, isOutlier: boolean, isSelected: boolean): string => {
  const color = isOutlier
    ? isSelected
      ? colors.selectedIsOutlier
      : colors.isOutlier
    : isSelected
    ? colors.selectedNotOutlier
    : colors.notOutlier;
  return color;
};
