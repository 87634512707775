import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import { CheckConfig, CheckConfigWithDatasource, CheckFormProps, SlowRequestsConfig } from 'types';
import { getAnalysisDisplayTitle } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

function SlowRequestsForm({ check, defaultConfig, onClose }: CheckFormProps<SlowRequestsConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { tempoFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<SlowRequestsConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues: CheckConfigWithDatasource<SlowRequestsConfig> = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
    autoDetectDatasource: (check.config.tempoDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData = {
          id: check.id ?? undefined,
          name: check.name,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            tempoDatasourceUid: autoDetectDatasource ? '' : config.tempoDatasourceUid,
          },
          title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, formState, setValue, watch }) => {
        const tempoDatasourceUid = watch('config.tempoDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} />
            </Field>

            <Field label={<LabelWithHelpText label="Threshold" checkName="slowRequests" fieldName="threshold" />}>
              <Input {...register('config.threshold')} placeholder={defaultConfig.threshold} />
            </Field>

            <DataSourceSelector
              title="Tempo Datasource"
              dsType="tempo"
              dsUid={tempoDatasourceUid ?? ''}
              autoDetectDatasource={autoDetectDatasource}
              filter={tempoFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.tempoDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { SlowRequestsForm };
