import React from 'react';

import { FieldValidationMessage, IconButton, InlineFieldRow, Input } from '@grafana/ui';

import { css } from '@emotion/css';

interface ServiceFieldProps {
  name: string;
  namespace: string;
  errorMessage: string;
  onChange: (name: string, namespace: string) => void;
  onRemove: () => void;
  onStartChange?: () => void;
  onEndChange?: () => void;
}

function ServiceField({
  name,
  namespace,
  errorMessage,
  onChange,
  onRemove,
  onStartChange,
  onEndChange,
}: ServiceFieldProps): JSX.Element {
  return (
    <InlineFieldRow className={styles.container}>
      <div className={styles.row}>
        <Input
          className={styles.serviceSelect}
          placeholder="Name"
          value={name}
          onChange={(option) =>
            option.currentTarget.value !== undefined && onChange(option.currentTarget.value, namespace)
          }
          onKeyDown={() => {
            if (onStartChange !== undefined) {
              onStartChange();
            }
          }}
          onBlur={() => {
            if (onEndChange !== undefined) {
              onEndChange();
            }
          }}
        />
        <Input
          className={styles.namespaceInput}
          placeholder="Namespace"
          value={namespace}
          onChange={(option) => option.currentTarget.value !== undefined && onChange(name, option.currentTarget.value)}
          onKeyDown={() => {
            if (onStartChange !== undefined) {
              onStartChange();
            }
          }}
          onBlur={() => {
            if (onEndChange !== undefined) {
              onEndChange();
            }
          }}
        />
        <IconButton name="trash-alt" aria-label="Remove service" onClick={() => onRemove()} tooltip="Remove service" />
      </div>
      {errorMessage !== '' ? (
        <FieldValidationMessage className={styles.validationMsg}>{errorMessage}</FieldValidationMessage>
      ) : null}
    </InlineFieldRow>
  );
}

export { ServiceField };

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    width: 100%;
  `,
  valueInput: css`
    flex-grow: 1;
    margin-right: 8px;
  `,
  serviceSelect: css`
    flex-grow: 1;
  `,
  namespaceInput: css`
    flex-grow: 1;
    margin-right: 8px;
  `,
  versionInput: css`
    flex-grow: 1;
    margin-right: 8px;
  `,
  validationMsg: css`
    flex-basis: 100%;
  `,
};
