import React from 'react';

import { Slider as SliderComponent, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export interface SliderProps {
  step: number;
  min: number;
  max: number;
  marks: { [key: number]: string };
  value: number;
  onAfterChange: (v?: number | undefined) => void;
  enabled: boolean;
}

// Wraps the Slider from the UI library, adding a disabled state
// When enabled is false, the opacity will be reduced and a div will overlay the slider to prevent clicks
export const Slider = ({
  step,
  min,
  max,
  marks,
  value,
  onAfterChange,
  enabled = true,
}: SliderProps): JSX.Element | null => {
  const styles = useStyles2(getStyles);
  return (
    <div id="abc" className={enabled ? styles.enabled : styles.disabled}>
      <SliderComponent step={step} min={min} max={max} marks={marks} value={value} onAfterChange={onAfterChange} />
      {!enabled ? <div className={styles.mask} style={{ position: 'absolute', top: 0 }}></div> : null}
    </div>
  );
};

const getStyles = () => ({
  enabled: css`
    & > div {
      .rc-slider {
        margin: auto 16px;
        width: 200px;
      }
      // Hide the input field showing the numeric value of the slider
      .rc-slider + div {
        display: none;
      }
    }
  `,
  disabled: css`
    & > div {
      .rc-slider {
        margin: auto 16px;
        width: 200px;
      }
      // Hide the input field showing the numeric value of the slider
      .rc-slider + div {
        display: none;
      }
    }
    opacity: 0.2;
    position: 'relative';
  `,
  mask: css`
    backgroundColor: 'none'
    overflow: hidden;
    height: 25px;
    width: 280px;
  `,
});
