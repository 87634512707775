import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Text, TextLink, Toggletip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

interface Props {
  explanation?: string;
}

export function Explanation({ explanation }: Props) {
  const styles = useStyles2(getStyles);

  if (!explanation) {
    return null;
  }
  return (
    <div
      className={css`
        display: flex;
        align-items: first baseline;
        gap: 6px;
      `}
    >
      <Toggletip
        content={
          <div className={styles.aiDisclaimer}>
            <Icon name="exclamation-circle" aria-label="exclamation-circle" className={styles.infoColor} />
            <Text variant="bodySmall" color="secondary">
              This content is AI-generated using the{' '}
              <TextLink
                variant="bodySmall"
                href="https://grafana.com/docs/grafana-cloud/alerting-and-irm/machine-learning/llm-plugin/"
                external
              >
                Grafana LLM plugin
              </TextLink>
            </Text>
          </div>
        }
      >
        <div className={styles.aiDisclaimerButton}>
          <Icon name="ai" aria-label="info-circle" />
          Explanation:
        </div>
      </Toggletip>
      <br />
      <code className={styles.code} style={{ whiteSpace: 'pre-line' }}>
        {explanation}
      </code>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    code: css`
      padding: 3px 6px;
      white-space: normal;
    `,
    infoColor: css`
      color: ${theme.colors.info.main};
    `,
    aiDisclaimer: css`
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0px;
      align-items: center;
      gap: ${theme.spacing(1)};
    `,
    aiDisclaimerButton: css`
      gap: 6px;
      align-items: center;
      display: flex;
      cursor: help;
    `,
  };
}
