import { DataFrame, Field, FieldType, PanelData } from '@grafana/data';

import { clone } from 'lodash';

import { OutlierField } from 'api/types';

// We sort outliers to the end of the list, so those lines will always
// be placed on top in the graphs.
export function sortedOutliersToEnd(panelData: PanelData | undefined): PanelData | undefined {
  return panelData !== undefined
    ? {
        ...panelData,
        state: panelData.state,
        series:
          panelData.series.length > 0
            ? [
                {
                  ...panelData?.series[0],
                  // clone to leave input untouched
                  fields: clone(panelData?.series[0].fields).sort((f) => {
                    if (f.type === FieldType.time) {
                      // maintain 'time' as first field
                      return -1;
                    }
                    return f !== undefined && f?.labels?.isOutlier === 'True' ? 1 : -1;
                  }),
                },
              ]
            : [],
      }
    : panelData;
}

// For `selectedIndex` to work correctly with Create/ViewOutlierGraph, the `index` needs to match the
// order of the DataFrame list passed to Create/ViewOutlierGraph.
export function sortedOutliersToEndAndReindex(panelData: PanelData | undefined): PanelData | undefined {
  if (panelData?.series?.[0] !== undefined) {
    let i = 1; // helps avoid falsy bugs
    panelData.series[0].fields.forEach((f: OutlierField<number>) => {
      if (f.type !== FieldType.time) {
        f.index = i;
        i += 1;
      }
    });
  }

  const sorted = sortedOutliersToEnd(panelData);
  return sorted;
}

export function filterDataKeepingOutliersOnly(
  panelData: PanelData | undefined,
  filterNonOutliers = false
): PanelData | undefined {
  return panelData !== undefined
    ? {
        ...panelData,
        state: panelData.state,
        series:
          panelData.series.length > 0
            ? [
                {
                  ...panelData?.series[0],
                  fields: panelData?.series[0].fields.reduce((acc: Array<Field<any, any[]>>, f: Field<any, any[]>) => {
                    if (f.type === FieldType.time) {
                      return acc;
                    }

                    let filterCondition = f?.labels?.isOutlier === 'True';

                    if (filterNonOutliers) {
                      // Flip the function to only keep non-outliers instead of only outliers
                      filterCondition = !filterCondition;
                    }

                    if (filterCondition) {
                      return [...acc, f];
                    }
                    return acc;
                  }, []),
                },
              ]
            : [],
      }
    : panelData;
}

export function sampleOutlierSeries(dataToSample: PanelData, limit: number): DataFrame[] {
  const sampleOutliers = filterDataKeepingOutliersOnly(dataToSample)?.series.slice(0, limit / 2) ?? [];
  // We might have less outliers than the limit (e.g. <10), so fill the rest with non-outliers.
  const remainingLimit = limit - sampleOutliers.length;
  const sampleNonOutliers = filterDataKeepingOutliersOnly(dataToSample, true)?.series.slice(0, remainingLimit) ?? [];
  return sampleOutliers.concat(sampleNonOutliers);
}

export function getlabelColumnValues(panelData: PanelData | undefined): { [key: string]: string[] } {
  const labelFields =
    panelData !== undefined && panelData.series.length > 0
      ? panelData.series[0].fields.filter((f: Field<any, any[]>) => f.type !== FieldType.time)
      : [];

  // FIXME: the aligned PanelData has an additional 'name' label on the data, which we need to remove only if
  // it contains the same content as the displayName
  const labelColumns = labelFields.reduce((list: string[], f: Field): string[] => {
    Object.keys(f.labels!).map((lbl: string) => {
      if (lbl !== '__name__' && lbl !== 'isOutlier' && !list.includes(lbl)) {
        list.push(lbl);
      }
    });
    return list;
  }, []);

  if (labelColumns.length === 0) {
    // Looks like this is a SQL-ish datasource which has no labels, fall back to reading Field `name`
    return { name: labelFields.map((f) => f.name) };
  }

  const labelBreakdown = labelColumns.reduce((result: { [key: string]: string[] }, label: string) => {
    if (!Object.keys(result).includes(label)) {
      result[label] = [];
    }
    labelFields.forEach((field: OutlierField<number>) => {
      Object.keys(field.labels ?? {})
        .filter((l: string) => l !== '__name__' && l !== 'isOutlier')
        .forEach((l: string) => {
          const labelValue = field.labels![l];
          if (label === l && labelValue !== undefined && !result[label]!.includes(labelValue)) {
            result[label]!.push(labelValue);
          }
        });
    });
    return result;
  }, {});

  return labelBreakdown;
}
