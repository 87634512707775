import React from 'react';

import { css } from '@emotion/css';

import { HyperparameterValues } from 'types';

import { Field } from './Field';

interface ObjectFieldProps {
  label: string;
  value?: HyperparameterValues;
}

export function ObjectField({ label, value }: ObjectFieldProps): JSX.Element {
  return value == null ? (
    <Field label={label} value="Null" />
  ) : (
    <div className={styles.wideColumn}>
      <h6>{label}</h6>
      {Object.entries(value).map(([k, v]) => {
        if (v == null) {
          // unsupported
          return null;
        }
        return <Field key={k} label={k} value={v} />;
      })}
    </div>
  );
}

const styles = {
  wideColumn: css`
    display: flex;
    margin: 18px 12px 0 12px;
    flex-direction: column;
  `,
};
