import { AppPlugin } from '@grafana/data';

import { isFunction } from 'lodash';

import * as alerting from './alerting';
import * as ml from './ml';
import * as sift from './sift';
import { ExtensionComponentConfig, ExtensionLinkConfig } from './types';

const linkConfigs: ExtensionLinkConfig[] = [
  // Dashboard panel menu
  ...ml.panelExtensionLinks,
  ...(sift.panelExtensionLinks as ExtensionLinkConfig[]),

  // Explore add button
  ...ml.exploreExtensionLinks,
  ...(sift.exploreExtensionLinks as ExtensionLinkConfig[]),

  // Alerting
  ...(sift.alertingExtensionLinks as ExtensionLinkConfig[]),

  // Command palette action
  ...(sift.commandPaletteExtensionLinks as ExtensionLinkConfig[]),

  // On call alert group
  ...(sift.onCallAlertGroupExtensionLinks as ExtensionLinkConfig[]),

  // Kubernetes app
  ...(sift.k8sExtensionLinks as ExtensionLinkConfig[]),

  // Sift panel
  sift.siftPanelLink as ExtensionLinkConfig,

  // Application Observability app
  ...(sift.appO11yExtensionLinks as ExtensionLinkConfig[]),
];

const componentExtensions: ExtensionComponentConfig[] = [
  ...(sift.siftAnalysisModals as ExtensionComponentConfig[]),
  sift.siftRunModal as ExtensionComponentConfig,
  alerting.alertingHomePageCallout,
];

export function configureAppExtensions(app: AppPlugin): AppPlugin {
  if (isFunction(app.configureExtensionComponent)) {
    app = componentExtensions.reduce((app, component) => {
      return app.configureExtensionComponent(component);
    }, app);
  }

  if (isFunction(app.configureExtensionLink)) {
    app = linkConfigs.reduce((app, link) => {
      return app.configureExtensionLink(link);
    }, app);
  }

  return app;
}
