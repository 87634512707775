import React, { useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Badge, HorizontalGroup, Icon, LinkButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { SiftModalData } from 'types';
import { formatDateTime } from 'utils';
import { findLabelMatcher } from 'utils/utils.sift';
import { getKubernetesMonitoringUrl } from 'utils/utils.url';

interface RecentDeploymentEventDetails {
  deployment?: string;
  statefulset?: string;
  namespace: string;
  cluster: string;
}

interface RecentDeploymentEvent {
  startTime: string;
  endTime: string;
  name: string;
  description: string;
}

interface RecentDeployment {
  cluster?: string;
  namespace?: string;
  workload: string;
  workloadKind: 'Deployment' | 'StatefulSet';
  mostRecent?: string;
  events: RecentDeploymentEvent[];
  k8sUrl?: string;
}

function RecentDeployments({ investigation, analysis, datasources }: SiftModalData): React.ReactElement {
  const events = analysis.result.events;

  // Cluster/namespace are present right now as of 2023-07-13, but in future will not be
  // required inputs so may not exist. By then, however, all analysis results should include
  // events, which include the items' cluster and namespace on the event object.
  const defaultCluster = findLabelMatcher(investigation, 'cluster', 'prometheusDatasource')?.value ?? '';
  const defaultNamespace = findLabelMatcher(investigation, 'namespace', 'prometheusDatasource')?.value ?? '';
  const { from, to } = investigation.timeRange;
  const prometheusDatasourceUid = datasources.prometheusDatasource.uid;
  const lokiDatasourceUid = datasources.lokiDatasource.uid;

  const deploymentList = useMemo<RecentDeployment[] | undefined>(() => {
    return events?.reduce((acc, curr) => {
      const details = curr.details as unknown as RecentDeploymentEventDetails | undefined;
      const workloadKind = details?.deployment !== undefined ? 'Deployment' : 'StatefulSet';
      const workload = details?.deployment ?? details?.statefulset ?? 'unknown';
      const namespace = details?.namespace ?? defaultNamespace;
      const cluster = details?.cluster ?? defaultCluster;
      const record = acc.find((item) => item.workloadKind === workloadKind && item.workload === workload);
      const k8sUrl = getKubernetesMonitoringUrl({
        from,
        to,
        prometheusDatasourceUid,
        lokiDatasourceUid,
        cluster,
        namespace,
        workloadKind: workloadKind.toLowerCase() as 'deployment' | 'statefulset',
        workload,
      });
      if (record === undefined) {
        acc.push({
          cluster,
          namespace,
          workloadKind,
          workload,
          mostRecent: curr.startTime,
          events: [
            {
              startTime: curr.startTime,
              endTime: curr.endTime,
              name: curr.name,
              description: curr.description ?? '',
            },
          ],
          k8sUrl,
        });
        return acc;
      } else {
        if (record.mostRecent == null || Date.parse(record.mostRecent) < Date.parse(curr.startTime)) {
          record.mostRecent = curr.startTime;
        }
        record.events.push({
          startTime: curr.startTime,
          endTime: curr.endTime,
          name: curr.name,
          description: curr.description ?? '',
        });
        return acc;
      }
    }, [] as RecentDeployment[]);
  }, [defaultCluster, defaultNamespace, events, prometheusDatasourceUid, lokiDatasourceUid, from, to]);

  deploymentList?.sort((a, b) => {
    if (a.mostRecent == null) {
      return 1;
    }
    if (b.mostRecent == null) {
      return -1;
    }
    return Date.parse(a.mostRecent) < Date.parse(b.mostRecent) ? 1 : -1;
  });

  return (
    <div>
      {deploymentList?.map((item) => (
        <DeploymentRow
          key={`${item.workloadKind}-${item.workload}`}
          deployment={item}
          defaultNamespace={defaultNamespace}
          defaultCluster={defaultCluster}
        />
      ))}
    </div>
  );
}

function DeploymentRow({
  deployment,
  defaultNamespace,
  defaultCluster,
}: {
  deployment: RecentDeployment;
  defaultNamespace: string;
  defaultCluster: string;
}): React.ReactElement {
  const { cluster, namespace, workload, workloadKind, mostRecent, events, k8sUrl } = deployment;
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = useStyles2(getStyles);
  return (
    <div key={`${workloadKind}-${workload}`} className={styles.rowContainer}>
      <div className={styles.headerContainer} onClick={() => setIsExpanded(!isExpanded)}>
        <Icon className={styles.expandedIcon} name={isExpanded ? 'angle-down' : 'angle-right'} />
        <div className={styles.primaryRow}>Workload: {workload}</div>
        {workloadKind === 'Deployment' ? (
          <Badge
            color="blue"
            text={`${events.length} deployment${events.length > 1 ? 's' : ''}`}
            className={styles.badge}
          />
        ) : (
          <Badge
            color="purple"
            text={`${events.length} stateful set${events.length > 1 ? 's' : ''}`}
            className={styles.badge}
          />
        )}
        <div>
          <HorizontalGroup>
            <div>
              <Icon name="clock-nine" /> {mostRecent != null ? formatDateTime(mostRecent) : ''}
            </div>
            {k8sUrl && (
              <LinkButton variant="secondary" target="_blank" size="sm" icon="external-link-alt" href={k8sUrl}>
                View in Kubernetes Monitoring
              </LinkButton>
            )}
          </HorizontalGroup>
        </div>
      </div>
      {isExpanded && events.length > 0 ? (
        <div className={styles.expandedContainer}>
          <div className={styles.expandedHeader}>
            <div style={{ width: '200px' }}>Namespace</div>
            <div style={{ width: '200px' }}>Cluster</div>
            <div style={{ width: '200px' }}>Rolled out</div>
          </div>
          {events.map((event, i) => {
            return (
              <div key={`${event.name}-${i}`} className={styles.expandedRow}>
                <div style={{ width: '200px' }}>{namespace ?? defaultNamespace}</div>
                <div style={{ width: '200px' }}>{cluster ?? defaultCluster}</div>
                <div style={{ width: '200px' }}>{formatDateTime(event.startTime)}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    labels: css`
      margin-bottom: 10px;
    `,
    rowContainer: css`
      background-color: ${theme.colors.secondary.border};
      margin-bottom: 10px;
    `,
    primaryRow: css`
      border-bottom: solid 1px ${theme.colors.secondary.border};
      cursor: pointer;
    `,

    headerContainer: css`
      display: flex;
      height: 35px;
      padding: 25px;
      @media screen and (min-width: 450px) {
        align-items: center;
      }
      @media screen and (max-width: 450px) {
        padding: 8px;
        align-items: left;
        flex-direction: column;
        overflow: hidden;
        height: auto;
      }
    `,
    expandedContainer: css`
      @media screen and (min-width: 450px) {
        padding: 5px 0px 20px 0px;
        margin: 0px 0px 0px 40px;
      }
      @media screen and (max-width: 450px) {
        padding: 5px;
        margin: 0px 0px 0px 0px;
      }
    `,
    expandedIcon: css`
      @media screen and (max-width: 450px) {
        justify-content: left;
      }
    `,
    expandedHeader: css`
      display: flex;
      font-weight: bold;
      padding: 5px 0px 5px 0px;
      border-bottom: solid 1px ${theme.colors.secondary.border};
    `,
    expandedRow: css`
      display: flex;
      align-items: center;
      border-bottom: solid 1px ${theme.colors.secondary.border};
      height: 35px;
    `,
    badge: css`
      @media screen and (min-width: 450px) {
        margin-left: auto;
        margin-right: 15px;
      }
      @media screen and (max-width: 450px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    `,
  };
}

export { RecentDeployments };
