import { ValidateNewJobResult } from 'types';

export function validateNumber(
  tv: number | string | null | undefined,
  required: boolean,
  integerOnly = false,
  minimum: number | undefined = undefined,
  maximum: number | undefined = undefined
): string | undefined {
  if (!required && tv == null) {
    return undefined;
  }
  if (tv == null) {
    return 'value required';
  }
  const asString = tv.toString().trim();
  if (!required && asString === '') {
    return undefined;
  }

  const asNum = Number(asString); // rejects almost everything non-numeric, unlike parseInt/Float
  // However Number() does interpret whitespace as 0, so need to check for that
  if (isNaN(asNum) || (asNum === 0 && asString === '')) {
    return 'not a number';
  }

  if (integerOnly && !Number.isInteger(asNum)) {
    return 'not an integer';
  }

  return (
    (minimum != null && asNum < minimum ? `value too small (min: ${minimum})` : undefined) ??
    (maximum != null && asNum > maximum ? `value too large (max: ${maximum})` : undefined)
  );
}

export async function validateName(name: string): Promise<string | undefined> {
  if (!/^[a-zA-Z_:][a-zA-Z0-9_:]*$/.test(name)) {
    return 'Invalid metric name, only alphanumeric characters, colon (:) and underscore (_) are permitted';
  }
  return undefined;
}

export async function validateUniqueName(
  name: string,
  validator: (arg: { name: string }) => Promise<ValidateNewJobResult | undefined>,
  checkCharacters = true
): Promise<string | undefined> {
  if (checkCharacters && !/^[a-zA-Z_:][a-zA-Z0-9_:]*$/.test(name)) {
    return 'Invalid metric name, only alphanumeric characters, colon (:) and underscore (_) are permitted';
  }

  const status = await validator({ name });
  if (status === undefined) {
    return 'Unable to validate the forecast name';
  }

  if (!status.valid) {
    return status.message;
  }

  return undefined;
}

// label validation for creating or editing a forecast
export function validateLabel(label: string) {
  const result = {
    invalid: false,
    errorMessage: '',
  };

  if (label?.startsWith('__')) {
    result.invalid = true;
    result.errorMessage = 'Label name cannot start with "__"';
  } else if (label === '') {
    result.invalid = true;
    result.errorMessage = 'Label name cannot be empty';
  } else if (!label.match('^[a-zA-Z_:][a-zA-Z0-9_:]*$')) {
    result.invalid = true;
    result.errorMessage = 'Label must start with a letter, colon, or underscore and cannot contain special characters';
  }

  return result;
}
