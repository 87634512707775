import { useMemo } from 'react';

import { FieldMatcherID, fieldMatchers, outerJoinDataFrames, PanelData } from '@grafana/data';

export function useAlignedPanelData(data: PanelData | undefined): PanelData | undefined {
  return useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    if (data.state !== 'Done') {
      return undefined;
    }

    // `data` is the raw PanelData returned by  the datasource, which can have several different
    // formats. Perform the following to standardize it.
    const alignedFrame = outerJoinDataFrames({
      frames: data.series,
      joinBy: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
      keep: fieldMatchers.get(FieldMatcherID.numeric).get({}),
      keepOriginIndices: true,
    });

    if (!alignedFrame) {
      return data;
    }

    return {
      ...data,
      series: [alignedFrame],
    };
  }, [data]);
}
