import { useMemo } from 'react';

import { rangeUtil, TimeOption, TimeRange } from '@grafana/data';
import { TimeZone } from '@grafana/schema';

export function useFutureTimeOptions(timeZone: TimeZone): {
  timeOptions: TimeOption[];
  defaultTimeRange: TimeRange;
} {
  const timeOptions = useMemo((): TimeOption[] => {
    return [
      {
        display: 'This week',
        from: 'now/w',
        to: 'now/w',
      },
      {
        display: 'This and next week',
        from: 'now/w',
        to: 'now+1w/w',
      },
      {
        display: 'This month',
        from: 'now/M',
        to: 'now/M',
      },
      {
        display: 'This and next month',
        from: 'now/M',
        to: 'now+1M/M',
      },
    ];
  }, []);

  const defaultTimeRange = useMemo(() => {
    const [defaultOption] = timeOptions;
    return rangeUtil.convertRawToRange(defaultOption, timeZone);
  }, [timeOptions, timeZone]);

  return { timeOptions, defaultTimeRange };
}
