import React from 'react';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { Icon, LinkButton, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { get } from 'lodash';

import { InvestigationSource, InvestigationSourceType } from 'types';

function BasicSourceButton({ icon, label }: { icon: IconName; label: string }): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.basicSourceButton}>
      <Icon name={icon} aria-label={label} size="sm" />
      <div>{label}</div>
    </div>
  );
}

interface SourceInfo {
  icon: IconName;
  label: string;
}

function SourceButton({ source, iconOnly }: { source: InvestigationSource; iconOnly?: boolean }): JSX.Element {
  if (source.type === InvestigationSourceType.OnCallIntegration) {
    // The `url` field points to the resolution notes, so we need to construct the link to the alert group.
    source.url = `/a/grafana-oncall-app/alert-groups/${source.id}`;
  }
  const sourceMap = {
    [InvestigationSourceType.AlertManager]: { icon: 'bell', label: 'Alerting' },
    [InvestigationSourceType.AppO11yOperation]: {
      icon: 'application-observability',
      label: 'Application Observability Operation',
    },
    [InvestigationSourceType.AppO11yService]: {
      icon: 'application-observability',
      label: 'Application Observability Service',
    },
    [InvestigationSourceType.CommandPalette]: { icon: 'keyboard', label: 'Command Palette' },
    [InvestigationSourceType.Explore]: { icon: 'compass', label: 'Explore' },
    [InvestigationSourceType.Incident]: { icon: 'fire', label: 'Incident' },
    [InvestigationSourceType.KubernetesCluster]: { icon: 'kubernetes', label: 'Kubernetes Cluster' },
    [InvestigationSourceType.KubernetesNamespace]: { icon: 'kubernetes', label: 'Kubernetes Namespace' },
    [InvestigationSourceType.KubernetesPod]: { icon: 'kubernetes', label: 'Kubernetes Pod' },
    [InvestigationSourceType.KubernetesWorkload]: { icon: 'kubernetes', label: 'Kubernetes Workload' },
    [InvestigationSourceType.ML]: { icon: 'gf-ml', label: 'Machine Learning' },
    [InvestigationSourceType.OnCallUI]: { icon: 'bell', label: 'OnCall Alert Group' },
    [InvestigationSourceType.OnCallIntegration]: { icon: 'bell', label: 'OnCall Alert Group' },
    [InvestigationSourceType.Panel]: { icon: 'dashboard', label: 'Dashboard' },
    [InvestigationSourceType.Rerun]: { icon: 'repeat', label: 'Rerun of Sift Investigation' },
  };
  const sourceInfo = get(sourceMap, source.type, null) as SourceInfo;
  if (sourceInfo) {
    if (iconOnly) {
      return (
        <Tooltip content={sourceInfo.label}>
          <Icon name={sourceInfo.icon} aria-label={sourceInfo.label} size="sm" />
        </Tooltip>
      );
    }
    if (source.type === InvestigationSourceType.ML) {
      return <BasicSourceButton icon="gf-ml" label="Machine Learning" />;
    }
    return (
      <LinkButton icon={sourceInfo.icon} variant="secondary" aria-label={sourceInfo.label} href={source.url}>
        {sourceInfo.label}
      </LinkButton>
    );
  }
  return <BasicSourceButton icon="question-circle" label="Unknown" />;
}

export function Source({ source, iconOnly }: { source: InvestigationSource; iconOnly?: boolean }): JSX.Element | null {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.sourceLayout}>
      {!iconOnly && <div className={styles.fieldLabel}> Originated from:</div>}
      <SourceButton source={source} iconOnly={iconOnly} />
    </div>
  );
}
const getStyles = (theme: GrafanaTheme2) => ({
  sourceLayout: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  basicSourceButton: css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: theme.spacing(0, 0.5);
    line-height: 30px;
  `,
  fieldLabel: css`
    color: ${theme.colors.text.secondary};
  `,
});
