import React from 'react';
import { useMeasure } from 'react-use';

import { LoadingBar, Modal, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useInvestigation, useInvestigationAnalyses } from 'api';

import { getAnalysisDisplayTitle } from '../../utils/utils.sift';
import AnalysisDetails from './AnalysisDetails';

// take an investigation id
// open a modal for that specific investigation
// hold state for the investigation
// based on the analysis chosen, render the appropriate component

// The context required to show the modal.
interface SiftModalContext {
  analysisId: string;
  investigationId: string;
  show?: boolean;
  modalButtons?: React.ComponentType<{ investigationId: string; analysisId: string }>;
  onOpen?: (investigationId?: string, analysisId?: string) => void;
  onClose?: (investigationId?: string, analysisId?: string) => void;
}

interface SiftModalProps {
  context?: SiftModalContext;
}

const SiftModalWrapper = ({ context }: SiftModalProps) => {
  if (context === undefined) {
    return null;
  }
  return <SiftModal context={context} />;
};

const SiftModal = ({ context }: Required<SiftModalProps>) => {
  const styles = useStyles2(getStyles);
  const { investigationId, analysisId, show, onOpen, onClose } = context;
  const [hasOpened, setHasOpened] = React.useState(false);

  // load investigation details and analysis list
  const {
    isError: investigationError,
    data: investigation,
    isLoading: investigationLoading,
  } = useInvestigation(investigationId);
  const {
    isError: analysisError,
    data: analysis,
    isLoading: analysisLoading,
  } = useInvestigationAnalyses(investigationId);
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  if (hasOpened === false) {
    setHasOpened(true);
    if (typeof onOpen === 'function') {
      onOpen(investigationId, analysisId);
    }
  }

  const onDismiss = () => {
    if (typeof onClose === 'function') {
      onClose(investigationId, analysisId);
    }
  };

  // TODO: handle error state
  if (investigationError || analysisError) {
    return null;
  }

  const loading = investigationLoading || analysisLoading;

  const selectedAnalysis = analysis?.find((a) => a.id === analysisId);

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={getAnalysisDisplayTitle(selectedAnalysis?.name, selectedAnalysis?.title)}
      trapFocus={true}
      closeOnEscape={true}
      onDismiss={onDismiss}
    >
      <div ref={ref}>
        {loading ? (
          <LoadingBar width={width} />
        ) : selectedAnalysis !== undefined && investigation !== undefined ? (
          <>
            {(context.modalButtons ?? '') !== '' ? (
              <div className={styles.modalButtons}>
                {context.modalButtons && (
                  <div className={styles.modalButtons}>
                    <context.modalButtons investigationId={investigationId} analysisId={analysisId} />
                  </div>
                )}
              </div>
            ) : null}
            <div>{selectedAnalysis?.result?.message}</div>
            <AnalysisDetails
              analysis={selectedAnalysis}
              investigation={investigation}
              datasources={investigation.datasources}
            />
          </>
        ) : (
          <div>Data not found</div>
        )}
      </div>
    </Modal>
  );
};

const getStyles = () => {
  return {
    modal: css`
      min-width: 1000px;
    `,
    modalButtons: css`
      position: absolute;
      right: 25px;
      top: 7px;
    `,
  };
};

export { SiftModalWrapper as SiftModal, SiftModalContext };
