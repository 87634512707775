// Copied directly from grafana/public/app/pligins/panel/timeseries/utils.ts at version 9.1.0
// and irrelevant parts removed.

import { DataFrame, Field, FieldType, getDisplayProcessor, GrafanaTheme2, isBooleanUnit } from '@grafana/data';
import { GraphFieldConfig, LineInterpolation } from '@grafana/schema';

/**
 * Returns null if there are no graphable fields
 */
export function prepareGraphableFields(series: DataFrame[], theme: GrafanaTheme2): DataFrame[] | null {
  if (series?.length === 0) {
    return null;
  }

  let copy: Field;

  const frames: DataFrame[] = [];

  for (const frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        case FieldType.time:
          hasTimeField = true;
          fields.push(field);
          break;
        case FieldType.number:
          hasValueField = true;
          copy = {
            ...field,
            values: field.values.map((v: number) => {
              if (!(Number.isFinite(v) || v == null)) {
                return null;
              }
              return v;
            }),
          };

          fields.push(copy);
          break; // ok
        case FieldType.boolean:
          hasValueField = true;
          const custom: GraphFieldConfig = field.config?.custom ?? {};
          const config = {
            ...field.config,
            max: 1,
            min: 0,
            custom,
          };

          // smooth and linear do not make sense
          if (custom.lineInterpolation !== LineInterpolation.StepBefore) {
            custom.lineInterpolation = LineInterpolation.StepAfter;
          }

          copy = {
            ...field,
            config,
            type: FieldType.number,
            values: field.values.map((v: number) => {
              if (v == null) {
                return v;
              }
              return Boolean(v) ? 1 : 0;
            }),
          };

          if (isBooleanUnit(config.unit) === false) {
            config.unit = 'bool';
            copy.display = getDisplayProcessor({ field: copy, theme });
          }

          fields.push(copy);
          break;

        case FieldType.string:
        case FieldType.trace:
        case FieldType.geo:
        case FieldType.other:
          break;
      }
    }

    if (hasTimeField && hasValueField) {
      frames.push({
        ...frame,
        fields,
      });
    }
  }

  if (frames.length > 0) {
    return frames;
  }

  return null;
}
