import React, { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import AutoSizer, { HorizontalSize } from 'react-virtualized-auto-sizer';

import {
  AppEvents,
  DataSourceInstanceSettings,
  FieldMatcherID,
  fieldMatchers,
  FieldType,
  GrafanaTheme2,
  outerJoinDataFrames,
  PanelData,
  rangeUtil,
} from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Alert, Collapse, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { JsonParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { useSaveOutlier } from 'api/outlier.api';
import { OutlierDetails, OutlierField } from 'api/types';
import { CappedDataDisclaimer } from 'components/CappedDataDisclaimer';
import { useQueryOutlierResult } from 'components/OutlierAlgorithms';
import { DEFAULT_OUTLIER_ALGORITHM, DEFAULT_SENSITIVITY, OUTLIER_RENDER_CAP_LIMIT } from 'consts';
import { useQueryResult, useTimeRange } from 'hooks';
import { useCapLimit } from 'hooks/useCapLimit';
import { useQueryContext } from 'hooks/useQueryContext';
import { ViewOutlierGraph } from 'projects/Outliers/View';
import { DataQueryWithExpression } from 'types';
import {
  defaultCreateJobTimeRange,
  filterDataKeepingOutliersOnly,
  getlabelColumnValues,
  getSparkRange,
  sampleOutlierSeries,
  sortedOutliersToEndAndReindex,
} from 'utils';

import { useOutlierState } from '../useOutlierState';
import {
  OutlierControls,
  OutlierQueryPanel,
  OutlierSavePanel,
  OutlierSparklines,
  OutlierStatusbar,
  OutlierTable,
  OutlierToolbar,
  SavePanelFields,
} from './';

interface CreateOutlierContentProps {
  initialDataSource: DataSourceInstanceSettings;
}

export const CreateOutlierContent: React.FC<CreateOutlierContentProps> = ({ initialDataSource }) => {
  const styles = useStyles2(getStyles);
  const { data: outlierState, setDatasource } = useOutlierState();
  const [showSavePanel, setShowSavePanel] = useState(false);
  const [sensitivity = DEFAULT_SENSITIVITY, setSensitivity] = useQueryParam('sensitivity', NumberParam);
  const [algorithm = DEFAULT_OUTLIER_ALGORITHM, setAlgorithm] = useQueryParam('algorithm', StringParam);
  const [editId] = useQueryParam('id', StringParam);
  const [editDefaults] = useQueryParam<OutlierDetails>('edit', JsonParam);
  const [queryError, setQueryError] = useState<string | undefined>(undefined);
  const { queryParams, updateQuery } = useQueryContext();

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [selectedSeries, setSelectedSeries] = useState<number | undefined>(undefined);

  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultCreateJobTimeRange()
  );

  useEffectOnce(() => {
    setSensitivity(sensitivity);
    setAlgorithm(algorithm);
  });

  // set a default resolution of 500 datapoints
  // resolution will change when the user resizes their window
  const [{ resolution, resolutionUpdated }, setResolution] = useState({
    resolution: 500,
    resolutionUpdated: false,
  });

  const finalQueries = useMemo(
    () => (queryParams === undefined ? [] : [{ ...queryParams, refId: 'A' }]),
    [queryParams]
  );

  // Fetch query data directly from datasource
  const [data, isRefreshing, runQuery] = useQueryResult(
    finalQueries,
    resolution,
    timeRange,
    timeZone,
    outlierState.datasource?.uid ?? '',
    resolutionUpdated
  );

  // Aligning the data removes datasource differences making it easier to process
  const alignedPanelData = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    setQueryError(data.errors?.[0].message);
    if (data.state !== 'Done') {
      return undefined;
    }

    // `data` is the raw PanelData returned by  the datasource, which can have several different
    // formats. Perform the following to standardize it.
    const alignedFrame = outerJoinDataFrames({
      frames: data.series,
      joinBy: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
      keep: fieldMatchers.get(FieldMatcherID.numeric).get({}),
      keepOriginIndices: true,
    });
    if (alignedFrame === undefined) {
      return data;
    }

    // convert back to panel data format
    const panelData: PanelData = {
      ...data,
      series: [alignedFrame],
    };
    return panelData;
  }, [data]);

  // The results of the outlier algorithm are kept here
  const [outlierResult, outlierConfig, outlierError] = useQueryOutlierResult(
    alignedPanelData,
    finalQueries,
    timeRange,
    timeZone,
    outlierState.datasource?.uid ?? '',
    algorithm === undefined ? DEFAULT_OUTLIER_ALGORITHM : algorithm!,
    sensitivity === null ? DEFAULT_SENSITIVITY : sensitivity
  );

  // This combines the outlier results with the PanelData for consumption elsewhere
  const dataWithOutlierInfo = useMemo(() => {
    if (outlierResult === undefined || alignedPanelData === undefined || alignedPanelData.series.length === 0) {
      return alignedPanelData;
    }

    // Update the outlier intervals defined on the data. This is a bit naughty but
    // really want to avoid copying all this data. So attach info in way that will
    // not clash with typical PanelData use.
    let i = 0;
    alignedPanelData.series[0].fields.forEach((valuesField: OutlierField<number>) => {
      if (valuesField.type === FieldType.time) {
        return;
      }
      if (!('labels' in valuesField)) {
        // Am (ab)using it to pass outlier state around, so create it
        valuesField['labels'] = {};
      }
      const isOutlier = i in outlierResult.outlierIntervals;
      if (isOutlier) {
        valuesField.outlierIntervals = outlierResult.outlierIntervals[i];
        valuesField.labels!.isOutlier = 'True';
      } else {
        delete valuesField.outlierIntervals;
        delete valuesField.labels!.isOutlier;
      }
      i += 1;
    });

    return alignedPanelData;
  }, [alignedPanelData, outlierResult]);

  useEffect(() => {
    setDatasource(initialDataSource, false);
  }, [setDatasource, initialDataSource]);

  const onQueryUpdate = (updatedQuery: DataQueryWithExpression) => {
    setIsDirty(true);
    updateQuery(updatedQuery);
    setSelectedSeries(undefined);
  };

  const onDatasourceChange = () => {
    updateQuery(undefined);
  };

  const onAlgorithmChange = (v: string | undefined) => {
    if (algorithm !== v) {
      setAlgorithm(v);
      setIsDirty(true);
      setSelectedSeries(undefined);
    }
  };
  const onSensitivityChange = (v: number | undefined) => {
    if (v !== sensitivity) {
      setSensitivity(v);
      setIsDirty(true);
    }
  };

  const onSeriesSelection = (seriesIndex: number | undefined) => {
    setSelectedSeries(seriesIndex);
  };

  const onOpenSavePanel = () => {
    setShowSavePanel(true);
  };

  const onCloseSavePanel = () => {
    setShowSavePanel(false);
  };

  const currentQueryParams = {
    queryParams: queryParams === undefined ? { refId: 'Z' } : queryParams!,
    algorithm: algorithm === undefined ? DEFAULT_OUTLIER_ALGORITHM : algorithm!,
    sensitivity: sensitivity === undefined ? DEFAULT_SENSITIVITY : sensitivity!,
    config: outlierConfig,
    startTime: timeRange.from.toISOString().substring(0, timeRange.from.toISOString().indexOf('.')),
    endTime: timeRange.to.toISOString().substring(0, timeRange.to.toISOString().indexOf('.')),
    interval: rangeUtil.calculateInterval(timeRange, resolution, '1m').intervalMs / 1000,
    datasource: outlierState.datasource === undefined ? initialDataSource : outlierState.datasource,
  };

  const { mutateAsync: saveOutlier } = useSaveOutlier(editId);
  const errorState = queryError !== undefined ? queryError : outlierError;

  const isOutlierValid = () => {
    return (
      !isRefreshing &&
      data !== undefined &&
      currentQueryParams.queryParams !== undefined && // TODO: how to check input valid?
      currentQueryParams.algorithm !== undefined &&
      (currentQueryParams.algorithm === 'dbscan' ? currentQueryParams.config !== undefined : true) &&
      currentQueryParams.datasource !== undefined &&
      currentQueryParams.sensitivity !== undefined &&
      errorState === undefined &&
      outlierResult !== undefined
    );
  };

  const onSaveOutlier = ({ id, name, description, metric }: SavePanelFields) => {
    saveOutlier({
      ...currentQueryParams,
      id,
      name,
      description,
      metric,
    }).then(() => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload:
          editId !== undefined
            ? ['Outlier detector edited', `Outlier detector '${name}' has been edited.`]
            : ['Outlier detector created', `Outlier detector '${name}' has been created.`],
      });
      setIsDirty(false);
    });
  };

  const sortedData = sortedOutliersToEndAndReindex(dataWithOutlierInfo);
  const outlierOnlyData = filterDataKeepingOutliersOnly(sortedData);

  const labelColumnValues = getlabelColumnValues(outlierOnlyData);
  const sparkRange = getSparkRange(dataWithOutlierInfo);
  const outlierCount = outlierOnlyData?.series[0]?.fields.length ?? 0;

  const capLimit = OUTLIER_RENDER_CAP_LIMIT; // maximum number of series to show initially
  const [cappedData, didCap, originalCount, setShouldCap] = useCapLimit(sortedData, capLimit, sampleOutlierSeries);

  return (
    <div className={styles.pageContainer}>
      <OutlierToolbar
        hasDataSource={true}
        isDirty={isDirty}
        isEdit={editId !== undefined}
        isRefreshing={isRefreshing}
        isValid={isOutlierValid()}
        timeRange={timeRange}
        timeZone={timeZone}
        onRefresh={runQuery}
        onChangeTimeRange={onChangeTimeRange}
        onChangeTimeZone={onChangeTimeZone}
        onZoomTimeRange={onZoomTimeRange}
        onOpenSavePanel={onOpenSavePanel}
      />
      <div className={styles.contentContainer}>
        <div className={styles.queryContainer}>
          <OutlierQueryPanel
            datasource={outlierState.datasource}
            timeRange={timeRange}
            onQueryUpdate={onQueryUpdate}
            onDatasourceChange={onDatasourceChange}
          />
        </div>
        {errorState !== undefined && (
          <div className={styles.errorAlertContainer}>
            <Alert title={errorState} severity="error" />
          </div>
        )}
        <OutlierControls
          onSensitivityChange={onSensitivityChange}
          sensitivity={sensitivity!}
          algorithm={algorithm!}
          onAlgorithmChange={onAlgorithmChange}
          enabled={outlierError === undefined}
        />
        <Collapse label="Graph" loading={isRefreshing} isOpen={true}>
          {didCap && (
            <CappedDataDisclaimer originalCount={originalCount} capLimit={capLimit} setShouldCap={setShouldCap} />
          )}
          <AutoSizer disableHeight>
            {({ width }: HorizontalSize) => {
              if (width === undefined || width < 3) {
                return <div>Window is too small to render graph, please increase size.</div>;
              }

              if (resolution !== width) {
                setResolution({ resolution: width, resolutionUpdated: true });
              }

              return (
                <div style={{ width }}>
                  <ViewOutlierGraph
                    data={cappedData}
                    onChangeTimeRange={onChangeTimeRange}
                    timeZone={timeZone}
                    width={width}
                    selectedIndex={selectedSeries}
                    outlierResults={outlierResult}
                  />
                </div>
              );
            }}
          </AutoSizer>
        </Collapse>
        {outlierError === undefined && (
          <Collapse label="Summary" loading={isRefreshing} isOpen={true}>
            <OutlierStatusbar data={alignedPanelData} outlierCount={outlierCount} />
            {outlierCount > 0 ? (
              <>
                <OutlierSparklines
                  alignedData={sortedData}
                  sparkRange={sparkRange}
                  onSeriesSelection={onSeriesSelection}
                  selectedIndex={selectedSeries}
                />
                <OutlierTable
                  alignedData={outlierOnlyData}
                  sparkRange={sparkRange}
                  labelColumnValues={labelColumnValues}
                  onSeriesSelection={onSeriesSelection}
                  selectedIndex={selectedSeries}
                />
              </>
            ) : (
              <></>
            )}
          </Collapse>
        )}
      </div>
      <OutlierSavePanel
        isOpen={showSavePanel}
        isEdit={editId !== undefined}
        onDismiss={onCloseSavePanel}
        onSaveOutlier={onSaveOutlier}
        defaults={editDefaults}
      />
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    pageContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
    `,
    loadingContainer: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      height: calc(100vh - 350px);
    `,
    queryContainer: css`
      background-color: ${theme.colors.background.primary};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 3px;
      padding: ${theme.spacing(1)};
      margin-bottom: 30px;
    `,
    feedbackText: css`
      font-size: 14px;
      color: #ddd;
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: space-between;
    `,
    errorAlertContainer: css`
      background-color: ${theme.colors.background.primary};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 3px;
      padding: ${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)};
      margin-bottom: 30px;
    `,
  };
}
