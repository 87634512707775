import React from 'react';

import { TimeRange } from '@grafana/data';
import {
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeCompare,
} from '@grafana/scenes';

import { ExploreButton } from 'components/ExploreButton';
import { useScene } from 'hooks';
import { SiftModalData } from 'types';

interface MetricQueryDetails {
  expr: string;
  message?: string;
}

export function MetricQuery({ investigation, analysis, datasources }: SiftModalData): React.ReactElement {
  if (!analysis.result.successful) {
    return <div>Failed to run check, see message above for details.</div>;
  }

  const datasourceUid = datasources.prometheusDatasource.uid;
  const timeRange = investigation.timeRange;
  const title = analysis.title;
  const details = analysis.result.details as unknown as MetricQueryDetails;

  return (
    <MetricQueryScene
      analysisId={analysis.id}
      datasourceUid={datasourceUid}
      timeRange={timeRange}
      title={title}
      details={details}
    />
  );
}

interface MetricQuerySceneProps {
  analysisId: string;
  datasourceUid: string;
  timeRange: TimeRange;
  title: string;
  details: MetricQueryDetails;
}

function MetricQueryScene({
  analysisId,
  datasourceUid,
  timeRange,
  title,
  details,
}: MetricQuerySceneProps): React.ReactElement {
  const getScene = () => {
    return new EmbeddedScene({
      $timeRange: new SceneTimeRange({
        to: timeRange.to.format(),
        from: timeRange.from.format(),
      }),
      controls: [new SceneControlsSpacer(), new SceneTimePicker({}), new SceneTimeRangeCompare({})],
      body: new SceneGridLayout({
        isDraggable: true,
        isResizable: true,
        children: [
          new SceneGridItem({
            x: 0,
            y: 0,
            height: 8,
            width: 24,
            body: PanelBuilders.text()
              .setOption('content', details.message ?? '')
              .setTitle('Message')
              .setDescription('Message template populated by query results')
              .setDisplayMode('transparent')
              .build(),
          }),
          new SceneGridItem({
            x: 4,
            y: 0,
            height: 16,
            width: 24,
            body: PanelBuilders.timeseries()
              .setTitle(title)
              .setData(
                new SceneQueryRunner({
                  datasource: {
                    type: 'prometheus',
                    uid: datasourceUid,
                  },
                  queries: [
                    {
                      refId: 'A',
                      ...details,
                    },
                  ],
                })
              )
              .setOption('legend', { showLegend: true })
              .setHeaderActions(
                <ExploreButton queries={[details.expr]} datasourceUid={datasourceUid} timeRange={timeRange} />
              )
              .setDescription(details.expr)
              .build(),
          }),
        ],
      }),
    });
  };
  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}
