import React, { Children, useState } from 'react';

import { FieldType, GrafanaTheme2, PanelData } from '@grafana/data';
import { Pagination, RadioButtonList, useStyles2, useTheme2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { OutlierField } from 'api/types';
import { Sparkline } from 'components/Sparkline';
import { OutlierLabels } from 'types';
import { getLineColor, getOutlierColors, SparkRange } from 'utils';

interface OutlierTableProps {
  alignedData?: PanelData;
  sparkRange: SparkRange;
  labelColumnValues: { [key: string]: string[] };
  selectedIndex: number | undefined;
  onSeriesSelection?: (seriesIndex: number | undefined) => void;
  selectable?: boolean;
}

export function OutlierTable({
  alignedData,
  sparkRange,
  labelColumnValues,
  selectedIndex,
  onSeriesSelection,
  selectable = true,
}: OutlierTableProps): JSX.Element {
  const { isDark } = useTheme2();
  const styles = useStyles2((theme) => getStyles(theme, selectable));
  const colors = getOutlierColors(isDark);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const pageMin = (page - 1) * pageSize + 1;
  const pageMax = page * pageSize;

  if (alignedData?.series?.[0] === undefined) {
    return <></>;
  }

  const pageCount =
    alignedData === undefined ? 0 : Math.ceil(((alignedData.series[0]?.fields.length ?? 0) + 1) / pageSize);

  // if filters alter the number of pages in the data set, reset current page to 0
  if (page < 1 || page > pageCount) {
    setPage(1);
  }

  const size = {
    height: 48,
    width: 96,
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <Pagination
          numberOfPages={pageCount}
          currentPage={page}
          onNavigate={(i) => {
            setPage(i);
          }}
          hideWhenSinglePage={true}
        />
      </div>

      <table className={styles.table}>
        <tr>
          {selectable && <th className={styles.checkboxColumn}></th>}
          <th className={styles.tableHeader} style={{ width: size.width + 10 }} scope="col"></th>
          {Object.keys(labelColumnValues).map((columnTitle: string) => (
            <th key={`col-${columnTitle}`} className={styles.dynamicHeader} scope="col">
              {columnTitle} ({labelColumnValues[columnTitle]!.length})
            </th>
          ))}
        </tr>
        {Children.toArray(
          [...alignedData.series[0].fields]
            .filter((f) => f.type !== FieldType.time)
            .reverse()
            .map((field: OutlierField<number>, idx: number) => {
              if (idx + 1 < pageMin || idx + 1 > pageMax) {
                return <></>;
              }

              const { isOutlier, ...labels } = field.labels as OutlierLabels;
              const isOutlierBool = isOutlier === 'True';
              return (
                <tr key={`row-${idx ?? ''}`}>
                  {selectable && (
                    <td className={styles.checkboxRow}>
                      <div
                        onMouseUp={() => {
                          if (selectedIndex === field.index) {
                            onSeriesSelection?.(undefined);
                          } else {
                            onSeriesSelection?.(field.index);
                          }
                        }}
                      >
                        <RadioButtonList
                          name={`outlier${field.index ?? ''}`}
                          options={[{ value: Number(field.index) }]}
                          value={selectedIndex === field.index ? field.index : undefined}
                        />
                      </div>
                    </td>
                  )}
                  <td
                    className={isOutlierBool ? styles.tableCellOutlier : styles.tableCell}
                    style={{ width: size.width + 10 }}
                  >
                    <div className={styles.sparkGraph} style={{ width: size.width + 1 }}>
                      <Sparkline
                        data={field}
                        height={size.height}
                        width={size.width}
                        fieldName={field.labels?.__name__ ?? ''}
                        minY={sparkRange.min!}
                        maxY={sparkRange.max!}
                        lineColor={getLineColor(colors, isOutlierBool, selectedIndex === field.index)}
                        selectedIndex={field.index}
                        isSelected={selectedIndex === field.index}
                        onClick={onSeriesSelection}
                      />
                    </div>
                  </td>
                  {Object.keys(labelColumnValues).map((columnKey: string) => (
                    <td
                      key={`label-${labels[columnKey] as string}`}
                      className={isOutlierBool ? styles.tableCellOutlier : styles.tableCell}
                      style={{ textAlign: 'left' }}
                      scope="col"
                    >
                      {labels[columnKey] ?? field.name}
                    </td>
                  ))}
                </tr>
              );
            })
        )}
      </table>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2, selectable: boolean) => {
  return {
    container: css`
      display: flex;
      flex-wrap: wrap;
      width: '100%';
      padding: 8px 0px;
    `,
    group: css`
      padding: 10px;
    `,
    header: css`
      width: 100%;
      font-weight: bold;
    `,
    sparkGraph: css({
      border: `solid 1px ${theme.colors.border.weak}`,
      margin: '4px',
      cursor: selectable ? 'cursor' : 'auto',
    }),
    sparkOutlier: css`
      margin: 0px 4px 4px 0px;
    `,
    spark: css`
      margin: 0px 4px 4px 0px;
    `,
    sparkName: css`
      font-size: 10px;
      overflow-wrap: break-word;
    `,
    table: css`
      width: 100%;
    `,
    tableHeader: css`
      color: ${theme.colors.info.shade};
      font-family: ${theme.typography.fontFamily};
      padding: 8px 8px 8px 20px;
      text-align: center;
      border-bottom: solid 1px ${theme.colors.border.weak};
    `,
    dynamicHeader: css`
      color: ${theme.colors.info.shade};
      font-family: ${theme.typography.fontFamily};
      padding: 8px 8px 8px 20px;
      text-align: left;
      border-bottom: solid 1px ${theme.colors.border.weak};
    `,
    tableCell: css`
      border-bottom: solid 1px ${theme.colors.border.weak};
      padding: 8px 8px 8px 20px;
    `,
    tableCellOutlier: css`
      background-color: ${theme.colors.background.secondary};
      border-bottom: solid 1px ${theme.colors.border.weak};
      padding: 8px 8px 8px 20px;
    `,
    controls: css`
      width: 100%;
      display: flex;
      justify-content: flex-end;
    `,
    checkboxColumn: css`
      width: 35px;
      text-align: center;
      border-bottom: solid 1px ${theme.colors.border.weak};
    `,
    checkboxRow: css`
      width: 35px;
      text-align: center;
      background-color: ${theme.colors.background.secondary};
      border-bottom: solid 1px ${theme.colors.border.weak};
      padding: 8px 0px 8px 20px;
    `,
  };
};
