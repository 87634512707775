import { AlertVariant } from '@grafana/ui';

type BackendError = {
  isHandled: boolean;
  data: {
    message: string;
    status: AlertVariant;
  };
};

export function isBackendError(error: unknown): error is BackendError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data &&
    typeof error.data.error === 'string'
  );
}
