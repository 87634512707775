import React from 'react';

import { DataSourceInstanceSettings, DataSourceJsonData, TimeRange } from '@grafana/data';

import { css } from '@emotion/css';

import { DataQueryWithExpression } from 'types';

import { DatasourceQueryEditor } from '../DatasourceQueryEditor';
import { useOutlierState } from '../useOutlierState';

interface OutlierQueryPanelProps {
  datasource: DataSourceInstanceSettings<DataSourceJsonData> | undefined;
  timeRange: TimeRange;
  onQueryUpdate: (query: DataQueryWithExpression) => void;
  onDatasourceChange: () => void;
}

export function OutlierQueryPanel({
  datasource,
  timeRange,
  onQueryUpdate,
  onDatasourceChange,
}: OutlierQueryPanelProps): JSX.Element | null {
  const { setDatasource, setQuery } = useOutlierState();
  return (
    <div className={styles.queryPanel}>
      <DatasourceQueryEditor
        datasource={datasource}
        onUpdateDatasource={(ds: DataSourceInstanceSettings) => {
          setDatasource(ds);
          onDatasourceChange();
        }}
        onQueryChange={(query: DataQueryWithExpression) => {
          setQuery(query);
        }}
        onRunQuery={(query: DataQueryWithExpression) => {
          setQuery(query);
          onQueryUpdate(query);
        }}
        timeRange={timeRange}
      />
    </div>
  );
}

const styles = {
  queryPanel: css`
    width: 100%;
    margin-bottom: 20px;
  `,
};
