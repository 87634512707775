import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input, TextArea } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import { CheckConfig, CheckConfigWithDatasource, CheckFormProps, MetricQueryConfig } from 'types';
import { getAnalysisDisplayTitle, parseInputToString } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithExample } from './Components/LabelWithExample';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

const queryPlaceholder = 'node_load5{cluster="$cluster", namespace="$namespace"}';
const templatePlaceholder = `{{ range .series }}
- {{ .Labels }} had value {{ .LastValue }} at {{ .LastTimestamp.UTC.Format \"2006-01-02T15:04:05Z07:00\" }}
{{ end }}`;

function MetricQueryForm({ check, onClose }: CheckFormProps<MetricQueryConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { prometheusFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<MetricQueryConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues: CheckConfigWithDatasource<MetricQueryConfig> = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
    autoDetectDatasource: (check.config?.prometheusDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData = {
          id: check.id ?? undefined,
          name: check.name,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            prometheusDatasourceUid: autoDetectDatasource ? '' : config.prometheusDatasourceUid,
          },
          title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, formState, setValue, watch }) => {
        const expr = watch('config.expr');
        const message = watch('config.message');
        const prometheusDatasourceUid = watch('config.prometheusDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} />
            </Field>

            <Field
              label={
                <LabelWithExample
                  label={<LabelWithHelpText label="Query" checkName="metricQuery" fieldName="expression" />}
                  tooltip="Use example query"
                  value={expr}
                  setValue={() => setValue('config.expr', queryPlaceholder)}
                />
              }
            >
              <TextArea
                rows={1}
                {...register('config.expr', { setValueAs: parseInputToString })}
                placeholder={queryPlaceholder}
              />
            </Field>

            <Field
              label={
                <LabelWithExample
                  label={<LabelWithHelpText label="Message template" checkName="metricQuery" fieldName="message" />}
                  tooltip="Use example template"
                  value={message}
                  setValue={() => setValue('config.message', templatePlaceholder)}
                />
              }
            >
              <TextArea
                rows={4}
                {...register('config.message', { setValueAs: parseInputToString })}
                placeholder={templatePlaceholder}
              />
            </Field>

            <DataSourceSelector
              title="Prometheus Datasource"
              dsType="prometheus"
              dsUid={prometheusDatasourceUid ?? ''}
              autoDetectDatasource={autoDetectDatasource}
              filter={prometheusFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.prometheusDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { MetricQueryForm };
