import { SceneObject } from '@grafana/scenes';

export type SceneCache = Map<string | object, SceneObject>;
const globalSceneCache: SceneCache = new Map<string | object, SceneObject>();

/**
 * Hook to create a scene object and cache it for the lifetime of the provided cache,
 * or the lifetime of the app if no cache is provided.
 *
 * @param factory - a factory function to create the scene.
 * @param key - A unique key to identify the scene. This must be legitimately unique
 *              to the visualisation, not just the type of scene. For examples, if
 *              you are creating a scene holding a single panel representing some
 *              subset of a Sift analysis' results, include the ID of the analysis
 *              and a key to uniquely identify the subset of the analysis results
 *              you're visualising.
 * @param cache - the cache to use. Defaults to a global cache with the lifetime of the app.
 *                This can be used to provide a cache with a shorter lifetime; for example,
 *                if your component may be unmounted and remounted but the scene factory
 *                captures a state callback, you may need to use a cache with the lifetime of
 *                your component or its parent. Otherwise the old scene will be reused but
 *                the state callback will be stale.
 */
export function useScene(factory: () => SceneObject, key?: string, cache: SceneCache = globalSceneCache): SceneObject {
  const cacheKey = key ?? factory;
  const cachedScene = cache.get(cacheKey);

  if (cachedScene) {
    return cachedScene;
  }

  const newScene = factory();
  cache.set(cacheKey, newScene);

  return newScene;
}
