import { Range } from 'react-date-range';

import { EventInput, HolidayOccurrence } from 'types';

export const FormatMonthHolidaysDate = (data: HolidayOccurrence[]): EventInput[] => {
  return data.map((item: HolidayOccurrence) => {
    return {
      id: generateId(),
      start: item.startTime,
      end: item.endTime,
      title: item.name !== undefined ? item.name : '',
    };
  });
};

export const FormatQuarterHolidaysDate = (data: HolidayOccurrence[]): Range[] => {
  return data.map((item: HolidayOccurrence) => {
    return {
      startDate: new Date(item.startTime),
      endDate: new Date(item.endTime),
    };
  });
};

const generateId = (): string => {
  return '_' + Math.random().toString(36).substring(2, 11);
};
