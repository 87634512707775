import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

const activeShadow = '0px 2px 5px 1px var(--card-active-shine-color), 0px 0px 2px 1px var(--card-active-shine-color), ';

const FlatBox = (box: string, active: boolean) =>
  cx(
    box,
    css`
      ${active
        ? css`
            outline: 1px solid var(--card-active-color);
          `
        : css``};
    `
  );
const SubtleBox = (box: string, active: boolean) =>
  cx(
    box,
    css`
      ${active
        ? css`
            outline: 1px solid var(--card-active-color);
          `
        : css``};
      box-shadow: ${active ? activeShadow : ''} ${ELEVATIONS.small};
    `
  );
const MediumBox = (box: string, active: boolean) =>
  cx(
    box,
    css`
      ${active
        ? css`
            outline: 1px solid var(--card-active-color);
          `
        : css``};
      box-shadow: ${active ? activeShadow : ''} ${ELEVATIONS.medium};
    `
  );
const ElevatedBox = (box: string, active: boolean) =>
  cx(
    box,
    css`
      ${active
        ? css`
            outline: 1px solid var(--card-active-color);
          `
        : css``};

      box-shadow: ${active ? activeShadow : ''} ${ELEVATIONS.large};
    `
  );

export default function Card({
  className,
  height = 'medium',
  variant = 'default',
  borderless = false,
  active = false,
  shine = false,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  active?: boolean;
  borderless?: boolean;
  shine?: boolean;
  height?: 'elevated' | 'flat' | 'medium' | 'subtle';
  variant?:
    | 'alternate-gradient'
    | 'blue'
    | 'default'
    | 'gradient'
    | 'green'
    | 'purple'
    | 'red'
    | 'secondary-gray'
    | 'transparent';
}): JSX.Element {
  const styles = useStyles2(getStyles);
  let color: string;
  let box: string;
  let image: string;
  let borders: string;

  if (borderless) {
    borders = css`
      --card-border-color: transparent;
    `;
  } else {
    borders = css``;
  }

  if (shine) {
    image = styles.ShineBG;
  } else {
    image = css`
      --card-image: linear-gradient(transparent, transparent);
    `;
  }

  switch (variant) {
    case 'transparent':
      color = styles.TransparentTheme;
      break;
    case 'secondary-gray':
      color = styles.SecondaryGrayTheme;
      break;
    case 'red':
      color = styles.RedTheme;
      break;
    case 'purple':
      color = styles.PurpleTheme;
      break;
    case 'blue':
      color = styles.BlueTheme;
      break;
    case 'green':
      color = styles.GreenTheme;
      break;
    case 'gradient':
      color = styles.GradientTheme;
      break;
    case 'alternate-gradient':
      color = styles.AlternateGradientCardTheme;
      break;
    default:
    case 'default':
      color = styles.GrayTheme;
      break;
  }

  switch (height) {
    case 'flat':
      box = FlatBox(styles.Box, active);
      break;
    case 'elevated':
      box = ElevatedBox(styles.Box, active);
      break;
    case 'subtle':
      box = SubtleBox(styles.Box, active);
      break;
    case 'medium':
    default:
      box = MediumBox(styles.Box, active);
      break;
  }

  return <div className={cx(className, color, image, borders, box)} {...props} />;
}

const ELEVATIONS = {
  small: `
    0.5px 0.8px 1.4px hsl(var(--shadow-color) / 0.15),
    0.4px 1.5px 3.0px -0.4px hsl(var(--shadow-color) / 0.20),
    1.5px 2.6px 5.0px -1.8px hsl(var(--shadow-color) / 0.20)
  `,
  medium: `
    0.2px 1px 1px hsl(var(--shadow-color) / 0.32),
    0.4px 1px 1.2px -0.9px hsl(var(--shadow-color) / 0.3),
    1.1px 2.6px 3px -1.9px hsl(var(--shadow-color) / 0.29),
    2.8px 6.4px 7.5px -2.8px hsl(var(--shadow-color) / 0.27)
  `,
  large: `
    0.2px 0.4px 0.5px hsl(var(--shadow-color) / 0.3),
    0.7px 1.6px 1.9px -0.4px hsl(var(--shadow-color) / 0.29),
    1.3px 2.9px 3.4px -0.8px hsl(var(--shadow-color) / 0.28),
    2.1px 4.8px 5.7px -1.2px hsl(var(--shadow-color) / 0.27),
    3.4px 7.9px 9.3px -1.6px hsl(var(--shadow-color) / 0.27),
    5.4px 10.5px 12.7px -2px hsl(var(--shadow-color) / 0.26),
    8.4px 15.2px 15.6px -2.4px hsl(var(--shadow-color) / 0.25),
    10.4px 17.5px 20.6px -2.8px hsl(var(--shadow-color) / 0.24)
  `,
};

function getStyles({ isDark }: GrafanaTheme2) {
  const shadow = isDark ? '2%' : '70%';
  return {
    ShineBG: css`
      --card-image: url(public/plugins/grafana-ml-app/assets/infocard-${isDark ? 'd' : 'l'}.svg);
    `,
    PurpleTheme: css`
      --card-border-color: hsla(281deg, 80%, ${isDark ? '30%' : '70%'}, 0.5);
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 265deg 30% ${shadow};
      ${isDark
        ? css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(265deg 38% 25%) 0%,
              hsl(266deg 40% 21%) 34%,
              hsl(268deg 43% 16%) 69%,
              hsl(270deg 49% 11%) 92%,
              hsl(281deg 100% 6%) 100%
            );
          `
        : css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(265deg 38% 98%) 0%,
              hsl(266deg 40% 94%) 34%,
              hsl(268deg 43% 88%) 69%,
              hsl(270deg 49% 78%) 92%,
              hsl(281deg 100% 76%) 100%
            );
          `}
    `,

    TransparentTheme: css`
      --card-border-color: rgba(0, 0, 0, 0.1);
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 0deg 0% ${shadow};
    `,

    SecondaryGrayTheme: css`
      --card-border-color: ${isDark ? 'hsla(10, 0%, 25%, 0.4)' : 'hsla(10, 0%, 65%, 0.2)'};
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 20deg 5% ${shadow};

      ${isDark
        ? css`
            --card-gradient: linear-gradient(145deg, hsl(220deg 12% 15%) 0%, hsl(220deg 11% 15%) 100%);
          `
        : css`
            --card-gradient: linear-gradient(145deg, hsl(180deg 5% 98%) 0%, hsl(180deg 10% 99%) 100%);
          `}
    `,

    GrayTheme: css`
      --card-border-color: ${isDark ? 'hsla(10, 0%, 25%, 0.5)' : 'hsla(10, 0%, 65%, 0.5)'};
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 20deg 5% ${shadow};

      ${isDark
        ? css`
            --card-gradient: linear-gradient(145deg, hsl(220deg 10% 13%) 0%, hsl(220deg 10% 11%) 100%);
          `
        : css`
            --card-gradient: linear-gradient(145deg, hsl(180deg 5% 96%) 0%, hsl(180deg 10% 96%) 100%);
          `}
    `,

    BlueTheme: css`
      --card-border-color: hsla(220deg, 100%, 50%, 0.5);
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 220deg 30% ${shadow};

      ${isDark
        ? css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(210deg 100% 18%) 0%,
              hsl(213deg 100% 17%) 34%,
              hsl(217deg 100% 15%) 69%,
              hsl(221deg 100% 13%) 92%,
              hsl(230deg 100% 11%) 100%
            );
          `
        : css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(210deg 100% 98%) 0%,
              hsl(213deg 100% 97%) 34%,
              hsl(217deg 100% 94%) 69%,
              hsl(221deg 100% 90%) 92%,
              hsl(230deg 100% 87%) 100%
            );
          `}
    `,

    RedTheme: css`
      --card-border-color: hsla(10deg, 100%, 50%, 0.5);
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 0 30% ${shadow};
      ${isDark
        ? css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(351deg 80% 14%) 0%,
              hsl(349deg 83% 13%) 34%,
              hsl(348deg 73% 11%) 69%,
              hsl(348deg 66% 10%) 92%,
              hsl(346deg 80% 6%) 100%
            );
          `
        : css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(0deg 70% 98%) 0%,
              hsl(5deg 80% 95%) 19%,
              hsl(7deg 90% 92%) 45%,
              hsl(10deg 100% 90%) 73%,
              hsl(12deg 100% 88%) 100%
            );
          `}
    `,

    GreenTheme: css`
      --card-border-color: hsla(120deg, 100%, 40%, 0.5);
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: 120deg 30% ${shadow};
      ${isDark
        ? css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(121deg 80% 14%) 0%,
              hsl(139deg 80% 13%) 19%,
              hsl(151deg 80% 11%) 45%,
              hsl(161deg 80% 9%) 73%,
              hsl(168deg 80% 9%) 100%
            );
          `
        : css`
            --card-gradient: linear-gradient(
              145deg,
              hsl(121deg 100% 98%) 0%,
              hsl(139deg 100% 95%) 19%,
              hsl(151deg 100% 92%) 45%,
              hsl(161deg 100% 90%) 73%,
              hsl(168deg 100% 88%) 100%
            );
          `}
    `,

    AlternateGradientCardTheme: css`
      color: ${isDark ? 'white' : 'inherit'};
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: ${isDark ? '15deg 100% 2%' : '15deg 20% 70%'};
      --card-border-color: hsla(10deg, 100%, 60%, 0.5);
      ${isDark
        ? css`
            --card-gradient: linear-gradient(345deg, #fcc15a 0%, #fd855e 100%);
          `
        : css`
            --card-gradient: linear-gradient(
              345deg,
              hsl(15deg 89% 53%) 0%,
              hsl(26deg 96% 51%) 5%,
              hsl(34deg 100% 50%) 13%,
              hsl(41deg 100% 50%) 32%,
              hsl(48deg 100% 51%) 100%
            );
          `}
    `,

    GradientTheme: css`
      --card-active-color: #5794f2;
      --card-active-shine-color: #5794f299;
      --shadow-color: ${isDark ? '22deg 100% 2%' : '23deg 27% 69%'};
      --card-border-color: ${isDark ? 'hsla(6deg, 60%, 80%, 0.14)' : 'hsla(290deg, 100%, 75%, 0.37)'};
      --card-gradient-orange: linear-gradient(
        325deg,
        hsl(36deg 96% 66%) 0%,
        hsl(29deg 96% 66%) 52%,
        hsl(21deg 96% 66%) 77%,
        hsl(10deg 90% 67%) 91%,
        hsl(356deg 76% 68%) 99%,
        hsl(341deg 61% 69%) 100%
      );
      --card-gradient: url(public/img/g8_login_${isDark ? 'dark' : 'light'}.svg);
    `,

    Box: css`
      border-radius: 3px;
      outline-offset: -1px;
      outline: 1px solid var(--card-border-color);
      background-image: var(--card-image), var(--card-gradient);
      background-repeat: no-repeat, no-rpead;
      background-size: cover;
      background-clip: padding-box;
    `,
  };
}
