import React from 'react';
import { useParams } from 'react-router-dom';

import { config, PluginPage } from '@grafana/runtime';

import { useAlertRuleInstances, useGetOutlier } from 'api';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';

import { ViewOutlierContent } from './ViewOutlierContent';

export const ViewOutlier: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetOutlier(id!);
  const { data: alertRules, isLoading: isAlertsLoading } = useAlertRuleInstances();
  const defaultTitle = 'View detector';

  if (isError) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <div>Error</div>
      </PluginPage>
    );
  }

  if (id == null || isLoading || isAlertsLoading || data == null) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <LoadingIndicator />
      </PluginPage>
    );
  }

  if (data == null) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <div>No outlier detector found</div>
      </PluginPage>
    );
  }

  const jobAlertRules = (alertRules ?? []).filter(
    (rule) => rule.annotations?.['grafana-ml-outlier-detector-id'] === id
  );

  return (
    <PluginPage pageNav={{ text: data.name }}>
      <QueryRunnerProvider>
        <ViewOutlierContent outlier={data} alertingEnabled={config.unifiedAlertingEnabled} alertRules={jobAlertRules} />
      </QueryRunnerProvider>
    </PluginPage>
  );
};
