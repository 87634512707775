import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import {
  Button,
  Field,
  Label as FieldLabel,
  HorizontalGroup,
  Icon,
  IconButton,
  Text,
  TextLink,
  Tooltip,
  useStyles2,
  VerticalGroup,
} from '@grafana/ui';

import { css } from '@emotion/css';
import { Labels } from 'grafana-ml-common/components/Labels';

import { Label, MatchType } from 'types';

// See https://prometheus.io/docs/concepts/data_model/#metric-names-and-labels
// for the regexes for label names.
const selectorRegex = /([a-zA-Z_][a-zA-Z0-9_]*)\s*(=~|!=|=|!~)\s*"?([^"]*)"?/;

type Conditions = Label[][];

export function convertIf(input?: string[]): Conditions {
  return input?.map(convertPromQL) ?? [];
}

function convertPromQL(input: string): Label[] {
  return input
    .split(',')
    .map(parseSelector)
    .filter((selector): selector is Label => selector !== null);
}

function parseSelector(input: string): Label | null {
  const match = input.match(selectorRegex);
  if (match === null) {
    return null;
  }
  const type = parseMatchType(match[2]);
  if (type === null) {
    return null;
  }
  return {
    name: match[1],
    type,
    value: match[3],
  };
}

function parseMatchType(input: string): MatchType | null {
  switch (input) {
    case '=':
      return MatchType.Equal;
    case '!=':
      return MatchType.NotEqual;
    case '=~':
      return MatchType.Regexp;
    case '!~':
      return MatchType.NotRegexp;
    default:
      return null;
  }
}

function stringifyMatchType(input: MatchType): string {
  switch (input) {
    case MatchType.Equal:
      return '=';
    case MatchType.NotEqual:
      return '!=';
    case MatchType.Regexp:
      return '=~';
    case MatchType.NotRegexp:
      return '!~';
  }
}

// Convert the array of label arrays storing form state into a string array
// suitable for the API.
export function convertConditions(input: Conditions): string[] {
  return input.map(convertLabel);
}

function convertLabel(input: Label[]): string {
  const matchers = input
    .map((label) => `${label.name}${stringifyMatchType(label.type ?? MatchType.Equal)}"${label.value}"`)
    .join(',');
  return `{${matchers}}`;
}

function ConditionsInput({ onChange, conditions }: Props): React.ReactElement {
  const styles = useStyles2(getStyles);
  return (
    <>
      <VerticalGroup spacing="sm">
        {conditions.flatMap((selector, i) => [
          i !== 0 ? (
            <HorizontalGroup width="100%" justify="center">
              <Text weight="light" variant="bodySmall">
                OR
              </Text>
            </HorizontalGroup>
          ) : null,
          <div key={i} className={styles.labelRow}>
            <Labels
              values={selector}
              onChange={(selector) => {
                onChange([...conditions.slice(0, i), selector, ...conditions.slice(i + 1)]);
              }}
              showErrors={false}
              validation={{ valid: true, messages: [] }}
            />
            <IconButton
              name="trash-alt"
              aria-label="Remove condition"
              onClick={() => onChange([...conditions.slice(0, i), ...conditions.slice(i + 1)])}
              tooltip="Remove condition"
            />
          </div>,
        ])}
        <Button
          size="sm"
          variant="secondary"
          aria-label="Add condition"
          onClick={() => onChange([...conditions, [{ name: '', type: MatchType.Equal, value: '' }]])}
        >
          + Add condition
        </Button>
      </VerticalGroup>
    </>
  );
}

interface Props {
  onChange: (selectors: Conditions) => void;
  conditions: Conditions;
}

function ConditionsLabel(): React.ReactElement {
  const styles = useStyles2(getStyles);
  return (
    <FieldLabel>
      <div style={{ marginRight: '8px' }}>Conditions</div>
      <Tooltip
        interactive
        content={
          <div>
            <p>Conditions that must be met for the check to run.</p>
            <p>
              See{' '}
              <TextLink
                variant="bodySmall"
                inline
                external
                href="https://grafana.com/docs/grafana-cloud/alerting-and-irm/machine-learning/configure/sift/#limiting-when-sift-checks-run"
              >
                Limiting when Sift checks run
              </TextLink>{' '}
              for more information.
            </p>
          </div>
        }
      >
        <div className={styles.label}>
          <Icon name="info-circle" />
        </div>
      </Tooltip>
    </FieldLabel>
  );
}

export function ConditionsField({ conditions, onChange }: Props): React.ReactElement {
  return (
    <Field label={<ConditionsLabel />}>
      <ConditionsInput conditions={conditions} onChange={onChange} />
    </Field>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  labelRow: css`
    border: 1px solid ${theme.colors.border.weak};
    padding: ${theme.spacing(1)};
  `,
  label: css`
    display: flex;
    align-items: center;
  `,
});
