import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

import { GrafanaTheme2, LoadingState, PanelData, TimeRange } from '@grafana/data';
import { createQueryRunner, PanelRenderer } from '@grafana/runtime';
import { LoadingBar, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

interface ErrorPatternPanelProps {
  logQuery: string;
  chartQuery: string;
  timeRange: TimeRange;
  timeZone: string;
  dsUid: string;
  visible: boolean;
}

function ErrorPatternPanel({
  logQuery,
  chartQuery,
  timeRange,
  timeZone,
  dsUid,
  visible = false,
}: ErrorPatternPanelProps): React.ReactElement {
  const styles = useStyles2(getStyles);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [data, update] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  const handleUpdate = useCallback(
    (v: PanelData | undefined) => {
      for (const frame of v?.series ?? []) {
        if (frame.refId === 'CHART') {
          frame.fields[1].config.displayNameFromDS = chartQuery;
        }
      }
      update(v);
      setIsRefreshing(v?.state === LoadingState.Loading);
    },
    [update, setIsRefreshing, chartQuery]
  );

  useEffect(() => {
    // This lint seems to be spurious since this signature hasn't been
    // deprecated.
    // eslint-disable-next-line deprecation/deprecation
    const s = runner.get().subscribe(handleUpdate);
    return () => {
      s.unsubscribe();
    };
  }, [runner, handleUpdate]);

  useEffect(() => {
    const toDestroy = runner;
    return () => {
      toDestroy.destroy();
    };
  }, [runner]);

  // memoize queries
  const queries = useMemo(
    () => [
      { refId: 'LOG', expr: logQuery, queryType: 'range' },
      { refId: 'CHART', expr: chartQuery, queryType: 'range' },
    ],
    [logQuery, chartQuery]
  );

  useEffect(() => {
    if (visible && data === undefined) {
      setIsRefreshing(true);
      runner.run({
        timeRange,
        queries,
        datasource: { uid: dsUid },
        maxDataPoints: 100,
        minInterval: null,
        timezone: timeZone,
      });
    }
  }, [dsUid, queries, runner, timeRange, timeZone, visible, data]);

  if (!visible) {
    return <></>;
  }

  if (isRefreshing) {
    return <LoadingBar width={50} />;
  }

  return (
    <div>
      <div className={styles.logContainer}>
        <PanelRenderer
          title="logs"
          pluginId="logs"
          onOptionsChange={() => {}}
          width={1200}
          height={350}
          data={data}
          options={{ wrapLogMessages: true }}
        />
      </div>
      <div className={styles.chartContainer}>
        <AutoSizer>
          {(size: Size) => (
            <PanelRenderer
              title="timeseries"
              pluginId="timeseries"
              onOptionsChange={() => {}}
              width={size.width ?? 0}
              height={size.height ?? 0}
              data={data}
              fieldConfig={{ defaults: { unit: 'mps' }, overrides: [] }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    logContainer: css`
      overflow: auto;
      height: 350px;
      border: solid 1px ${theme.colors.border.weak};
      width: 100%;
      background-color: ${theme.colors.background.canvas};
      padding: 10px;
    `,
    chartContainer: css`
      overflow: none;
      padding: 10px;
      width: 100%;
      height: 300px;
      color: ${theme.colors.text.primary};
    `,
  };
}

export { ErrorPatternPanel };
