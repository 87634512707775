import React, { useMemo } from 'react';

import { applyFieldOverrides, DataFrame, Field, FieldMatcher, FieldType, PanelProps } from '@grafana/data';
import { config, PanelDataErrorView } from '@grafana/runtime';
import {
  KeyboardPlugin,
  preparePlotFrame,
  TimeSeries,
  TooltipDisplayMode,
  TooltipPlugin,
  usePanelContext,
  useTheme2,
  ZoomPlugin,
} from '@grafana/ui';

import { OutlierResults } from 'api/types';

import { OutlierPlugin } from './OutlierPlugin';
import { OutlierTimeSeriesOptions } from './OutlierTimeSeriesPanel.types';
import { prepareGraphableFields } from './OutlierTimeSeriesPanel.utils';

////// Following not exported by Grafana, using local copy to please TS
export interface XYFieldMatchers {
  x: FieldMatcher;
  y: FieldMatcher;
}
//////
interface OutlierTimeSeriesPanelProps extends PanelProps<OutlierTimeSeriesOptions> {
  selectedIndex?: number | undefined;
  outlierResults?: OutlierResults | undefined;
}

export const OutlierTimeSeriesPanel: React.FC<OutlierTimeSeriesPanelProps> = ({
  data,
  timeRange,
  timeZone,
  width,
  height,
  options,
  onChangeTimeRange,
  id,
  selectedIndex,
  outlierResults,
}) => {
  const { sync } = usePanelContext();
  const theme = useTheme2();

  const preparePlotFrameWithOutlierExtras = (dataFrames: DataFrame[], dimFields: XYFieldMatchers): DataFrame => {
    // Process the query data into format ideal for uPlot/GraphNG, but also our Outlier plugin.
    // This involves first using default implementation of `preparePlotFrame` to process the query data,
    // and then inserting 2 additional frames to designate the normal band. OutlierPlugin will then
    // fill the region.
    const preparedFrame = preparePlotFrame(dataFrames, dimFields, timeRange);

    // If there is a normal band in the outlierResults, insert it as 2 additional series into the preparedFrame
    if (outlierResults?.normalBand !== undefined && preparedFrame !== null) {
      const newClusterField = (type: 'lower' | 'upper', values: Array<number | undefined>): Field => {
        return {
          name: 'Value',
          type: FieldType.number,
          config: {},
          labels: { normal: type },
          values: values,
        };
      };

      // Need to jump through this hoop to generate suitably prepared Fields for GraphNG to render.
      const wrappingDF: DataFrame = {
        name: '',
        fields: [
          newClusterField('lower', outlierResults.normalBand[0]),
          newClusterField('upper', outlierResults.normalBand[1]),
        ],
        length: 2,
      };
      const out = applyFieldOverrides({
        data: [wrappingDF],
        fieldConfig: {
          defaults: {},
          overrides: [],
        },
        replaceVariables: (str: string) => str,
        theme,
        timeZone,
      });

      const preparedFields = out[0]!.fields;

      // Insert into existing preparedFrame so that is rendered under all other series. Note that the
      // first series in `fields` is the `time` series, so we insert after this.
      preparedFrame.fields.splice(1, 0, preparedFields[0]!);
      preparedFrame.fields.splice(2, 0, preparedFields[1]!);
    }

    return preparedFrame!;
  };

  const frames = useMemo(() => prepareGraphableFields(data.series, config.theme2), [data]);

  if (frames == null) {
    return <PanelDataErrorView panelId={id} data={data} needsTimeField={true} needsNumberField={true} />;
  }

  return (
    <TimeSeries
      frames={frames}
      structureRev={data.structureRev}
      timeRange={timeRange}
      timeZone={timeZone}
      width={width}
      height={height}
      legend={options.legend}
      preparePlotFrame={preparePlotFrameWithOutlierExtras}
    >
      {(plotConfig, alignedDataFrame) => {
        return (
          <>
            <KeyboardPlugin config={plotConfig} />
            <ZoomPlugin config={plotConfig} onZoom={onChangeTimeRange} />

            {options.tooltip.mode === TooltipDisplayMode.None || (
              <TooltipPlugin
                data={alignedDataFrame}
                config={plotConfig}
                mode={options.tooltip.mode}
                sortOrder={options.tooltip.sort}
                sync={sync}
                timeZone={timeZone}
              />
            )}

            <OutlierPlugin config={plotConfig} alignedDataFrame={alignedDataFrame} selectedIndex={selectedIndex} />
          </>
        );
      }}
    </TimeSeries>
  );
};
