import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

import {
  DataFrame,
  dataFrameFromJSON,
  DataFrameJSON,
  dateTimeFormatISO,
  LoadingState,
  PanelData,
  ScopedVars,
  TimeRange,
} from '@grafana/data';
import { config, FetchResponse } from '@grafana/runtime';
import { DataQuery, TimeZone } from '@grafana/schema';

import { adminApiPost } from 'api';
import { DEFAULT_NEW_JOB_FORM } from 'consts';
import { timeUnitToSeconds } from 'utils';

import { getQueryCustomParams } from '../../utils';
import { isBackendError } from '../ErrorAlert/guards';

type ForecastOptions = {
  query?: DataQuery;
  timeRange: TimeRange;
  timeZone: TimeZone;
  scopedVars?: ScopedVars;
};

type QueryResult = {
  status: number;
  frames: DataFrameJSON[];
};

type ForecastResponse = {
  data: {
    results?: Record<string, QueryResult>;
  };
  error?: string;
  message?: string;
  status?: string;
};

// Hard code interval and training window to match the new job form.
// This way we'll only ever run a single training for the modal even if
// users zoom in and out.
const interval = timeUnitToSeconds(
  DEFAULT_NEW_JOB_FORM.parameters.intervalValue,
  DEFAULT_NEW_JOB_FORM.parameters.intervalUnit
) as number;
const trainingWindow = timeUnitToSeconds(
  DEFAULT_NEW_JOB_FORM.parameters.trainingWindowValue,
  DEFAULT_NEW_JOB_FORM.parameters.trainingWindowUnit
) as number;

export function useForecast(opts: ForecastOptions): AsyncState<PanelData | undefined> {
  const { query, timeRange, scopedVars, timeZone } = opts;

  return useAsync(async (): Promise<PanelData | undefined> => {
    if (!query) {
      return undefined;
    }

    try {
      const response: FetchResponse<ForecastResponse> = await adminApiPost('predict/api/v1/forecast', {
        data: {
          job: {
            name: 'some name',
            metric: 'metric',
            grafanaUrl: config.appUrl,
            // ML injects key for us
            grafanaApiKey: 'grafana-ml',
            datasourceUid: query.datasource?.uid,
            datasourceType: query.datasource?.type,
            queryParams: getQueryCustomParams(query),
            interval,
            algorithm: 'grafana_prophet_1_0_1',
            trainingWindow,
            trainingFrequency: 3600,
          },
          forecastParams: {
            start: dateTimeFormatISO(timeRange.from),
            end: dateTimeFormatISO(timeRange.to),
            interval,
          },
        },
      });

      return {
        state: LoadingState.Done,
        series: mapToSeries(query.refId, response.data.data.results),
        timeRange: timeRange,
        request: {
          app: 'ML',
          startTime: Date.now(),
          timezone: timeZone,
          range: timeRange,
          intervalMs: interval * 1000,
          requestId: '0',
          interval: String(interval),
          scopedVars: scopedVars ?? {},
          targets: [query],
        },
      };
    } catch (error) {
      if (isBackendError(error)) {
        error.isHandled = true;
      }
      throw error;
    }
  }, [timeRange, query, scopedVars, timeZone]);
}

function mapToSeries(refId: string, results: Record<string, QueryResult> | undefined): DataFrame[] {
  const frames = results?.[refId].frames ?? [];
  return frames.map((frame) => {
    return dataFrameFromJSON(frame);
  });
}
