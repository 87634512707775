import React from 'react';

import { Alert, VerticalGroup } from '@grafana/ui';

import { Investigation } from 'types';

import { RerunInvestigationButton } from './RerunInvestigationButton';

interface Props {
  investigation: Investigation;
}

export function FailedInvestigation({ investigation }: Props) {
  const { retryable, failureReason } = investigation;
  return (
    <Alert title="Investigation failed to run">
      <VerticalGroup>
        <p>{failureReason}</p>
        {retryable && <RerunInvestigationButton investigation={investigation} variant="primary" />}
      </VerticalGroup>
    </Alert>
  );
}
