import React, { useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { Field, MultiSelect, VerticalGroup } from '@grafana/ui';

import { useAllHolidays } from 'api';

import { TabProps } from '../../utils/tabs';

export function AttachHoliday({ preSelectedHolidayIds = [], setSelectedHolidays }: TabProps): JSX.Element {
  const { data } = useAllHolidays();

  const holidays = data?.map((holiday) => ({ label: holiday.name, value: holiday.name, key: holiday.id }));
  const holidaysFiltered = holidays?.filter((holiday) => preSelectedHolidayIds.includes(holiday.key)) ?? [];
  const preSelectedHolidays =
    holidays != null && holidays?.length > 0 && preSelectedHolidayIds?.length > 0 ? holidaysFiltered : [];
  const [selectedUpdatedHolidays, setUpdatedSelectedHolidays] = useState<
    Array<SelectableValue<string>> | string[] | undefined
  >(preSelectedHolidays);

  return (
    <div>
      <Field label="Link holidays">
        <VerticalGroup>
          <MultiSelect
            width={50}
            value={selectedUpdatedHolidays}
            onChange={(selectedHolidays) => {
              setUpdatedSelectedHolidays(selectedHolidays);
              if (setSelectedHolidays !== undefined) {
                setSelectedHolidays(selectedHolidays?.map((holiday) => holiday.key as string));
              }
            }}
            placeholder="Holiday"
            options={holidays}
          />
        </VerticalGroup>
      </Field>
    </div>
  );
}
