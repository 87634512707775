import { DataSourceInstanceSettings } from '@grafana/data';

import { create } from 'zustand';

import { DEFAULT_NEW_JOB_FORM } from 'consts';
import { DataQueryWithExpression, FullFormModel, Job, ModelParameters } from 'types';

interface ForecastingStateData {
  datasource?: DataSourceInstanceSettings;
  description?: string;
  dirty: boolean;
  errorMessages: string[];
  isValid: boolean;
  modelForm: FullFormModel;
  name?: string;
  queryErrorMessage?: string;
  showCreateModal: boolean;
  showEditModal: boolean;
  forecastDrawerDatasourceName?: string;
  forecastDrawerItem?: Job;
  // for internal use only
  _queryChangedSinceLastRun: boolean;
  _queryDirty: boolean;
  _queryValid: boolean;
  _paramsDirty: boolean;
  _paramsValid: boolean;
}

interface ForecastingState {
  data: ForecastingStateData;
  save(name: string, description?: string): void;
  setDatasource(datasource?: DataSourceInstanceSettings): void;
  setQueryError(error: string): void;
  setQueryValid(dirty?: boolean): void;
  setQueryInProgress(): void;
  setQueryChangedSinceLastRun(queryDirty: boolean): void;
  setInitialState(datasource?: DataSourceInstanceSettings, modelForm?: FullFormModel): void;
  setQuery(query?: DataQueryWithExpression): void;
  setParameters(parameters: ModelParameters): void;
  setParamsValid(paramsValid: boolean): void;
  setParamsDirty(paramsDirty: boolean): void;
  setShowCreateModal(showCreateModal: boolean): void;
  setShowEditModal(showEditModal: boolean): void;
  setShowErrorModal(showErrorModal: boolean): void;
  setForecastDrawer(job?: Job, datasourceName?: string): void;
  clearForecastDrawer: () => void;
}

/*
  ForecastingState - manages the Create/Edit forecasting form state.

  This form is complex, as it is a combination of a useForm<> form (in AlgorithmEditorTab) for the algorithm parameters
  with a separate form for the metric query defined in the QueryEditor, which behaves in its own way.

  This separately tracks the state of the Query form & the Parameters form, but combines their diry, isValid and errors.
  These states are internally tracked.

  The Query form has 2 dirty states: the first when the user edits the query at all, and the second when the user
  runs the query and it succeeds (so the 'submitted' query is dirty). They are distinguished here as bools:
    - queryDirty - true iff the query is changed, run and it returns data (is valid)
    - queryChangedSinceLastRun - true if user edits the query after a run
  If the query succeeds, queryValid is true and the overall form is marked dirty.
  If it fails, queryValid is false and the reported error message is in queryErrorMessage.

  The Parameters form is more typical, with dirty and isValid states.

  The dirty & isVaild states are calculated in the `updateComputed` function below.
*/

const useForecastingState = create<ForecastingState>((set) => ({
  // here we set the values we want stored in state

  data: {
    datasource: undefined,
    description: '',
    dirty: false,
    isValid: false,
    queryErrorMessage: undefined,
    modelForm: DEFAULT_NEW_JOB_FORM,
    errorMessages: [],
    name: '',
    showCreateModal: false,
    showEditModal: false,
    showErrorModal: false,
    forecastDrawerDatasourceName: undefined,
    forecastDrawerItem: undefined,
    _queryChangedSinceLastRun: false, // true when user changes query after a run, reset to false when run
    _queryDirty: false, // only true after a run happens
    _paramsDirty: false,
    _queryValid: false,
    _paramsValid: false,
  },

  // here we define functions to update state values

  // save the name and description of the model
  save: (name: string, description?: string) =>
    set((state) => ({
      data: {
        ...state.data,
        description: description || '',
        name: name || '',
        _queryDirty: false,
        _paramsDirty: false,
      },
    })),

  // set the datasource
  setDatasource: (datasource?: DataSourceInstanceSettings) =>
    set((state) => ({ data: updateComputed({ ...state.data, datasource, _queryChangedSinceLastRun: true }) })),

  // set the query
  setQuery: (query: DataQueryWithExpression) =>
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          modelForm: {
            ...state.data.modelForm,
            query: { key: query?.refId, value: query },
          },
        }),
      };
    }),

  // query has been edited by the user (but not run yet)
  setQueryChangedSinceLastRun: (queryChangedSinceLastRun: boolean) =>
    set((state) => {
      return {
        data: updateComputed({ ...state.data, _queryChangedSinceLastRun: queryChangedSinceLastRun }),
      };
    }),

  // query was run and returns an error
  setQueryError: (queryError: string) =>
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          queryErrorMessage: queryError,
          _queryDirty: true,
          _queryValid: false,
          _queryChangedSinceLastRun: false,
        }),
      };
    }),

  // query was run and returned data successfully (on Edit page, firstRun should be set so form not
  // marked dirty after the saved query is executed)
  setQueryValid: (firstRun = false) =>
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          queryErrorMessage: undefined,
          _queryValid: true,
          _queryDirty: !firstRun,
          _queryChangedSinceLastRun: false,
        }),
      };
    }),

  // query is being run, hide any old errors
  setQueryInProgress: () =>
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          queryErrorMessage: undefined,
          _queryValid: true,
          _queryDirty: true,
          _queryChangedSinceLastRun: false,
        }),
      };
    }),

  // set the initial modelForm state
  setInitialState: (
    datasource?: DataSourceInstanceSettings,
    modelForm: FullFormModel | undefined = DEFAULT_NEW_JOB_FORM
  ) =>
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          modelForm,
          datasource,
          _paramsDirty: false,
          _queryChangedSinceLastRun: false,
        }),
      };
    }),

  // set the parameters
  setParameters: (parameters: ModelParameters) => {
    set((state) => {
      return {
        data: updateComputed({
          ...state.data,
          modelForm: {
            ...state.data.modelForm,
            parameters,
          },
        }),
      };
    });
  },

  // are the parameters valid - duplicating the useForm state
  setParamsValid: (paramsValid: boolean) =>
    set((state) => {
      return { data: updateComputed({ ...state.data, _paramsValid: paramsValid }) };
    }),

  // have the parameters been changed?
  setParamsDirty: (paramsDirty: boolean) =>
    set((state) => {
      return { data: updateComputed({ ...state.data, _paramsDirty: paramsDirty }) };
    }),

  // set the showCreateModal flag
  setShowCreateModal: (showCreateModal: boolean) => set((state) => ({ data: { ...state.data, showCreateModal } })),

  // set the showEditModal flag
  setShowEditModal: (showEditModal: boolean) => set((state) => ({ data: { ...state.data, showEditModal } })),

  // set the showError flag
  setShowErrorModal: (showErrorModal: boolean) => set((state) => ({ data: { ...state.data, showErrorModal } })),

  // set the forcast drawer info
  setForecastDrawer: (job?: Job, datasourceName?: string) =>
    set((state) => ({
      data: { ...state.data, forecastDrawerItem: job, forecastDrawerDatasourceName: datasourceName },
    })),

  // clear forecast drawer info
  clearForecastDrawer: () =>
    set((state) => ({
      data: { ...state.data, forecastDrawerItem: undefined, forecastDrawerDatasourceName: undefined },
    })),
}));

function updateComputed(data: ForecastingStateData): ForecastingStateData {
  data.dirty = data._queryDirty || data._paramsDirty;
  data.isValid = data._queryValid && data._paramsValid && !data._queryChangedSinceLastRun;
  data.errorMessages = [];
  if (!data._queryValid) {
    data.errorMessages.push('The query is not valid, please fix');
  }
  if (!data._paramsValid) {
    data.errorMessages.push('The Algorithm parameters are invalid, please fix');
  }
  if (data._queryChangedSinceLastRun) {
    data.errorMessages.push('Run the query to validate it');
  }
  return data;
}

export { useForecastingState };
