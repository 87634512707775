import React, { useState } from 'react';

import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { setupPlugin } from 'api/setup.api';
import { useIsAdmin } from 'hooks';

export default function Initialize({ variant = 'wide' }: { variant?: 'tall' | 'wide' }): JSX.Element {
  const { featureButton, wrapper, featuresAside, wide, tall } = useStyles2(getStyles);
  const isAdmin = useIsAdmin();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    setIsLoading(true);
    setupPlugin().then(
      // Page is reloaded on successful setup so no need to do anything here.
      () => {},
      // Error in setup - emit an alert event.
      (e) => {
        console.error(e);
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error initializing plugin: check console logs for details`],
        });
        setIsLoading(false);
      }
    );
  };

  return (
    <div className={wrapper}>
      <aside className={cx(variant === 'wide' ? wide : tall, featuresAside)}>
        <div className={featureButton}>
          <label>To enable Grafana Machine Learning, click below:</label>
          <Button size="lg" disabled={!isAdmin || isLoading} variant="primary" onClick={handleClick}>
            Initialize Grafana ML
          </Button>
        </div>
      </aside>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    wide: css`
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-gap: 18px;
      @media only screen and (min-width: 840px) {
        grid-gap: 12px;
      }
    `,
    tall: css`
      grid-template-rows: repeat(auto-fit);
      grid-gap: 20px;
      @media only screen and (min-width: 840px) {
        grid-gap: 40px;
      }
    `,

    wrapper: css`
      padding: 20px;
      @media only screen and (min-width: 780px) {
        padding: 28px;
      }
      @media only screen and (min-width: 1140px) {
        padding: 35px;
      }
      a.blueLink {
        color: ${theme.colors.text.link};
      }
    `,

    featureButton: css`
      grid-column: 2/2;
      grid-row: 2;
      text-align: center;
      display: flex;
      flex-direction: column !important;
      flex-wrap: nowrap;
    `,

    featuresAside: css`
      display: grid;
      grid-auto-flow: dense;

      h2 {
        font-size: 17px;
        margin: 0 0 4px 0;
      }
      p {
        margin: 0;
        line-height: 1.2;
      }
      > div {
        align-self: center;
        justify-self: start;
        display: flex;
        gap: 7px;
        @media only screen and (min-width: 840px) {
          gap: 12px;
        }
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > svg {
          width: 40px;
          min-width: 25px;
          max-width: 30px;
          @media only screen and (min-width: 840px) {
            width: 40px;
            min-width: 30px;
            max-width: 55px;
          }
          @media only screen and (min-width: 1240px) {
            width: 65px;
          }
          color: #ff8833;
          filter: drop-shadow(2px 4px 6px ${theme.isDark ? 'rgb(19 2 43 / 76%)' : 'rgb(127 131 171 / 30%)'});
        }
      }

      > #learn {
      }

      > #create {
      }

      > #alert {
      }

      > #action {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: end;
        gap: 12px;
        align-items: center;
      }
    `,
  };
}
