import React, { useState } from 'react';

import { Button, Icon, Input, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export interface Filter {
  type: string;
  value: string;
}

interface SearchBarProps {
  filters: Filter[];
  onFilter: (filters: Filter[]) => void;
  showAddFilter?: boolean;
}

export const SearchBar = ({ onFilter, showAddFilter = false }: SearchBarProps) => {
  const styles = useStyles2(getStyles);
  const [search, setSearch] = useState<string>('');

  const parseSearch = (search: string): Filter[] => {
    return [
      {
        type: 'match',
        value: search,
      },
    ];
  };

  return (
    <div className={styles.container}>
      <Input
        suffix={<Icon name="search" />}
        placeholder="Search or add filter"
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        onBlur={() => onFilter(parseSearch(search))}
        onKeyUp={() => onFilter(parseSearch(search))}
      />
      {showAddFilter ? (
        <Button icon="filter" variant="secondary">
          Add filter
        </Button>
      ) : null}
    </div>
  );
};

const getStyles = () => ({
  container: css`
    width: 100%;
    display: flex;
    gap: 12px;
  `,
});
