import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, FieldArray, Form, Input } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { CheckConfig, CheckFormProps, FieldValues, StatusPageOutagesConfig } from 'types';
import { getAnalysisDisplayTitle } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

function StatusPageOutagesForm({
  check,
  defaultConfig,
  onClose,
}: CheckFormProps<StatusPageOutagesConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<StatusPageOutagesConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues: FieldValues = {
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={(data) => {
        const saveData = {
          id: check.id ?? undefined,
          name: check.name,
          disabled: Boolean(data?.disabled ?? false),
          if: convertConditions(ifConditions),
          config: {
            cloudProviders: data.config.cloudProviders ?? check.config?.cloudProviders ?? [],
          },
          title: data.title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, control, formState, getValues }) => {
        const cloudProviders = getValues('config.cloudProviders');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} />
            </Field>

            <Field
              label={
                <LabelWithHelpText label="Cloud providers" checkName="statusPageOutages" fieldName="cloudProviders" />
              }
            >
              <FieldArray control={control} name="config.cloudProviders">
                {({ fields, append }) => (
                  <>
                    <div style={{ marginBottom: '1rem' }}>
                      {(fields ?? []).map((field, index) => (
                        <Input
                          key={field.id}
                          {...register(`config.cloudProviders.${index}` as const)}
                          defaultValue={cloudProviders[index] ?? ''}
                          placeholder={(defaultConfig.cloudProviders ?? [])[index]}
                        />
                      ))}
                    </div>
                    <Button onClick={() => append('' as unknown as FieldValues)}>+</Button>
                  </>
                )}
              </FieldArray>
            </Field>

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { StatusPageOutagesForm };
