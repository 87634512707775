import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@grafana/ui';

import { css } from '@emotion/css';

import { useRemoveHoliday } from 'api';
import Card from 'components/Card';
import { PLUGIN_ROOT } from 'consts';
import { useConfirmModal, useIsEditor } from 'hooks';
import { Holiday } from 'types';
import { useUtilityStyles } from 'utils';

interface HolidayItemProps {
  item: Holiday;
}

export const HolidayItem: FC<HolidayItemProps> = ({ item }) => {
  const navigate = useNavigate();
  const isEditor = useIsEditor();
  const utilityStyle = useUtilityStyles();
  const doDelete = useRemoveHoliday();
  const onDelete = useCallback(
    (holidayId: string) => {
      doDelete.mutate(holidayId);
    },
    [doDelete]
  );

  const [confirmDelete, ConfirmDeleteModal] = useConfirmModal(async () => {
    onDelete(item.id);
  });

  const onView = (itemId: string) => {
    navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${itemId}`);
  };

  const onEdit = (itemId: string) => {
    navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${itemId}/edit?source=list`);
  };

  return (
    <>
      <Card height="subtle" variant="secondary-gray" className={styles.card}>
        <a href={`${PLUGIN_ROOT}/metric-forecast/holiday/${item.id}`} className={styles.heading}>
          <h5>{item.name}</h5>
          <div>{item.description}</div>
          {item.customPeriods != null ? (
            <div>
              {item.customPeriods.length} {item.customPeriods.length === 1 ? 'holiday' : 'holidays'}
            </div>
          ) : null}
        </a>
        <div className={styles.actions}>
          <IconButton key="view" name="eye" tooltip="View holiday" onClick={() => onView(item.id)}>
            View
          </IconButton>
          <IconButton key="edit" name="edit" tooltip="Edit this holiday" onClick={() => onEdit(item.id)}>
            Edit
          </IconButton>
          {isEditor ? (
            <IconButton key="delete" name="trash-alt" tooltip="Delete this holiday" onClick={confirmDelete} />
          ) : null}
        </div>
      </Card>
      <ConfirmDeleteModal icon="trash-alt" title="Delete holiday" actionText="Delete">
        <div className={utilityStyle.textMd}>Are you sure you want to delete &quot;{item.name}&quot;?</div>
      </ConfirmDeleteModal>
    </>
  );
};

const styles = {
  card: css`
    display: flex;
    flex-direction: flex-end;
    padding: 18px;
    margin-bottom: 8px;
  `,
  actions: css`
    display: flex;
    gap: 8px;
  `,
  heading: css`
    width: 100%;
    display: flex;
    flex-direction: flex-start;
    gap: 8px;
    cursor: pointer;
    margin: 0px auto;
    > h5 {
      font-size: 14px;
      font-weight: 500;
    }
    > div {
      opacity: 0.8;
    }
  `,
  secondary: css`
    display: flex;
    flex-flow: row nowrap;
    gap: 3px;
  `,
};
