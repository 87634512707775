import React, { FC } from 'react';

import { DataSourceInstanceSettings, urlUtil } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { IconButton, LinkButton, Menu } from '@grafana/ui';

import { css } from '@emotion/css';

import { PLUGIN_ROOT } from 'consts';
import { useMlDataSource } from 'hooks/useSupportedDatasources';
import { AlertStateCounts, Job, RuleState } from 'types';

/// Create a URL to the 'View alerts' screen with some defaults for the filters.
function listAlertsUrl(job: Job, mlDatasource: DataSourceInstanceSettings, returnTo: string): string {
  const jobIdFilter = `{ml_job_id="${job.id}"}`;
  returnTo = returnTo.startsWith('/') ? returnTo : `/${returnTo}`;
  return `/alerting/list?dataSource=${encodeURIComponent(mlDatasource.name)}&queryString=${encodeURIComponent(
    jobIdFilter
  )}&returnTo=${encodeURIComponent(PLUGIN_ROOT + returnTo)}`;
}

export type ViewAlertsButtonType = 'icon' | 'text' | 'menu-item';

interface ViewAlertsButtonProps {
  job: Job;
  alertingEnabled: boolean;
  jobReady: boolean;
  jobDisabled: boolean;
  returnTo: string;
  alertStateCounts: AlertStateCounts;
  kind: ViewAlertsButtonType;
}

export const ViewAlertsButton: FC<ViewAlertsButtonProps> = ({
  job,
  alertingEnabled,
  jobReady,
  jobDisabled,
  returnTo,
  kind,
  alertStateCounts,
}) => {
  const mlDataSource = useMlDataSource();
  const hasAlerts = alertStateCounts.total > 0;

  // Don't let users view alerts unless:
  // - Grafana Alerting is enabled
  // - the ML datasource exists
  // - the job can be viewed.
  // - the job has at least one alert created.
  const alertButtonEnabled = alertingEnabled && mlDataSource !== undefined && jobReady && hasAlerts;

  // Indicate how many alert rules are in each state in the tooltip.
  const tooltip: string = (['total', 'firing', 'error', 'pending', 'inactive'] as Array<RuleState | 'error' | 'total'>)
    // Don't add empty counts to the tooltip.
    .filter((state) => alertStateCounts[state] > 0)
    .map((state) => {
      const count: number = alertStateCounts[state];
      // Grafana Alerting uses 'normal' instead of 'inactive'; be consistent here.
      return `${count} ${state === 'inactive' ? 'normal' : state}`;
    })
    .join(', ');

  const title = !alertingEnabled
    ? 'Grafana Alerting must be enabled to view forecast alerts.'
    : mlDataSource == null
    ? 'Grafana ML datasource not found, please re-initialize the plugin.'
    : !jobReady
    ? 'Forecast is still training, please wait until it is ready.'
    : jobDisabled
    ? 'Forecast is disabled and cannot be used for alerting.'
    : !hasAlerts
    ? 'No alerts exist for this forecast.'
    : // Success case.
      `View alerts using this forecast:\n\n${tooltip}`;

  const href = mlDataSource == null ? undefined : listAlertsUrl(job, mlDataSource, returnTo);
  const firingOrError = alertStateCounts['firing'] > 0 || alertStateCounts['error'] > 0;
  const className = alertButtonEnabled ? styles.empty : styles.disabledWithTooltip;

  const viewOnClick = () =>
    locationService.push({
      pathname: '/alerting/list',
      search: urlUtil.toUrlParams({
        dataSource: mlDataSource?.name ?? null,
        queryString: `{ml_job_id="${job.id}"}`,
        returnTo,
      }),
    });

  return kind === 'icon' ? (
    <IconButton
      key="list-alerts"
      name="link"
      disabled={!alertButtonEnabled}
      className={className}
      tooltip={title}
      // Tooltips only work on enabled buttons, so use a title if it's disabled.
      title={alertButtonEnabled ? undefined : title}
      // There's no href prop on an IconButton, and wrapping it in an anchor messes with the layout,
      // so redirect using JS instead.
      onClick={viewOnClick}
      // We're slightly misusing this API: it's not really a destructive action, but this makes the
      // icon appear red if there's an alert firing, which is useful.
      variant={firingOrError ? 'destructive' : 'secondary'}
    />
  ) : kind === 'menu-item' ? (
    <Menu.Item disabled={!alertButtonEnabled} label="View linked alerts" icon="link" onClick={viewOnClick} />
  ) : (
    <LinkButton
      key="list-alerts"
      href={href}
      disabled={!alertButtonEnabled}
      className={className}
      title={title}
      variant="secondary"
    >
      View Alerts
    </LinkButton>
  );
};

const styles = {
  empty: css``,
  disabledWithTooltip: css`
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: auto;
  `,
};
