import React, { FC } from 'react';

import { InlineField, Input } from '@grafana/ui';

interface FieldProps {
  label: string;
  value?: { toString: () => string };
}

export const Field: FC<FieldProps> = ({ label, value }) =>
  value == null ? null : (
    <InlineField label={label} disabled labelWidth={25} grow={true}>
      <Input readOnly disabled value={value.toString()} />
    </InlineField>
  );
