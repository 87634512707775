import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';

import { TimeRange } from '@grafana/data';
import { config } from '@grafana/runtime';

import { v4 as uuid } from 'uuid';

import {
  Alert,
  Analysis,
  AnyConfig,
  CheckConfig,
  CheckConfigList,
  DatasourceConfig,
  Holiday,
  Investigation,
  Job,
  Metadata,
  Model,
  NewHoliday,
  NewInvestigation,
  NewMetricJob,
  RuleInstance,
  TenantInfo,
  UnnamedMetadata,
  ValidateNewJob,
  ValidateNewJobResult,
} from 'types';
import { toTimeRange } from 'utils';

import { adminApiDelete, adminApiGet, adminApiPost, adminApiPut } from './core.api';
import { getAlertRules, getAlerts } from './grafana.api';

interface MetricJobOptions {
  [key: string]: boolean | number | string | null;
}

export function useAllMetricJobs(options?: MetricJobOptions): QueryObserverResult<Job[], Error> {
  return useQuery<Job[], Error>(
    'metric_job',
    async () => {
      const response = await adminApiGet('/manage/api/v1/jobs');
      if (!response.ok) {
        throw response.data;
      }
      return response.data.data as Job[];
    },
    { refetchInterval: 10000, refetchOnMount: true, ...options }
  );
}

export function useMetricJob(
  jobId: string,
  options: { [key: string]: string | number } = {}
): QueryObserverResult<Job, Error> {
  return useQuery<Job, Error>(
    ['metric_job', jobId],
    async () => {
      const response = await adminApiGet(`/manage/api/v1/jobs/${jobId}`);
      if (!response.ok) {
        throw response.data;
      }
      const res = response.data.data as Job | null | undefined;
      if (res == null) {
        throw new Error('Not found');
      }
      return res;
    },
    { refetchOnMount: true, ...options }
  );
}

/// Create a new metric job with optimistic local creation and updating after success, and removal on failure.
export function useCreateMetricJob(): UseMutationResult<Job, unknown, NewMetricJob, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (newJob: NewMetricJob) => {
      const response = await adminApiPost(`/manage/api/v1/jobs`, {
        data: newJob,
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Job;
    },
    {
      onMutate: async (newJob: NewMetricJob) => {
        await queryClient.cancelQueries('metric_job');

        const optMeta: Metadata = {
          id: uuid(),
          name: newJob.name,
          created: new Date().toISOString(),
        };

        const optJob: Job & { isOptimistic: boolean } = { ...newJob, ...optMeta, isOptimistic: true };
        queryClient.setQueryData<Job[]>('metric_job', (old) => (old == null ? [optJob] : [optJob as Job].concat(old)));
        return { optimisticNewJob: optJob };
      },

      onSuccess: async (newJob, _variables, context) => {
        const optId = context?.optimisticNewJob.id;
        queryClient.setQueryData<Job>(['metric_job', newJob.id], newJob);
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [newJob] : old.map((job) => (job.id === optId ? newJob : job))
        );
      },

      onError: async (_error, _variables, context) => {
        const optId = context?.optimisticNewJob.id;
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [] : old.filter((job) => job.id !== optId)
        );
      },

      onSettled: async () => queryClient.invalidateQueries('metric_job'),
    }
  );
}

/// Update a metric job with optimistic local updates, and revert optimism on on failure.
export function useUpdateMetricJob(): UseMutationResult<Job, unknown, Job, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatedMetricJob: Job) => {
      const response = await adminApiPost(`/manage/api/v1/jobs/${updatedMetricJob.id}`, {
        data: {
          ...updatedMetricJob,
          id: undefined,
          // Always re-enable a job when updating it.
          // Jobs should behave properly after being edited otherwise the UI
          // should not allow them to be saved.
          enabled: true,
        },
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Job;
    },
    {
      onMutate: async (newJob: Job) => {
        await queryClient.cancelQueries('metric_job');

        const optMeta: Metadata = {
          id: newJob.id,
          name: newJob.name,
          created: new Date().toISOString(),
        };

        const optJob: Job & { isOptimistic: boolean } = { ...newJob, ...optMeta, isOptimistic: true };
        queryClient.setQueryData<Job>(['metric_job', newJob.id], optJob);
        queryClient.setQueryData<Job[]>('metric_job', (old) => {
          if (old == null) {
            return [optJob];
          }
          return old.map((job) => (job.id === optJob.id ? newJob : job));
        });
        return { optimisticNewJob: optJob };
      },

      onSuccess: async (newJob, _variables, context) => {
        const optId = context?.optimisticNewJob.id;
        queryClient.setQueryData<Job>(['metric_job', newJob.id], newJob);
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [newJob] : old.map((job) => (job.id === optId ? newJob : job))
        );
      },

      onError: async (_error, _variables, context) => {
        const optId = context?.optimisticNewJob.id;
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [] : old.filter((job) => job.id !== optId)
        );
      },

      onSettled: async () => queryClient.invalidateQueries('metric_job'),
    }
  );
}

export function useRetrainMetricJob(): UseMutationResult<Job, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (jobId: string) => {
      const response = await adminApiPost(`/manage/api/v1/jobs/${jobId}`, {
        data: {},
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Job;
    },
    {
      onSuccess: async (newJob) => {
        queryClient.setQueryData<Job>(['metric_job', newJob.id], newJob);
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [newJob] : old.map((job) => (job.id === newJob.id ? newJob : job))
        );
      },
    }
  );
}

interface EnableMetricJobParams {
  id: string;
  enabled: boolean;
}

export function useEnableMetricJob(): UseMutationResult<Job, unknown, EnableMetricJobParams, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, enabled }: EnableMetricJobParams) => {
      const response = await adminApiPost(`/manage/api/v1/jobs/${id}`, {
        data: {
          enabled,
        },
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Job;
    },
    {
      onSuccess: async (newJob) => {
        queryClient.setQueryData<Job>(['metric_job', newJob.id], newJob);
        queryClient.setQueryData<Job[]>('metric_job', (old) =>
          old == null ? [newJob] : old.map((job) => (job.id === newJob.id ? newJob : job))
        );
      },
    }
  );
}

export function useRemoveMetricJob(): UseMutationResult<boolean, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (metricJobId: string) => {
      const response = await adminApiDelete(`/manage/api/v1/jobs/${metricJobId}`);
      return response.ok;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('metric_job');
      },
    }
  );
}

export function useValidateNewJob(): UseMutationResult<
  ValidateNewJobResult | undefined,
  unknown,
  ValidateNewJob,
  unknown
> {
  return useMutation(async (validation: ValidateNewJob) => {
    const response = await adminApiPost('/manage/api/v1/jobs/validate', { data: validation });
    return response?.data?.data as ValidateNewJobResult | undefined;
  });
}

/// Fetch all models
export function useAllModels(): QueryObserverResult<Model[], Error> {
  return useQuery<Model[], Error>(
    'metric_model',
    async () => {
      const response = await adminApiGet('/manage/api/v1/models');
      return response.data.data as Model[];
    },
    { refetchOnMount: true }
  );
}

/// Fetch tenant information for the currently logged in user.
///
/// If the plugin is running with auth disabled, this will return 'zero values'
/// for the various `TenantInfo` fields, except for `canAccess` which will be `true`.
export function useTenantInfo(): QueryObserverResult<TenantInfo, Error> {
  return useQuery<TenantInfo, Error>(
    'tenant_info',
    async () => {
      const response = await adminApiGet(`/tenant/api/v1/info`);
      if (!response.ok) {
        throw response.data;
      }
      return response.data.data as TenantInfo;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

/// Fetch Grafana Alert rules, if available.
///
/// Throws if unified alerting is not enabled for the current Grafana instance.
export function useAlertRules(): QueryObserverResult<Alert[], Error> {
  return useQuery<Alert[], Error>(
    'grafana_alert_rules',
    async () => {
      const response = await getAlertRules();
      return response.Alerts ?? [];
    },
    { enabled: config.unifiedAlertingEnabled, retry: false, refetchOnMount: true }
  );
}

/// Fetch Grafana Alerts rule instances, if available.
///
/// Throws if unified alerting is not enabled for the current Grafana instance.
export function useAlertRuleInstances(): QueryObserverResult<RuleInstance[], Error> {
  return useQuery<RuleInstance[], Error>(
    'grafana_alert_rule_instances',
    async () => {
      const response = await getAlerts();
      return response.data.groups.flatMap((group) => group.rules);
    },
    { enabled: config.unifiedAlertingEnabled, retry: false, refetchOnMount: true }
  );
}

export function useAllHolidays(): QueryObserverResult<Holiday[], Error> {
  return useQuery<Holiday[], Error>(
    'holidays',
    async () => {
      const response = await adminApiGet('/manage/api/v1/holidays');
      if (!response.ok) {
        throw response.data;
      }
      return response.data.data as Holiday[];
    },
    { refetchInterval: 100000, refetchOnMount: true }
  );
}

export function useHoliday(holidayId: string): QueryObserverResult<Holiday, Error> {
  return useQuery<Holiday, Error>(
    ['holiday', holidayId],
    async () => {
      const response = await adminApiGet(`/manage/api/v1/holidays/${holidayId}`);
      if (!response.ok) {
        throw response.data;
      }
      const res = response.data.data as Holiday | null | undefined;
      if (res == null) {
        throw new Error('Not found');
      }
      return res;
    },
    { refetchOnMount: true }
  );
}

export function useCreateHoliday(): UseMutationResult<Holiday, unknown, NewHoliday, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (newHoliday: NewHoliday) => {
      const response = await adminApiPost(`/manage/api/v1/holidays`, {
        data: newHoliday,
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Holiday;
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('holiday');
      },
      onSettled: async () => queryClient.invalidateQueries('holidays'),
    }
  );
}

interface UpdateHolidayParams {
  id: string;
  updatedHoliday: NewHoliday;
}

export function useUpdateHoliday(): UseMutationResult<Holiday, unknown, UpdateHolidayParams, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updatedHoliday }: UpdateHolidayParams) => {
      const response = await adminApiPost(`/manage/api/v1/holidays/${id}`, {
        data: updatedHoliday,
      });

      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Holiday;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('holidays');
      },
      onSettled: async (holiday) => {
        await queryClient.invalidateQueries(['holiday', holiday?.id]);
      },
    }
  );
}

export function useRemoveHoliday(): UseMutationResult<boolean, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (holidayId: string) => {
      const response = await adminApiDelete(`/manage/api/v1/holidays/${holidayId}`);
      return response.ok;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('holidays');
      },
    }
  );
}

export function useHolidayMetricForecasts(holidayId: string): QueryObserverResult<Job[], Error> {
  return useQuery<Job[], Error>(
    ['holiday-metric-forecast', holidayId],
    async () => {
      const response = await adminApiGet(`/manage/api/v1/holidays/${holidayId}/jobs`);
      if (!response.ok) {
        throw response.data;
      }
      const res = response.data.data as Job[];
      if (res == null) {
        throw new Error('Not found');
      }
      return res;
    },
    { refetchOnMount: true, staleTime: 500 }
  );
}

interface PutHolidaysJobParams {
  id: string;
  newHolidays: { holidays: string[] };
}

export function usePutHolidaysJob(): UseMutationResult<string[], unknown, PutHolidaysJobParams, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, newHolidays }: PutHolidaysJobParams) => {
      const response = await adminApiPut(`/manage/api/v1/jobs/${id}/holidays`, {
        data: newHolidays,
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as string[];
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('holidays');
      },
      onSettled: async () => queryClient.invalidateQueries('holidays'),
    }
  );
}

export function useInvestigations(timeRange: TimeRange): QueryObserverResult<Investigation[], Error> {
  return useQuery<Investigation[], Error>(
    'investigation',
    async () => {
      const response = await adminApiGet(
        '/sift/api/v1/investigations?' +
          new URLSearchParams({
            start: timeRange.from.toISOString(),
            end: timeRange.to.toISOString(),
          })
      );
      if (!response.ok) {
        throw response.data;
      }
      return response.data.data as Investigation[];
    },
    { refetchInterval: false, refetchOnMount: true }
  );
}

export function useInvestigation(investigationId: string): QueryObserverResult<Investigation, Error> {
  return useQuery<Investigation, Error>(
    ['investigation', investigationId],
    async () => {
      const response = await adminApiGet(`/sift/api/v1/investigations/${investigationId}`);
      if (!response.ok) {
        throw response.data;
      }
      const res = response.data.data as Investigation | null | undefined;
      if (res == null) {
        throw new Error('Not found');
      }
      res.timeRange = toTimeRange({ from: res.requestData.start, to: res.requestData.end }, 'UTC');
      return res;
    },
    { refetchOnMount: true }
  );
}

/// Create a new metric job with optimistic local creation and updating after success, and removal on failure.
export function useCreateInvestigation(): UseMutationResult<Investigation, unknown, NewInvestigation, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (newInvestigation: NewInvestigation) => {
      const response = await adminApiPost(`/sift/api/v1/investigations`, {
        data: newInvestigation,
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Investigation;
    },
    {
      onMutate: async (newInvestigation: NewInvestigation) => {
        await queryClient.cancelQueries('investigation');

        const optMeta: UnnamedMetadata = {
          id: uuid(),
          created: new Date().toISOString(),
        };
        const optDatasources: DatasourceConfig = {
          lokiDatasource: { uid: '' },
          prometheusDatasource: { uid: '' },
          tempoDatasource: { uid: '' },
          pyroscopeDatasource: { uid: '' },
        };

        const optInvestigation: Investigation & { isOptimistic: boolean } = {
          ...newInvestigation,
          ...optMeta,
          datasources: optDatasources,
          timeRange: toTimeRange(
            { from: newInvestigation.requestData.start, to: newInvestigation.requestData.end },
            'UTC'
          ),
          analyses: {
            countsByStage: {},
            items: [],
          },
          status: 'pending',
          failureReason: '',
          isOptimistic: true,
        };
        queryClient.setQueryData<Investigation[]>('investigation', (old) =>
          old == null ? [optInvestigation] : [optInvestigation as Investigation].concat(old)
        );
        return { optimisticNewInvestigation: optInvestigation };
      },

      onSuccess: async (newInvestigation, _variables, context) => {
        const optId = context?.optimisticNewInvestigation.id;
        queryClient.setQueryData<Investigation>(['investigation', newInvestigation.id], newInvestigation);
        queryClient.setQueryData<Investigation[]>('investigation', (old) =>
          old == null ? [newInvestigation] : old.map((job) => (job.id === optId ? newInvestigation : job))
        );
      },

      onError: async (_error, _variables, context) => {
        const optId = context?.optimisticNewInvestigation.id;
        queryClient.setQueryData<Investigation[]>('investigation', (old) =>
          old == null ? [] : old.filter((job) => job.id !== optId)
        );
      },

      onSettled: async () => queryClient.invalidateQueries('investigation'),
    }
  );
}

/// Update an investigation with optimistic local updates, and revert optimism on on failure.
export function useUpdateInvestigation(): UseMutationResult<Investigation, unknown, Investigation, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatedInvestigation: Investigation) => {
      const response = await adminApiPost(`/sift/api/v1/investigations/${updatedInvestigation.id}`, {
        data: { ...updatedInvestigation, id: undefined },
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as Investigation;
    },
    {
      onMutate: async (newInvestigation: Investigation) => {
        await queryClient.cancelQueries('investigation');

        const optMeta: UnnamedMetadata = {
          id: newInvestigation.id,
          created: new Date().toISOString(),
        };

        const optInvestigation: Investigation & { isOptimistic: boolean } = {
          ...newInvestigation,
          ...optMeta,
          isOptimistic: true,
        };
        queryClient.setQueryData<Investigation>(['investigation', newInvestigation.id], optInvestigation);
        queryClient.setQueryData<Investigation[]>('investigation', (old) => {
          if (old == null) {
            return [optInvestigation];
          }
          return old.map((job) => (job.id === optInvestigation.id ? newInvestigation : job));
        });
        return { optimisticNewInvestigation: optInvestigation };
      },

      onSuccess: async (newInvestigation, _variables, context) => {
        const optId = context?.optimisticNewInvestigation.id;
        queryClient.setQueryData<Investigation>(['investigation', newInvestigation.id], newInvestigation);
        queryClient.setQueryData<Investigation[]>('investigation', (old) =>
          old == null ? [newInvestigation] : old.map((job) => (job.id === optId ? newInvestigation : job))
        );
      },

      onError: async (_error, _variables, context) => {
        const optId = context?.optimisticNewInvestigation.id;
        queryClient.setQueryData<Investigation[]>('investigation', (old) =>
          old == null ? [] : old.filter((job) => job.id !== optId)
        );
      },

      onSettled: async () => queryClient.invalidateQueries('investigation'),
    }
  );
}

export function useRemoveInvestigation(): UseMutationResult<boolean, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (investigationId: string) => {
      const response = await adminApiDelete(`/sift/api/v1/investigations/${investigationId}`);
      return response.ok;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('investigation');
      },
    }
  );
}

export function useInvestigationAnalyses(investigationId: string): QueryObserverResult<Analysis[], Error> {
  return useQuery<Analysis[], Error>(
    ['investigation', investigationId, 'analyses'],
    async () => {
      const response = await adminApiGet(`/sift/api/v1/investigations/${investigationId}/analyses`);
      if (!response.ok) {
        throw response.data;
      }
      const res = response.data.data as Analysis[] | null | undefined;
      if (res == null) {
        throw new Error('Not found');
      }
      return res;
    },
    { refetchOnMount: true }
  );
}

export function useSiftConfig(): QueryObserverResult<CheckConfigList, Error> {
  return useQuery<any, Error>(
    ['config'],
    async () => {
      const response = await adminApiGet(`/sift/api/v1/config`);

      if (!response.ok) {
        throw response.data;
      }

      const res = response.data.data.checks as CheckConfigList | null | undefined;

      if (res == null) {
        throw new Error('Not found');
      }
      return res;
    },
    { refetchOnMount: true }
  );
}

export function useCreateSiftConfig(): UseMutationResult<
  CheckConfig<AnyConfig>,
  unknown,
  CheckConfig<AnyConfig>,
  unknown
> {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatedCheck: CheckConfig<AnyConfig>) => {
      const response = await adminApiPost(`/sift/api/v1/config/checks`, {
        data: {
          name: updatedCheck.name,
          title: updatedCheck.title,
          if: updatedCheck.if,
          config: updatedCheck.config,
          disabled: updatedCheck.disabled,
        },
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as CheckConfig<AnyConfig>;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('config');
      },
    }
  );
}

export function useUpdateSiftConfig(): UseMutationResult<
  CheckConfig<AnyConfig>,
  unknown,
  CheckConfig<AnyConfig>,
  unknown
> {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatedCheck: CheckConfig<AnyConfig>) => {
      const response = await adminApiPost(`/sift/api/v1/config/checks/${updatedCheck.id}`, {
        data: {
          name: updatedCheck.name,
          title: updatedCheck.title,
          if: updatedCheck.if,
          config: updatedCheck.config,
          disabled: updatedCheck.disabled,
        },
      });
      if (!response.ok) {
        throw response.data;
      }
      return response?.data?.data as CheckConfig<AnyConfig>;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('config');
      },
    }
  );
}

export function useDeleteSiftConfig(): UseMutationResult<boolean, unknown, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    async (checkId: string) => {
      const response = await adminApiDelete(`/sift/api/v1/config/checks/${checkId}`);
      return response.ok;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('config');
      },
    }
  );
}
