import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

import { FullFormModel, Model, ModelId } from 'types';
import { onFormSubmit } from 'utils';

import { TabProps } from '../../utils/tabs';
import { AlgorithmOptions } from './AlgorithmOptions';

export function AlgorithmEditorTab({
  models,
  defaultValues,
  setParamsDirty,
  onSave,
  setSelectedModelId,
  selectedModelId,
  setParamsValid,
  holidays,
}: TabProps): JSX.Element {
  return (
    <ParamForm
      onSave={onSave}
      setDirty={setParamsDirty}
      defaultValues={defaultValues}
      selectedModelId={selectedModelId}
      setSelectedModelId={setSelectedModelId}
      models={models}
      setIsValid={setParamsValid}
      holidays={holidays}
    />
  );
}

const getStyles = () => {
  return {
    container: css`
      width: 500px;
    `,
    hyperParams: css`
      padding-bottom: 150px;
    `,
  };
};

interface ParamFormProps {
  onSave: (params: FullFormModel) => void;
  defaultValues: FullFormModel;
  setDirty: (dirty: boolean) => void;
  selectedModelId: ModelId | null;
  setSelectedModelId: Dispatch<SetStateAction<ModelId | null>>;
  setIsValid: (_: boolean) => void;
  models: readonly Model[];
  holidays: string[];
}

function ParamForm({
  models,
  onSave,
  setDirty,
  defaultValues,
  setSelectedModelId,
  selectedModelId,
  holidays,
  setIsValid,
}: ParamFormProps): JSX.Element {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<FullFormModel>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const styles = useStyles(getStyles);

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  const hasError = Object.entries(errors ?? {}).some(([k, x]) => {
    if (k !== 'name' && k !== 'description') {
      return x != null;
    }
    return false;
  });

  useEffect(() => {
    setIsValid(!hasError);
  }, [setIsValid, hasError]);

  // Reset the form if the defaultValues change. This happens when the
  // modelForm is populated.
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form id="algorithm-form" onSubmit={onFormSubmit(handleSubmit(onSave))}>
      <div className={styles.container}>
        <AlgorithmOptions
          control={control}
          errors={errors}
          register={register}
          availableModels={models}
          selectedModelId={selectedModelId}
          setSelectedModelId={setSelectedModelId}
          holidays={holidays}
        />
      </div>
    </form>
  );
}
