import React, { FC, useCallback } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Alert, Button, Drawer, FieldSet, VerticalGroup } from '@grafana/ui';

import { css } from '@emotion/css';
import { formatDuration, intervalToDuration } from 'date-fns';

import { useEnableMetricJob } from 'api';
import { useIsEditor } from 'hooks';
import { Job } from 'types';
import { getJobQueryString } from 'utils/utils.jobs';

import { Field } from './Field';
import { JobTrainingHistory } from './JobTrainingHistory';
import { ObjectField } from './ObjectField';

export function duration(seconds?: number): string {
  return seconds != null ? formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 })) : '-';
}

interface JobDetailsDrawerProps {
  item: Job;
  datasourceName: string;
  onClose: () => void;
}

const enableMessage = (isEditor: boolean): string =>
  isEditor
    ? `Please fix the issue above before re-enabling the forecast.
     You can also delete the forecast if it is no longer in use.`
    : 'Please ask an administrator to fix the issue and re-enable the forecast.';

export const JobDetailsDrawer: FC<JobDetailsDrawerProps> = ({ item, datasourceName, onClose }) => {
  const isEditor = useIsEditor();
  const doEnable = useEnableMetricJob();
  const onEnable = useCallback(() => {
    doEnable.mutate({ id: item.id, enabled: true });
    getAppEvents().publish({
      type: AppEvents.alertSuccess.name,
      payload: ['Forecast enabled', `Forecast '${item.name}' has been enabled and will be retrained shortly.`],
    });
  }, [item.id, item.name, doEnable]);

  return (
    <Drawer onClose={onClose} title={item.name} subtitle={item.description}>
      {item.disabled && (
        <Alert title="This forecast has been disabled:" severity="warning">
          <VerticalGroup>
            <div className={styles.disabledReason}>{item.disabledReason}</div>
            <small>{enableMessage(isEditor)}</small>
            <Button key="enable" variant="secondary" onClick={onEnable} disabled={!isEditor}>
              Enable
            </Button>
          </VerticalGroup>
        </Alert>
      )}
      {(item.lastTrainingStatus?.warnings ?? []).map((w, o) => (
        <Alert key={o} title="Warning during training" severity="warning">
          {w}
        </Alert>
      ))}
      {item.lastTrainingStatus?.status === 'error' ? (
        <Alert title="An unexpected error happened during training" severity="error">
          {item.lastTrainingStatus.error}
        </Alert>
      ) : null}
      <FieldSet label="Forecast Settings">
        <Field label="Lookback window" value={duration(item.trainingWindow)} />
        <Field label="Data interval" value={duration(item.interval)} />
        <Field label="Status" value={item.status} />
        <Field label="Datasource" value={datasourceName} />
        <Field label="Datasource type" value={item.datasourceType} />
        <Field label="Query" value={getJobQueryString(item)} />
        <Field label="Created at" value={item.created != null ? new Date(item.created).toLocaleString() : ''} />
        <Field label="Modified at" value={item.modified != null ? new Date(item.modified).toLocaleString() : ''} />
        <Field label="Created by" value={item.createdBy ?? 'unknown user'} />
        <Field label="Modified by" value={item.modifiedBy ?? 'never modified'} />
        <Field label="ID" value={item.id} />
        <Field label="Metric name" value={item.metric} />
        <Field label="Algorithm" value={item.algorithm} />
        <ObjectField label="Hyperparameters" value={item.hyperParams} />
      </FieldSet>
      <FieldSet label="Training Runs">
        <Field label="Training frequency" value={duration(item.trainingFrequency)} />
        <Field
          label="Next training"
          value={item.nextTrainingAt == null ? '' : new Date(item.nextTrainingAt).toLocaleString()}
        />
        <h4 className={styles.header}>Recent training runs</h4>
        <p>Most recent training runs with parameters</p>
        <JobTrainingHistory job={item} />
      </FieldSet>
    </Drawer>
  );
};

const styles = {
  header: css`
    margin-top: 20px;
  `,
  disabledReason: css`
    padding: 10px;
  `,
};
