import React, { useState } from 'react';

import { getTimeZone } from '@grafana/data';
import { HorizontalGroup, Icon, LinkButton, useStyles2, VerticalGroup } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { SiftModalData } from 'types';
import {
  getKubernetesMonitoringUrl,
  getLokiExploreUrl,
  getPyroscopeExploreUrl,
  isWorkloadKind,
  ProfileType,
} from 'utils/utils.url';

import { ErrorPatternPanel } from '../ErrorPatternLogs/ErrorPatternPanel';

interface KubeCrashesDetails {
  OOMKilled: OOMKilled[] | LegacyOOMKilled;
  Errored: Errored[];
}

type LegacyOOMKilled = Array<Record<string, string>>;

interface OOMKilled {
  labels: Record<string, string>;
  hasProfilingData: boolean;
}

function isLegacyOOMKilled(obj: LegacyOOMKilled | OOMKilled[]): obj is LegacyOOMKilled {
  return obj.length > 0 && !('hasProfilingData' in obj[0]);
}

function transformLegacyOOMKilled(legacyOOMKilled: LegacyOOMKilled): OOMKilled[] {
  return legacyOOMKilled.map((labels) => ({
    labels,
    hasProfilingData: false,
  }));
}

interface Errored {
  labels: Record<string, string>;
  logLines?: string[];
}

function selectors(labels: Record<string, string>): string {
  const cluster = labels['cluster'] ?? '';
  const namespace = labels['namespace'] ?? '';
  const pod = labels['pod'] ?? '';
  return `{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}`;
}

function query(labels: Record<string, string>): string {
  return `${selectors(labels)} |~\`(?i)(panic:|traceback |error:|fatal)\` !~\`(?i)(info|debug)\``;
}

function rateQuery(labels: Record<string, string>): string {
  return `rate(${query(labels)}[5m])`;
}

const KubeCrashes = ({ analysis, investigation, datasources }: SiftModalData): React.ReactElement => {
  const styles = useStyles2(getStyles);
  const [panelStatus, setPanelStatus] = useState<{ [key: number]: boolean }>({});
  const details = (analysis.result?.details || {
    OOMKilled: [],
    Errored: [],
  }) as unknown as KubeCrashesDetails;

  if (isLegacyOOMKilled(details.OOMKilled)) {
    details.OOMKilled = transformLegacyOOMKilled(details.OOMKilled);
  }

  const lokiUid = datasources.lokiDatasource.uid;
  const prometheusUid = datasources.prometheusDatasource.uid;
  const timeRange = investigation.timeRange;
  const timeZone = getTimeZone();

  if (!analysis.result.successful) {
    return <div>Error running analysis: {analysis.result.message}</div>;
  }

  if (!analysis.result.interesting) {
    return <div>No Crashed Pods Found</div>;
  }

  return (
    <div
      className={css`
        container: sift-modal-container / inline-size;
      `}
    >
      <table className={styles.tableStyle}>
        <thead>
          <tr
            className={cx(
              styles.tableDataStyle,
              css`
                border-bottom: 1px solid var(--grafana-border-color-weak);
              `
            )}
          >
            <th>
              <div className={styles.headerCellStyle}>Cluster</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Namespace</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Workload Kind</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Workload</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Pod</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Container</div>
            </th>
            <th
              className={css`
                width: 80px;
              `}
            >
              <div className={styles.headerCellStyle}>Status</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Description</div>
            </th>
            <th>
              <div className={styles.headerCellStyle}>Actions</div>
            </th>
          </tr>
        </thead>
        {details.Errored.length === 0 && details.OOMKilled.length === 0 ? (
          <tbody />
        ) : (
          <tbody>
            {details.Errored.map(({ labels }, index) => {
              const q = query(labels);

              // example output of kubeErrors.labels
              // cluster: "dev-us-central-0"
              // container: "grafana"
              // instance: "kube-state-metrics-5b76b69884-5cm2j:kube-state-metrics:ksm"
              // job: "kube-state-metrics/kube-state-metrics"
              // namespace: "hosted-grafana"
              // pod: "xavitesteasystart-grafana-7cc47fb544-crt27"
              // reason: "Error"
              // uid: "63ce6f65-c579-40c7-8c24-5336362ee79a"
              // workload: "xavitesteasystart-grafana"
              // workloadKind: "Deployment"

              const k8sUrl =
                isWorkloadKind(labels.workloadKind) && labels.workload !== undefined
                  ? getKubernetesMonitoringUrl({
                      from: timeRange.from,
                      to: timeRange.to,
                      prometheusDatasourceUid: prometheusUid,
                      lokiDatasourceUid: lokiUid,
                      cluster: labels.cluster,
                      namespace: labels.namespace,
                      workloadKind: labels.workloadKind,
                      workload: labels.workload,
                      pod: labels.pod,
                    })
                  : undefined;
              return (
                <React.Fragment key={`kubeerror-${index}`}>
                  <tr className={styles.tableDataStyle}>
                    <td>
                      <div
                        onClick={() => {
                          setPanelStatus({ ...panelStatus, [index]: !panelStatus[index]! });
                        }}
                      >
                        <Icon name={panelStatus[index] === true ? 'angle-down' : 'angle-right'} />
                        {labels.cluster}
                      </div>
                    </td>
                    <td>
                      <div>{labels.namespace}</div>
                    </td>
                    <td>
                      <div>{labels.workloadKind ?? 'unknown'}</div>
                    </td>
                    <td>
                      {/* if workloadKind is not present, use container name for backwards compatibility */}
                      <div>{labels.workload ?? labels.container}</div>
                    </td>
                    <td>
                      <div>{labels.pod}</div>
                    </td>
                    <td>
                      <div>{labels.container}</div>
                    </td>
                    <td>
                      <div>{labels.reason}</div>
                    </td>
                    <td>
                      <div>{labels.description}</div>
                    </td>
                    <td>
                      <VerticalGroup>
                        <HorizontalGroup>
                          <LinkButton
                            variant="secondary"
                            target="_blank"
                            size="sm"
                            icon="gf-logs"
                            href={getLokiExploreUrl(lokiUid, q, timeRange)}
                          >
                            View logs
                          </LinkButton>

                          <LinkButton
                            variant="secondary"
                            target="_blank"
                            size="sm"
                            icon="chart-line"
                            href={getLokiExploreUrl(lokiUid, rateQuery(labels), timeRange)}
                          >
                            View log rate
                          </LinkButton>
                        </HorizontalGroup>

                        {k8sUrl && (
                          <LinkButton
                            variant="secondary"
                            target="_blank"
                            size="sm"
                            icon="external-link-alt"
                            href={k8sUrl}
                          >
                            View in Kubernetes Monitoring
                          </LinkButton>
                        )}
                      </VerticalGroup>
                    </td>
                  </tr>
                  <tr
                    className={css`
                      border-bottom: 1px solid var(--grafana-border-color-weak);
                    `}
                  >
                    <td colSpan={7}>
                      {panelStatus[index] === true ? (
                        <div
                          className={css`
                            padding: 8px 0;
                          `}
                        >
                          <ErrorPatternPanel
                            visible={panelStatus[index] === true}
                            logQuery={q}
                            chartQuery={rateQuery(labels)}
                            timeRange={timeRange}
                            timeZone={timeZone}
                            dsUid={lokiUid}
                          />
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            {details.OOMKilled.map((kubeErrors, index) => {
              const k8sUrl =
                isWorkloadKind(kubeErrors.labels.workloadKind) && kubeErrors.labels.workload !== undefined
                  ? getKubernetesMonitoringUrl({
                      from: timeRange.from,
                      to: timeRange.to,
                      prometheusDatasourceUid: prometheusUid,
                      lokiDatasourceUid: lokiUid,
                      cluster: kubeErrors.labels.cluster,
                      namespace: kubeErrors.labels.namespace,
                      workloadKind: kubeErrors.labels.workloadKind,
                      workload: kubeErrors.labels.workload,
                      pod: kubeErrors.labels.pod,
                    })
                  : undefined;
              return (
                <React.Fragment key={`oomkilled-${index}`}>
                  <tr
                    className={cx(
                      styles.tableDataStyle,
                      css`
                        border-bottom: 1px solid var(--grafana-border-color-weak);
                      `
                    )}
                  >
                    <td>
                      <div
                        onClick={() => {
                          setPanelStatus({ ...panelStatus, [index]: !panelStatus[index]! });
                        }}
                      >
                        <Icon name={panelStatus[index] === true ? 'angle-down' : 'angle-right'} />
                        {kubeErrors.labels.cluster}
                      </div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.namespace}</div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.workloadKind ?? 'unknown'}</div>
                    </td>
                    <td>
                      {/* if workloadKind is not present, use container name for backwards compatibility */}
                      <div>{kubeErrors.labels.workload ?? kubeErrors.labels.container}</div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.pod}</div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.container}</div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.reason}</div>
                    </td>
                    <td>
                      <div>{kubeErrors.labels.description}</div>
                    </td>
                    <td>
                      {k8sUrl && (
                        <LinkButton
                          variant="secondary"
                          target="_blank"
                          size="sm"
                          icon="external-link-alt"
                          href={k8sUrl}
                        >
                          View in Kubernetes Monitoring
                        </LinkButton>
                      )}
                    </td>
                    <td>
                      {kubeErrors.hasProfilingData && (
                        <LinkButton
                          variant="secondary"
                          target="_blank"
                          size="sm"
                          icon="fire"
                          href={getPyroscopeExploreUrl(
                            selectors(kubeErrors.labels),
                            ProfileType.MemoryProfile,
                            timeRange
                          )}
                        >
                          Profile Memory
                        </LinkButton>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

const getStyles = () => ({
  tableStyle: css`
    width: 100%;
    table-layout: fixed;
  `,
  tableDataStyle: css`
    td,
    th {
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;

      > div {
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80vw;
        padding: 6px 3px;
      }

      &:hover {
        > div {
          backdrop-filter: brightness(0.1);
          border: 1px solid var(--grafana-main-color-info);
        }
      }
    }
  `,
  headerCellStyle: css`
    display: flex;
    flex-flow: row nowrap;
    gap: 3px;
    align-items: center;
    justify-content: flex-start;
  `,
});

// tableDataStyle
// only apply this style to the main table elements since the log viewer styles
// within the loki logs were getting overwritten

export { KubeCrashes };
