import { ComponentClass } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';

import { AppConfig } from 'pages/AppConfig';
import { MlAppSettings } from 'types';

import { App } from './App';
import { configureAppExtensions } from './extensions';

export const plugin = configureAppExtensions(
  new AppPlugin<MlAppSettings>()
    .setRootPage(App as unknown as ComponentClass<AppRootProps<MlAppSettings>, unknown>)
    .addConfigPage({
      title: 'Configuration',
      icon: 'cog',
      // Implementation: https://github.com/grafana/grafana/blob/78ee95814349f2bbab82c22f6a86e3892e8f06d6/public/app/features/plugins/admin/components/PluginDetailsBody.tsx#L56
      /**
       * Would expect a Class component, however works absolutely fine with a functional one
       */
      body: AppConfig,
      id: 'configuration',
    })
);
