import React, { useEffect, useRef, useState } from 'react';

import {
  applyFieldOverrides,
  createDataFrame,
  createTheme,
  DataFrame,
  DataLinkClickEvent,
  Field,
  FieldType,
  GrafanaTheme2,
  ScopedVars,
  toDataFrame,
} from '@grafana/data';
import { TableFieldOptions, ThresholdsMode } from '@grafana/schema';
import { Table, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { SiftModalData } from 'types';
import { findLabelMatcher } from 'utils/utils.sift';
import { getKubernetesMonitoringUrl, KubernetesMonitoringPodOptions } from 'utils/utils.url';

interface Node {
  name: string;
  normalizedLoad: number;
  podsUsingTooMuchCpu: Pod[];
}

interface Pod {
  name: string;
  namespace: string;
  node: string;
  maxRatio: number;
  maxRequests: number;
  maxUsage: number;
  workload?: string;
  workloadKind?: string;
}

interface NoisyNeighborDetails {
  nodes: Record<string, Node>;
  pods: Pod[];
}

function podsDataFrame(
  details: NoisyNeighborDetails,
  onClick: (event: DataLinkClickEvent) => void,
  hasWorkload: boolean,
  nodeK8sUrl: string,
  podK8sUrl: string
): DataFrame {
  const podNames = details.pods.map((pod) => pod.name);
  const podNamespaces = details.pods.map((pod) => pod.namespace);
  const nodeNames = details.pods.map((pod) => pod.node);
  const saturation = details.pods.map((pod) => {
    return details.nodes[pod.node]?.normalizedLoad;
  });

  const fields: Field[] = [
    {
      name: 'Pod',
      type: FieldType.string,
      config: {
        links: hasWorkload
          ? [
              {
                title: 'View in Kubernetes Monitoring',
                url: podK8sUrl,
              },
            ]
          : [],
      },
      values: podNames,
    },
    {
      name: 'Namespace',
      type: FieldType.string,
      config: {},
      values: podNamespaces,
    },
    {
      name: 'Node',
      type: FieldType.string,
      config: {
        links: hasWorkload
          ? [
              {
                title: 'View possible noisy neighbors',
                url: '',
                onClick: onClick,
              },
              {
                title: 'View in Kubernetes Monitoring',
                url: nodeK8sUrl,
              },
            ]
          : [
              {
                title: 'View possible noisy neighbors',
                url: '',
                onClick: onClick,
              },
            ],
      },
      values: nodeNames,
    },
    {
      name: 'Node Saturation',
      type: FieldType.number,
      config: {
        custom: {
          displayMode: 'color-text',
        },
        color: {
          mode: 'thresholds',
        },
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'orange',
              value: 0.8,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
        unit: 'percentunit',
        decimals: 2,
      },
      values: saturation,
    },
  ];
  // Analyses created before #3875 don't contain a workload/workloadKind
  // field in the pod details. This makes sure we don't add those fields
  // if they are not present.
  if (hasWorkload) {
    const workload = details.pods.map((pod) => pod.workload as string);
    const workloadKind = details.pods.map((pod) => pod.workloadKind as string);
    // These two columns are hidden and are only used to populate the
    // data links in the table.
    fields.push({
      name: 'workload',
      type: FieldType.string,
      config: {
        custom: {
          hidden: true,
        },
      },
      values: workload,
    });
    fields.push({
      name: 'workloadKind',
      type: FieldType.string,
      config: {
        custom: {
          hidden: true,
        },
      },
      values: workloadKind,
    });
  }
  return applyTableFieldOverrides({ fields, length: podNames.length });
}

interface FieldEvent {
  field: Field;
  rowIndex: number;
}

export default function NoisyNeighbors({ investigation, analysis, datasources }: SiftModalData): React.ReactElement {
  const styles = useStyles2(getStyles);
  const details = analysis.result?.details as unknown as NoisyNeighborDetails | undefined;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { from, to } = investigation.timeRange;
  const cluster = findLabelMatcher(investigation, 'cluster', 'prometheusDatasource')?.value ?? '';
  const namespace = findLabelMatcher(investigation, 'namespace', 'prometheusDatasource')?.value ?? '';
  const prometheusDatasourceUid = datasources.prometheusDatasource.uid;
  const lokiDatasourceUid = datasources.lokiDatasource.uid;

  const [node, setNode] = useState<Node | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current != null) {
      const dims = containerRef.current.getBoundingClientRect();
      setWidth(dims.width);
      setHeight(dims.height);
    }
  }, [containerRef]);

  if (details?.pods === undefined) {
    return <div>No noisy neighbors found.</div>;
  }

  const onClick = (event: DataLinkClickEvent<FieldEvent>): void => {
    if (event.origin.rowIndex === undefined) {
      setNode(undefined);
    }
    const nodeName = event.origin.field.values[event.origin.rowIndex];
    if (nodeName === undefined) {
      setNode(undefined);
    }
    setNode(details.nodes[nodeName]);
  };

  const hasWorkload = details.pods.some((pod) => pod.workload !== undefined);

  const podOptions: KubernetesMonitoringPodOptions = {
    from,
    to,
    prometheusDatasourceUid,
    lokiDatasourceUid: lokiDatasourceUid !== '' ? lokiDatasourceUid : undefined,
    cluster,
    namespace,
    pod: '${__value.text}',
    // These will be populated by the applyTableFieldOverrides function.
    workload: '${__data.fields["workload"].text}',
    // @ts-ignore-next-line
    workloadKind: '${__data.fields["workloadKind"].text}',
  };
  const podK8sUrl = getKubernetesMonitoringUrl(podOptions);
  const nodeK8sUrl = getKubernetesMonitoringUrl({
    from,
    to,
    prometheusDatasourceUid,
    lokiDatasourceUid: lokiDatasourceUid !== '' ? lokiDatasourceUid : undefined,
    cluster,
    // This will be populated by the applyTableFieldOverrides function.
    node: '${__value.text}',
  });

  return (
    <div ref={containerRef} className={styles.tableContainer}>
      {details.pods.length === 0 ? <h5>No noisy neighbors found.</h5> : null}
      <div className={styles.instructions}>Select a node to see possible noisy neighbors</div>
      {width !== 0 && height !== 0 ? (
        <Table
          data={podsDataFrame(details, onClick, hasWorkload, nodeK8sUrl, podK8sUrl)}
          width={width}
          height={height}
          initialSortBy={[{ displayName: 'Node Saturation', desc: true }]}
        />
      ) : null}
      {node !== undefined ? <NoisyPods node={node} hasWorkload={hasWorkload} podK8sUrl={podK8sUrl} /> : null}
    </div>
  );
}

interface NoisyPodsProps {
  node: Node;
  podK8sUrl: string;
  hasWorkload: boolean;
}

function NoisyPods({ node, podK8sUrl, hasWorkload }: NoisyPodsProps): React.ReactElement {
  const styles = useStyles2(getStyles);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current != null) {
      const dims = containerRef.current.getBoundingClientRect();
      setWidth(dims.width);
      setHeight(dims.height);
    }
  }, [containerRef]);

  const fields: Field[] = [
    {
      name: 'Pod',
      type: FieldType.string,
      config: {
        links: hasWorkload
          ? [
              {
                title: 'View in Kubernetes Monitoring',
                url: podK8sUrl,
              },
            ]
          : [],
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.name),
    },
    {
      name: 'Namespace',
      type: FieldType.string,
      config: {},
      values: node.podsUsingTooMuchCpu.map((pod) => pod.namespace),
    },
    {
      name: 'Maximum Requests',
      type: FieldType.number,
      config: {
        description: 'Maximum requests of the pod over the investigation period.',
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.maxRequests),
    },
    {
      name: 'Maximum Usage',
      type: FieldType.number,
      config: {
        description: 'Maximum CPU usage of the pod over the investigation period.',
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.maxUsage),
    },
    {
      name: 'Maximum Ratio',
      type: FieldType.number,
      config: {
        description: 'Maximum ratio of Usage / Requests over the investigation period.',
        custom: {
          displayMode: 'color-text',
        } as TableFieldOptions,
        color: {
          mode: 'thresholds',
        },
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'orange',
              value: 1,
            },
            {
              color: 'red',
              value: 1.5,
            },
          ],
        },
        decimals: 2,
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.maxRatio),
    },
  ];
  if (hasWorkload) {
    fields.push({
      // The next two columns are hidden and are only used to populate the
      // data links in the table.
      name: 'workload',
      type: FieldType.string,
      config: {
        custom: {
          hidden: true,
        },
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.workload),
    });
    fields.push({
      name: 'workloadKind',
      type: FieldType.string,
      config: {
        custom: {
          hidden: true,
        },
      },
      values: node.podsUsingTooMuchCpu.map((pod) => pod.workloadKind),
    });
  }
  const dataFrame = applyTableFieldOverrides(toDataFrame({ fields }));

  if (dataFrame.length === 0) {
    return (
      <h4
        className={css`
          margin-top: 10px;
        `}
      >
        The selected node has no data to display
      </h4>
    );
  }
  return (
    <div
      ref={containerRef}
      className={cx(
        styles.tableContainer,
        css`
          margin-top: 10px;
        `
      )}
    >
      <h4>The following pods on {node.name} are using more CPU than requested:</h4>
      {width !== 0 && height !== 0 ? (
        <Table
          data={dataFrame}
          width={width}
          height={height}
          initialSortBy={[{ displayName: 'Maximum Ratio', desc: true }]}
        />
      ) : null}
    </div>
  );
}

function applyTableFieldOverrides(dataFrame: DataFrame): DataFrame {
  const dataFrames = applyFieldOverrides({
    data: [dataFrame],
    fieldConfig: {
      defaults: {},
      overrides: [],
    },
    // This is necessary to make text variables show up in tables, though I am
    // not completely sure why. It is adapted from getDefaultDataFrame() in
    // https://github.com/grafana/grafana/blob/main/packages/grafana-ui/src/components/Table/Table.test.tsx.
    replaceVariables: (value: string, vars?: ScopedVars, _format?: Function | string): string => {
      const scopedVal = vars?.__dataContext?.value;
      if (vars === undefined || scopedVal === undefined || scopedVal.rowIndex === undefined) {
        return value;
      }
      // Replace any instances of __value.text with the actual value from the field.
      const { field, rowIndex, frame } = scopedVal;
      let replaced = value.replaceAll('${__value.text}', field.values[rowIndex] as string);
      // Replace any instances of __data.fields["<field name>"].text with the actual value from the field.
      for (const f of frame.fields) {
        const toReplace = '${__data.fields["' + f.name + '"].text}';
        replaced = replaced.replaceAll(toReplace, f.values[rowIndex] as string);
      }
      return replaced;
    },
    theme: createTheme(),
  });

  return dataFrames[0] ?? createDataFrame({ fields: [] });
}

const getStyles = (_theme: GrafanaTheme2) => {
  return {
    tableContainer: css`
      width: 100%;
      min-height: 500px;
    `,
    instructions: css`
      margin: 10px 0px 20px 0px;
    `,
  };
};

export { NoisyNeighbors };
