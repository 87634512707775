import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useMetricJob } from 'api';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';
import { useQueryContext } from 'hooks/useQueryContext';
import { DataQueryWithExpression, TenantInfo } from 'types';

import { EditJobContent } from './EditJobContent';

interface EditJobProps {
  tenantInfo: TenantInfo;
}

export default function EditJob({ tenantInfo }: EditJobProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError } = useMetricJob(id!, { cacheTime: 0 });
  const title = 'Edit metric forecast';

  const { updateQuery, isEmpty } = useQueryContext();

  useEffect(() => {
    if (isEmpty && data?.queryParams !== undefined) {
      updateQuery(data?.queryParams as DataQueryWithExpression);
    }
  }, [updateQuery, data?.queryParams, isEmpty]);

  if (isError) {
    return (
      <PluginPage pageNav={{ text: title }}>
        <div>Error</div>
      </PluginPage>
    );
  }

  if (isLoading || data == null) {
    return (
      <PluginPage pageNav={{ text: title }}>
        <div className={styles.wrapper}>
          <PageToolbar className={styles.pageToolbar} data-element="forecasting-edit-buttons">
            <ToolbarButton id="SaveAs" disabled={true} form="algorithm-form" title="Save changes as new forecast">
              Save As
            </ToolbarButton>
            <ToolbarButton
              id="Save"
              form="algorithm-form"
              disabled={true}
              variant="primary"
              title="Apply changes and go back to list of forecasts"
            >
              Update
            </ToolbarButton>
          </PageToolbar>
          <div className={styles.verticalSplitPanesWrapper}>
            <LoadingIndicator />
          </div>
        </div>
      </PluginPage>
    );
  }

  return (
    <QueryRunnerProvider>
      <PluginPage pageNav={{ text: title }}>
        <div className={styles.wrapper}>
          <EditJobContent originalJob={data} tenantInfo={tenantInfo} />
        </div>
      </PluginPage>
    </QueryRunnerProvider>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
    `,
    verticalSplitPanesWrapper: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      height: calc(100vh - 350px);
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: theme.spacing(2),
    }),
  };
};
