import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { locationService, PluginPage } from '@grafana/runtime';
import { Tab, TabContent, TabsBar, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { PLUGIN_ROOT } from 'consts';
import { useIsEditor } from 'hooks';

import { Configuration } from './Configuration/Configuration';
import { InvestigationList } from './List/InvestigationList';
import { Welcome } from './Welcome';

interface InvestigationTabsProps {
  view: string;
}

function InvestigationTabs({ view }: InvestigationTabsProps): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const isEditor = useIsEditor();

  return (
    <PluginPage
      subTitle=""
      renderTitle={() => {
        return (
          <div className={styles.pageHeader}>
            <div className={styles.pageTitle}>Sift investigations</div>
          </div>
        );
      }}
    >
      <Welcome />
      <TabsBar className={styles.tabsBar}>
        <Tab
          label="Investigations"
          active={view === 'list' || view === 'create'}
          onChangeTab={() => locationService.replace({ pathname: `${PLUGIN_ROOT}/investigations` })}
        />
        {isEditor ? (
          <Tab
            label="Configuration"
            active={view === 'config'}
            onChangeTab={() => locationService.replace({ pathname: `${PLUGIN_ROOT}/investigations/configuration` })}
          />
        ) : null}
      </TabsBar>
      <TabContent className="style.tab-content">
        {view === 'list' ? <InvestigationList /> : null}
        {view === 'create' ? <InvestigationList showModal /> : null}
        {view === 'config' ? <Configuration /> : null}
      </TabContent>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tabsBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    pageHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      height: 32px;
    `,
    pageTitle: css`
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.2;
      color: ${theme.colors.text.primary};
    `,
  };
};

export { InvestigationTabs };
