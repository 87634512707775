import React from 'react';

import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

export const RowField: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const styles = useStyles(getStyles);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <span className={styles.title}>Query</span>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    wrapper: css`
      margin-bottom: ${theme.spacing.md};
    `,
    container: css`
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      border-radius: ${theme.border.radius.sm};
      background: ${theme.colors.bg2};
      min-height: ${theme.spacing.formInputHeight}px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    title: css`
      font-size: ${theme.typography.size.sm};
      font-weight: ${theme.typography.weight.semibold};
      overflow: hidden;
    `,
    content: css`
      margin-top: ${theme.spacing.inlineFormMargin};
      margin-left: ${theme.spacing.lg};
    `,
  };
};
