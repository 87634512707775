import React, { useMemo } from 'react';

import { GrafanaTheme } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { PageToolbar, ToolbarButton, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';
import { StringParam, useQueryParam } from 'use-query-params';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';
import { useLastSelectedStorage, useSupportedDatasources } from 'hooks';
import { TenantInfo } from 'types';

import { CreateJobContent } from './CreateJobContent';

interface CreateJobProps {
  tenantInfo: TenantInfo;
}

export default function CreateJob({ tenantInfo }: CreateJobProps): JSX.Element | null {
  const datasources = useSupportedDatasources();
  const [qsDatasourceId] = useQueryParam('ds', StringParam);
  const styles = useStyles(getStyles);
  const [lastSelected] = useLastSelectedStorage();

  const initialDataSource = useMemo(() => {
    // If the datasource is passed in as a query parameter, use that first.
    if (qsDatasourceId !== null && qsDatasourceId !== undefined) {
      const found = datasources.find((x) => x.uid === qsDatasourceId);
      if (found != null) {
        return found;
      }
    }
    // If a datasource has been used previously use that next.
    if (lastSelected != null) {
      const found = datasources.find((x) => x.uid === lastSelected);
      if (found != null) {
        return found;
      }
    }
    // Find the default datasource and use that if it exists.
    const found = datasources.find((x) => x.isDefault === true);
    if (found != null) {
      return found;
    }
    // Finally, use the last datasource in the list.
    return datasources[datasources.length - 1];
  }, [lastSelected, qsDatasourceId, datasources]);

  const title = 'New metric forecast';

  if (initialDataSource == null) {
    return (
      <PluginPage pageNav={{ text: title }}>
        <div className={styles.wrapper}>
          <PageToolbar className={styles.pageToolbar} data-element="forecasting-create-buttons">
            <ToolbarButton
              id="Save"
              disabled={true}
              variant="primary"
              title="Create forecast and go back to list of forecasts"
            >
              Save
            </ToolbarButton>
            <ToolbarButton id="Cancel" disabled={true} title="Undo all changes">
              Cancel
            </ToolbarButton>
          </PageToolbar>
          <div className={styles.verticalSplitPanesWrapper}>
            <LoadingIndicator />
          </div>
        </div>
      </PluginPage>
    );
  }

  return (
    <PluginPage pageNav={{ text: title }}>
      <QueryRunnerProvider>
        <CreateJobContent initialDataSource={initialDataSource} tenantInfo={tenantInfo} />
      </QueryRunnerProvider>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme) => {
  return {
    wrapper: css`
      z-index: ${theme.zIndex.navbarFixed};
      top: 0;
      bottom: 0;
      right: 0;
      left: 60px;
      background: ${theme.colors.dashboardBg};
      display: flex;
      flex-direction: column;
    `,
    verticalSplitPanesWrapper: css`
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 300px;
      height: calc(100vh - 350px);
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: '16px',
    }),
  };
};
