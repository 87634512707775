import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { SiftModalData } from 'types';

interface RelatedAlertsDetails {
  alerts: Array<Record<string, string>>;
}

export default function RelatedAlerts({ analysis }: SiftModalData): JSX.Element {
  const styles = useStyles2(getStyles);

  const details = analysis.result?.details as RelatedAlertsDetails | undefined;
  const alerts = details?.alerts ?? [];

  if (alerts.length === 0) {
    return <div>No related alerts found</div>;
  }

  return (
    <div>
      {alerts.map((alert: Record<string, string>) => {
        return (
          <div key={JSON.stringify(alert)} className={styles.alertBox}>
            <h4>{alert['alertname']}</h4>
            <ul>
              {Object.keys(alert)
                .filter((labelName) => {
                  // Filter out generic labels that will not be helpful for the user, or are already displayed.
                  return labelName !== '__name__' && labelName !== 'alertname' && labelName !== 'alertstate';
                })
                .map((labelName) => {
                  return (
                    <li key={labelName}>
                      {labelName}: {alert[labelName]}
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

const getStyles = (_theme: GrafanaTheme2) => {
  return {
    alertBox: css`
      padding: 1em;
    `,
  };
};
