import React from 'react';

import { CodeEditor } from '@grafana/ui';

import { analysisContext, RudderstackEvents, trackRudderStackEvent } from 'hooks/useRudderstack';
import { Analysis, DatasourceConfig, Investigation, SiftModalData } from 'types';

import { CorrelatedSeries } from './Checks/CorrelatedSeries';
import { ErrorPatternLogs } from './Checks/ErrorPatternLogs';
import { HTTPErrorSeries } from './Checks/HTTPErrorSeries';
import { KubeCrashes } from './Checks/KubeCrashes';
import { LogQuery } from './Checks/LogQuery';
import { MetricQuery } from './Checks/MetricQuery';
import { NoisyNeighbors } from './Checks/NoisyNeighbors';
import { RecentDeployments } from './Checks/RecentDeployments';
import { ResourceContentions } from './Checks/ResourceContentions';
import { SlowRequests } from './Checks/SlowRequests';
// Outdated result UIs (these need updating and moving).
import ErrorSpans from './View/ErrorSpans';
import RelatedAlerts from './View/RelatedAlerts';
import StatusPageOutages from './View/StatusPageOutages';

function JSONDetails({ analysis }: SiftModalData): JSX.Element {
  const resultDetails = JSON.stringify(analysis.result?.details ?? {}, null, 2);
  return (
    <CodeEditor
      width={'100%'}
      height={'300px'}
      language="json"
      showLineNumbers={true}
      showMiniMap={resultDetails.length > 100}
      value={resultDetails}
      readOnly={true}
    />
  );
}

function getAnalysisDataSources(analysis: Analysis, investigation: Investigation): DatasourceConfig {
  const datasources = investigation.datasources;
  if (!analysis.config) {
    return datasources;
  }

  if (!!analysis.config.lokiDatasourceUid) {
    datasources.lokiDatasource.uid = analysis.config.lokiDatasourceUid;
  }
  if (!!analysis.config.prometheusDatasourceUid) {
    datasources.prometheusDatasource.uid = analysis.config.prometheusDatasourceUid;
  }
  if (!!analysis.config.tempoDatasourceUid) {
    datasources.tempoDatasource.uid = analysis.config.tempoDatasourceUid;
  }
  if (!!analysis.config.pyroscopeDatasourceUid) {
    datasources.pyroscopeDatasource = { uid: analysis.config.pyroscopeDatasourceUid };
  }
  return datasources;
}

function CustomDetailsPanel({ analysis, investigation }: SiftModalData): JSX.Element {
  trackRudderStackEvent(RudderstackEvents.ViewAnalysis, analysisContext(analysis));
  const datasources = getAnalysisDataSources(analysis, investigation);

  switch (analysis.name) {
    case 'NoisyNeighbors':
      return <NoisyNeighbors analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'ErrorPatternLogs':
      return <ErrorPatternLogs analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'ErrorSpans':
      return <ErrorSpans analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'HTTPErrorSeries':
      return <HTTPErrorSeries analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'RelatedAlerts':
      return <RelatedAlerts analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'CorrelatedSeries':
      return <CorrelatedSeries analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'SlowRequests':
      return <SlowRequests analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'KubeCrashes':
      return <KubeCrashes analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'ResourceContentions':
      return <ResourceContentions analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'MetricQuery':
      return <MetricQuery analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'RecentDeployments':
      return <RecentDeployments analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'StatusPageOutages':
      return <StatusPageOutages analysis={analysis} investigation={investigation} datasources={datasources} />;
    case 'LogQuery':
      return <LogQuery analysis={analysis} investigation={investigation} datasources={datasources} />;
    default:
      return <JSONDetails analysis={analysis} investigation={investigation} datasources={datasources} />;
  }
}

export default CustomDetailsPanel;
