import React, { ReactElement } from 'react';

import { Slider } from 'components/Slider';

type Props = {
  enabled?: boolean;
  sensitivity: number;
  onSensitivityChange: (sensitivity: number) => void;
};

export function OutlierSensitivity(props: Props): ReactElement {
  const { sensitivity, onSensitivityChange, enabled = true } = props;

  return (
    <Slider
      step={0.01}
      min={0}
      max={1.0}
      marks={{ 0: 'low', 0.25: '', 0.5: 'medium', 0.75: '', 1: 'high' }}
      value={sensitivity}
      onAfterChange={(v) => {
        if (typeof v === 'number') {
          onSensitivityChange(v);
        }
      }}
      enabled={enabled}
    />
  );
}
