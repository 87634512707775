import React from 'react';

import { getMutableTimeRange } from 'utils/timeRange';
import { getTimeZoneWithDefault } from 'utils/utils.timeZone';

import { ForecastModal } from './components/ForecastModal';
import { OutlierModal } from './components/OutlierModal';
import { configureExploreLink, configurePanelLink } from './configureLink';
import { assertExploreContext, assertPanelContext } from './guards';
import { ExtensionLinkConfig, ExtensionLinkParams, ExtensionPointIds } from './types';
import { createLinkConfig } from './utils';

export const panelExtensionLinks: ExtensionLinkConfig[] = [
  createForecastLinkConfig({
    extensionPointId: ExtensionPointIds.DashboardPanelMenu,
    configure: configurePanelLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertPanelContext(context);
      const [query] = context.targets;

      openModal({
        title: 'Forecast preview',
        body: (props) => (
          <ForecastModal
            {...props}
            onDismissInitialize={props.onDismiss}
            query={query}
            timeZone={getTimeZoneWithDefault(context.timeZone)}
          />
        ),
        height: '80%',
        width: '80%',
      });
    },
  }),
  createOutlierLinkConfig({
    extensionPointId: ExtensionPointIds.DashboardPanelMenu,
    configure: configurePanelLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertPanelContext(context);
      const [query] = context.targets;

      openModal({
        title: `Outlier detection preview`,
        body: (props) => (
          <OutlierModal
            {...props}
            onDismissInitialize={props.onDismiss}
            timeRange={getMutableTimeRange(context.timeRange)}
            query={query}
            timeZone={getTimeZoneWithDefault(context.timeZone)}
          />
        ),
        height: '80%',
        width: '80%',
      });
    },
  }),
];

export const exploreExtensionLinks: ExtensionLinkConfig[] = [
  createForecastLinkConfig({
    extensionPointId: ExtensionPointIds.ExploreToolbarAction,
    configure: configureExploreLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertExploreContext(context);
      const [query] = context.targets;

      openModal({
        title: 'Forecast preview',
        body: (props) => (
          <ForecastModal
            {...props}
            onDismissInitialize={props.onDismiss}
            query={query}
            timeZone={getTimeZoneWithDefault(context.timeZone)}
          />
        ),
        height: '80%',
        width: '80%',
      });
    },
  }),
  createOutlierLinkConfig({
    extensionPointId: ExtensionPointIds.ExploreToolbarAction,
    configure: configureExploreLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertExploreContext(context);
      const [query] = context.targets;

      openModal({
        title: `Outlier detection preview`,
        body: (props) => (
          <OutlierModal
            {...props}
            onDismissInitialize={props.onDismiss}
            timeRange={getMutableTimeRange(context.timeRange)}
            query={query}
            timeZone={getTimeZoneWithDefault(context.timeZone)}
          />
        ),
        height: '80%',
        width: '80%',
      });
    },
  }),
];

function createForecastLinkConfig(params: ExtensionLinkParams): ExtensionLinkConfig {
  // Applying default values for a forecast link
  const title = params.title ?? 'Create forecast';
  const description = params.description ?? 'Use Machine Learning to forecast this query into the future';
  const category = params.category ?? 'Machine Learning';
  const icon = params.icon ?? 'gf-ml';

  return createLinkConfig({ ...params, title, description, category, icon });
}

function createOutlierLinkConfig(params: ExtensionLinkParams): ExtensionLinkConfig {
  // Applying default values for a outlier link
  const title = params.title ?? 'Detect outliers';
  const description = params.description ?? 'Use Machine Learning to detect outlying series in this query';
  const category = params.category ?? 'Machine Learning';
  const icon = params.icon ?? 'gf-ml';

  return createLinkConfig({ ...params, title, description, category, icon });
}
