import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmModal, IconName } from '@grafana/ui';

interface ConfirmModalProps {
  icon: IconName;
  title: string;
  actionText: string;
}

export function useConfirmModal(
  didConfirm: () => Promise<void>,
  options: { confirmOnNavigate?: boolean; skipConfirm?: boolean } = {}
): [() => Promise<void>, FC<ConfirmModalProps>] {
  const [showConfirm, setShowConfirm] = useState(false);

  const handlePopState = useCallback(() => {
    setShowConfirm(true);
  }, []);

  useEffect(() => {
    if (options.skipConfirm !== true) {
      return () => void 0;
    }
    if (options.confirmOnNavigate !== true) {
      return () => void 0;
    }
    history.pushState(null, '', null);
    const handler = handlePopState;
    addEventListener('popstate', handler);
    return () => {
      removeEventListener('popstate', handler);
    };
  }, [handlePopState, options.confirmOnNavigate, options.skipConfirm]);

  const handleCancel = useCallback(() => {
    setShowConfirm(false);
    if (options.confirmOnNavigate === true || options.skipConfirm === true) {
      history.pushState(null, '', null);
    }
  }, [options.confirmOnNavigate, options.skipConfirm]);

  const handleConfirm = useCallback(() => {
    setShowConfirm(false);
    void didConfirm();
  }, [didConfirm]);

  const DoConfirmModal: FC<ConfirmModalProps> = useMemo(
    () =>
      showConfirm
        ? ({ icon, title, actionText, children }) => (
            <ConfirmModal
              isOpen
              icon={icon}
              title={title}
              body={children}
              confirmText={actionText}
              onDismiss={handleCancel}
              onConfirm={handleConfirm}
            />
          )
        : () => null,
    [handleCancel, handleConfirm, showConfirm]
  );

  return [
    async () => {
      if (options.skipConfirm === true) {
        setShowConfirm(false);
        await didConfirm();
      } else {
        setShowConfirm(true);
      }
    },
    DoConfirmModal,
  ];
}
