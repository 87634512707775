import React, { ReactElement } from 'react';

import { dateTimeFormat, GrafanaTheme2, TimeRange, timeZoneFormatUserFriendly } from '@grafana/data';
import { TimeZone } from '@grafana/schema';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { orange } from './colors';

type Props = {
  timeRange: TimeRange;
  timeZone: TimeZone;
};

export function TimeRangeTooltip({ timeRange, timeZone }: Props): ReactElement {
  const styles = useStyles2(getStyles);

  return (
    <>
      {dateTimeFormat(timeRange.from, { timeZone })}
      <div className={styles.center}>to</div>
      {dateTimeFormat(timeRange.to, { timeZone })}
      <div className={styles.center}>
        <span className={styles.utc}>{timeZoneFormatUserFriendly(timeZone)}</span>
      </div>
    </>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    utc: css({
      color: orange,
      fontSize: theme.typography.bodySmall.fontSize,
      paddingLeft: '6px',
      lineHeight: '28px',
      verticalAlign: 'bottom',
      fontWeight: theme.typography.fontWeightMedium,
    }),
    center: css({
      textAlign: 'center',
    }),
  };
};
