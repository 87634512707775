import React, { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { dateTime, getTimeZone, getTimeZoneInfo, GrafanaTheme2, TimeZoneInfo } from '@grafana/data';
import {
  Button,
  DateTimePicker,
  Field,
  HorizontalGroup,
  IconButton,
  Input,
  MultiSelect,
  RadioButtonGroup,
  TextArea,
  TimeZonePicker,
  useStyles2,
  VerticalGroup,
} from '@grafana/ui';

import { css } from '@emotion/css';
import { StringParam, useQueryParam } from 'use-query-params';

import { useAllMetricJobs } from 'api';
import { PLUGIN_ROOT } from 'consts';
import { Holiday, HolidayForm, Job, NewHoliday } from 'types';
import { onFormSubmit } from 'utils';

enum HOLIDAY_SOURCE {
  ICAL = 'ical',
  MANUAL = 'manual',
}

const calendarMode = [
  { label: 'iCal URL', value: HOLIDAY_SOURCE.ICAL },
  { label: 'Manual', value: HOLIDAY_SOURCE.MANUAL },
];
interface CreateUpdateHolidayContentProps {
  originalHoliday?: Holiday;
  onSubmitForm: (holiday: NewHoliday) => Promise<void>;
}

interface ForecastingJob {
  key: string;
  label: string;
  value: string;
}

/** Adjust an ISO datetime string to a timezone, also accounting for browser time.
 *
 * The manual holiday dates in the form are generated using `new Date()` which generates
 * a date in the browser's timezone. The dates are then converted to ISO strings and
 * stored in the form. When the form is submitted, we want the holidays to be in the
 * timezone selected by the user, so we need to adjust the date to the selected timezone.
 * First we need to remove the browser offset to make the date equal to its UTC representation,
 * then we add the offset for the selected timezone.
 * Finally we convert the date to an ISO string.
 */
function adjustToTimeZone(dateTimeISOString: string, timeZone: TimeZoneInfo): string {
  const dt = dateTime(dateTimeISOString);
  return dateTime(dt.valueOf() + dt.utcOffset() * 60 * 1000 + timeZone.offsetInMins * 60 * 1000).toISOString();
}

/** Adjust an ISO datetime string _from_ a timezone, also accounting for browser time.
 *
   This is the reverse of `adjustToTimeZone`, and is applied to datetime strings returned from
   the API (which are in UTC) to convert them back to the user's chosen time zone.
 */
function adjustFromTimeZone(dateTimeISOString: string, timeZone: TimeZoneInfo): string {
  const dt = dateTime(dateTimeISOString);
  return dateTime(dt.valueOf() - dt.utcOffset() * 60 * 1000 - timeZone.offsetInMins * 60 * 1000).toISOString();
}

export const CreateUpdateHolidayContent: FC<CreateUpdateHolidayContentProps> = ({ originalHoliday, onSubmitForm }) => {
  const styles = useStyles2(getStyles);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [source] = useQueryParam<string | null | undefined>('source', StringParam);
  const { data, isLoading } = useAllMetricJobs({
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const [holidaySource, setHolidaySource] = useState<HOLIDAY_SOURCE>(HOLIDAY_SOURCE.ICAL);

  const getJobList = (list: Job[] | undefined): ForecastingJob[] | undefined => {
    return list?.map((job: Job) => ({ key: job.id, value: job.id, label: job.name }));
  };

  const getSelectedJobs = (jobs: ForecastingJob[] | undefined) => {
    return jobs?.filter((job) => originalHoliday?.jobs?.includes(job.key));
  };

  const defaultTimeZone = originalHoliday?.iCalTimeZone ?? getTimeZone();
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { isValid, errors },
    unregister,
    watch,
  } = useForm<HolidayForm>({
    mode: 'onChange',
    defaultValues: {
      iCalTimeZone: defaultTimeZone,
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'customPeriods',
    shouldUnregister: true,
  });

  const customPeriods = watch('customPeriods');

  const onSubmitHoliday: SubmitHandler<HolidayForm> = async (form) => {
    const timeZone = getTimeZoneInfo(form.iCalTimeZone, new Date().valueOf());
    const formCustomPeriods =
      form.customPeriods !== undefined && timeZone !== undefined
        ? form.customPeriods.map((cp) => ({
            name: cp.name,
            startTime: adjustToTimeZone(cp.startTime, timeZone),
            endTime: adjustToTimeZone(cp.endTime, timeZone),
          }))
        : undefined;
    const newHoliday: NewHoliday = {
      name: form.name,
      description: form.description,
      ...(holidaySource === HOLIDAY_SOURCE.ICAL && { iCalUrl: form.iCalUrl }),
      ...(holidaySource === HOLIDAY_SOURCE.MANUAL && { customPeriods: formCustomPeriods }),
      iCalTimeZone: timeZone?.ianaName,
      jobs: form.jobs,
    };
    void onSubmitForm(newHoliday);
    if (originalHoliday !== undefined) {
      queryClient.invalidateQueries(['holiday-metric-forecast']).catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    if (originalHoliday !== undefined) {
      if (originalHoliday.customPeriods !== undefined && originalHoliday.customPeriods !== null) {
        setHolidaySource(HOLIDAY_SOURCE.MANUAL);
        // Try to determine the original time zone; if that fails, use the browser time zone, which should never fail.
        const originalTimeZone =
          getTimeZoneInfo(originalHoliday.iCalTimeZone ?? 'browser', new Date().valueOf()) ??
          getTimeZoneInfo('browser', new Date().valueOf())!;
        originalHoliday.customPeriods?.forEach((customPeriod) =>
          append({
            name: customPeriod?.name,
            startTime: adjustFromTimeZone(customPeriod?.startTime, originalTimeZone),
            endTime: adjustFromTimeZone(customPeriod?.endTime, originalTimeZone),
          })
        );
      } else if (originalHoliday.iCalUrl !== '') {
        setHolidaySource(HOLIDAY_SOURCE.ICAL);
      }
    }
  }, [originalHoliday, append, setHolidaySource, defaultTimeZone]);

  return (
    <div className={styles.wrapper}>
      <form onSubmit={onFormSubmit(handleSubmit(onSubmitHoliday))}>
        <Field
          label="Name"
          description="Name the holiday"
          invalid={Boolean(errors?.name?.message)}
          error={errors?.name?.message}
        >
          <Input
            defaultValue={originalHoliday !== undefined ? originalHoliday.name : ''}
            autoFocus
            className={styles.fieldWrapper}
            {...register('name', {
              required: 'Name is required.',
            })}
          />
        </Field>
        <Field label="Description (optional)" description="Add a description to the holiday">
          <TextArea
            {...register('description')}
            className={styles.fieldWrapper}
            defaultValue={originalHoliday !== undefined ? originalHoliday.description : ''}
          />
        </Field>
        <Field label="Holiday source" description="Add holidays via iCal URL or manually entering dates">
          <RadioButtonGroup
            className={styles.fieldWrapper}
            options={calendarMode}
            value={holidaySource}
            onChange={(value) => {
              if (value === HOLIDAY_SOURCE.ICAL) {
                unregister('customPeriods');
              } else {
                append({ name: '', startTime: new Date().toISOString(), endTime: new Date().toISOString() });
                unregister('iCalUrl');
              }
              setHolidaySource(value);
            }}
          />
        </Field>

        {holidaySource === HOLIDAY_SOURCE.ICAL && (
          <>
            <Field
              label="iCal URL"
              description="Paste your iCal URL here"
              defaultValue={'http://'}
              invalid={Boolean(errors?.iCalUrl?.message)}
              error={errors?.iCalUrl?.message}
            >
              <Input
                className={styles.fieldWrapper}
                defaultValue={originalHoliday !== undefined ? originalHoliday.iCalUrl : ''}
                {...register('iCalUrl', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: 'Please enter a valid iCal URL.',
                  },
                  required: 'Please enter an iCal URL.',
                })}
              />
            </Field>
          </>
        )}

        {holidaySource === HOLIDAY_SOURCE.MANUAL && (
          <div className={styles.customPeriodWrapper}>
            {fields.map((field, index) => {
              const customErrors = (errors.customPeriods ?? [])[index] ?? {};
              const customField: { name?: string; startTime?: string; endTime?: string } =
                (customPeriods ?? [])[index] ?? {};
              return (
                <HorizontalGroup align="flex-start" key={field.id}>
                  <Field
                    className={styles.fieldWrapper}
                    label="Holiday occurrence name"
                    invalid={Boolean(customErrors.name?.message)}
                    error={customErrors?.name?.message}
                  >
                    <Input
                      defaultValue={''}
                      {...register(`customPeriods.${index}.name`, {
                        required: 'Holiday occurrence name required.',
                      })}
                    />
                  </Field>
                  <Field
                    className={styles.fieldWrapper}
                    label="Start date"
                    invalid={Boolean(customErrors.startTime?.message)}
                    error={customErrors?.startTime?.message}
                  >
                    <DateTimePicker
                      date={dateTime(field.startTime)}
                      {...register(`customPeriods.${index}.startTime`, {
                        required: 'Start date required.',
                      })}
                      onChange={(start) => {
                        // if end date is earlier than start date, set end date to start date
                        update(index, {
                          name: customField.name ?? '',
                          startTime: start.toISOString(),
                          endTime:
                            dateTime(customField.endTime) < start ? start.toISOString() : customField.endTime ?? '',
                        });
                      }}
                    />
                  </Field>
                  <Field
                    className={styles.fieldWrapper}
                    label="End date"
                    invalid={Boolean(customErrors.endTime?.message)}
                    error={customErrors?.endTime?.message}
                  >
                    <DateTimePicker
                      date={dateTime(field.endTime)}
                      {...register(`customPeriods.${index}.endTime`, {
                        required: 'End date required.',
                      })}
                      onChange={(end) => {
                        // if end date is earlier than start date, set start date to equal end date
                        update(index, {
                          name: customField.name ?? '',
                          startTime:
                            dateTime(customField.startTime) > end ? end.toISOString() : customField.startTime ?? '',
                          endTime: end.toISOString(),
                        });
                      }}
                    />
                  </Field>
                  {index !== 0 ? (
                    <IconButton
                      className={styles.iconPlaceholder}
                      key="delete"
                      name="trash-alt"
                      tooltip="Delete this holiday"
                      onClick={() => remove(index)}
                    />
                  ) : (
                    <div className={styles.iconPlaceholder}></div>
                  )}
                </HorizontalGroup>
              );
            })}

            <Button
              variant="secondary"
              icon="plus"
              onClick={() =>
                append({ name: '', startTime: new Date().toISOString(), endTime: new Date().toISOString() })
              }
            >
              Add holiday
            </Button>
          </div>
        )}

        <Controller
          name="iCalTimeZone"
          control={control}
          defaultValue={defaultTimeZone}
          render={({ field }) => (
            <Field
              label="Timezone"
              defaultValue={defaultTimeZone}
              invalid={Boolean(errors?.iCalTimeZone?.message)}
              error={errors?.iCalTimeZone?.message}
            >
              <TimeZonePicker
                includeInternal
                value={field.value}
                onChange={(selectedTimeZone) => {
                  field.onChange(selectedTimeZone);
                }}
              />
            </Field>
          )}
        />

        {isLoading ? (
          <div>Loading forecasting jobs...</div>
        ) : (
          <Field label="Link metric forecasts" className={styles.metricForecast}>
            <VerticalGroup className={styles.fieldWrapper}>
              <Controller
                name="jobs"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    defaultValue={getSelectedJobs(getJobList(data))}
                    className={styles.fieldWrapper}
                    onChange={(selctedJobs) => {
                      field.onChange(selctedJobs.map((selectedJob) => selectedJob.value));
                      setValue('jobs', selctedJobs.map((selectedJob) => selectedJob.value) as string[]);
                    }}
                    placeholder="Metric forecast"
                    options={getJobList(data)}
                  />
                )}
              />
            </VerticalGroup>
          </Field>
        )}

        <div className={styles.actionWrapper}>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              if (originalHoliday !== undefined && source !== 'list') {
                navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${originalHoliday.id}`);
              } else {
                navigate(`${PLUGIN_ROOT}/metric-forecast/holiday`);
              }
            }}
          >
            Discard
          </Button>
          <Button variant="primary" disabled={!isValid} type="submit" className={styles.button}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    wrapper: css`
      width: 70%;
    `,
    button: css`
      margin-left: ${theme.spacing(2)};
    `,
    actionWrapper: css`
      margin: ${theme.spacing(3)} 0px;
    `,
    metricForecast: css`
      margin-top: ${theme.spacing(2)};
    `,
    customPeriodWrapper: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    title: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    fieldWrapper: css`
      margin-bottom: ${theme.spacing(2)};
      margin-right: ${theme.spacing(1)};
    `,
    iconPlaceholder: css`
      margin-top: 26px;
      width: 20px;
      margin-left: -10px;
    `,
  };
}
