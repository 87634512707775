import React from 'react';
import Markdown from 'react-markdown';

import { Icon, Label, Tooltip } from '@grafana/ui';

// the help text for each sift item
// can be rendered using markdown
const helpTextData = {
  correlatedSeries: {
    default:
      'Finds series which are potentially correlated with one or more input series associated with the investigation.',
    topology:
      'The search can use service topology information to inform which series should be prioritized as correlation candidates. \n\nHere you can add service topology data by first defining your services as named nodes with PromQL selectors, then by adding edges between them representing dependencies.',
    keepLabels: 'Labels that should never be removed.',
    ignoreLabels: 'Labels that should be ignored when generating permutations and not included in candidate queries.',
    labelAliases: 'Labels that should be considered equivalent for the purposes of query permutation generation.',
    maxRemovedLabels: 'The maximum number of labels that should be removed when generating query permutations.',
    removeLabels: "Labels to aggregate over. If empty, aggregations are calculated over 'pod' and 'instance'.",
    typeAugmentor: 'Time range used when generating Prometheus rate() queries, in seconds.',
    limit: 'The maximum number of candidate correlation series to be returned per input series.',
    lookBehind:
      'How far in the past to look for the input pattern, as a multiple of the selected time range. \n\nSet this to 0 to only search for matches inside the input time range, or >0 to also look for the pattern in the past.',
    timeout: 'Number of seconds after which the search should be stopped.',
  },
  errorPatternLogs: {
    default:
      'Analyzes error logs and identifies groups of similar log lines, highlighting groups with significantly increased log rates based on shared patterns.',
    maxExamples: 'Maximum number of example logs to show for each pattern.',
    minCount: 'Minimum number of log occurrences before a pattern is considered interesting.',
    initialQuery: 'The query used to find error logs.',
  },
  httpErrorSeries: {
    default: 'Searches for series exhibiting elevated HTTP errors within a specified cluster and namespace.',
    cutoffTime:
      'The maximum time to look back for anomalies. \n\nIncrease this value to look further in the past for erroring series, or decrease it to reduce false positives.',
    percentageThreshold:
      'The minimum percentage change of HTTP errors from the rolling average before a series is considered anomalous.',
  },
  logQuery: {
    default: 'Executes a configurable LogQL query against a Loki instance.',
    expression: 'The custom LogQL query expression to run.',
    message:
      'A Go template string used to format the output of the check.\n\nThe template string has access to the following variables:\n- expr: the input expression string\n- interesting: a boolean indicating whether this check found any interesting results\n- streams: an array of log streams. Each element has two fields:\n    - Labels, a map from label name to label value identifying the stream\n    - Entries, an array of log entries. Each element has two fields:\n    - Timestamp, a time.Time\n    - Line, a string\n',
    maxLogLines: 'The maximum log lines to include for each stream in the output.',
  },
  metricQuery: {
    default: 'Executes a configurable PromQL query against a Prometheus instance.',
    expression: 'The custom PromQL query expression to run.',
    message:
      'A Go template string used to format the output of the check.\n\nThe template string has access to the following variables:\n- expr: the input expression string\n- interesting: a boolean indicating whether this check found any interesting results\n- streams: an array of time series. Each element has three fields:\n    - Labels, a Prometheus Metric implemented as a map from label name to label value used to identify the series.\n    - LastTimestamp, the latest timestamp found in the input query.\n    - LastValue, the latest value found in the input query.\n',
  },
  noisyNeighbors: {
    default:
      'Identifies over-saturated hosts where load exceeds CPU core count, leading to high latency, and examines pods on those hosts for deeper insights into the underlying issues.',
    loadThreshold:
      "The threshold above which nodes will be considered to have 'high load'. This must be between 0.3 and 1.0; values outside that range will be clipped to the nearest value within the range.",
    usageQuantile: 'The quantile used to determine if a pod is using too much of a specific resource.',
  },
  slowRequests: {
    default: 'Analyzes traces in Tempo to identify requests taking longer than a specified threshold.',
    threshold: "The threshold above which traces are considered 'slow'.",
  },
  statusPageOutages: {
    default: 'Fetches status pages for configured cloud providers to look for upstream problems.',
    cloudProviders: 'The cloud providers to check.',
  },
};

function standardizeCheckName(checkName: string) {
  if (checkName.length === 0) {
    return checkName;
  }

  const parsedName = checkName.replace('HTTP', 'http');
  return parsedName[0].toLowerCase() + parsedName.slice(1);
}

interface LabelWithHelpTextProps {
  // title displayed, if empty only icon is displayed
  label?: string;
  // Name of the sift check
  checkName: string;
  // Name of the field we want help text for
  // if empty, we get the default text for the check
  fieldName?: string;
}

function LabelWithHelpText({ label, checkName, fieldName }: LabelWithHelpTextProps): JSX.Element | null {
  // pull string from json object
  const checkSettings = helpTextData[standardizeCheckName(checkName) as keyof typeof helpTextData];

  // if we cant find the check in the text object, just return the header
  if (checkSettings === undefined) {
    return <Label>{label}</Label>;
  }

  const helpText = checkSettings[(fieldName ?? 'default') as keyof typeof checkSettings];

  if ((label ?? '') === '') {
    return (
      <Tooltip content={<Markdown>{helpText}</Markdown>} placement="bottom">
        <Icon name="info-circle" />
      </Tooltip>
    );
  }

  return (
    <Label>
      <div style={{ marginRight: '8px' }}>{label}</div>
      {(helpText ?? '') !== '' ? (
        <Tooltip content={<Markdown>{helpText}</Markdown>} placement="bottom">
          <Icon name="info-circle" />
        </Tooltip>
      ) : null}
    </Label>
  );
}

export { LabelWithHelpText };
