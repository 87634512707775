import React from 'react';
import { Control, Controller, FieldError, FieldPath, FieldValues, PathValue } from 'react-hook-form';

import { SelectableValue } from '@grafana/data';
import { FieldValidationMessage, InlineField, Select, VerticalGroup } from '@grafana/ui';

interface InlineEnumFieldProps<T extends FieldValues, N extends FieldPath<T> = FieldPath<T>> {
  control: Control<T>;
  error?: FieldError;
  name: N;
  label?: string;
  tooltip?: string;
  options: Array<SelectableValue<unknown>>;
  isClearable?: boolean;
  defaultValue?: PathValue<T, N>;
}

export function InlineEnumField<T extends FieldValues, N extends FieldPath<T> = FieldPath<T>>({
  control,
  error,
  name,
  label = '',
  tooltip,
  options,
  isClearable,
  defaultValue = undefined,
}: InlineEnumFieldProps<T, N>): JSX.Element | null {
  return (
    <InlineField grow labelWidth={35} label={label} tooltip={tooltip}>
      <VerticalGroup>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              onChange={(e) => {
                // If isClearable is set and the user attempts to clear the field,
                // `e` is null, so we need to forward the null rather try to access
                // `e.value`.
                field.onChange(e?.value ?? null);
              }}
              invalid={error != null}
              options={options}
              isClearable={isClearable}
            />
          )}
        />

        {error == null ? null : <FieldValidationMessage>{error.message ?? ''}</FieldValidationMessage>}
      </VerticalGroup>
    </InlineField>
  );
}
