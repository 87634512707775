import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import { CheckConfig, CheckFormProps, ErrorPatternLogsConfig, ErrorPatternLogsConfigSchema } from 'types';
import { getAnalysisDisplayTitle, parseInputToInt, parseInputToString } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

function ErrorPatternLogsForm({
  check,
  defaultConfig,
  onClose,
}: CheckFormProps<ErrorPatternLogsConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { lokiFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<ErrorPatternLogsConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: check.config,
    autoDetectDatasource: (check.config.lokiDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData: CheckConfig<ErrorPatternLogsConfig> = {
          id: check.id ?? undefined,
          name: check.name,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            lokiDatasourceUid: autoDetectDatasource ? '' : config.lokiDatasourceUid,
          },
          title,
        };
        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, errors, formState, setValue, watch }) => {
        const lokiDatasourceUid = watch('config.lokiDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} placeholder={check.name} />
            </Field>

            <Field
              invalid={!!errors.config?.maxExamples}
              error="must be between 1 and 10"
              label={<LabelWithHelpText label="Max examples" checkName="errorPatternLogs" fieldName="maxExamples" />}
            >
              <Input
                type="number"
                {...register('config.maxExamples', {
                  min: Number(ErrorPatternLogsConfigSchema.shape.maxExamples.minValue),
                  max: Number(ErrorPatternLogsConfigSchema.shape.maxExamples.maxValue),
                  setValueAs: parseInputToInt,
                })}
                placeholder={(defaultConfig.maxExamples ?? '').toString()}
              />
            </Field>

            <Field
              label={<LabelWithHelpText label="Min count" checkName="errorPatternLogs" fieldName="minCount" />}
              invalid={!!errors.config?.minCount}
              error="must be between 1 and 10"
            >
              <Input
                type="number"
                {...register('config.minCount', {
                  min: Number(ErrorPatternLogsConfigSchema.shape.minCount.minValue),
                  max: Number(ErrorPatternLogsConfigSchema.shape.minCount.maxValue),
                  setValueAs: parseInputToInt,
                })}
                placeholder={(defaultConfig.minCount ?? '').toString()}
              />
            </Field>

            <Field
              label={<LabelWithHelpText label="Initial query" checkName="errorPatternLogs" fieldName="initialQuery" />}
            >
              <Input
                {...register('config.initialQuery', { setValueAs: parseInputToString })}
                placeholder={(defaultConfig.initialQuery ?? '').toString()}
              />
            </Field>

            <DataSourceSelector
              title="Loki Datasource"
              dsType="loki"
              dsUid={lokiDatasourceUid ?? ''}
              autoDetectDatasource={autoDetectDatasource}
              filter={lokiFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.lokiDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { ErrorPatternLogsForm };
