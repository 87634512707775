import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Collapse, HorizontalGroup, Icon, Spinner, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { Analysis, CheckStage } from 'types';

interface AnalysisListSectionProps {
  header: string;
  current: string;
  setCurrent: (current: string) => void;
  analyses: Analysis[];
  icon: React.ReactNode;
  startExpanded?: boolean;
}

function AnalysisListSection({
  icon,
  header,
  analyses,
  current,
  setCurrent,
  startExpanded = false,
}: AnalysisListSectionProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  if (analyses.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.section}>
      <Collapse
        label={
          <HorizontalGroup spacing="sm" justify="space-between">
            <HorizontalGroup spacing="none" align="center">
              {icon}
              {header}
            </HorizontalGroup>
            <div>
              <Icon name={isExpanded ? 'angle-down' : 'angle-right'} size="sm" />
            </div>
          </HorizontalGroup>
        }
        isOpen={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
        className={styles.collapse}
      >
        {analyses.map((analysis) => {
          return (
            <div
              key={analysis.id}
              className={styles.menuItem}
              onClick={() => {
                setCurrent(analysis.id);
              }}
            >
              <div className={current === analysis.id ? styles.selected : styles.unselected}>
                <div className={styles.checkName[analysis.stage]}>{analysis.name}</div>
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
}

interface Props {
  interesting: Analysis[];
  completed: Analysis[];
  failed: Analysis[];
  skipped: Analysis[];
  running: Analysis[];
  selectedAnalysisId: string;
  setSelectedAnalysisId: (id: string) => void;
}

export function AnalysisList({
  interesting,
  completed,
  failed,
  running,
  skipped,
  selectedAnalysisId,
  setSelectedAnalysisId,
}: Props): React.ReactElement {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.outer}>
      <AnalysisListSection
        header="Interesting results"
        icon={<Icon name="bolt" className={styles.interesting} />}
        analyses={interesting}
        current={selectedAnalysisId}
        setCurrent={setSelectedAnalysisId}
        startExpanded
      />
      <AnalysisListSection
        header="Completed checks"
        icon={<Icon name="check" className={styles.passed} />}
        analyses={completed}
        current={selectedAnalysisId}
        setCurrent={setSelectedAnalysisId}
      />
      <AnalysisListSection
        header="Failed checks"
        icon={<Icon name="info-circle" className={styles.unsuccessful} />}
        analyses={failed}
        current={selectedAnalysisId}
        setCurrent={setSelectedAnalysisId}
      />
      <AnalysisListSection
        header="Skipped checks"
        icon={<Icon name="forward" className={styles.skipped} />}
        analyses={skipped}
        current={selectedAnalysisId}
        setCurrent={setSelectedAnalysisId}
      />
      <AnalysisListSection
        header="Processing"
        icon={
          running.find((x) => x.status === 'running') !== undefined ? (
            <Spinner inline={true} />
          ) : (
            <Icon name="fa fa-spinner" className={styles.passed} />
          )
        }
        analyses={running}
        current={selectedAnalysisId}
        setCurrent={setSelectedAnalysisId}
        startExpanded
      />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  const baseCheckName = css`
    padding-left: 10px;
    line-height: 40px;
  `;
  const checkName: Record<CheckStage, string> = {
    GeneralAvailability: baseCheckName,
    PublicPreview: baseCheckName,
    Experimental: cx(
      baseCheckName,
      css`
        color: ${theme.colors.text.secondary};
      `
    ),
    Dummy: cx(
      baseCheckName,
      css`
        color: ${theme.colors.text.disabled};
      `
    ),
    Deprecated: cx(
      baseCheckName,
      css`
        color: ${theme.colors.text.disabled};
      `
    ),
  };
  return {
    section: css`
      padding-bottom: 10px;
      padding-top: 10px;
      border-top: 1px solid ${theme.colors.border.weak};
    `,
    outer: css`
      min-width: 220px;
      & > div:first-child {
        border-top: 0;
      }
    `,
    menuItem: css`
      display: flex;
      flex-direction: rows;
      height: 40px;
      padding-left: 0px;
      cursor: pointer;
    `,
    selected: css`
      display: flex;
      width: 100%;
      border-left: 5px solid rgb(255, 120, 10);
      background-color: ${theme.colors.background.secondary};
      padding-left: 7px;
      margin-left: 6px;
    `,
    unselected: css`
      display: flex;
      width: 100%;
      border-left: 5px solid ${theme.colors.background.primary};
      padding-left: 9px;
      margin-left: 4px;
    `,
    passed: css`
      text-align: center;
      stroke: ${theme.colors.info.main};
      margin-right: 6px;
    `,
    interesting: css`
      height: 22px;
      width: 22px;
      text-align: center;
      stroke: #ff8833;
      fill: #ff8833;
      margin-right: 2px;
    `,
    unsuccessful: css`
      text-align: center;
      stroke: ${theme.colors.error.main};
      margin-right: 6px;
    `,
    skipped: css`
      text-align: center;
      stroke: ${theme.colors.info.text};
      margin-right: 6px;
    `,
    checkName,
    checkPrompt: css`
      gap: 4px;
      margin-left: 8px;
      display: flex;
      align-items: center;
    `,
    collapse: css`
      border: 0;
    `,
  };
};
