import React from 'react';
import { Control, Controller, FieldError, FieldPath, FieldValues, PathValue } from 'react-hook-form';

import { SelectableValue } from '@grafana/data';
import { FieldValidationMessage, HorizontalGroup, InlineField, Input, Select, VerticalGroup } from '@grafana/ui';

import { validateNumber } from './validators';

interface InlineNumberAndUnitsFieldProps<
  T extends FieldValues,
  U extends FieldPath<T> = FieldPath<T>,
  N extends FieldPath<T> = FieldPath<T>
> {
  control: Control<T>;
  error?: FieldError;
  name: N;
  unitsName: U;
  label: string;
  tooltip?: string;
  required?: boolean;
  minimum?: number;
  maximum?: number;
  defaultValue?: PathValue<T, N>;
  integerOnly?: boolean;
  unitsOptions: Array<SelectableValue<PathValue<T, U>>>;
  unitsDefaultValue?: PathValue<T, U>;
}

export function InlineNumberAndUnitsField<
  T extends FieldValues,
  U extends FieldPath<T> = FieldPath<T>,
  N extends FieldPath<T> = FieldPath<T>
>({
  control,
  error,
  name,
  label,
  tooltip,
  required = true,
  minimum,
  maximum,
  defaultValue,
  integerOnly = false,
  unitsName,
  unitsOptions,
  unitsDefaultValue,
}: InlineNumberAndUnitsFieldProps<T, U, N>): JSX.Element | null {
  return (
    <InlineField grow labelWidth={35} label={label} tooltip={tooltip} required={required}>
      <VerticalGroup>
        <HorizontalGroup>
          <Controller
            control={control}
            defaultValue={defaultValue}
            rules={{
              required: required ? 'required' : undefined,
              validate: (tv) => validateNumber(tv as number | string, required, integerOnly, minimum, maximum),
            }}
            name={name}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  value={field.value}
                  invalid={error != null}
                  placeholder={defaultValue == null ? undefined : String(defaultValue)}
                  onBlur={(e) => {
                    const res = parseFloat(e.currentTarget.value);
                    field.onChange(isNaN(res) ? undefined : res);
                  }}
                />
              );
            }}
          />

          <Controller
            defaultValue={unitsDefaultValue}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(x) => {
                  field.onChange(x.value);
                }}
                width={12}
                options={unitsOptions}
              />
            )}
            name={unitsName}
          />
        </HorizontalGroup>

        {error == null ? null : <FieldValidationMessage>{error.message ?? ''}</FieldValidationMessage>}
      </VerticalGroup>
    </InlineField>
  );
}
