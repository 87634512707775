import React, { ReactElement } from 'react';

import { TimeOption } from '@grafana/data';
import { Menu } from '@grafana/ui';

type Props = {
  options: TimeOption[];
  value?: TimeOption;
  onChange: (option: TimeOption) => void;
};

export function TimeOptionMenu(props: Props): ReactElement {
  const { options, onChange } = props;

  return (
    <Menu>
      {options.map((o) => (
        <Menu.Item key={`${o.from}-${o.to}`} label={o.display} onClick={() => onChange(o)} />
      ))}
    </Menu>
  );
}
