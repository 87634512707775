import React from 'react';
import { useParams } from 'react-router-dom';

import { config, PluginPage } from '@grafana/runtime';

import { useAlertRuleInstances, useMetricJob } from 'api';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';

import { ViewJobContent } from './ViewJobContent';

export const ViewJob: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError } = useMetricJob(id!, { cacheTime: 0 });
  const { data: alertRules, isLoading: isAlertsLoading } = useAlertRuleInstances();

  const defaultTitle = 'View metric forecast';

  if (isError) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <div>Error</div>
      </PluginPage>
    );
  }

  if (id == null || isLoading || isAlertsLoading || data == null) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <LoadingIndicator />
      </PluginPage>
    );
  }

  if (data == null) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <div>No forecast found</div>
      </PluginPage>
    );
  }

  const jobAlertRules = (alertRules ?? []).filter((rule) => rule.annotations?.['grafana-ml-forecast-id'] === id);

  return (
    <PluginPage pageNav={{ text: data.name }}>
      <QueryRunnerProvider>
        <ViewJobContent job={data} alertingEnabled={config.unifiedAlertingEnabled} alertRules={jobAlertRules} />
      </QueryRunnerProvider>
    </PluginPage>
  );
};
