import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Card, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { SiftModalData } from 'types';

interface ErrorSpansDetails {
  commonRootServices: Record<string, number>;
  commonServicesPath: Record<string, number>;
  commonOperationsPath: Record<string, number>;
  traceIDs: string[];
}

// We forgot to uncapitalize the fields in the backend, so we have to support both.
interface ErrorSpansDetailsCaps {
  CommonRootServices: Record<string, number>;
  CommonServicesPath: Record<string, number>;
  CommonOperationsPath: Record<string, number>;
  TraceIDs: string[];
}

function toErrorSpansDetails(details: ErrorSpansDetails | ErrorSpansDetailsCaps): ErrorSpansDetails {
  if ('commonRootServices' in details) {
    return details;
  }
  return {
    commonRootServices: details.CommonRootServices,
    commonServicesPath: details.CommonServicesPath,
    commonOperationsPath: details.CommonOperationsPath,
    traceIDs: details.TraceIDs,
  };
}

export default function ErrorSpans({ analysis }: SiftModalData): JSX.Element {
  const styles = useStyles2(getStyles);
  if (!analysis.result.successful) {
    return <div>Error running analysis: {analysis.result.message}</div>;
  }

  if (!analysis.result.interesting) {
    return <div>No Error Traces Found</div>;
  }

  if (analysis.result.details === undefined) {
    return <div>Analysis has no result details.</div>;
  }
  const details = toErrorSpansDetails(analysis.result.details as unknown as ErrorSpansDetails | ErrorSpansDetailsCaps);

  return (
    <div>
      {Object.keys(details.commonRootServices).map((serviceName) => {
        return (
          <Card key={`errored{i}`} className={styles.card}>
            <Card.Heading>
              <div>
                <span className={styles.title}>
                  {serviceName} - Found {details.commonRootServices[serviceName]} instances
                </span>
              </div>
            </Card.Heading>
          </Card>
        );
      })}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    card: css`
      display: block !important;
    `,
    heading: css`
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
    `,
    title: css`
      color: ${theme.colors.text.secondary};
      font-weight: 400;
      > b {
        color: ${theme.colors.text.primary};
      }
    `,
    buttonRow: css`
      clear: both;
      justify-content: space-between;
    `,
    icon: css`
      height: 20px;
      font-size: 18px;
    `,
  };
}
