import React from 'react';

import { Button, Spinner, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

function getTooltipContent(llmEnabled: boolean, disabledMessage?: string, error?: Error): string | undefined {
  if (!llmEnabled) {
    return 'LLM plugin is not enabled or configured. Please contact your organization admin.';
  }
  return error
    ? 'Failed to generate content using OpenAI. Please try again or if the problem persists, contact your organization admin. Error: ' +
        error?.message
    : disabledMessage;
}

interface Props {
  enabled: boolean;
  generating: boolean;
  loading: boolean;
  error?: Error;
  generateExplanation: () => void;
  explanation?: string;
}

export function ExplanationButton({ enabled, generating, loading, error, generateExplanation, explanation }: Props) {
  const styles = useStyles2(getStyles);
  const disabledMessage = explanation ? 'Explanation already generated' : undefined;

  // For now do not display the button if LLM plugin is not enabled.
  if (loading || !enabled || explanation) {
    return <></>;
  }

  const icon = generating ? undefined : 'ai';
  const text = generating ? 'Generating' : 'Generate Explanation';
  const tooltipContent = getTooltipContent(enabled, disabledMessage, error);
  const button = (
    <span className={styles.container}>
      {generating && <Spinner size="sm" />}
      <Button
        // Ignore type check; the 'ai' icon does exist.
        // @ts-ignore-next-line
        icon={icon}
        variant="secondary"
        size="sm"
        disabled={false}
        onClick={generateExplanation}
        className={styles.button}
      >
        {text}
      </Button>
    </span>
  );
  if (tooltipContent === undefined) {
    return button;
  }
  return (
    <Tooltip theme={error !== undefined ? 'error' : 'info'} interactive content={tooltipContent}>
      {button}
    </Tooltip>
  );
}

function getStyles() {
  return {
    button: css`
      margin: 0 3px;
    `,
    container: css`
      display: flex;
    `,
  };
}
