import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export function Welcome(): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <Alert title="Welcome to Metric forecasting" severity="info">
      <div>Metric forecasting can predict how your metrics will perform in the future.</div>
      <div>
        {'Create a new forecast to get started, or '}
        <a
          className={styles.blueLink}
          href="https://grafana.com/docs/grafana-cloud/alerting-and-irm/machine-learning/"
          rel="noreferrer"
          target="_blank"
        >
          visit the docs
        </a>
        {' to learn more!'}
      </div>
    </Alert>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  blueLink: css`
    color: ${theme.colors.text.link};
  `,
});
