import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Badge, Dropdown, IconButton, LinkButton, Menu, Spinner } from '@grafana/ui';

import { css } from '@emotion/css';

import { useRemoveOutlier } from 'api/outlier.api';
import { Outlier } from 'api/types';
import Card from 'components/Card';
import { CreateOutlierAlertButton } from 'components/CreateOutlierAlertButton';
import { PLUGIN_ROOT } from 'consts';
import { useConfirmModal, useIsEditor, useSupportedDatasources } from 'hooks';
import { AlertStateCounts } from 'types';
import { onHandler, useUtilityStyles } from 'utils';
import { alertStateCountsEq } from 'utils/utils.alerts';

import { OutlierAlertIconButton } from './OutlierAlertIconButton';

interface OutlierListItemProps {
  item: Outlier;
  datasourceName: string;
  alertingEnabled: boolean;
  alertStateCounts: AlertStateCounts;
}

const OutlierListItemInner: FC<OutlierListItemProps> = ({ item, alertingEnabled, alertStateCounts }) => {
  const navigate = useNavigate();
  const supportedDatasources = useSupportedDatasources();
  const doDelete = useRemoveOutlier();
  const onDelete = useCallback(
    (metricJobId: string) => {
      doDelete.mutate(metricJobId);
    },
    [doDelete]
  );

  const utilityStyles = useUtilityStyles();

  const [confirmDelete, ConfirmDeleteModal] = useConfirmModal(
    async () => {
      if (item.id !== undefined) {
        onDelete(item.id);
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Outlier detector deleted', `Outlier detector has been deleted.`],
        });
      }
    },
    { confirmOnNavigate: false }
  );

  const isEditor = useIsEditor();

  const onView = useCallback(() => {
    navigate(`${PLUGIN_ROOT}/outlier-detector/${item.id}`, { replace: true });
  }, [item.id, navigate]);

  const onEdit = useCallback(() => {
    const linkToOutlier = (outlier: Outlier, edit: boolean) => {
      const dataSource = supportedDatasources.find((ds) => ds.id === outlier.datasourceId);
      const editDefaults = encodeURIComponent(
        JSON.stringify({
          name: outlier.name,
          description: outlier.description,
          metric: outlier.metric,
        })
      );
      const link =
        `${PLUGIN_ROOT}/outlier-detector/create?` +
        `query_params=${encodeURIComponent(JSON.stringify(outlier.queryParams))}` +
        `&algorithm=${outlier.algorithm.name}` +
        `&ds=${dataSource?.uid ?? ''}` +
        `&sensitivity=${outlier.algorithm.sensitivity.toString()}` +
        (edit ? `&id=${outlier.id}&edit=${editDefaults}` : ``);
      return link;
    };

    if (isEditor) {
      const link = linkToOutlier(item, true);
      navigate(link, { replace: true });
    }
  }, [item, isEditor, navigate, supportedDatasources]);

  const OptionsMenu = useMemo(
    () => (
      <Menu>
        <CreateOutlierAlertButton
          kind="menu-item"
          outlier={item}
          alertingEnabled={alertingEnabled}
          returnTo="/outlier-detector"
        />
        <OutlierAlertIconButton
          kind="menu-item"
          outlier={item}
          alertingEnabled={alertingEnabled}
          alertStateCounts={alertStateCounts}
          returnTo="/outlier-detector"
        />
        <Menu.Divider />
        <Menu.Item label="View outlier" icon="eye" onClick={onView} />
        <Menu.Item label="Edit outlier" icon="edit" disabled={!isEditor} onClick={onEdit} />
        <Menu.Item label="Delete outlier" icon="trash-alt" onClick={onHandler(confirmDelete)} destructive />
      </Menu>
    ),
    [item, alertingEnabled, alertStateCounts, onView, onEdit, confirmDelete, isEditor]
  );

  const createdTime = new Date(item.created);

  return (
    <div key={item.id}>
      <Card height="subtle" variant="secondary-gray" className={styles.card}>
        <a href={`${PLUGIN_ROOT}/outlier-detector/${item.id}`} className={styles.heading}>
          <h5>
            {item.name} ({item.metric})
          </h5>
          {item.description != null ? <div>{item.description}</div> : null}
        </a>

        <aside className={styles.tags}>
          <div className={styles.statusWithMessage}>
            {item.errorState != null && item.errorState !== '' ? (
              <div>
                <Badge text={`Warning: ${item.errorState}`} color="orange" />
              </div>
            ) : null}
          </div>
        </aside>

        <aside className={styles.status}>
          {item.algorithm.name != null ? <span>{item.algorithm.name}</span> : null}
          {item.datasourceType != null ? <span>{item.datasourceType}</span> : null}
          {createdTime != null ? <span>{createdTime.toLocaleString()}</span> : null}
        </aside>

        <div className={styles.actions}>
          <IconButton key="view" name="eye" tooltip="View outlier" title={'View outlier'} onClick={onView} />
          <IconButton
            key="edit"
            name="edit"
            tooltip="Edit outlier"
            disabled={!isEditor}
            title={'Edit outlier'}
            onClick={onEdit}
          />
          <OutlierAlertIconButton
            kind="icon"
            outlier={item}
            alertingEnabled={alertingEnabled}
            alertStateCounts={alertStateCounts}
            returnTo="/metric-forecast"
          />
          <Dropdown overlay={OptionsMenu}>
            <IconButton name="ellipsis-h" aria-label="Additional options" tooltip="Additional options" />
          </Dropdown>
        </div>
      </Card>
      <ConfirmDeleteModal icon="trash-alt" title="Delete outlier" actionText="Delete">
        <div className={utilityStyles.textMd}>Are you sure you want to delete &quot;{item.name}&quot;?</div>
      </ConfirmDeleteModal>
    </div>
  );
};

export const OutlierListItem = React.memo(
  OutlierListItemInner,
  (prev, next) =>
    prev.item === next.item &&
    prev.datasourceName === next.datasourceName &&
    prev.alertingEnabled === next.alertingEnabled &&
    alertStateCountsEq(prev.alertStateCounts, next.alertStateCounts)
);

export function PlaceholderOutlierListItem(): JSX.Element {
  return (
    <Card height="flat" className={styles.card}>
      <header className={styles.heading}>
        <h5>Loading</h5>
      </header>

      <aside className={styles.tags}>
        <div className={styles.statusWithMessage}>
          <Spinner />
        </div>
      </aside>

      <aside className={styles.status}>Loading...</aside>

      <main className={styles.actions}>
        <div className={styles.primary}>
          <LinkButton
            key="view"
            variant="secondary"
            href={`${PLUGIN_ROOT}/outlier-detector`}
            disabled
            className={styles.disabled}
          >
            View
          </LinkButton>
        </div>
      </main>
    </Card>
  );
}

const styles = {
  empty: css``,

  card: css`
    display: flex;
    justify-content: flex-end;
    padding: 18px;
  `,

  statusWithMessage: css`
    display: flex;
    align-items: center;
    gap: 12px;
    flex-flow: row nowrap;
  `,

  status: css`
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    font-size: 12px;
    opacity: 0.7;
    min-width: 220px;
    padding-right: 20px;

    > span:not(:last-child):after {
      content: '|';
      padding-left: 6px;
    }

    @media (max-width: 800px) {
      display: none;
      min-width: none;
    }
  `,

  tags: css`
    align-self: flex-start;
    justify-self: flex-end;
  `,

  heading: css`
    display: flex;
    gap: 12px;
    margin: 0px auto 0px 0px;
    cursor: pointer;

    > h5 {
      font-size: 14px;
      font-weight: 500;
      white-space: no-wrap;
    }
    > div {
      opacity: 0.7;
      min-width: 150px;
      text-overflow: ellipsis;
    }

    @media (max-width: 1300px) {
      > div {
        display: none;
      }
    }
  `,

  actions: css`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  `,

  secondary: css`
    display: flex;
    flex-flow: row nowrap;
    gap: 3px;
  `,
  primary: css``,

  disabled: css`
    opacity: 0.6;
    cursor: not-allowed;
  `,
};
