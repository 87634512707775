import { useCallback, useEffect, useState } from 'react';

import { LoadingState, PanelData, TimeRange } from '@grafana/data';
import { config } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { useCancelQuery, useQueryRunner } from 'components/QueryRunner';
import { tryGte } from 'utils/utils.general';

/// Perform a set of data queries;
/// returning current panel along with isRefresh boolean, a refresh function, and a cancel function
export function useQueryResult(
  queries: Array<DataQuery & { expr?: string | undefined }>,
  maxDataPoints: number,
  timeRange: TimeRange,
  timeZone: string,
  datasourceUid: string,
  runQuery = true
): [PanelData | undefined, boolean, () => void, () => void] {
  const runner = useQueryRunner();
  const cancelQuery = useCancelQuery();

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [value, update] = useState<PanelData | undefined>(undefined);
  const handleUpdate = useCallback(
    (v: PanelData | undefined) => {
      update(v);
      setIsRefreshing(v?.state === LoadingState.Loading);
    },
    [update, setIsRefreshing]
  );

  const [refreshCount, setRefreshCount] = useState<number>(0);

  useEffect(() => {
    // This lint seems to be spurious since this signature hasn't been
    // deprecated.
    // eslint-disable-next-line deprecation/deprecation
    const s = runner.get().subscribe(handleUpdate);
    return () => s.unsubscribe();
  }, [runner, handleUpdate]);

  // Workaround for API change in Grafana 8.3 where need to specify datasource as DataSourceRef
  // instead of a string. https://github.com/grafana/machine-learning/issues/899
  const workaround84 = tryGte(config.buildInfo.version, '8.3.0');

  // start a new query if certain parameters change.
  useEffect(() => {
    setIsRefreshing(true);
    // since hooks cant be run conditionally, we allow an option to pass to this hook
    // to indicate if the query params are ready or not (required because maxDataPoints is
    // determined after render, so a render must happen prior to the value being correct)
    if (runQuery) {
      runner.run({
        timeRange,
        queries,
        // @ts-expect-error
        datasource: workaround84 ? { uid: datasourceUid } : datasourceUid,
        maxDataPoints,
        minInterval: null,
        timezone: timeZone,
      });
    }
    // don't include maxDataPoints in deps, as we dont want this to re-run constantly as browser resizes
  }, [refreshCount, datasourceUid, queries, runner, timeRange, timeZone, workaround84, runQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, isRefreshing, () => setRefreshCount((x) => x + 1), cancelQuery];
}
