import { DataFrame, Field, Labels, PanelData } from '@grafana/data';

export function filterPanelSeriesData(
  data: PanelData | undefined,
  seriesOptions: Labels[],
  selectedSeriesIdxs: number[],
  structureRev: number
): PanelData | undefined {
  if (data == null) {
    return undefined;
  }
  return {
    ...data,
    structureRev: structureRev,
    series: data.series.filter((series: DataFrame) => {
      // There should always be one 'time' field and one non-'time' field in the returned
      // series, since we control their format.
      const valueField = series.fields.find((f: Field) => f.type !== 'time');
      if (valueField?.labels == null) {
        console.error(
          "Expected field named 'Value' to exist in fields of series %o (refId %o). ignoring it.",
          series.name,
          series.refId
        );
        return false;
      }
      const valueFieldLabels = valueField.labels;

      return selectedSeriesIdxs.some((idx) =>
        Object.entries(seriesOptions[idx] ?? { _: 'empty' }).every(
          ([lab, selected]) => valueFieldLabels[lab] === selected
        )
      );
    }),
  };
}
