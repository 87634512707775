import React from 'react';

import { TimeRange } from '@grafana/data';
import { IconButton } from '@grafana/ui';

import { getPrometheusExploreUrl } from 'utils/utils.url';

interface ExploreButtonProps {
  queries: string[];
  datasourceUid: string;
  timeRange?: TimeRange;
}

export const ExploreButton: React.FC<ExploreButtonProps> = ({ queries, datasourceUid, timeRange }) => {
  const url = getPrometheusExploreUrl(datasourceUid, queries, timeRange);
  return (
    <IconButton
      name="compass"
      variant="primary"
      aria-label="Explore"
      tooltip={'Explore'}
      onClick={(e) => {
        e.stopPropagation();
        window.open(url, '_blank');
      }}
    />
  );
};
