import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, IconButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useCreateSiftConfig, useDeleteSiftConfig, useUpdateSiftConfig } from 'api';
import { useCheckStages } from 'hooks';
import { AnyConfig, CheckConfig } from 'types';

import { Badge } from '../../../../components/Badge';
import { getAnalysisDisplayTitle } from '../../../../utils/utils.sift';

interface ConfigurationItemProps {
  // The name of the check.
  name: string;
  // The instances of the check.
  checkInstances: Array<CheckConfig<AnyConfig>>;
  // Callback called when a user clicks the 'Edit' button.
  // It is passed the check instance corresponding to the clicked button.
  onEdit: (props: { check: CheckConfig<AnyConfig> }) => void;
  // Callback called when a user clicks the 'Add' button.
  onAdd: () => void;
}

// Return whether a check is an unconfigured 'default' instance.
function checkIsDefault(check: CheckConfig<AnyConfig>): boolean {
  return check.id === undefined;
}
// Return whether a check is configurable.
function checkHasConfig(check: CheckConfig<AnyConfig>): boolean {
  const field = checkIsDefault(check) ? check.config : check.mergedConfig;
  return field !== undefined && field !== null && Object.keys(field).length > 0;
}

function ConfigurationItem({ checkInstances, name, onAdd, onEdit }: ConfigurationItemProps) {
  const styles = useStyles2(getStyles);
  const stages = useCheckStages();

  const hasConfig = checkInstances.length === 0 || checkHasConfig(checkInstances[0]);

  return (
    <div className={styles.configBox}>
      <div className={styles.configBoxHeader}>
        <div className={styles.checkName}>{getAnalysisDisplayTitle(name)}</div>
      </div>
      <div className={styles.configBoxBody}>
        {/* If the check has no instances but can be configured, hint to the user that they should add a configured instance. */}
        {checkInstances.length === 0 && hasConfig && (
          <>
            <div>No instances of this check are configured.</div>
            <div>Use the Add button to configure an instance of this check.</div>
          </>
        )}
        {/* Render any check instances. */}
        {checkInstances.map(
          (check, i) =>
            stages.includes(check.stage!) && (
              <ConfigurationInstance key={`${check.name}-${i}`} check={check} onEdit={onEdit} />
            )
        )}
        {/* If the check is configurable, allow the user to add more instances. */}
        {hasConfig && (
          <div className={styles.addButtonContainer}>
            <Button size="sm" variant="secondary" onClick={onAdd}>
              <Icon name="plus" className={styles.buttonIcon} />
              Add
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

interface ConfigurationInstanceProps {
  check: CheckConfig<AnyConfig>;
  onEdit?: (props: { check: CheckConfig<AnyConfig> }) => void;
}

function ConfigurationInstance({ check, onEdit }: ConfigurationInstanceProps) {
  const styles = useStyles2(getStyles);
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: deleteConfig } = useDeleteSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDefault = checkIsDefault(check);
  const hasConfig = checkHasConfig(check);
  const showDetails = () => {
    if (hasConfig) {
      setIsOpen(!isOpen);
    }
  };

  const handleWithCreate = async (disabledValue: boolean) => {
    if (check.id === undefined) {
      createConfig({ ...check, disabled: disabledValue });
    } else {
      updateConfig({ ...check, disabled: disabledValue });
    }
  };

  return (
    <>
      <div className={styles.instanceContainer}>
        <div className={styles.instanceIcon} onClick={() => showDetails()}>
          {hasConfig ? <Icon name={isOpen ? 'angle-down' : 'angle-right'} size="lg" /> : null}
        </div>
        <div className={styles.instanceTitle}>{getAnalysisDisplayTitle(check.name, check.title)}</div>
        <div className={styles.instanceButtons}>
          {hasConfig ? (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                if (onEdit) {
                  onEdit({ check });
                }
              }}
            >
              Edit
            </Button>
          ) : null}
          {check.disabled ? (
            <div
              className={styles.enableButton}
              onClick={() => {
                handleWithCreate(false);
              }}
            >
              <Badge badgeType={'disabled'} tooltipText="" />
            </div>
          ) : (
            <div
              className={styles.enableButton}
              onClick={() => {
                handleWithCreate(true);
              }}
            >
              <Badge badgeType={'enabled'} tooltipText="" />
            </div>
          )}
          <IconButton
            name="trash-alt"
            aria-label="Delete check instance"
            onClick={() => check.id && deleteConfig(check.id)}
            tooltip="Delete check instance"
            disabled={isDefault}
          />
        </div>
      </div>
      {isOpen ? (
        <div className={styles.instanceDetails}>
          <DisplayConfigValues config={isDefault ? check.config : check.mergedConfig} />
        </div>
      ) : null}
    </>
  );
}

interface DisplayConfigValuesProps {
  config?: Record<string, unknown>;
}

interface ConfigItem {
  [key: string]: string | string[] | Record<string, unknown>;
}

const DisplayConfigValues = ({ config }: DisplayConfigValuesProps) => {
  const styles = useStyles2(getStyles);
  if (config === undefined || config === null) {
    return null;
  }

  return (
    <div>
      {Object.keys(config).map((key) => {
        const setting = config[key] as ConfigItem;
        if (typeof setting === 'object') {
          return (
            <div key={`config${key}`} className={styles.configField}>
              <div className={styles.configPrompt}>{key}</div>
              <div className={styles.configValue}>
                {Object.keys(setting ?? {}).map((subKey) => {
                  const subItem = setting![subKey];
                  const subVal = Array.isArray(subItem) ? subItem.join(', ') : subItem;
                  return (
                    <div key={`config${key}-${subKey}`} className={styles.configField}>
                      <div className={styles.configPrompt}>{subKey}</div>
                      <div className={styles.configValue}>{subVal}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        return (
          <div key={`config${key}`} className={styles.configField}>
            <div className={styles.configPrompt}>{key}</div>
            <div className={styles.configValue}>{config[key]}</div>
          </div>
        );
      })}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    instanceContainer: css`
      display: flex;
      border-bottom: solid 1px ${theme.colors.border.weak};
      padding: 8px;
      color: ${theme.colors.text.primary};
      width: 100%;
    `,
    instanceIcon: css`
      display: flex;
      align-items: center;
      margin-right: 8px;
      width: 20px;
    `,
    instanceTitle: css`
      display: flex;
    `,
    instanceButtons: css`
      display: flex;
      gap: 8px;
      margin-left: auto;
    `,
    instanceDetails: css`
      width: calc(100%-16px);
      padding: 8px 8px 8px 48px;
      margin-top: -1px;
    `,
    instanceValues: css``,
    viewContainer: css``,
    configBox: css`
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 5px;
      margin-bottom: 16px;
      width: 100%;
    `,
    configBoxHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: ${theme.colors.background.secondary};
      padding: 8px;
    `,
    configBoxBody: css`
      padding: 16px;
    `,
    checkName: css``,
    controls: css`
      display: flex;
      margin-left: auto;
      gap: 8px;
    `,
    buttonIcon: css`
      margin-right: 4px;
    `,
    configField: css`
      display: flex;
      gap: 8px;
    `,
    configPrompt: css`
      font-eight: bold;
    `,
    configValue: css`
      font-style: italic;
    `,
    enableButton: css`
      display: flex;
    `,
    addButtonContainer: css`
      display: flex;
      justify-content: flex-end;
      padding: 8px 8px 0px 0px;
      width: 100%;
    `,
  };
};

export { ConfigurationItem };
