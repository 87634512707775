import React, { FC } from 'react';

import { dateTimeAsMoment, GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { HolidayOccurrence } from 'types';

interface CustomOccurrenceListProps {
  holidayOccurrences?: HolidayOccurrence[];
}

export const CustomOccurrenceList: FC<CustomOccurrenceListProps> = ({ holidayOccurrences }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.container}>
      {holidayOccurrences !== undefined
        ? holidayOccurrences?.map((holidayOccurrence, index) => (
            <div className={styles.sideContainer} key={index}>
              <Button variant="primary" className={styles.link}>
                {holidayOccurrence.name}
              </Button>
              <div className={styles.linkDetails}>
                {dateTimeAsMoment(holidayOccurrence.startTime).format('dddd')}
                {', '} {dateTimeAsMoment(holidayOccurrence.startTime).format('MMM DD YYYY')}
                {'-'} {dateTimeAsMoment(holidayOccurrence.endTime).format('dddd')}
                {', '} {dateTimeAsMoment(holidayOccurrence.endTime).format('MMM DD YYYY')}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      max-height: 650px;
      overflow-y: scroll;
    `,
    sideContainer: css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing(1)} ${theme.spacing(1)};
    `,
    link: css`
      color: #6e9fff;
      padding: 0px 4px;
      background-color: transparent;
      &:hover: {
        background-color: transparent;
      }
    `,
    linkDetails: css`
      padding: 0px 4px;
      color: ${theme.isDark ? 'rgba(204, 204, 220, 0.65)' : 'rgba(0, 0, 0, 0.6)'};
    `,
  };
}
