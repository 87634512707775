import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { SceneComponentProps, SceneLayout, SceneObject, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { Button, HorizontalGroup, Text, VerticalGroup } from '@grafana/ui';

import { css } from '@emotion/css';

interface SceneDrilldownState extends SceneObjectState {
  // Whether to show the outer layout.
  // This is the default layout.
  // This should be mutually exclusive with `showDrilldown`
  // (note: this is not enforced, and the component itself actually
  // sets both to true during the transition).
  showOuter: boolean;
  // Whether to show the drilldown layout.
  showDrilldown: boolean;
  // The currently selected drilldown index. Defaults to 0.
  selected: number;
  // The layout for the outer view.
  // This could be a flex layout, for example.
  outerLayout: SceneLayout;
  // A function that returns the child items for the outer layout.
  // This will be called with a function that can be used to set the
  // drilldown index when an item is clicked.
  getOuterChildren: (setDrilldownIdx: (drillDownIdx: number) => void) => SceneObject[];
  // The layout for the drilldown view.
  // This could be a grid layout, for example.
  drilldownLayout: SceneLayout;
  // A function that returns the child items for the drilldown layout.
  // This will be called with the index of the selected item when
  // the drilldown is due to be shown.
  getDrilldownChildren: (idx: number) => SceneObject[];
  // A title for the drilldown view.
  getDrilldownTitle?: (idx: number) => string;
}

export class SceneDrilldown extends SceneObjectBase<SceneDrilldownState> {
  static Component = SceneDrilldownRenderer;
  // Cache for drilldown children.
  // We cache the children to avoid having to re-render the entire
  // scene when the drilldown is shown, since that can involve expensive
  // data queries.
  private _drilldownCache = new Map<number, SceneObject[]>();

  public constructor(
    state: Partial<SceneDrilldownState> &
      Pick<SceneDrilldownState, 'outerLayout' | 'getOuterChildren' | 'drilldownLayout' | 'getDrilldownChildren'>
  ) {
    super({
      showOuter: state.showOuter ?? true,
      showDrilldown: state.showOuter ?? false,
      selected: state.selected ?? 0,
      ...state,
    });
    this.state.outerLayout.setState({ children: state.getOuterChildren((idx) => this.setSelected(idx)) });
    this.state.drilldownLayout.setState({ children: state.getDrilldownChildren(this.state.selected) });
  }

  // Set the selected drilldown index.
  // This will compute the drilldown children for the given index,
  // then transition to the drilldown view.
  public setSelected(idx: number) {
    const children = this._getDrilldownChildren(idx);
    this.state.drilldownLayout.setState({ children });
    this.setState({ selected: idx });
    this.setShowDrilldown(true);
  }

  // Set whether to show the outer view.
  public setShowOuter(show: boolean) {
    this.setState({ showOuter: show });
  }

  // Set whether to show the drilldown view.
  public setShowDrilldown(show: boolean) {
    this.setState({ showDrilldown: show });
  }

  // Get the drilldown children for the given index.
  // This will cache the result so that subsequent calls
  // for the same index don't have to recompute the children.
  private _getDrilldownChildren(idx: number): ReturnType<SceneDrilldownState['getDrilldownChildren']> {
    const cached = this._drilldownCache.get(idx);
    if (cached) {
      return cached;
    }
    const scene = this.state.getDrilldownChildren(idx);
    this._drilldownCache.set(idx, scene);
    return scene;
  }
}

// Renderer for the drilldown component.
//
// This component is responsible for rendering the outer layout and the drilldown layout.
// It also handles the sliding animation between the two.
function SceneDrilldownRenderer({ model }: SceneComponentProps<SceneDrilldown>) {
  const { outerLayout, drilldownLayout, showOuter, showDrilldown, getDrilldownTitle, selected } = model.useState();
  return (
    <>
      {showOuter && <outerLayout.Component model={outerLayout} />}
      <CSSTransition
        in={showDrilldown}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{ ...styles }}
        onEnter={() => model.setShowOuter(false)}
        onExited={() => model.setShowOuter(true)}
      >
        <VerticalGroup>
          <HorizontalGroup justify="space-between">
            {getDrilldownTitle && <Text element="h4">{getDrilldownTitle(selected)}</Text>}
            <HorizontalGroup justify="flex-end">
              <Button onClick={() => model.setShowDrilldown(false)}>Back</Button>
            </HorizontalGroup>
          </HorizontalGroup>
          <drilldownLayout.Component model={drilldownLayout} />
        </VerticalGroup>
      </CSSTransition>
    </>
  );
}

// Styles for the sliding CSS transition.
const styles = {
  // Applied when the component starts entering.
  enter: css`
    transform: translateX(100%);
  `,
  // Applied while the component is entering.
  enterActive: css`
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
  `,
  // Applied when the component starts exiting.
  exit: css`
    transform: translateX(0);
  `,
  // Applied while the component is exiting.
  exitActive: css`
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
  `,
};
