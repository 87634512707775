import React from 'react';
import { Control, FieldErrors, useWatch } from 'react-hook-form';

import { FieldSet } from '@grafana/ui';

import { useAllHolidays } from 'api/queries';
import { InlineEnumField } from 'components/FormFields/InlineEnumField';
import { InlineNumberField } from 'components/FormFields/InlineNumberField';
import { FullFormModel } from 'types';

interface ProphetHyperparametersFormProps {
  control: Control<FullFormModel>;
  errors?: FieldErrors<FullFormModel> | undefined;
  holidayIds: string[];
}

export function ProphetHyperparametersForm({
  control,
  errors,
  holidayIds,
}: ProphetHyperparametersFormProps): JSX.Element | null {
  const growthSelected = useWatch({ control, name: 'parameters.hyperparameters.grafana_prophet_1_0_1.growth' });
  const logisticGrowthCapValue = useWatch({
    control,
    name: 'parameters.hyperparameters.grafana_prophet_1_0_1.logistic_growth_cap',
  });

  const conditionalDailySeasonalitySelected =
    useWatch({ control, name: 'parameters.hyperparameters.grafana_prophet_1_0_1.conditional_daily_seasonality' }) !=
    null;
  const conditionalWeeklySeasonalitySelected =
    useWatch({ control, name: 'parameters.hyperparameters.grafana_prophet_1_0_1.conditional_weekly_seasonality' }) !=
    null;

  const { data: allHolidays } = useAllHolidays();

  const holidays =
    allHolidays
      ?.filter((holiday) => holidayIds.includes(holiday.id))
      .map((holiday) => ({ label: holiday.name, value: holiday.id, key: holiday.id })) ?? [];

  return (
    <FieldSet>
      <aside className="grafana-info-box span8" style={{ margin: '0 0 25px 0' }}>
        Prophet offers forecasting of time series data based on an additive/multiplicative model where non-linear trends
        are fit with yearly, weekly, and daily seasonality, plus holiday effects. It works best with time series that
        have strong seasonal effects and several seasons of historical data. Prophet is robust to missing data and
        shifts in the trend, and typically handles outliers well. Visit the{' '}
        <a href="https://facebook.github.io/prophet/">Prophet project page</a> for more details.
      </aside>
      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.changepoint_prior_scale}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.changepoint_prior_scale"
        label="Changepoint Prior Scale"
        tooltip="Determines the flexibility of the trend and in particular how much the trend
        changes at the trend changepoints. If it is too small the trend will be underfit
        and variance that should have been modeled with trend changes will instead end
        up being handled with the noise term. If it is too large the trend will overfit
        and in the most extreme case you can end up with the trend capturing yearly
        seasonality. A range of [0.001, 0.5] would likely be about right."
        defaultValue={0.05}
        minimum={0.001}
        maximum={10.0}
      />

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.changepoint_range}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.changepoint_range"
        label="Changepoint Range"
        tooltip="Controls what portion of the training data to check for changepoints. For the default, 0.8,
        the first 80% of the data is checked for changepoints, and any changepoints in the last
        20% will be ignored."
        defaultValue={0.8}
        minimum={0.01}
        maximum={1.0}
      />

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.seasonality_prior_scale}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.seasonality_prior_scale"
        label="Seasonality Prior Scale"
        tooltip="Controls the flexibility to fit the seasonality. A large value allows the seasonality to fit large
          fluctuations, whereas a small value shrinks the magnitude of the seasonality."
        defaultValue={0.05}
        minimum={0.01}
        maximum={10.0}
      />

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.interval_width}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.interval_width"
        label="Uncertainty interval width"
        tooltip="Uncertainty intervals (yhat_upper, yhat_lower) are computed as quantiles of the
        predicted value to use. The default of 0.95 provides an 95% confidence interval.
        95% of future data should be expected to be between (yhat_upper, yhat_lower)."
        defaultValue={0.95}
        minimum={0.01}
        maximum={1.0}
      />

      <InlineEnumField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.seasonality_mode}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.seasonality_mode"
        label="Seasonality mode"
        tooltip="Set to 'multiplicative' if it appears that the magnitude of seasonal fluctuations
          grows with the magnitude of the time series."
        options={[
          {
            label: 'Additive',
            value: 'additive',
            description: 'If the seasonality effect should be added to the trend in the forecast',
          },
          {
            label: 'Multiplicative',
            value: 'multiplicative',
            description: 'If the impact of seasonality should be multiplied with the trend in the forecast',
          },
        ]}
        defaultValue={'additive'}
      />

      <InlineEnumField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.growth}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.growth"
        label="Growth"
        tooltip="The type of model used for the growth trend component.
        Set to 'flat' only if you are certain that the trend is constant and that the
        time series mostly exhibits seasonality patterns, rather than trend changes.
        A flat growth model can reduce the width of uncertainty intervals in such cases.
        Choose 'linear' if modelling something that always increases linearly,
        for example, disk space consumed by a constant rate of write operations,
        Choose 'logistic' if attempting to model growth with a known maximum value.
        This requires specifying a maximum achievable point, or carrying capacity, that
        the forecast should saturate at. An optional floor may also be specified."
        options={[
          { label: 'Linear', value: 'linear', description: 'If the data contains linear trends' },
          { label: 'Flat', value: 'flat', description: 'If you know the trend is fixed.' },
          { label: 'Logistic', value: 'logistic', description: 'If data appears to exhibit growth' },
        ]}
        defaultValue={'linear'}
      />

      {growthSelected === 'logistic' ? (
        <FieldSet style={{ margin: '0 0 0 25px' }}>
          <aside className="grafana-info-box span8">
            A Logistic Growth trend model needs additional information about the data. It requires specifying the
            &ldquo;Logistic Cap&rdquo;, i.e. the expected maximum achievable value. Optionally the &ldquo;Logistic
            Floor&rdquo;, i.e. the expected minimum value, can be specified.
            <InlineNumberField
              control={control}
              error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.logistic_growth_cap}
              name="parameters.hyperparameters.grafana_prophet_1_0_1.logistic_growth_cap"
              label="Logistic Cap"
              tooltip="Expected maximum achievable value"
            />
            <InlineNumberField
              control={control}
              error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.logistic_growth_floor}
              name="parameters.hyperparameters.grafana_prophet_1_0_1.logistic_growth_floor"
              label="Logistic Floor"
              tooltip="Expected minimum achievable value (optional)"
              required={false}
              validate={(tv) => {
                return logisticGrowthCapValue !== undefined && tv >= logisticGrowthCapValue
                  ? 'Must be less than "Logistic Cap"'
                  : undefined;
              }}
            />
          </aside>
        </FieldSet>
      ) : (
        <></>
      )}

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.weekly_seasonality}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.weekly_seasonality"
        label="Weekly Fourier Order"
        tooltip="How many Fourier orders to use when calculating weekly seasonality. Leaving this value empty
          will use the default of 3 when the training data range is larger than two weeks, and disable
          seasonality on smaller training data ranges. Setting the Fourier order to 0 will disable weekly
          seasonality. Note that it is not possible to disable weekly seasonality if using a separate weekly
          seasonality based on a holiday. If there is a strong weekday vs weekend component to your data it
          is recommended to use a fourier order between 10 and 25."
        minimum={conditionalWeeklySeasonalitySelected ? 1 : 0}
        maximum={25}
        integerOnly={true}
        required={false}
      />

      <InlineEnumField
        control={control}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.conditional_weekly_seasonality"
        label="Separate weekly seasonality for holiday"
        tooltip="Optionally, a holiday for which weekly seasonality should be modelled separately.
          This allows the model to capture the fact that the holiday may have a different day-of-week
          effects to non-holidays. For example, public holidays may not show the same weekday effects
          as non-holidays."
        options={holidays}
        isClearable
      />

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.daily_seasonality}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.daily_seasonality"
        label="Daily Fourier Order"
        tooltip="How many Fourier orders to use when calculating daily seasonality. Leaving this value empty
          will use the default of 4 when the training data range is larger than two days, and disable
          seasonality on smaller training data ranges. Setting the Fourier order to 0 will disable daily
          seasonality. Note that it is not possible to disable daily seasonality if using a separate daily
          seasonality based on a holiday."
        minimum={conditionalDailySeasonalitySelected ? 1 : 0}
        maximum={25}
        integerOnly={true}
        required={false}
      />

      <InlineEnumField
        control={control}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.conditional_daily_seasonality"
        label="Separate daily seasonality for holiday"
        tooltip="Optionally, a holiday for which daily seasonality should be modelled separately.
          This allows the model to capture the fact that the holiday may have a different hour-of-day
          effects to non-holidays. For example, public holidays may not show the same spike during
          work hours as non-holidays."
        options={holidays}
        isClearable
      />

      <InlineNumberField
        control={control}
        error={errors?.parameters?.hyperparameters?.grafana_prophet_1_0_1?.holidays_prior_scale}
        name="parameters.hyperparameters.grafana_prophet_1_0_1.holidays_prior_scale"
        label="Holidays Prior Scale"
        tooltip=" Controls the flexibility to fit holidays. A large value allows fitting to large
        fluctuations on holidays, whereas a small value shrinks the the impact of those fluctuations on
        the fit."
        defaultValue={10.0}
        minimum={0.01}
        maximum={10.0}
        required={false}
      />
    </FieldSet>
  );
}
