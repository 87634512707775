import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';

import { css, cx } from '@emotion/css';

interface DetailsProps {
  summary: JSX.Element | string;
}

function useAnimateDetails<
  Details extends HTMLDivElement,
  Summary extends HTMLDivElement,
  Content extends HTMLDivElement
>() {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailsHeight, setDetailsHeight] = useState('');
  const [detailsOverflow, setDetailsOverflow] = useState('visible');
  const detailsRef = useRef<Details>(null);
  const summaryRef = useRef<Summary>(null);
  const contentRef = useRef<Content>(null);
  const isOpening = useRef(false);
  const isClosing = useRef(false);
  const animationRef = useRef<Animation>();

  const onAnimationFinish = useCallback((open) => {
    setDetailsOpen(open);
    setDetailsOverflow('');
    setDetailsHeight('');
    animationRef.current = undefined;
    isClosing.current = false;
    isOpening.current = false;
  }, []);

  const doExpand = useCallback(() => {
    isOpening.current = true;
    const detailsDims = detailsRef.current?.getBoundingClientRect();
    const summaryDims = summaryRef.current?.getBoundingClientRect();
    const contentDims = contentRef.current?.getBoundingClientRect();
    const startHeight = `${detailsDims?.height ?? 0}px`;
    const endHeight = `${(summaryDims?.height ?? 0) + (contentDims?.height ?? 0)}px`;

    if (animationRef.current != null) {
      animationRef.current.cancel();
    }

    animationRef.current = detailsRef.current?.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 200,
        easing: 'ease-out',
      }
    );

    if (animationRef.current != null) {
      animationRef.current.onfinish = () => {
        onAnimationFinish(true);
      };
      animationRef.current.oncancel = () => {
        isOpening.current = false;
      };
    }
  }, [contentRef, detailsRef, onAnimationFinish, summaryRef]);

  const doOpen = useCallback(() => {
    const detailsDims = detailsRef.current?.getBoundingClientRect();
    setDetailsHeight(`${detailsDims?.height ?? 0}px`);
    setDetailsOpen(true);
    window.requestAnimationFrame(() => doExpand());
  }, [detailsRef, doExpand]);

  const doClose = useCallback(() => {
    isClosing.current = true;
    const detailsDims = detailsRef.current?.getBoundingClientRect();
    const summaryDims = summaryRef.current?.getBoundingClientRect();
    const startHeight = `${detailsDims?.height ?? 0}px`;
    const endHeight = `${summaryDims?.height ?? 0}px`;

    if (animationRef.current != null) {
      animationRef.current.cancel();
    }

    animationRef.current = detailsRef.current?.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 200,
        easing: 'ease-out',
      }
    );

    if (animationRef.current != null) {
      animationRef.current.onfinish = () => {
        onAnimationFinish(false);
      };
      animationRef.current.oncancel = () => {
        isClosing.current = false;
      };
    }
  }, [detailsRef, summaryRef, onAnimationFinish]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      setDetailsOverflow('hidden');
      const isRefOpen = (detailsRef.current?.dataset['isOpen'] ?? false) === 'true';
      const shouldOpen = isClosing.current || !isRefOpen;
      if (shouldOpen) {
        doOpen();
      } else {
        doClose();
      }
    },
    [detailsRef, doClose, doOpen]
  );

  useEffect(() => {
    if (summaryRef.current != null) {
      summaryRef.current.addEventListener('click', handleClick);
    }
  }, [handleClick, summaryRef]);

  return { detailsRef, summaryRef, contentRef, detailsHeight, detailsOpen, detailsOverflow };
}

/// Makes a details drawer that looks like grafana and keeps children mounted,
/// using native details tag. Staying mounted means form fields will be included.
export const Details: FC<DetailsProps> = ({ summary, children }) => {
  const styles = useStyles(getStyles);
  const { detailsRef, summaryRef, contentRef, detailsOpen, detailsOverflow, detailsHeight } = useAnimateDetails();
  if (summary != null) {
    return (
      <main
        ref={detailsRef}
        className={cx(styles.drawerStyles, detailsOpen ? styles.drawerOpen : styles.drawerClosed)}
        style={{ height: detailsHeight, overflow: detailsOverflow }}
        data-is-open={detailsOpen.toString()}
      >
        <div ref={summaryRef}>
          <span>{summary}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className={styles.iconStyles}
          >
            <path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"></path>
          </svg>
        </div>
        <aside ref={contentRef} className={styles.contents}>
          {children}
        </aside>
      </main>
    );
  }
  return (
    <main
      ref={detailsRef}
      style={{ height: detailsHeight, overflow: detailsOverflow }}
      className={cx(styles.drawerStyles, detailsOpen ? styles.drawerOpen : styles.drawerClosed)}
      data-is-open={detailsOpen.toString()}
    >
      {children}
    </main>
  );
};

function getStyles(theme: GrafanaTheme) {
  return {
    drawerOpen: css`
      & > aside {
        display: flex;
        flex-direction: row;
      }
      & > div > svg {
        transform: rotate(90deg);
      }
    `,
    drawerClosed: css`
      & > aside {
        display: none;
      }
      & > div > svg {
        transform: rotate(0);
      }
    `,
    contents: css`
      margin: 12px 0;
      padding-bottom: 18px;
      box-sizing: border-box;
    `,
    iconStyles: css`
      vertical-align: middle;
      display: inline-block;
      fill: currentcolor;
    `,
    drawerStyles: css`
      border-radius: ${theme.border.radius.sm};
      background: ${theme.colors.bg1};
      min-height: ${theme.spacing.formInputHeight}px;
      font-size: ${theme.typography.size.sm};
      overflow: visible;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      & > div {
        font-size: ${theme.typography.size.sm};
        font-weight: ${theme.typography.weight.semibold};
        border-radius: ${theme.border.radius.sm};
        background: ${theme.colors.bg2};
        color: ${theme.colors.textBlue};
        padding: 0 8px;
        display: block;
        position: relative;
        cursor: pointer;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        line-height: 32px;
        border: none;
        list-style: none;
      }

      & > div > svg {
        width: 16px;
        height: 16px;
        transform: rotate(0);
        transform-origin: 50% 50%;
        transition: 0.3s transform ease;
      }
    `,
  };
}
