import { config } from '@grafana/runtime';

import { InvestigationPreview, NewInvestigation, ResponseWrapper } from 'types';

import { adminApiPost, isErrorResponse } from '../core.api';

export const getSiftPreview = async (investigation: NewInvestigation) => {
  try {
    const response = await adminApiPost<ResponseWrapper<InvestigationPreview>>('/sift/api/v1/investigations/preview', {
      data: investigation,
      headers: { 'x-grafana-url': config.appUrl },
    });

    if (isErrorResponse(response)) {
      throw new Error(response.data.error);
    }

    return response.data.data;
  } catch (error) {
    const msg = (error as unknown as { data: { message: string } }).data.message;
    throw new Error(`Error: ${msg}`) as Error;
  }
};
