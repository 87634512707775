import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Button, RadioButtonGroup, Spinner, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useHoliday, useRemoveHoliday } from 'api';

import 'react-day-picker/dist/style.css';

import { PLUGIN_ROOT } from 'consts';
import { useConfirmModal } from 'hooks';
import { Holiday } from 'types';
import { onHandler, useUtilityStyles } from 'utils';
import { FormatMonthHolidaysDate, FormatQuarterHolidaysDate } from 'utils/utils.holiday';

import { CustomOccurrenceList } from './CustomOccurrenceList';
import { MonthViewCalendar } from './MonthViewCalendar';
import { QuarterViewCalendar } from './QuarterViewCalendar';

export enum TabView {
  HOLIDAYS = 'HOLIDAYS',
  FORECASTS = 'FORECASTS',
}

export enum CalendarView {
  QUARTER = 'Quarter',
  MONTH = 'Month',
}

export const HolidayDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useHoliday(id!);
  const styles = useStyles2(getStyles);

  const defaultTitle = 'View holiday';
  const title = data != null && data.name !== '' ? data.name : 'Untitled';

  if (isLoading) {
    return (
      <PluginPage pageNav={{ text: defaultTitle }}>
        <Spinner />
      </PluginPage>
    );
  }
  return (
    <div className={styles.tabContainer}>
      <HolidayDetailsInternal title={title} data={data} />
    </div>
  );
};

export const HolidayDetailsInternal = ({ data, title }: { data: Holiday | undefined; title?: string }) => {
  const navigate = useNavigate();
  const [calendarView, setCalendarView] = useState(CalendarView.MONTH);
  const styles = useStyles2(getStyles);
  const utilityStyle = useUtilityStyles();
  const options = [
    { label: 'Month', value: CalendarView.MONTH },
    { label: 'Quarter', value: CalendarView.QUARTER },
  ];
  const formattedHolidays =
    data?.customPeriods !== undefined && data?.customPeriods !== null
      ? FormatMonthHolidaysDate(data?.customPeriods)
      : data?.resolvedDates !== null && data?.resolvedDates !== undefined
      ? FormatMonthHolidaysDate(data?.resolvedDates)
      : [];
  const formattedHolidaysQuarter =
    data?.customPeriods !== undefined && data?.customPeriods !== null
      ? FormatQuarterHolidaysDate(data?.customPeriods)
      : data?.resolvedDates !== null && data?.resolvedDates !== undefined
      ? FormatQuarterHolidaysDate(data?.resolvedDates)
      : [];

  const doDelete = useRemoveHoliday();
  const onDelete = useCallback(
    (holidayId: string) => {
      doDelete.mutate(holidayId);
    },
    [doDelete]
  );

  const [confirmDelete, ConfirmDeleteModal] = useConfirmModal(async () => {
    data?.id !== undefined ? onDelete(data?.id) : console.error('no id provided');
    navigate(`${PLUGIN_ROOT}/metric-forecast/holiday`);
  });

  const dates = (data?.customPeriods ?? []).length > 0 ? data?.customPeriods ?? [] : data?.resolvedDates ?? [];

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.pageToolbar}>
        {title !== undefined ? <div className={styles.title}>{title}</div> : <div></div>}
        <div className={styles.buttonRow}>
          <Button
            variant="primary"
            title={`Edit this holiday.`}
            onClick={() => {
              navigate(`${PLUGIN_ROOT}/metric-forecast/holiday/${data?.id ?? ''}/edit`, { state: { holiday: data } });
            }}
          >
            Edit
          </Button>
          <Button variant="secondary" title={`Delete this holiday.`} onClick={onHandler(confirmDelete)}>
            Delete
          </Button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.calendarPanel}>
          <div className={styles.side}>
            <div className={styles.holidayList}>
              <CustomOccurrenceList holidayOccurrences={dates} />
            </div>
            <div className={styles.calendarContainer}>
              <div className={styles.calendarViewToggle}>
                <RadioButtonGroup options={options} value={calendarView} onChange={(value) => setCalendarView(value)} />
              </div>
              {calendarView === CalendarView.QUARTER ? (
                <div className={styles.container}>
                  <QuarterViewCalendar formattedHolidays={formattedHolidaysQuarter} numberOfMonths={3} />
                </div>
              ) : null}
              {calendarView === CalendarView.MONTH ? <MonthViewCalendar formattedHolidays={formattedHolidays} /> : null}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal icon="trash-alt" title="Delete holiday" actionText="Delete">
        <div className={utilityStyle.textMd}>Are you sure you want to delete &quot;{data?.name}&quot;?</div>
      </ConfirmDeleteModal>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    mainWrapper: css`
      width: 100%;
    `,
    contentContainer: css`
      display: flex;
      position: relative;
      flex-direction: column;
    `,
    calendarViewToggle: css`
      position: absolute;
      right: 0px;
      top: 8px;
    `,
    calendarPanel: css`
      background-color: ${theme.colors.background.primary};
      margin-bottom: 30px;
    `,
    forecastList: css`
      padding-top: 8px;
    `,
    nav: css`
      display: flex;
      align-items: center;
    `,
    container: css`
      display: flex;
      margin-top: 27px;
      height: calc(100% - 27px);
      min-height: 400px;
    `,
    content: css`
      padding-top: ${theme.spacing(3)};
    `,
    calendarContainer: css`
      flex: 1 0 0;
    `,
    holidaysList: css`
      flex: 0 1 auto;
    `,
    calendar: css`
      margin-right: ${theme.spacing(1)};
    `,
    holidayList: css`
      overflow-y: scroll;
      margin-top: 56px;
      border-top: solid 1px ${theme.isDark ? 'rgba(204, 204, 220, 0.3)' : 'rgb(221, 221, 221)'};
      border-bottom: solid 1px ${theme.isDark ? 'rgba(204, 204, 220, 0.3)' : 'rgb(221, 221, 221)'};
      border-left: solid 1px ${theme.isDark ? 'rgba(204, 204, 220, 0.3)' : 'rgb(221, 221, 221)'};
      background-color: ${theme.isDark ? '#1C1F23' : '#fdfdfd'};
    `,
    arrowIcon: css`
      background-color: ${theme.colors.secondary.main};
      width: 40px;
      height: 32px;
      padding: 15px;
      margin-left: 2px;
    `,
    monthLabel: css`
      padding-top: 3px;
      margin-left: 3px;
    `,
    link: css`
      padding: 0px;
      background-color: transparent;
      &:hover: {
        background-color: transparent;
      }
    `,
    side: css`
      display: flex;
      margin-top: 10px;
      position: relative;
    `,
    title: css`
      font-size: 20px;
      font-weight: 600;
      text-align: left;
    `,
    pageToolbar: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 20px 0px;
      gap: 4px;
    `,
    buttonRow: css`
      display: flex;
      gap: 4px;
    `,
    tabContainer: css`
      margin: 0px 10px 10px 20px;
    `,
  };
}
