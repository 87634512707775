import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataSourceInstanceSettings, IconName } from '@grafana/data';
import { config } from '@grafana/runtime';
import { ButtonVariant, IconButton, LinkButton, Menu } from '@grafana/ui';

import { css } from '@emotion/css';

import { PLUGIN_ROOT } from 'consts';
import { useMlDataSource } from 'hooks/useSupportedDatasources';
import { Job } from 'types';
import { tryGte } from 'utils/utils.general';
import { absoluteUrlForJob } from 'utils/utils.jobs';

/// Create a URL to the 'Create Alert' screen with some defaults for the form.
function newAlertUrl(job: Job, mlDatasource: DataSourceInstanceSettings, returnTo: string): string {
  const interval = job.interval ?? 300;
  // Set the `for` clause to 2x the job's `interval`. The queries will return the same data
  // for the whole interval because we don't make more granular predictions/queries, so we need to
  // ensure that the `for` clause is greater than the interval. The default `for` is 5m, which
  // is the same as our default `interval`, hence the need to override this.
  // Note that we also never set the `for` clause to less than 5 minutes, to avoid flappy alerts.
  const evaluateForSeconds = interval < 150 ? 300 : 2 * interval;
  const evaluateFor = `${evaluateForSeconds / 60}m`;

  const [instant, reducer] = tryGte(config.buildInfo.version, '8.4.0') ? [false, 'last'] : [true, 'mean'];

  const defaults = {
    type: 'grafana',
    name: `${job.name} (Grafana ML)`,
    // Grouping was introduced in Grafana 8.5+
    group: 'grafana-ml',
    condition: 'B',
    evaluateFor,
    queries: [
      {
        refId: 'A',
        queryType: '',
        relativeTimeRange: { from: 2 * evaluateForSeconds, to: 0 },
        datasourceUid: mlDatasource.uid,
        model: {
          expr: `${job.metric}:anomalous`,
          legendFormat: '',
          exemplar: false,
          instant,
          datasource: { type: 'prometheus', uid: job.datasourceUid },
          refId: 'A',
        },
      },
      {
        refId: 'B',
        queryType: '',
        // "-100" is a special datasourceUid understood by Grafana to mean a server side expression
        // rather than a query.
        datasourceUid: '-100',
        model: {
          conditions: [
            {
              evaluator: {
                params: [0, 0],
                type: 'gt',
              },
              operator: {
                type: 'and',
              },
              query: {
                params: [],
              },
              reducer: {
                params: [],
                type: 'avg',
              },
              type: 'query',
            },
          ],
          datasource: {
            type: '__expr__',
            uid: '__expr__',
          },
          expression: 'A',
          hide: false,
          reducer,
          refId: 'B',
          type: 'reduce',
        },
      },
    ],
    annotations: [
      { key: 'grafana-ml-forecast-id', value: job.id },
      { key: 'grafana-ml-forecast-url', value: absoluteUrlForJob(job.id) },
      { key: 'grafana-ml-forecast-name', value: job.name },
      { key: 'grafana-ml-forecast-metric', value: job.metric },
      { key: 'grafana-ml-forecast-description', value: job.description ?? null },
    ],
  };
  returnTo = returnTo.startsWith('/') ? returnTo : `/${returnTo}`;
  return `/alerting/new?defaults=${encodeURIComponent(JSON.stringify(defaults))}&returnTo=${encodeURIComponent(
    PLUGIN_ROOT + returnTo
  )}`;
}

interface CreateAlertButtonProps {
  job: Job;
  alertingEnabled: boolean;
  jobReady: boolean;
  jobDisabled: boolean;
  returnTo: string;
  variant?: ButtonVariant;
  icon?: string;
  kind: 'icon' | 'button' | 'menu-item';
}

const CreateAlertButtonInner: FC<CreateAlertButtonProps> = ({
  job,
  alertingEnabled,
  jobReady,
  jobDisabled,
  returnTo,
  variant,
  icon,
  kind = 'icon',
}) => {
  const navigate = useNavigate();
  const mlDataSource = useMlDataSource();
  // Don't let users create alerts unless:
  // - Grafana Alerting is enabled
  // - the ML datasource exists
  // - the job can be viewed.
  const alertButtonEnabled = alertingEnabled && mlDataSource !== undefined && jobReady && !jobDisabled;
  const title = !alertingEnabled
    ? 'Grafana Alerting must be enabled to create an alert.'
    : mlDataSource == null
    ? 'Grafana ML datasource not found, please re-initialize the plugin.'
    : !jobReady
    ? 'Forecast is still training, please wait until it is ready before creating an alert.'
    : jobDisabled
    ? 'Forecast is disabled and cannot be used for alerting.'
    : // Success case.
      'Create an alert using Grafana Alerting';
  const href = alertButtonEnabled && mlDataSource !== undefined ? newAlertUrl(job, mlDataSource, returnTo) : undefined;

  if (kind === 'menu-item' && href !== undefined) {
    return (
      <Menu.Item
        label="Create alert"
        icon="bell"
        onClick={() => {
          navigate(href, { replace: true });
        }}
      />
    );
  }

  if (kind === 'icon' && href !== undefined) {
    return (
      <IconButton
        key="create-alert"
        name={icon as IconName}
        tooltip={title}
        disabled={!alertButtonEnabled}
        title={title}
        onClick={() => {
          navigate(href, { replace: true });
        }}
      />
    );
  }

  return (
    <LinkButton
      key="create-alert"
      href={href}
      disabled={!alertButtonEnabled}
      className={alertButtonEnabled ? styles.empty : styles.disabledWithTooltip}
      title={title}
      variant={variant ?? 'secondary'}
    >
      Create Alert
    </LinkButton>
  );
};

export const CreateAlertButton = React.memo(CreateAlertButtonInner, (prev, next) => {
  return (
    prev.jobReady === next.jobReady &&
    prev.alertingEnabled === next.alertingEnabled &&
    prev.jobDisabled === next.jobDisabled
  );
});

const styles = {
  empty: css``,
  disabledWithTooltip: css`
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: auto;
  `,
};
