import React from 'react';

import { HorizontalGroup, InlineField, RadioButtonGroup, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { OutlierSensitivity } from './OutlierSensitivity';

interface OutlierControlsProps {
  sensitivity: number;
  onSensitivityChange: (v?: number | undefined) => void;
  algorithm: string;
  onAlgorithmChange: (v?: string | undefined) => void;
  enabled: boolean;
}

// TODO: improve tooltip explanation based on feedback
const AlgorithmTooltip = (
  <Tooltip content="Algo" placement="bottom">
    <>
      <div>
        <p>DBSCAN Clustering</p>
        <p style={{ fontWeight: 100 }}>
          Clusters data points based on their density and distances and flags them if a series has data points outside
          the largest cluster. DBSCAN works with a rolling window, which means the band of normal behavior will move
          with your data. Use DBSCAN if you expect your series to move in sync over time or if you have strong trends in
          your data.
        </p>
      </div>
      <div>
        <p>MAD: Median Absolute Deviation</p>
        <p style={{ fontWeight: 100 }}>
          Compares the distances of data points at each timestamp to the rolling 24-hour median and flags them if a
          series has data points outside the chosen sensitivity threshold. MAD works best when you expect all members of
          a group to move within a stable band of normal behavior and is less affected by out-of-sync events, such as
          instances restarting at different times.
        </p>
      </div>
    </>
  </Tooltip>
);

export function OutlierControls({
  sensitivity,
  onSensitivityChange,
  algorithm,
  onAlgorithmChange,
  enabled = true,
}: OutlierControlsProps): JSX.Element | null {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.controlGroup}>
      <HorizontalGroup>
        <InlineField grow labelWidth={12} label="Algorithm" tooltip={AlgorithmTooltip} transparent={true}>
          <RadioButtonGroup
            options={[
              { label: 'DBSCAN', value: 'dbscan' },
              { label: 'MAD', value: 'mad' },
            ]}
            value={algorithm}
            onChange={onAlgorithmChange}
            disabled={!enabled}
          />
        </InlineField>
        <InlineField grow labelWidth={12} label="Sensitivity" transparent={true}>
          <OutlierSensitivity onSensitivityChange={onSensitivityChange} sensitivity={sensitivity} />
        </InlineField>
      </HorizontalGroup>
    </div>
  );
}

const getStyles = () => ({
  controlGroup: css`
    display: flex;
    & > div {
      justify-content: flex-start;
    }
  `,
});
