import React from 'react';
import { useAsync } from 'react-use';

import { PanelData, TimeRange } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { AngularQueryEditor, isDataSourceWithAngularQueryEditor } from './AngularQueryEditor';
import { QueryEditorNotices } from './QueryEditorNotices';

interface QueryEditorProps {
  timeRange: TimeRange;
  name?: string;
  onChange: (q: DataQuery & { expr: string }) => void;
  onRunQuery: () => void;
  query: DataQuery;
  data?: PanelData;
}

export function QueryEditor({ name, timeRange, query, onChange, data, onRunQuery }: QueryEditorProps): JSX.Element {
  const state = useAsync(async () => {
    if (name == null || name === '') {
      return Promise.resolve(undefined);
    }
    return getDataSourceSrv().get(name);
  }, [name]);

  if (name == null) {
    return <div>Loading datasource editor</div>;
  }

  if (state.loading) {
    return <div>Loading datasource editor</div>;
  }

  if (state.error != null) {
    return <div>Error loading datasource editor</div>;
  }

  const { value: datasource } = state;

  if (datasource == null) {
    return <div>Error loading datasource editor</div>;
  }

  if (isDataSourceWithAngularQueryEditor(datasource)) {
    return (
      <AngularQueryEditor
        dataSource={datasource}
        timeRange={timeRange}
        onChange={onChange as (q: DataQuery) => void}
        query={query}
        canToggleEditorModes
      />
    );
  }

  const ReactQueryEditor = datasource.components?.QueryEditor;

  if (ReactQueryEditor == null) {
    return <div>Query editor not available for datasource</div>;
  }

  return (
    <>
      <QueryEditorNotices datasourceType={datasource.type} />
      <ReactQueryEditor
        data={data}
        range={timeRange}
        onRunQuery={onRunQuery}
        query={query}
        datasource={datasource}
        onChange={onChange as (q: DataQuery) => void}
      />
    </>
  );
}
