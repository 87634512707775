import { DataSourceInstanceSettings } from '@grafana/data';

import { create } from 'zustand';

import { DataQueryWithExpression } from 'types';

interface OutlierState {
  data: {
    datasource?: DataSourceInstanceSettings;
    query?: DataQueryWithExpression;
  };
  setDatasource(datasource?: DataSourceInstanceSettings, dirty?: boolean): void;
  setQuery(query?: DataQueryWithExpression, dirty?: boolean): void;
}

const useOutlierState = create<OutlierState>((set) => ({
  // here we set the values we want stored in state

  data: {
    datasource: undefined,
    query: undefined,
  },

  // here we define functions to update state values

  // set the datasource and dirty flag
  setDatasource: (datasource?: DataSourceInstanceSettings, dirty = true) =>
    set((state) => ({ data: { ...state.data, datasource, dirty } })),

  // set the query to force refrenders within the query editor
  setQuery: (query: DataQueryWithExpression) => set((state) => ({ data: { ...state.data, query } })),
}));

export { useOutlierState };
