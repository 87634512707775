import React, { useMemo } from 'react';

import { DashboardCursorSync, getTimeZone, GrafanaTheme2, TimeRange } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  PanelBuilders,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneReactObject,
  SceneTimeRange,
} from '@grafana/scenes';
import { BigValueColorMode, BigValueGraphMode, BigValueTextMode, ThresholdsMode } from '@grafana/schema';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { ExploreButton } from 'components/ExploreButton';
import { useScene } from 'hooks';
import { LLMPanel } from 'projects/Sift/Components/LLMPanel';
import { SiftModalData } from 'types';

interface ErrorPatternDetails {
  patterns: ErrorPattern[];
  maxExamples: number;
}

interface ErrorPattern {
  pattern: string;
  query: string;
  rateQuery: string;
  increased: boolean;
  increaseRate: number;
  count: number;
  streams?: StreamCounter[];
  commonLabels?: Record<string, string>;
}

interface StreamCounter {
  labels: Map<string, number>;
  count: number;
}

export function ErrorPatternLogsWrapper({
  analysis,
  investigation,
  datasources,
}: SiftModalData): React.ReactElement | null {
  const datasourceUid = datasources.lokiDatasource.uid;
  // Memoise without dependencies since it won't change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeRange = useMemo(() => investigation.timeRange, []);

  const details = (analysis.result?.details ?? {
    patterns: [],
  }) as unknown as ErrorPatternDetails;

  if (!analysis.result.interesting || details.patterns.length === 0) {
    return null;
  }

  return (
    <ErrorPatternLogs analysisId={analysis.id} datasourceUid={datasourceUid} details={details} timeRange={timeRange} />
  );
}

interface ErrorPatternLogsProps {
  analysisId: string;
  datasourceUid: string;
  timeRange: TimeRange;
  details: ErrorPatternDetails;
}

function ErrorPatternLogs({
  analysisId,
  datasourceUid,
  timeRange,
  details,
}: ErrorPatternLogsProps): React.ReactElement {
  const getScene = () => {
    return new EmbeddedScene({
      body: new SceneFlexLayout({
        direction: 'column',
        children: details.patterns
          .filter((pattern) => pattern.increased)
          .map(
            (pattern, i) =>
              new SceneFlexItem({
                body: new ErrorPatternPanel({
                  pattern,
                  datasourceUid,
                  timeRange,
                  storageKey: `${analysisId}-${i}`,
                  maxExamples: details.maxExamples,
                }),
              })
          ),
      }),
    });
  };
  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}

interface ErrorPatternPanelState extends SceneObjectState {
  pattern: ErrorPattern;
  datasourceUid: string;
  timeRange: TimeRange;
  storageKey: string;
  inner: SceneFlexLayout;
  userMessage: string;
  maxExamples: number;
}

class ErrorPatternPanel extends SceneObjectBase<ErrorPatternPanelState> {
  static Component = ErrorPatternPanelRenderer;
  static systemMessage = `You are a seasoned Site Reliability Engineer helping debug an alert received by your colleague for their production systems.
Be as concise as possible while responding to the following.
`;

  public constructor(
    state: Pick<ErrorPatternPanelState, 'pattern' | 'datasourceUid' | 'storageKey' | 'timeRange' | 'maxExamples'>
  ) {
    const examplesData = getLogExamplesData(state.pattern.query, state.datasourceUid, state.maxExamples);
    examplesData.addActivationHandler(() => {
      const sub = examplesData.subscribeToState((data) => {
        if (data.data?.state === 'Done') {
          const lines = data.data?.series[0]?.fields[2]?.values as string[];
          this.setState({ userMessage: createMessage(lines) });
        }
      });
      return () => {
        sub.unsubscribe();
      };
    });
    const inner = new SceneFlexLayout({
      $behaviors: [new behaviors.CursorSync({ key: 'error-pattern-logs', sync: DashboardCursorSync.Crosshair })],
      $timeRange: new SceneTimeRange({
        to: state.timeRange.to.format(),
        from: state.timeRange.from.format(),
        timeZone: getTimeZone(),
      }),
      direction: 'column',
      height: '300px',
      children: [
        getTopRowScene(
          state.pattern.rateQuery,
          state.datasourceUid,
          state.pattern.pattern,
          state.pattern.increaseRate,
          state.pattern.count,
          state.timeRange
        ),
        getLogExamplesScene(state.pattern.query, state.datasourceUid, examplesData, state.timeRange),
      ],
    });
    super({
      ...state,
      inner,
      userMessage: '',
    });
  }
}

function createMessage(examples: string[]): string {
  let appendedLogs = '';
  const maxLength = 2048;
  if (examples.length > 0 && examples[0].length > maxLength) {
    return `Please concisely explain the following truncated log line in at most two sentences:
      ${examples[0].slice(0, maxLength)}
      Mention the software only if the logs appear to be coming from a commonly used software.
      Further, add 2 helpful hints for how to fix these errors.`;
  }
  for (let i = 0; i < examples.length; i++) {
    const example = examples[i];
    if (appendedLogs.length + example.length <= maxLength) {
      appendedLogs += `${i}: ${example}`;
    } else {
      break;
    }
  }

  return `Please concisely explain the following log lines in at most two sentences:
      ${appendedLogs}
      Mention the software only if the logs appear to be coming from a commonly used software.
      Further, add 2 helpful hints for how to fix these errors.`;
}

function ErrorPatternPanelRenderer({ model }: SceneComponentProps<ErrorPatternPanel>) {
  const styles = useStyles2(getStyles);
  const { inner, storageKey, userMessage } = model.useState();
  return (
    <div className={styles.panel}>
      <LLMPanel systemMessage={ErrorPatternPanel.systemMessage} userMessage={userMessage} storageKey={storageKey}>
        <inner.Component model={inner} />
      </LLMPanel>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    panel: css`
      border: 1px solid ${theme.colors.border.weak};
      background-color: ${theme.colors.background.secondary};
      padding: ${theme.spacing(1)};
      border-radius: ${theme.shape.radius.default};
    `,
    wildcard: css`
      color: ${theme.colors.text.secondary};
      font-weight: ${theme.typography.fontWeightLight};
    `,
    pattern: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: start;
      font-weight: ${theme.typography.fontWeightBold};
      overflow: auto;
    `,
    patternContainer: css`
      display: flex;
      flex-direction: column;
    `,
    title: css`
      font-weight: ${theme.typography.fontWeightBold};
    `,
  };
}

interface PatternProps {
  pattern: string;
}

function Pattern({ pattern }: PatternProps): React.ReactElement {
  const styles = useStyles2(getStyles);
  // Split patterns to separate wildcards (<*>) from the rest of the pattern. Then highlight the strings
  // between the wildcards.
  const patternParts = pattern.split(/(<.*?>)/g);
  const patternElements = patternParts.map((part, index) => {
    if (part.startsWith('<') && part.endsWith('>')) {
      return <span className={styles.wildcard} key={index}>{`${part}`}</span>;
    }
    return <span key={index}>{part}</span>;
  });

  return (
    <div className={styles.patternContainer}>
      <h5 className={styles.title}>Log Pattern</h5>
      <div className={styles.pattern}>{patternElements}</div>
    </div>
  );
}

function getTopRowScene(
  query: string,
  datasourceUid: string,
  pattern: string,
  increaseRate: number,
  occurrences: number,
  timeRange?: TimeRange
) {
  return new SceneFlexItem({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new SceneReactObject({
            component: Pattern,
            props: { pattern },
          }),
          maxWidth: '75%',
        }),
        getSparklineScene(query, datasourceUid, occurrences, increaseRate, timeRange),
      ],
    }),
    direction: 'row',
    height: '150px',
  });
}

function getSparklineScene(
  query: string,
  datasourceUid: string,
  occurrences: number,
  increaseRate: number,
  timeRange?: TimeRange
) {
  const data = new SceneQueryRunner({
    queries: [
      {
        refId: 'A',
        expr: query,
        queryType: 'range',
      },
    ],
    datasource: {
      type: 'loki',
      uid: datasourceUid,
    },
  });
  const formatIncreaseRate = (rate: number) => Number(rate.toFixed(1));
  const increaseRateString = increaseRate === -1 ? '🆕' : `↑${formatIncreaseRate(increaseRate)}x`;

  return new SceneFlexItem({
    body: PanelBuilders.stat()
      .setData(data)
      .setTitle(`${occurrences} occurrences`)
      .setOption('graphMode', BigValueGraphMode.Area)
      .setOption('textMode', BigValueTextMode.Name)
      .setOption('text', { valueSize: 40 })
      .setDisplayName(increaseRateString)
      .setOption('colorMode', BigValueColorMode.None)
      .setThresholds({ mode: ThresholdsMode.Absolute, steps: [{ value: 0, color: 'green' }] })
      .setHeaderActions(<ExploreButton queries={[query]} datasourceUid={datasourceUid} timeRange={timeRange} />)
      .build(),
    height: '150px',
    maxWidth: '25%',
  });
}

function getLogExamplesData(query: string, datasourceUid: string, maxLines = 5) {
  return new SceneQueryRunner({
    queries: [
      {
        refId: 'A',
        expr: query,
        maxLines,
      },
    ],
    datasource: {
      type: 'loki',
      uid: datasourceUid,
    },
  });
}

function getLogExamplesScene(query: string, datasourceUid: string, data: SceneQueryRunner, timeRange?: TimeRange) {
  return new SceneFlexItem({
    body: PanelBuilders.logs()
      .setData(data)
      .setDisplayMode('transparent')
      .setTitle('Log examples')
      .setOption('showCommonLabels', true)
      .setHeaderActions(<ExploreButton queries={[query]} datasourceUid={datasourceUid} timeRange={timeRange} />)
      .build(),
    height: '200px',
  });
}
