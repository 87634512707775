import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { DataSourceInstanceSettings } from '@grafana/data';
import { config } from '@grafana/runtime';

import { pipe } from 'fp-ts/lib/function';
import { none, some } from 'fp-ts/lib/Option';
import { filterMap } from 'fp-ts/lib/ReadonlyArray';

import { DEFAULT_MODEL } from 'consts';
import {
  ForecastCustomLabels,
  FullFormModel,
  Input,
  InvestigationFormModel,
  Job,
  Model,
  ModelId,
  NewInvestigation,
  NewMetricJob,
} from 'types';
import { timeUnitToSeconds } from 'utils';

/// Search for a model by id, permitting unspecified, in which case will chose the most reasonable default
export function findModel(models: readonly Model[], id?: ModelId | null): Model | undefined {
  const bestDefaultModel = (models ?? []).find((f) => DEFAULT_MODEL.test(f.id) && !f.deprecated);
  const searchId = id ?? bestDefaultModel?.id ?? 'grafana_prophet_1_0_1';
  return models.find((x) => x.id === searchId);
}

/// Convert the FE form into the structure expected by backend for creating a new job
export function createJobRequestFromFormModel(
  form: FullFormModel,
  datasource: DataSourceInstanceSettings
): NewMetricJob {
  const customLabels = form.labels?.reduce<ForecastCustomLabels>((acc, label) => {
    return { ...acc, [label.prop]: label.value };
  }, {});

  return {
    name: form.name,
    metric: form.metric,
    description: form.description,
    grafanaUrl: config.appUrl,
    datasourceId: datasource.id,
    datasourceUid: datasource.uid,
    datasourceType: datasource.type,
    queryParams: { ...form.query.value, refId: 'A', queryType: undefined },
    algorithm: form.parameters.id,
    hyperParams: form.parameters.hyperparameters?.[form.parameters.id],
    interval: timeUnitToSeconds(form.parameters.intervalValue, form.parameters.intervalUnit),
    trainingWindow: timeUnitToSeconds(form.parameters.trainingWindowValue, form.parameters.trainingWindowUnit),
    trainingFrequency: timeUnitToSeconds(form.parameters.trainingFrequencyValue, form.parameters.trainingFrequencyUnit),
    holidays: form.holidays,
    customLabels: customLabels,
  };
}

/// Convert the FE form into the structure expected by backend for editing an existing job
export function editJobRequestFromFormModel(
  id: string,
  form: FullFormModel,
  datasource: DataSourceInstanceSettings
): Job {
  const customLabels = form.labels?.reduce<ForecastCustomLabels>((acc, label) => {
    return { ...acc, [label.prop]: label.value };
  }, {});

  return {
    ...createJobRequestFromFormModel(form, datasource),
    id,
    customLabels,
  };
}

export function useSelectedModel(
  models: readonly Model[] = []
): [ModelId | null, Dispatch<SetStateAction<ModelId | null>>, Model | undefined] {
  const [selectedModelId, setSelectedModelId] = useState<ModelId | null>(null);
  const selectedModel = useMemo(() => findModel(models ?? [], selectedModelId), [models, selectedModelId]);
  return [(selectedModel?.id ?? null) as ModelId | null, setSelectedModelId, selectedModel];
}

/// @deprecated
export function useActiveModels(models: Model[] | undefined, selectedModelId: ModelId | null): readonly Model[] {
  return useMemo(
    () =>
      pipe(
        models ?? [],
        filterMap((model) =>
          model.deprecated && model.id !== selectedModelId
            ? none
            : some(
                model.deprecated
                  ? ({ ...model, name: `${model.name} - (${model.version} deprecated)` } as Model)
                  : model
              )
        )
      ),
    [models, selectedModelId]
  );
}

export function useModelSelectOptions(
  models: readonly Model[] | undefined,
  selectedModelId: ModelId | null | undefined
): ReadonlyArray<{ label: string; value: ModelId }> {
  return useMemo(
    () =>
      pipe(
        models ?? [],
        filterMap((model) =>
          model.deprecated && model.id !== selectedModelId
            ? none
            : some(
                model.deprecated
                  ? { label: `${model.name} - (${model.version} deprecated)`, value: model.id as ModelId }
                  : { label: model.name, value: model.id as ModelId }
              )
        )
      ),
    [models, selectedModelId]
  );
}

/// Convert the FE form into the structure expected by backend for creating a new investigation
export function createInvestigationRequestFromFormModel(form: InvestigationFormModel): NewInvestigation {
  const labelInputs: Input[] = form.labels.map(({ name, value, type }) => ({
    type: 'label',
    label: { name, value, type },
  }));
  const metricInputs: Input[] = form.queries.map((metric) => ({
    type: 'metric-query',
    metric,
  }));
  const serviceInputs: Input[] =
    form.services?.map((service) => ({
      type: 'service',
      service,
    })) ?? [];
  const datasourceInputs: Input[] =
    form.datasources?.map((datasource) => ({
      type: 'datasource',
      datasource,
    })) ?? [];
  return {
    name: form.name,
    inputs: [...labelInputs, ...metricInputs, ...serviceInputs, ...datasourceInputs],
    requestData: {
      start: form.start,
      end: form.end,
      labels: {},
      // We can use any hostname for the URL, as long as the query expression is in the `g0.expr` param.
      queryUrl:
        form.alertQuery !== undefined
          ? `https://grafana.com/?g0.expr=${encodeURIComponent(form.alertQuery)}`
          : undefined,

      investigationSource: form.investigationSource,
    },
    grafanaUrl: config.appUrl,
  };
}
