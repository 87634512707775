import { ExploreUrlState, RawTimeRange, toURLRange, urlUtil } from '@grafana/data';
import { DataQuery, DataSourceRef } from '@grafana/schema';

import _ from 'lodash';

import { DATASOURCE_NAME } from 'consts';

export async function getExploreUrl(
  panelTargets: DataQuery[],
  range: RawTimeRange,
  datasourceUid: string
): Promise<string> {
  const exploreTargets: DataQuery[] = panelTargets.map((t) => _.omit(t, 'legendFormat'));
  const datasource: DataSourceRef = { uid: datasourceUid };
  const state: Partial<ExploreUrlState> = {
    datasource: DATASOURCE_NAME,
    range: toURLRange(range),
    queries: exploreTargets.map((t) => ({ ...t, datasource: datasource })),
  };

  return urlUtil.renderUrl('/explore', { left: JSON.stringify(state) });
}
