import { LoadingState, PanelData } from '@grafana/data';

import { hasDuplicateSeriesLabels } from 'utils/utils.jobs';

interface ErrorMessage {
  message: string;
}
interface DataNotLoaded {
  kind: 'DataNotLoaded';
}
interface NoData {
  kind: 'NoData';
}

interface NonNumericData {
  kind: 'NonNumericData';
}

interface HasDuplicateSeries {
  kind: 'HasDuplicateSeries';
}
interface TooManySeries {
  kind: 'TooManySeries';
  limit: number;
  found: number;
}
interface InvalidQuery {
  kind: 'InvalidQuery';
}
export type ErrorReason = ErrorMessage &
  (DataNotLoaded | HasDuplicateSeries | NoData | NonNumericData | TooManySeries | InvalidQuery);

export function errorReason(data: PanelData | undefined, maxSeries: number): ErrorReason | null {
  if ((data?.series ?? []).every((x) => (x?.fields ?? []).length === 0)) {
    return { kind: 'NoData', message: 'No data returned' };
  } else if (data?.state !== LoadingState.Done) {
    return { kind: 'DataNotLoaded', message: 'Data not loaded' };
  } else if ((data?.series ?? []).every((x) => (x?.fields ?? []).every((y) => y.type !== 'number'))) {
    // at least 1 numeric field should be returned, otherwise non-metric Loki queries will be valid
    return { kind: 'NoData', message: 'No numeric data returned' };
  } else if (hasDuplicateSeriesLabels(data?.series ?? [])) {
    return { kind: 'HasDuplicateSeries', message: '' };
  } else if ((data?.series ?? []).length > maxSeries) {
    return {
      kind: 'TooManySeries',
      message: 'Too many series returned',
      limit: maxSeries,
      found: (data?.series ?? []).length,
    };
  } else if (!(data?.series ?? []).every((x) => (x?.fields ?? []).every((y) => ['number', 'time'].includes(y.type)))) {
    // if not every series are from type number or time, then it's not a valid metric
    // if datasource is Splunk, advices to use timechart instead of stats
    if (data?.request?.targets[0]?.datasource?.type === 'grafana-splunk-datasource') {
      return {
        kind: 'NonNumericData',
        message: 'Non-numeric data returned. For Splunk, we recommend using timechart.',
      };
    }
    return { kind: 'NonNumericData', message: 'Non-numeric data returned' };
  } else if (
    data?.request?.targets[0]?.datasource?.type === 'influxdb' &&
    data?.request?.targets[0].hasOwnProperty('query')
  ) {
    // Check that query has the required macros.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const influxQuery = data?.request?.targets[0]?.query as string;
    if (!isValidInfluxQuery(influxQuery)) {
      return {
        kind: 'InvalidQuery',
        message: invalidInfluxQueryMessage,
      };
    }
    return null;
  } else {
    return null;
  }
}

export function errorString(e: ErrorReason | null): string {
  if (e === null) {
    return 'unknown error';
  }
  switch (e.kind) {
    case 'TooManySeries':
      return `${e.message}: ${e.found} returned, limit is ${e.limit}`;
    default:
      return e.message;
  }
}

const invalidInfluxQueryMessage =
  'Not all required macros found, please use v.timeRangeStart and v.timeRangeStop for range and v.windowPeriod for time aggregation (if using Flux), or WHERE $__timeFilter for range and GROUP BY time($__interval) (for InfluxQL) (see https://grafana.com/docs/grafana/latest/datasources/influxdb/query-editor/#use-macros)';

// Influx queries can come in three forms, each with different requirements:
// 1. InfluxQL, the original query language, which has its own custom macros in Grafana's
//    InfluxDB datasource: $timeFilter, $interval, $__interval and $__interval_ms. See
//    https://github.com/grafana/grafana/blob/9203f84bc83a9f710749c47a20130ebd3a63f337/pkg/tsdb/influxdb/models/query.go#L39-L42
//    for where these macros are interpolated by the Grafana backend.
// 2. Flux, the second query language, which also has its own custom macros in Grafana:
//    v.timeRangeStart, v.timeRangeStop and v.windowPeriod. See
//    https://github.com/grafana/grafana/blob/9203f84bc83a9f710749c47a20130ebd3a63f337/pkg/tsdb/influxdb/flux/macros.go#L37-L42.
// 3. SQL, supported since Influx 3.0, which uses Grafana's standard SQL parsing macros:
//    $__timeFilter, $__interval, $__dateBin(<column>), $__dateBinAlias(<column>), etc.
//    These are covered in the [docs](https://grafana.com/docs/grafana/latest/datasources/influxdb/query-editor/#macros).
//
// We need to make sure that a reasonably correct looking combination of these are present in the
// query: in particular we need the user to be filtering using a time filter and grouping using
// the interval/window period.
//
// This may need to evolve even more over time, particularly for the SQL macros, so it's been split
// out into several functions.
function isValidInfluxQuery(query: string): boolean {
  return isValidInfluxQLQuery(query) || isValidFluxQuery(query) || isValidInfluxSQLQuery(query);
}

function isValidInfluxQLQuery(query: string): boolean {
  const hasTimeFilter = query.includes('$timeFilter');
  const hasGroupByInterval = query.match(/GROUP\s+BY\s+time\s*\(\s*\$__interval\s*\)/gm) !== null;
  return hasTimeFilter && hasGroupByInterval;
}

function isValidFluxQuery(query: string): boolean {
  const hasTimeRangeStart = query.includes('v.timeRangeStart');
  const hasTimeRangeStop = query.includes('v.timeRangeStop');
  const hasWindowPeriod = query.includes('v.windowPeriod');
  return hasTimeRangeStart && hasTimeRangeStop && hasWindowPeriod;
}

function isValidInfluxSQLQuery(query: string): boolean {
  const hasTimeFilter = query.includes('$__timeFilter');
  // We need data to be binned using time intervals. Accept either the `$__dateBin`
  // or `$__dateBinAlias` macros, or a custom `date_bin($__interval, ...)` field.
  const hasDateBinMacro = query.includes('$__dateBin');
  const hasCustomDateBin = query.includes('date_bin($__interval');

  return hasTimeFilter && (hasDateBinMacro || hasCustomDateBin);
}
