import { ComponentType, Dispatch, ReactNode, SetStateAction } from 'react';

import { DataSourceInstanceSettings, IconName, TimeRange } from '@grafana/data';

import { TabItem } from 'components/TabView/TabView';
import { DataQueryWithExpression, FullFormModel, Model, ModelId } from 'types';

export interface TabProps {
  datasource?: DataSourceInstanceSettings;
  onUpdateDatasource: (ds: DataSourceInstanceSettings) => void;
  onSave: (params: FullFormModel) => void;
  onRunQuery: (query: DataQueryWithExpression) => void;
  onQueryChange?: (query: DataQueryWithExpression) => void;
  setParamsValid: (valid: boolean) => void;
  setParamsDirty: (dirty: boolean) => void;
  selectedModelId: ModelId | null;
  models: readonly Model[];
  setSelectedModelId: Dispatch<SetStateAction<ModelId | null>>;
  timeRange: TimeRange;
  children?: { children?: ReactNode };
  defaultValues: FullFormModel;
  preSelectedHolidayIds?: string[];
  setSelectedHolidays?: (selectedHolidays: string[]) => void;
  holidays: string[];
}

/**
 * Return tab settings for the query panel in forecasting.
 */
export const getTabs = (
  QueryEditorTab: ComponentType<TabProps & { tabId: string }>,
  AlgorithmEditorTab: ComponentType<TabProps & { tabId: string }>,
  AttachHoliday: ComponentType<TabProps & { tabId: string }>
): Array<TabItem<TabProps>> => {
  const tabs: Array<{
    id: string;
    text: string;
    icon: IconName;
    component: ComponentType<TabProps & { tabId: string }>;
  }> = [
    {
      id: 'query',
      text: 'Query builder',
      icon: 'database',
      component: QueryEditorTab,
    },
    {
      id: 'algorithm',
      text: 'Training model',
      icon: 'calculator-alt',
      component: AlgorithmEditorTab,
    },
    {
      id: 'holiday',
      text: 'Holidays',
      icon: 'calendar-alt',
      component: AttachHoliday,
    },
  ];
  return tabs;
};
