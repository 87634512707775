import { FeatureDescription } from 'types';

export const FEATURES: FeatureDescription[] = [
  {
    name: 'AugursMSTL',
    description: 'Allow use of the Augurs MSTL algorithm.',
    defaultValue: false,
  },
  {
    name: 'ShowSiftModalButton',
    description: 'Adds a button to open sift resuts as modals, for testing purposes.',
    defaultValue: false,
  },
  {
    name: 'SIFTDev',
    description: 'Add SIFT development features.',
    defaultValue: false,
  },
  {
    name: 'SIFTExperimental',
    description: 'Allow access experimental SIFT checks.',
    defaultValue: false,
  },
  {
    name: 'SIFTDeprecated',
    description: 'Show deprecated SIFT check results.',
    defaultValue: true,
  },
  {
    name: 'SIFTDummy',
    description: 'Show SIFT dummy check results.',
    defaultValue: true,
  },
  {
    name: 'SIFTPreview',
    description: 'Show a preview for the Start Investigation modal.',
    defaultValue: false,
  },
];
