import React, { useMemo, useRef } from 'react';

import {
  applyFieldOverrides,
  createFieldConfigRegistry,
  EventBusExtended,
  EventBusSrv,
  FieldConfigSource,
  PanelData,
  TimeRange,
} from '@grafana/data';
import { SortOrder, TooltipDisplayMode } from '@grafana/schema';
import { LegendDisplayMode, LegendPlacement, useTheme2 } from '@grafana/ui';

import { OutlierResults } from 'api/types';
import { OutlierTimeSeriesPanel } from 'components/OutlierTimeSeriesPanel';
import { toTimeRange } from 'utils';

interface ViewOutlierGraphProps {
  data?: PanelData;
  onChangeTimeRange: (timeRange: TimeRange) => void;
  timeZone: string;
  width: number;
  selectedIndex?: number | undefined;
  outlierResults?: OutlierResults | undefined;
}

export function ViewOutlierGraph({
  data,
  onChangeTimeRange,
  timeZone,
  width,
  selectedIndex,
  outlierResults,
}: ViewOutlierGraphProps): JSX.Element | null {
  const options = {
    legend: {
      displayMode: LegendDisplayMode.Hidden,
      calcs: [],
      showLegend: false,
      placement: 'bottom' as LegendPlacement,
    },
    tooltip: {
      mode: TooltipDisplayMode.Single,
      sort: SortOrder.Ascending,
    },
  };

  const defaultFieldConfig = {
    defaults: {},
    overrides: [],
  };

  const dataWithOverrides = useFieldOverrides({ fieldConfig: defaultFieldConfig }, data, timeZone);
  if (dataWithOverrides == null) {
    return <div>No panel data</div>;
  }

  const appEvents: EventBusExtended = new EventBusSrv();

  return (
    <>
      <OutlierTimeSeriesPanel
        id={1}
        title="Outlier detector"
        width={width}
        height={300}
        data={dataWithOverrides}
        timeRange={dataWithOverrides.timeRange}
        timeZone={timeZone}
        transparent={false}
        renderCounter={0}
        replaceVariables={(str: string) => str}
        eventBus={appEvents}
        onOptionsChange={() => {}}
        onFieldConfigChange={() => null}
        onChangeTimeRange={(t) => onChangeTimeRange(toTimeRange(t, timeZone))}
        options={options}
        fieldConfig={defaultFieldConfig}
        selectedIndex={selectedIndex}
        outlierResults={outlierResults}
      />
    </>
  );
}

interface DefaultOptions {
  fieldConfig: FieldConfigSource;
}

// Below largely copied from Grafana version 9.1.0
function useFieldOverrides(
  defaultOptions: DefaultOptions | undefined,
  data: PanelData | undefined,
  timeZone: string
): PanelData | undefined {
  const fieldConfig = defaultOptions?.fieldConfig;
  const series = data?.series;
  const fieldConfigRegistry = createFieldConfigRegistry({}, 'outlier');
  const theme = useTheme2();
  const structureRev = useRef(0);

  return useMemo(() => {
    if (fieldConfigRegistry === undefined || fieldConfig === undefined || data === undefined) {
      return;
    }
    structureRev.current = structureRev.current + 1;

    // eslint-disable-next-line consistent-return
    return {
      ...data,
      series: applyFieldOverrides({
        data: series,
        fieldConfig,
        fieldConfigRegistry,
        replaceVariables: (str: string) => str,
        theme,
        timeZone,
      }),
      structureRev: structureRev.current,
    };
  }, [fieldConfigRegistry, fieldConfig, data, series, timeZone, theme]);
}
