import { getBackendSrv } from '@grafana/runtime';

import { lastValueFrom } from 'rxjs';

import { Alert, AlertGroup } from 'types';

export interface ApiKey {
  id: number;
  name: string;
  key: string;
  role: string;
}

export const getApiKeys = async (): Promise<ApiKey[]> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: `/api/auth/keys`,
      method: 'GET',
    })
  );

  return response.data as ApiKey[];
};

interface CreateApiKeyResult {
  name: string;
  key: string;
}

export const createApiKey = async (
  name: string,
  role: string,
  secondsToLive = 0
): Promise<CreateApiKeyResult | undefined> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: `/api/auth/keys`,
      method: 'POST',
      data: { name, role, secondsToLive },
    })
  );

  return response.data as CreateApiKeyResult | undefined;
};

export const deleteApiKey = async (id: number): Promise<void> => {
  await lastValueFrom(
    getBackendSrv().fetch({
      url: `/api/auth/keys/${id}`,
      method: `DELETE`,
    })
  );
};

interface CreateDatasourceRequest {
  name: string;
  url: string;
  authToken: string;
}

interface CreateDatasourceResult {
  id: number;
  name: string;
  message: string;
}

export const createDatasource = async ({
  name,
  url,
  authToken,
}: CreateDatasourceRequest): Promise<CreateDatasourceResult> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: `/api/datasources`,
      method: 'POST',
      data: {
        name,
        type: 'prometheus',
        url,
        access: 'proxy',
        jsonData: {
          httpHeaderName1: 'Authorization',
          httpMethod: 'POST',
          manageAlerts: false,
        },
        secureJsonData: {
          httpHeaderValue1: 'Bearer ' + authToken,
        },
      },
    })
  );

  return response.data as CreateDatasourceResult;
};

interface UpdateDatasourceRequest {
  id: number;
  name: string;
  url: string;
  authToken: string;
}

interface UpdateDatasourceResult {
  id: number;
  name: string;
  message: string;
}

export const updateDatasource = async ({
  id,
  name,
  url,
  authToken,
}: UpdateDatasourceRequest): Promise<UpdateDatasourceResult> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: `/api/datasources/${id}`,
      method: 'PUT',
      data: {
        id,
        name,
        type: 'prometheus',
        url,
        access: 'proxy',
        jsonData: {
          httpHeaderName1: 'Authorization',
          httpMethod: 'POST',
          manageAlerts: false,
        },
        secureJsonData: {
          httpHeaderValue1: 'Bearer ' + authToken,
        },
      },
    })
  );

  return response.data as UpdateDatasourceResult;
};

// The result of a request to fetch Grafana Alert rules.
interface GetAlertRulesResult {
  // The alert rules found for this request.
  Alerts?: Alert[];
}

// Get a list of Grafana Alert rules in the current Grafana instance.
//
// If an instance does not have Grafana Alerting enabled
// (i.e. [unified_alerting.enabled] = false, or `GF_UNIFIED_ALERTING_ENABLED=false`),
// the promise will throw an exception.
export const getAlertRules = async (): Promise<GetAlertRulesResult> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: '/api/ruler/grafana/api/v1/rules',
      method: 'GET',
      showErrorAlert: false,
      retry: 0,
    })
  );
  return response.data as GetAlertRulesResult;
};

// The contents of the 'data' field when fetching alerts.
interface GetAlertsData {
  /// The alert groups present.
  groups: AlertGroup[];
}

// The result of a request to fetch Grafana Alerts.
interface GetAlertsResult {
  // The status of this request.
  // Should be 'success' if successful.
  status: string;
  // The data for this request.
  data: GetAlertsData;
}

// Get a list of Grafana Alerts in the current Grafana instance.
//
// If an instance does not have Grafana Alerting enabled
// (i.e. [unified_alerting.enabled] = false, or `GF_UNIFIED_ALERTING_ENABLED=false`),
// the promise will throw an exception.
export const getAlerts = async (): Promise<GetAlertsResult> => {
  const response = await lastValueFrom(
    getBackendSrv().fetch({
      url: '/api/prometheus/grafana/api/v1/rules',
      method: 'GET',
      showErrorAlert: false,
      retry: 0,
    })
  );
  return response.data as GetAlertsResult;
};
