import { useCallback, useState } from 'react';

import { getTimeZone, TimeRange } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { TimeZone } from '@grafana/schema';

import { SerializableTimeRange } from 'types';
import { getZoomedTimeRange, toSerializableTimeRange, toTimeRange } from 'utils';

interface ChangeTimeRange {
  onChangeTimeRange: (range: TimeRange) => void;
  onZoomTimeRange: () => void;
  onChangeTimeZone: (timeZone: TimeZone) => void;
  timeZone: TimeZone;
  timeRange: TimeRange;
}

export function useTimeRange(defaultTimeRange: SerializableTimeRange): ChangeTimeRange {
  const [timeZone, setTimeZone] = useState<TimeZone>(() => getTimeZone());
  const [timeRange, setTimeRange] = useState<TimeRange>(toTimeRange(defaultTimeRange, timeZone));

  const onChangeTimeRange = useCallback(
    (newTimeRange: TimeRange) => {
      const serializable = toSerializableTimeRange(newTimeRange, timeZone);
      updateQueryParams(serializable);
      setTimeRange(newTimeRange);
    },
    [setTimeRange, timeZone]
  );

  const onZoomTimeRange = useCallback(() => {
    onChangeTimeRange(getZoomedTimeRange(timeRange, 2));
  }, [onChangeTimeRange, timeRange]);

  return {
    timeZone,
    timeRange,
    onZoomTimeRange,
    onChangeTimeRange,
    onChangeTimeZone: setTimeZone,
  };
}

function updateQueryParams(timeRange: SerializableTimeRange) {
  const current = locationService.getSearchObject();
  locationService.partial({ ...current, ...timeRange }, true);
}
