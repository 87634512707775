import React from 'react';

import { TabProps } from '../../utils/tabs';
import { DatasourceQueryEditor } from '../Outliers/DatasourceQueryEditor';

export function QueryEditorTab({
  datasource,
  onUpdateDatasource,
  onRunQuery,
  onQueryChange,
  timeRange,
}: TabProps): JSX.Element {
  return (
    <DatasourceQueryEditor
      datasource={datasource}
      onUpdateDatasource={onUpdateDatasource}
      onRunQuery={onRunQuery}
      onQueryChange={onQueryChange}
      timeRange={timeRange}
    />
  );
}
