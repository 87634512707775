import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useAsync, useAsyncFn, useLocalStorage } from 'react-use';

import { llms } from '@grafana/experimental';
import { HorizontalGroup, VerticalGroup } from '@grafana/ui';

import { finalize } from 'rxjs';

import { Explanation } from './Explanation';
import { ExplanationButton } from './ExplanationButton';

function useStoredExplanation(
  key: string
): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>, () => void] {
  const localStorageKey = `grafana.ml.sift.investigation.${key}`;
  return useLocalStorage(localStorageKey, '');
}

interface LLMPanelProps {
  systemMessage: string;
  userMessage: string;
  storageKey: string;
  children: ReactNode;
}

export function LLMPanel({ systemMessage, userMessage, storageKey, children }: LLMPanelProps) {
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [storedExplanation, setStoredExplanation] = useStoredExplanation(storageKey);
  const [explanation, setExplanation] = useState(storedExplanation ?? '');

  const enabledCheck = useAsync(async () => {
    // Check if the LLM plugin is enabled and configured.
    // If not, we won't be able to make requests, so return early.
    return (await llms.openai.enabled()) ?? false;
  });
  const enabled = enabledCheck.value ?? false;

  // We only want to update this once when the explanation finishes generating, not
  // continuously as it's streamed in, so ignore this lint.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setStoredExplanation(explanation), [generating, setStoredExplanation]);

  const [llmState, generateExplanation] = useAsyncFn(async (user_message: string, system_message: string) => {
    setGenerating(true);
    setError(undefined);
    const stream = llms.openai
      .streamChatCompletions({
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: system_message,
          },
          {
            role: 'user',
            content: user_message,
          },
        ],
        temperature: 0,
      })
      .pipe(
        llms.openai.accumulateContent(),
        finalize(() => setGenerating(false))
      );
    // This lint seems to be spurious since this signature hasn't been
    // deprecated.
    // eslint-disable-next-line deprecation/deprecation
    return stream.subscribe({
      next: setExplanation,
      error: (e) => {
        setError(e);
      },
    });
  });

  const generateExplanationMemoized = useCallback(
    () => generateExplanation(userMessage, systemMessage),
    [generateExplanation, userMessage, systemMessage]
  );

  const loading = llmState.loading || userMessage === '';

  return (
    <VerticalGroup>
      {children}
      <HorizontalGroup>
        <ExplanationButton
          enabled={enabled}
          error={error}
          explanation={explanation}
          generateExplanation={generateExplanationMemoized}
          generating={generating}
          loading={loading}
        />
        <Explanation explanation={explanation} />
      </HorizontalGroup>
    </VerticalGroup>
  );
}
