import React from 'react';
import { useNavigate } from 'react-router-dom';

import { GrafanaTheme2, TimeRange } from '@grafana/data';
import { PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { PLUGIN_ROOT } from 'consts';
import { useConfirmModal } from 'hooks';
import { onHandler } from 'utils';

interface OutlierToolbarProps {
  hasDataSource?: boolean;
  isRefreshing?: boolean;
  timeRange?: TimeRange;
  timeZone?: string;
  isDirty?: boolean;
  isEdit?: boolean;
  isValid?: boolean;
  onRefresh?: () => void;
  onChangeTimeRange?: (timeRange: TimeRange) => void;
  onChangeTimeZone?: (timeZone: string) => void;
  onOpenSavePanel?: () => void;
  onZoomTimeRange?: () => void;
}

export function OutlierToolbar({
  hasDataSource = false,
  isDirty = false,
  isEdit = false,
  isRefreshing = false,
  isValid = false,
  timeRange,
  timeZone,
  onRefresh,
  onChangeTimeRange,
  onChangeTimeZone,
  onOpenSavePanel,
  onZoomTimeRange,
}: OutlierToolbarProps): JSX.Element | null {
  const navigate = useNavigate();
  const styles = useStyles2(getStyles);

  const [confirmAbandon, ConfirmAbandon] = useConfirmModal(
    async () => {
      navigate(`${PLUGIN_ROOT}/outlier-detector`, { replace: true });
    },
    { skipConfirm: isDirty }
  );

  if (!hasDataSource) {
    return (
      <div className={styles.toolbarContainer} data-element="outlier-buttons">
        <PageToolbar className={styles.pageToolbar}>
          <ToolbarButton
            id="Save"
            disabled={true}
            variant="primary"
            title="Create an outlier detector and return to the main screen"
          >
            Save
          </ToolbarButton>
          <ToolbarButton id="Cancel" disabled={true} title="Undo all changes">
            Cancel
          </ToolbarButton>
        </PageToolbar>
      </div>
    );
  }

  return (
    <div className={styles.toolbarContainer} data-element="outlier-buttons">
      <ConfirmAbandon icon="exclamation-triangle" title="Unsaved changes" actionText="Yes">
        <div>Are you sure you want to discard changes to this outlier detector?</div>
      </ConfirmAbandon>
      <PageToolbar className={styles.pageToolbar}>
        <ToolbarTimeRange
          timeRange={timeRange!}
          isRefreshing={isRefreshing}
          timeZone={timeZone!}
          onRefresh={onRefresh!}
          onChangeTimeRange={onChangeTimeRange!}
          onChangeTimeZone={onChangeTimeZone!}
          onZoomTimeRange={onZoomTimeRange!}
        />
        <ToolbarButton id="Cancel" onClick={onHandler(confirmAbandon)} title="Undo all changes">
          Cancel
        </ToolbarButton>
        <ToolbarButton
          disabled={!isValid || (isEdit && !isDirty)}
          variant="primary"
          title={`${isEdit ? 'Update an existing' : 'Create an'} outlier detector and return to the main screen`}
          onClick={onOpenSavePanel}
        >
          Save
        </ToolbarButton>
      </PageToolbar>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    toolbarContainer: css`
      width: 100%;
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: theme.spacing(2),
    }),
  };
}
