import React, { ReactElement } from 'react';

import { Alert } from '@grafana/ui';

import { isBackendError } from './guards';

type Props = {
  title: string;
  error: unknown;
};

export function ErrorAlert(props: Props): ReactElement {
  const { error, title } = props;

  if (isBackendError(error)) {
    return (
      <Alert title={title} severity={error.data.status}>
        {error.data.message}
      </Alert>
    );
  }
  return (
    <Alert title={title} severity="error">
      {String(error ?? 'Could not create forecast due to an unexpected error')}
    </Alert>
  );
}
