import React from 'react';

import { GrafanaTheme, LoadingState } from '@grafana/data';
import { PanelRenderer } from '@grafana/runtime';
import { LegendDisplayMode, PanelChrome, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

import Card from 'components/Card';
import { toTimeRange } from 'utils';
import { createFieldOverrides, getJobSeriesInfo, hasDuplicateSeriesLabels } from 'utils/utils.jobs';
import { noop } from 'utils/utils.noop';

import { JobGraphContentProps } from './JobGraph.types';

export const JobGraphContent: React.FC<JobGraphContentProps> = ({
  job,
  width,
  height,
  data,
  onCancelQuery,
  isRefreshing,
  onChangeTimeRange,
  timeZone,
}) => {
  const styles = useStyles(getStyles);
  const isEmpty = (data?.series ?? []).every((x) => (x?.fields ?? []).length === 0);

  if (data == null || data.state === LoadingState.Loading) {
    return (
      <div className={`panel-empty ${styles.panelEmpty}`}>
        <p>Loading...</p>
      </div>
    );
  }

  if (data.state === LoadingState.Error) {
    return (
      <div className={`panel-empty ${styles.panelEmpty}`}>
        <div
          className={css`
            margin: auto;
            width: 100%;
            text-align: center;
          `}
        >
          <h2>Error</h2>
          <p>{data.errors?.[0].message ?? '"An unknown error occurred."'}</p>
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={`panel-empty ${styles.panelEmpty}`}>
        <Card
          height="elevated"
          className={css`
            margin: auto;
            width: 300px;
            padding: 22px;
            text-align: center;
            p {
              margin-bottom: 0;
            }
          `}
        >
          <h3>No data for query</h3>
          <p>Specify and refine it in the query editor below</p>
        </Card>
      </div>
    );
  }

  if (data.state === LoadingState.Done && hasDuplicateSeriesLabels(data?.series)) {
    return (
      <div className={`panel-empty ${styles.panelEmpty}`}>
        <p>
          Query contains series with duplicated label sets. Please ensure each series returned by your query has
          distinct labels.
        </p>
      </div>
    );
  }

  const fieldConfig = {
    defaults: {
      custom: {
        spanNulls: true,
      },
    },
    overrides: getJobSeriesInfo(data).flatMap(createFieldOverrides),
  };

  return (
    <PanelChrome
      width={width}
      height={height}
      actions={[
        <PanelChrome.LoadingIndicator loading={isRefreshing} onCancel={onCancelQuery} key="loading-indicator" />,
      ]}
    >
      {(innerWidth, innerHeight) => (
        <PanelRenderer
          title={job?.name ?? ''}
          pluginId="timeseries"
          onOptionsChange={noop}
          onChangeTimeRange={(t) => onChangeTimeRange(toTimeRange(t, timeZone))}
          width={innerWidth}
          height={innerHeight}
          timeZone={timeZone}
          data={data}
          options={panelOptions}
          fieldConfig={fieldConfig}
        />
      )}
    </PanelChrome>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  panelEmpty: css`
    background-color: ${theme.colors.panelBg};
  `,
});

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'single',
  },
};
