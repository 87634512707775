import React, { PropsWithChildren } from 'react';

import { Spinner } from '@grafana/ui';

import { InitializeApp } from './InitializeApp';
import { usePluginMeta } from './usePluginMeta';

interface Props {
  onDismissInitialize?: () => void;
}

export function EnsureInitialized({ children, onDismissInitialize }: PropsWithChildren<Props>): JSX.Element {
  const { loading, error, value: meta } = usePluginMeta();
  const initialized = meta?.jsonData?.initialized === true;
  if (loading) {
    return <Spinner />;
  }
  if (error || !initialized) {
    return <InitializeApp onDismiss={onDismissInitialize} />;
  }
  return <>{children}</>;
}

export type PropsWithOnDismissInitialized<T> = T & Props;
