import useAsync, { AsyncState } from 'react-use/lib/useAsync';

import {
  ConstantVariableModel,
  CustomVariableModel,
  PluginExtensionPanelContext,
  QueryVariableModel,
  ScopedVars,
  TextBoxVariableModel,
  TypedVariableModel,
} from '@grafana/data';
import { getDataSourceSrv, getTemplateSrv } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { cluesToInputs } from 'api';
import {
  PluginExtensionAlertInstanceContext,
  PluginExtensionAppO11yServiceContext,
  PluginExtensionContextWrapper,
  PluginExtensionExploreContext,
  PluginExtensionK8sClusterContext,
  PluginExtensionK8sNamespaceContext,
  PluginExtensionK8sPodContext,
  PluginExtensionK8sWorkloadContext,
  PluginExtensionOnCallAlertGroupContext,
  PluginExtensionSiftPanelContext,
} from 'extensions/types';
import {
  isAlertingContextWrapper,
  isAppO11yOperationContextWrapper,
  isAppO11yServiceContextWrapper,
  isExploreContextWrapper,
  isK8sClusterContextWrapper,
  isK8sNamespaceContextWrapper,
  isK8sPodContextWrapper,
  isK8sWorkloadContextWrapper,
  isOnCallAlertGroupContextWrapper,
  isPanelContextWrapper,
  isSiftPanelContextWrapper,
} from 'extensions/utils';
import { Clue, Label, LabelInput, MatchType, OnCallAlertGroup, Input as SiftInput } from 'types';

async function cluesFromTargets(targets: DataQuery[], scopedVars?: ScopedVars): Promise<Clue[]> {
  if (targets.length === 0) {
    return [];
  }
  const [firstQuery] = targets;
  const ds = await getDataSourceSrv().get(firstQuery.datasource);
  // Interpolate template variables, if required.
  const queries =
    ds.interpolateVariablesInQueries !== undefined
      ? ds.interpolateVariablesInQueries(targets, scopedVars ?? {})
      : targets;
  return queries.map((payload: DataQuery) => ({ type: 'grafana-query', payload }));
}

async function exploreInputs(context: PluginExtensionExploreContext): Promise<SiftInput[]> {
  const { targets, timeRange } = context;
  const clues = await cluesFromTargets(targets);
  return [...(await cluesToInputs({ clues })), { type: 'time-range', timeRange }];
}

// The placeholder value used for an 'All' multi-variable.
const ALL_VALUE = '$__all';
// Some variables types aren't useful (e.g. datasource, although we may want that as an input later).
const USEFUL_VARIABLE_TYPES: Readonly<Array<TypedVariableModel['type']>> = [
  'constant',
  'custom',
  'query',
  'textbox',
] as const;
const KNOWN_VARIABLES = ['cluster', 'namespace'];
type UsefulVariable = ConstantVariableModel | CustomVariableModel | QueryVariableModel | TextBoxVariableModel;
function isUsefulVariable(variable: TypedVariableModel): variable is UsefulVariable {
  return USEFUL_VARIABLE_TYPES.includes(variable.type);
}

// Attempt to extract a single label filter of type `MatchEqual` from a variable.
// This looks at all variables with type 'adhoc' or with any type in `USEFUL_VARIABLE_TYPES`,
// checks whether the id of the variable is in `KNOWN_VARIABLES`, and makes sure
// there's either only one _possible_ value for the variable, or only one _selected_
// value (if there is more than one option).
function extractLabel(variable: TypedVariableModel): Label | undefined {
  if (variable.type === 'adhoc') {
    for (const filter of variable.filters) {
      if (KNOWN_VARIABLES.includes(filter.key)) {
        return { name: filter.key, value: filter.value, type: MatchType.Equal };
      }
    }
  }
  if (isUsefulVariable(variable) && KNOWN_VARIABLES.includes(variable.id)) {
    const { current, options } = variable;
    // If the variable only has one non-All option we can just select it, even if 'All'
    // is selected.
    if (options.length === 1 || (options.length === 2 && options.find((v) => v.value === ALL_VALUE))) {
      const firstNotAll = options.find((v) => v.value !== ALL_VALUE);
      if (firstNotAll !== undefined) {
        return {
          name: variable.id,
          value: typeof firstNotAll.value === 'string' ? firstNotAll.value : firstNotAll.value[0],
          type: MatchType.Equal,
        };
      }
    }
    // Otherwise let's look at the currently selected value and return it, as long as it's not
    // 'All'
    if (
      (typeof current.value === 'string' && current.value !== ALL_VALUE) ||
      (current.value.length === 1 && current.value[0] !== '$__all')
    ) {
      return {
        name: variable.id,
        value: typeof current.value === 'string' ? current.value : current.value[0],
        type: MatchType.Equal,
      };
    }
  }
  return undefined;
}

// Get inputs representing the template variables from a dashboard.
//
// For now this will only include variables that we know are very important:
// `cluster` and `namespace`.
function variableInputs(variables: TypedVariableModel[]): SiftInput[] {
  const inputs: SiftInput[] = [];
  for (const v of variables) {
    const label = extractLabel(v);
    if (label !== undefined) {
      inputs.push({ type: 'label', label });
    }
  }
  return inputs;
}

// Given a set of inputs, return all of the non-label inputs, and a deduplicated
// set of the label inputs.
//
// Deduplication works by choosing the 'most useful' label per name based on the match type,
// where usefulness is ranked as follows from least to most useful:
// - NotRegexp
// - NotEqual
// - Regexp
// - Equal
// If there are multiple inputs for a given label name with the same usefulness,
// the first one is kept.
function dedupeLabels(inputs: SiftInput[]): SiftInput[] {
  const nonLabelInputs = inputs.filter((x) => x.type !== 'label');
  const labelMap: Map<string, SiftInput> = inputs
    .filter((x): x is LabelInput => x.type === 'label')
    .reduce((acc, input) => {
      const existing = acc.get(input.label.name);
      if (
        // If this is the first time we've seen the label then we just want to use it.
        existing === undefined ||
        // Or if the new label is more useful than the existing one, overwrite it.
        (existing.label.type === MatchType.NotRegexp && input.label.type !== MatchType.NotRegexp) ||
        (existing.label.type === MatchType.NotEqual &&
          (input.label.type === MatchType.Regexp || input.label.type === MatchType.Equal)) ||
        (existing.label.type === MatchType.Regexp && input.label.type === MatchType.Equal)
      ) {
        acc.set(input.label.name, input);
      }
      return acc;
    }, new Map<string, LabelInput>());
  return [...labelMap.values(), ...nonLabelInputs];
}

async function panelInputs(context: PluginExtensionPanelContext): Promise<SiftInput[]> {
  const { scopedVars, targets, timeRange } = context;
  const clues = await cluesFromTargets(targets, scopedVars);
  const varInputs = variableInputs(getTemplateSrv().getVariables());
  return dedupeLabels([...(await cluesToInputs({ clues })), ...varInputs, { type: 'time-range', timeRange }]);
}

function alertingInputs(context: PluginExtensionAlertInstanceContext): Promise<SiftInput[]> {
  return cluesToInputs({ clues: [{ type: 'alert', payload: context.instance }] });
}

function onCallAlertGroupInputs({ alertGroup }: PluginExtensionOnCallAlertGroupContext): Promise<SiftInput[]> {
  const alerts = [];
  for (const alert of alertGroup.alerts ?? []) {
    alerts.push({ id: alert.id });
  }
  const payload: OnCallAlertGroup = {
    pk: alertGroup.pk,
    alerts,
    alert_receive_channel: {
      integration: alertGroup.alert_receive_channel.integration,
    },
    render_for_web: {
      title: alertGroup.render_for_web.title,
    },
  };
  return cluesToInputs({ clues: [{ type: 'on-call-alert-group', payload }] });
}

function k8sDatasourceInputs(datasources: PluginExtensionK8sPodContext['datasources']): SiftInput[] {
  if (datasources === undefined) {
    return [];
  }
  const { prometheus, loki } = datasources;
  return [
    { type: 'datasource', datasource: { uid: prometheus.uid, type: 'prometheus' } },
    { type: 'datasource', datasource: { uid: loki.uid, type: 'loki' } },
  ];
}

function k8sClusterInputs({ timeRange, cluster, datasources }: PluginExtensionK8sClusterContext): SiftInput[] {
  return [
    { type: 'time-range', timeRange },
    { type: 'label', label: { name: 'cluster', value: cluster, type: MatchType.Equal } },
    ...k8sDatasourceInputs(datasources),
  ];
}

function k8sNamespaceInputs({
  timeRange,
  cluster,
  namespace,
  datasources,
}: PluginExtensionK8sNamespaceContext): SiftInput[] {
  return [
    { type: 'time-range', timeRange },
    { type: 'label', label: { name: 'cluster', value: cluster, type: MatchType.Equal } },
    { type: 'label', label: { name: 'namespace', value: namespace, type: MatchType.Equal } },
    ...k8sDatasourceInputs(datasources),
  ];
}

function k8sWorkloadInputs({
  timeRange,
  cluster,
  namespace,
  workload,
  datasources,
}: PluginExtensionK8sWorkloadContext): SiftInput[] {
  return [
    { type: 'time-range', timeRange },
    { type: 'label', label: { name: 'cluster', value: cluster, type: MatchType.Equal } },
    { type: 'label', label: { name: 'namespace', value: namespace, type: MatchType.Equal } },
    { type: 'label', label: { name: 'pod', value: `${workload}-.+`, type: MatchType.Regexp } },
    ...k8sDatasourceInputs(datasources),
  ];
}

function k8sPodInputs({ timeRange, cluster, namespace, pod, datasources }: PluginExtensionK8sPodContext): SiftInput[] {
  return [
    { type: 'time-range', timeRange },
    { type: 'label', label: { name: 'cluster', value: cluster, type: MatchType.Equal } },
    { type: 'label', label: { name: 'namespace', value: namespace, type: MatchType.Equal } },
    { type: 'label', label: { name: 'pod', value: pod, type: MatchType.Equal } },
    ...k8sDatasourceInputs(datasources),
  ];
}

function siftPanelInputs({ timeRange, labels }: PluginExtensionSiftPanelContext): SiftInput[] {
  const labelInputs: LabelInput[] = labels.map((label) => ({ type: 'label', label }));
  return [{ type: 'time-range', timeRange: timeRange.raw }, ...labelInputs];
}

const KNOWN_K8S_ATTRIBUTES: Record<string, string> = {
  'k8s.cluster.name': 'cluster',
  'k8s.namespace.name': 'namespace',
};

function findDataSourceUID({ name, type }: { name: string; type: string }): string | undefined {
  return getDataSourceSrv()
    .getList({ type })
    .find((ds) => ds.name === name)?.uid;
}

function appO11yServiceInputs({
  timeRange,
  service: { name, namespace },
  datasources: { prometheus, loki, tempo },
  k8s,
}: PluginExtensionAppO11yServiceContext): SiftInput[] {
  const inputs: SiftInput[] = [
    { type: 'time-range', timeRange },
    { type: 'service', service: { name, namespace } },
  ];

  // Add some special handling for namespace.
  // Ideally it would be included in the k8s attributes; this is more likely to be
  // useful to Sift than the service namespace (on the off chance they're different).
  // If we don't find it in the k8s attributes, and the service namespace is defined,
  // use it as a fallback.
  let foundNamespace = false;

  if (k8s !== undefined) {
    const k8sAttributes: Record<string, Set<string>> = k8s.reduce((acc, obj) => {
      for (const [key, value] of Object.entries(obj)) {
        const current = acc[key] ?? new Set();
        current.add(value);
        acc[key] = current;
      }
      return acc;
    }, {} as Record<string, Set<string>>);
    for (const [key, values] of Object.entries(k8sAttributes)) {
      const labelName = KNOWN_K8S_ATTRIBUTES[key];
      // For now, only add labels that have a single value.
      // In the future we may want to use a regexp matcher if there are multiple.
      if (labelName !== undefined && values.size === 1) {
        const [value] = [...values];
        inputs.push({ type: 'label', label: { name: labelName, value, type: MatchType.Equal } });
        foundNamespace = foundNamespace || labelName === 'namespace';
      }
    }
  }

  if (!foundNamespace && namespace !== undefined) {
    inputs.push({ type: 'label', label: { name: 'namespace', value: namespace, type: MatchType.Equal } });
  }

  if (prometheus) {
    const uid = findDataSourceUID({ name: prometheus.name, type: 'prometheus' });
    if (uid !== undefined) {
      inputs.push({ type: 'datasource', datasource: { uid } });
    }
  }
  if (loki) {
    const uid = findDataSourceUID({ name: loki.name, type: 'loki' });
    if (uid !== undefined) {
      inputs.push({ type: 'datasource', datasource: { uid } });
    }
  }
  if (tempo) {
    const uid = findDataSourceUID({ name: tempo.name, type: 'tempo' });
    if (uid !== undefined) {
      inputs.push({ type: 'datasource', datasource: { uid } });
    }
  }
  return inputs;
}

export function useInputs(wrapper?: PluginExtensionContextWrapper): AsyncState<SiftInput[]> {
  return useAsync(async () => {
    if (wrapper === undefined) {
      return [];
    }
    if (isExploreContextWrapper(wrapper)) {
      return exploreInputs(wrapper.context);
    }
    if (isPanelContextWrapper(wrapper)) {
      return panelInputs(wrapper.context);
    }
    if (isAlertingContextWrapper(wrapper)) {
      return alertingInputs(wrapper.context);
    }
    if (isOnCallAlertGroupContextWrapper(wrapper)) {
      return onCallAlertGroupInputs(wrapper.context);
    }
    if (isK8sClusterContextWrapper(wrapper)) {
      return k8sClusterInputs(wrapper.context);
    }
    if (isK8sNamespaceContextWrapper(wrapper)) {
      return k8sNamespaceInputs(wrapper.context);
    }
    if (isK8sWorkloadContextWrapper(wrapper)) {
      return k8sWorkloadInputs(wrapper.context);
    }
    if (isK8sPodContextWrapper(wrapper)) {
      return k8sPodInputs(wrapper.context);
    }
    if (isSiftPanelContextWrapper(wrapper)) {
      return siftPanelInputs(wrapper.context);
    }
    if (isAppO11yServiceContextWrapper(wrapper) || isAppO11yOperationContextWrapper(wrapper)) {
      return appO11yServiceInputs(wrapper.context);
    }
    return [];
  });
}
