import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

import { PLUGIN_ROOT } from 'consts';

export default function Features({
  variant = 'wide',
  enableActions = true,
  children,
}: {
  variant?: 'tall' | 'wide';
  enableActions?: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  const { featureBox, featureText, featureButton, wrapper, featuresAside, wide, tall, buttonStyle } =
    useStyles2(getStyles);
  return (
    <div className={wrapper}>
      <aside className={cx(variant === 'wide' ? wide : tall, featuresAside)}>
        <div id="learn" className={featureBox}>
          <div className={featureText}>
            <h2>Metric forecasts</h2>
            <p>Predict current and future values for timeseries based on historical data and holiday seasons</p>
          </div>
          <div className={featureButton}>
            {enableActions && (
              <LinkButton className={buttonStyle} size="md" variant="primary" href={`${PLUGIN_ROOT}/metric-forecast`}>
                View forecasts
              </LinkButton>
            )}
          </div>
        </div>
        <div id="create" className={featureBox}>
          <div className={featureText}>
            <h2>Outlier detection</h2>
            <p>Detect when some members of a group are behaving differently from the rest</p>
          </div>
          <div className={featureButton}>
            {enableActions && (
              <LinkButton className={buttonStyle} size="md" variant="primary" href={`${PLUGIN_ROOT}/outlier-detector`}>
                View outliers
              </LinkButton>
            )}
          </div>
        </div>
        <div id="sift" className={featureBox}>
          <div className={featureText}>
            <h2>Sift investigations</h2>
            <p>Run checks to find unusual system activity</p>
          </div>
          <div className={featureButton}>
            {enableActions && (
              <LinkButton className={buttonStyle} size="md" variant="primary" href={`${PLUGIN_ROOT}/investigations`}>
                View investigations
              </LinkButton>
            )}
          </div>
        </div>
        {children != null ? <div id="action">{children}</div> : null}
      </aside>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    wide: css`
      grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-gap: 8px;
      @media only screen and (min-width: 840px) {
        grid-gap: 12px;
      }
    `,
    tall: css`
      grid-template-rows: repeat(auto-fit);
      grid-gap: 20px;
      @media only screen and (min-width: 840px) {
        grid-gap: 40px;
      }
    `,

    wrapper: css`
      padding: 10px;
      @media only screen and (min-width: 780px) {
        padding: 28px;
      }
      @media only screen and (min-width: 1140px) {
        padding: 35px;
      }
      a.blueLink {
        color: ${theme.colors.text.link};
      }
    `,

    featureBox: css`
      min-height: 150px;
      margin: 0px 20px;
      display: grid !important;
      grid-template-colums: 40px 1fr;
      grid-template-rows: 1fr 40px;
    `,

    featureIcon: css`
      grid-column: 1/2;
      grid-row: 1;
    `,

    featureText: css`
      grid-column: 2/2;
      grid-row: 1;
      align-self: start;
      h2 {
        font-size: 17px;
        font-weight: 600;
        margin: 0 0 4px 0;
      }
    `,

    featureButton: css`
      grid-column: 2/2;
      grid-row: 2;
    `,

    featuresAside: css`
      display: grid;
      grid-auto-flow: dense;

      h2 {
        font-size: 17px;
        margin: 0 0 4px 0;
      }
      p {
        margin: 0;
        line-height: 1.2;
      }
      > div {
        align-self: center;
        justify-self: start;
        display: flex;
        gap: 7px;
        @media only screen and (min-width: 840px) {
          gap: 12px;
        }
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > svg {
          width: 40px;
          min-width: 25px;
          max-width: 30px;
          @media only screen and (min-width: 840px) {
            width: 40px;
            min-width: 30px;
            max-width: 55px;
          }
          @media only screen and (min-width: 1240px) {
            width: 65px;
          }
          color: #ff8833;
          filter: drop-shadow(2px 4px 6px ${theme.isDark ? 'rgb(19 2 43 / 76%)' : 'rgb(127 131 171 / 30%)'});
        }
      }

      > #learn {
      }

      > #create {
      }

      > #alert {
      }

      > #action {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: end;
        gap: 12px;
        align-items: center;
      }
    `,
    buttonStyle: css`
      width: 180px;
    `,
  };
}
