import React, { useCallback, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, LinkButton, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { flow, pipe } from 'fp-ts/lib/function';
import { contramap, reverse as reverseOrd } from 'fp-ts/lib/Ord';
import { sortBy } from 'fp-ts/lib/ReadonlyArray';
import { groupBy } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import * as N from 'fp-ts/number';
import { map as rMap } from 'fp-ts/Record';
import { DateTime, Duration } from 'luxon';

import { SiftModalData } from 'types';
import { getTempoExploreUrl } from 'utils/utils.url';

interface Trace {
  traceID: string;
  rootServiceName: string;
  rootTraceName: string;
  startTimeUnixNano: number;
  durationMs: number;
}

interface Traces {
  traces: Trace[];
}

interface TraceGrouping {
  success: boolean;
  error: null | { message: string };
  data: Traces;
}

const ordByDuration = pipe(
  N.Ord,
  contramap((trace: Trace) => trace.durationMs),
  reverseOrd
);

const ordByStartTime = pipe(
  N.Ord,
  contramap((trace: Trace) => trace.startTimeUnixNano)
);

const getTracesByService = flow(
  (d: Traces) => d.traces,
  groupBy((trace) => trace.rootServiceName ?? '~ no service name ~')
);

const getTracesByOperationName = flow(
  (d: Traces) => d.traces,
  groupBy((trace) => trace.rootTraceName ?? '~ no operation name ~')
);

function SlowRequests({ analysis, investigation, datasources }: SiftModalData): React.ReactElement {
  const [serviceOrOperation, setServiceOrOperation] = useState<'service' | 'operation'>('service');
  const [ordBy, setOrdBy] = useState<'duration' | 'start'>('duration');
  const [expanded, setExpanded] = useState(new Set<string>());
  const handleToggle = useCallback(
    (toToggle: string) => () => {
      setExpanded((existing) => (existing.delete(toToggle) ? new Set(existing) : new Set(existing.add(toToggle))));
    },
    []
  );
  const styles = useStyles2(getStyles);
  const details = useMemo(() => {
    let result: TraceGrouping = { success: false, error: null, data: { traces: [] } };
    try {
      result.success = true;
      result.data = (analysis.result?.details as unknown as Traces) ?? { traces: [] };
    } catch (e: any) {
      result.success = false;
      result.data = { traces: [] };
      result.error = { message: e.message };
    }
    return result;
  }, [analysis.result?.details]);

  const tracesBySelected = useMemo(
    () =>
      pipe(
        details.success
          ? serviceOrOperation === 'service'
            ? getTracesByService(details.data)
            : getTracesByOperationName(details.data)
          : {},
        rMap(sortBy<Trace>([ordBy === 'duration' ? ordByDuration : ordByStartTime]))
      ),
    [details, serviceOrOperation, ordBy]
  );

  if (!analysis.result.successful) {
    return <div>Error running analysis: {analysis.result.message}</div>;
  }

  if (!analysis.result.interesting || !details.success || details.data.traces.length === 0) {
    if (!details.success) {
      console.error('error parsing upstream: ', details.error);
    }
    return <div>No Slow Traces Found</div>;
  }

  return (
    <div>
      <div
        className={css`
          margin: 0 0 12px 0;
          display: flex;
          flex-flow: row nowrap;
          gap: 8px;
          align-items: center;
        `}
      >
        <div>Group by</div>
        <RadioButtonGroup
          size="md"
          options={[
            { label: 'Service', value: 'service' },
            { label: 'Operation', value: 'operation' },
          ]}
          value={serviceOrOperation}
          onChange={(t) => {
            setServiceOrOperation(t as 'service' | 'operation');
            setExpanded(new Set());
          }}
        />
        <div>Order by</div>
        <RadioButtonGroup
          size="md"
          options={[
            { label: 'Duration', value: 'duration' },
            { label: 'Start time', value: 'start' },
          ]}
          value={ordBy}
          onChange={(t) => {
            setOrdBy(t as 'duration' | 'start');
          }}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th />
            <th>Service</th>
            <th>Operation</th>
            <th>Started</th>
            <th>Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tracesBySelected).map((selectedTraceGrouping) => {
            const traces: readonly Trace[] = tracesBySelected[selectedTraceGrouping] ?? [];
            if (traces.length === 0) {
              return null;
            }

            return (
              <>
                <tr className="group">
                  <td width="40">
                    {expanded.has(selectedTraceGrouping) ? (
                      <Icon name="angle-down" className={styles.icon} onClick={handleToggle(selectedTraceGrouping)} />
                    ) : (
                      <Icon name="angle-right" className={styles.icon} onClick={handleToggle(selectedTraceGrouping)} />
                    )}
                  </td>
                  {serviceOrOperation === 'service' ? null : <td></td>}
                  <td colSpan={serviceOrOperation === 'service' ? 5 : 4} onClick={handleToggle(selectedTraceGrouping)}>
                    {selectedTraceGrouping} - Found {traces.length} slow traces
                  </td>
                </tr>
                {expanded.has(selectedTraceGrouping)
                  ? traces.map((trace) => {
                      return (
                        <tr key={trace.traceID} className={styles.buttonRow}>
                          <td></td>
                          <td>{trace.rootServiceName}</td>
                          <td>{trace.rootTraceName}</td>
                          <td>
                            {DateTime.fromMillis(trace.startTimeUnixNano / 1000000).toLocaleString(
                              DateTime.DATETIME_SHORT
                            )}
                          </td>
                          <td>
                            {Duration.fromMillis(trace.durationMs)
                              .shiftTo('seconds', 'milliseconds')
                              .normalize()
                              .toHuman({ listStyle: 'narrow', unitDisplay: 'narrow' })}
                          </td>
                          <td width="80">
                            <LinkButton
                              variant="secondary"
                              target="_blank"
                              size="sm"
                              icon="external-link-alt"
                              href={getTempoExploreUrl(
                                datasources.tempoDatasource.uid,
                                trace.traceID,
                                investigation.timeRange
                              )}
                            >
                              View
                            </LinkButton>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    table: css`
      width: 100%;
      border-radius: ${theme.shape.radius.default};
      border: solid 1px ${theme.colors.border.weak};
      thead {
        background-color: ${theme.colors.background.secondary};
        font-weight: 500;
        position: sticky;
        top: -25px;
        z-index: 10;
      }

      th {
        padding: ${theme.spacing(1)};
      }

      td {
        padding: 0 ${theme.spacing(1)};
      }

      tr {
        height: 38px;
      }

      tr.group td {
        background-color: ${theme.colors.background.secondary};
        border-top: 1px solid ${theme.colors.border.strong};
        font-weight: 500;
        padding-top: 12px;
        padding-bottom: 12px;
        opacity: 0.8;
        cursor: pointer;
        user-select: none;
      }
    `,
    card: css`
      display: block !important;
    `,
    heading: css`
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
    `,
    title: css`
      color: ${theme.colors.text.secondary};
      font-weight: 400;
      > b {
        color: ${theme.colors.text.primary};
      }
    `,
    buttonRow: css`
      clear: both;
      justify-content: space-between;
    `,
    icon: css`
      height: 20px;
      font-size: 18px;
    `,
  };
}

export { SlowRequests };
