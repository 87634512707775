import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { locationService, PluginPage } from '@grafana/runtime';
import { Tab, TabContent, TabsBar, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { JobDetailsDrawer } from 'components/JobDetailsDrawer';
import { PLUGIN_ROOT } from 'consts';
import { useIsEditor } from 'hooks';
import Holidays from 'projects/Forecasting/Tabs/Holidays';
import { CreateHoliday } from 'projects/Forecasting/Tabs/Holidays/Create/CreateHoliday';
import { EditHoliday } from 'projects/Forecasting/Tabs/Holidays/Edit/EditHoliday';
import { HolidayDetails } from 'projects/Forecasting/Tabs/Holidays/View/HolidayDetails';
import { Welcome } from 'projects/Forecasting/Tabs/Welcome';
import { useForecastingState } from 'projects/Forecasting/useForecastingState';

import { JobList } from './List/JobList';

interface ForecastingTabsProps {
  view: string;
}

function ForecastingTabs({ view }: ForecastingTabsProps): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const isEditor = useIsEditor();
  const { data: forecastingState, clearForecastDrawer } = useForecastingState();
  const { forecastDrawerItem, forecastDrawerDatasourceName } = forecastingState;

  return (
    <PluginPage
      subTitle=""
      renderTitle={() => {
        return (
          <div className={styles.pageHeader}>
            <div className={styles.pageTitle}>Metric forecasts</div>
          </div>
        );
      }}
    >
      <Welcome />

      <TabsBar className={styles.tabsBar}>
        <Tab
          label="Forecasts"
          active={view === 'list'}
          onChangeTab={() => locationService.replace({ pathname: `${PLUGIN_ROOT}/metric-forecast` })}
        />
        {isEditor ? (
          <Tab
            label="Holidays"
            active={
              view === 'holiday' || view === 'holiday-create' || view === 'holiday-edit' || view === 'holiday-details'
            }
            onChangeTab={() => locationService.replace({ pathname: `${PLUGIN_ROOT}/metric-forecast/holiday` })}
          />
        ) : null}
      </TabsBar>
      <TabContent className="style.tab-content">
        {view === 'list' ? (
          <>
            <JobList />
            {forecastDrawerItem !== undefined ? (
              <JobDetailsDrawer
                item={forecastDrawerItem}
                datasourceName={forecastDrawerDatasourceName ?? ''}
                onClose={clearForecastDrawer}
              />
            ) : null}
          </>
        ) : null}
        {view === 'holiday' ? <Holidays /> : null}
        {view === 'holiday-create' ? <CreateHoliday /> : null}
        {view === 'holiday-edit' ? <EditHoliday /> : null}
        {view === 'holiday-details' ? <HolidayDetails /> : null}
      </TabContent>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tabsBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    pageHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      height: 32px;
    `,
    pageTitle: css`
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.2;
      color: ${theme.colors.text.primary};
    `,
  };
};

export { ForecastingTabs };
