import { useMemo } from 'react';

import { DataSourceInstanceSettings, DataSourceJsonData } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';

import { DATASOURCE_NAME, SUPPORTED_DATASOURCE_TYPES } from 'consts';

export function supportedDatasource(ds: DataSourceInstanceSettings): boolean {
  if (SUPPORTED_DATASOURCE_TYPES.includes(ds.type)) {
    return ds.name !== DATASOURCE_NAME;
  }
  return false;
}

const EMPTY: never[] = [];

function useAllDatasources(): Array<DataSourceInstanceSettings<DataSourceJsonData>> {
  const datasources = (getDataSourceSrv()?.getList() ?? EMPTY).sort((a, b) => a.name.localeCompare(b.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => datasources, []);
}

export function useSupportedDatasources(): Array<DataSourceInstanceSettings<DataSourceJsonData>> {
  const datasources = useAllDatasources();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => datasources.filter(supportedDatasource), []);
}

export function useDataSourceSettings(datasourceId: number | undefined): DataSourceInstanceSettings | undefined {
  const datasources = useSupportedDatasources();
  return useMemo(() => datasources.find((ds) => ds.id === datasourceId), [datasources, datasourceId]);
}
interface SiftConfigDatasources {
  supportedDatasources: Array<DataSourceInstanceSettings<DataSourceJsonData>>;
  prometheusFilter: (ds: DataSourceInstanceSettings) => boolean;
  lokiFilter: (ds: DataSourceInstanceSettings) => boolean;
  tempoFilter: (ds: DataSourceInstanceSettings) => boolean;
}

export function useSiftConfigDatasources(): SiftConfigDatasources {
  const datasources = useAllDatasources();
  const supportedDatasources = datasources.filter(supportedDatasource);

  return {
    supportedDatasources,
    prometheusFilter: (ds: DataSourceInstanceSettings) => {
      return ds.type === 'prometheus';
    },
    lokiFilter: (ds: DataSourceInstanceSettings) => {
      return ds.type === 'loki';
    },
    tempoFilter: (ds: DataSourceInstanceSettings) => {
      return ds.type === 'tempo';
    },
  };
}

/// Hook that returns the Grafana Machine Learning datasource for this Grafana instance,
/// if it is present.
export function useMlDataSource(): DataSourceInstanceSettings | undefined {
  const datasources = useAllDatasources();
  return useMemo(() => datasources.find((ds) => ds.name === DATASOURCE_NAME), [datasources]);
}
