import React, { useEffect, useMemo, useState } from 'react';

import { AppEvents, DataSourceInstanceSettings, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Alert, Badge, PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { isEqual } from 'lodash';

import { useAllModels } from 'api';
import { JobGraph } from 'components/JobGraph';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { TabView } from 'components/TabView';
import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { useQueryResult, useTimeRange } from 'hooks';
import { useQueryContext } from 'hooks/useQueryContext';
import { useDataSourceSettings } from 'hooks/useSupportedDatasources';
import { AttachHoliday } from 'projects/Forecasting/AttachHoliday';
import { DataQueryWithExpression, FullFormModel, Job, ModelId, TenantInfo } from 'types';
import { defaultEditJobTimeRange, secondsToTimeUnit, useSelectedModel } from 'utils';

import { errorReason, errorString } from '../../../utils/errorHandling';
import { getTabs, TabProps } from '../../../utils/tabs';
import { AlgorithmEditorTab } from '../AlgorithmEditorTab';
import { QueryEditorTab } from '../QueryEditorTab';
import { useForecastingState } from '../useForecastingState';
import { EditJobModal } from './EditJobModal';

interface EditJobProps {
  originalJob: Job;
  tenantInfo: TenantInfo;
}

export function EditJobContent({ originalJob, tenantInfo }: EditJobProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const {
    data: forecastState,
    setDatasource,
    setParamsDirty,
    setParamsValid,
    setQueryValid,
    setQueryError,
    setQueryInProgress,
    setQueryChangedSinceLastRun,
    setInitialState,
    setQuery,
    setParameters,
    setShowEditModal,
  } = useForecastingState();

  const tabs = getTabs(QueryEditorTab, AlgorithmEditorTab, AttachHoliday);

  const { data: models, isLoading, isError } = useAllModels();
  const [selectedModelId, setSelectedModelId] = useSelectedModel(models);

  const [maxDataPoints, setMaxDataPoints] = useState<number>(100);
  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultEditJobTimeRange()
  );

  const { queryParams, isEmpty } = useQueryContext();

  const originalDatasource = useDataSourceSettings(originalJob.datasourceId);

  useEffect(() => {
    const [trainingWindowValue, trainingWindowUnit] = secondsToTimeUnit(originalJob.trainingWindow ?? 0);
    const [trainingFrequencyValue, trainingFrequencyUnit] = secondsToTimeUnit(originalJob.trainingFrequency ?? 0);
    const [intervalValue, intervalUnit] = secondsToTimeUnit(originalJob.interval ?? 0);

    setInitialState(originalDatasource, {
      name: originalJob.name,
      metric: originalJob.metric,
      description: originalJob.description,
      query: {
        key: 'A',
        value: originalJob.queryParams,
      },
      customLabels: originalJob.customLabels,
      parameters: {
        id: originalJob.algorithm as ModelId,
        intervalUnit,
        intervalValue,
        trainingWindowUnit,
        trainingWindowValue,
        trainingFrequencyUnit,
        trainingFrequencyValue,
        hyperparameters: {
          [originalJob.algorithm as string]: originalJob.hyperParams,
        },
      },
    });
    setSelectedModelId((originalJob.algorithm ?? null) as ModelId | null);
  }, [originalDatasource, setInitialState, setQuery, originalJob.queryParams, originalJob, setSelectedModelId]);

  const finalQueries = useMemo(() => (queryParams === undefined ? [] : [queryParams]), [queryParams]);

  const [data, isRefreshing, runQuery, cancelQuery] = useQueryResult(
    finalQueries,
    maxDataPoints,
    timeRange,
    timeZone,
    forecastState.datasource?.uid ?? ''
  );

  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [showErrorBadge, setShowErrorBadge] = useState<boolean>(false);
  const [holidays, setHolidays] = useState<string[] | undefined>(originalJob.holidays);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    if (data?.state === 'Error' || data?.state === 'Done') {
      // the two query-completed states
      // Request is erroneous either due to invalid query, or no data being returned
      const requestError = errorReason(data, tenantInfo.maxSeriesPerJob);
      if (requestError !== null) {
        const error = errorString(requestError);
        setQueryError(error);
        if (
          requestError?.kind === 'HasDuplicateSeries' ||
          requestError?.kind === 'TooManySeries' ||
          requestError?.kind === 'InvalidQuery'
        ) {
          getAppEvents().publish({ type: AppEvents.alertError.name, payload: [`Error with query: ${error}`] });
        }
        if (!firstRun) {
          // first run is with default query, it will fail, but want to hide the error
          setShowErrorBadge(true);
        }
      } else {
        setQueryValid(firstRun);
        setShowErrorBadge(false);
        if (firstRun) {
          setFirstRun(false);
        }
      }
    } else {
      // the loading state
      setQueryInProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.state, data]);

  useEffect(() => {
    // hide the error badge when the user changes the query
    setShowErrorBadge(false);
  }, [forecastState.dirty]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || models == null) {
    return <Alert title="Error with models">Error loading models; can not edit</Alert>;
  }

  if (originalJob.algorithm == null) {
    console.error('Job has no modelId', originalJob);
    return <Alert title="Error with job">Error with job; can not edit</Alert>;
  }

  if (isEmpty) {
    return <></>;
  }

  return (
    <>
      {forecastState.datasource !== undefined ? (
        <EditJobModal
          id={originalJob.id}
          datasource={forecastState.datasource}
          modelForm={forecastState.modelForm}
          queryParams={queryParams}
          isOpen={forecastState.showEditModal}
          onDismiss={() => setShowEditModal(false)}
          attachedHolidays={holidays !== undefined ? holidays : []}
        />
      ) : null}

      <>
        <PageToolbar className={styles.pageToolbar} data-element="forecasting-edit-buttons">
          {showErrorBadge ? (
            <Badge color="red" icon="exclamation-circle" text="Error" tooltip={forecastState.queryErrorMessage} />
          ) : null}
          <ToolbarTimeRange
            timeRange={timeRange}
            isRefreshing={isRefreshing}
            timeZone={timeZone}
            onRefresh={runQuery}
            onChangeTimeRange={onChangeTimeRange}
            onChangeTimeZone={onChangeTimeZone}
            onZoomTimeRange={onZoomTimeRange}
          />
          <ToolbarButton
            disabled={!forecastState.isValid}
            onClick={() => {
              setShowEditModal(true);
            }}
            form="algorithm-form"
            title="Save changes as new forecast"
          >
            Save As
          </ToolbarButton>
          <ToolbarButton
            form="algorithm-form"
            disabled={!forecastState.isValid || !forecastState.dirty}
            variant="primary"
            title={
              !forecastState.isValid
                ? `There are problems with the form: ${forecastState.errorMessages.join('. ')}}`
                : 'Apply changes and go back to list of forecasts'
            }
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Update
          </ToolbarButton>
        </PageToolbar>
        <div className={styles.contentContainer}>
          <div className={styles.queryContainer}>
            <TabView<TabProps>
              tabs={tabs}
              tabProps={{
                selectedModelId,
                setSelectedModelId,
                models,
                defaultValues: forecastState.modelForm,
                datasource: forecastState.datasource,
                setParamsDirty,
                setParamsValid,
                onUpdateDatasource: (datasource: DataSourceInstanceSettings) => {
                  setDatasource(datasource);
                },
                onRunQuery: (query: DataQueryWithExpression) => {
                  setQuery(query);
                },
                onQueryChange: (query: DataQueryWithExpression) => {
                  const changedSinceLastRun = !isEqual(query, queryParams);
                  setQueryChangedSinceLastRun(changedSinceLastRun);
                },
                onSave: (modelForm: FullFormModel) => {
                  setParameters(modelForm.parameters);
                },
                timeRange,
                setSelectedHolidays: setHolidays,
                preSelectedHolidayIds: holidays,
                holidays: holidays ?? [],
              }}
            />
          </div>
          <div className={styles.chartContainer}>
            <JobGraph
              data={data}
              timeZone={timeZone}
              timeRange={timeRange}
              onChangeTimeRange={onChangeTimeRange}
              isRefreshing={isRefreshing}
              onRunQuery={runQuery}
              onCancelQuery={cancelQuery}
              maxDataPoints={maxDataPoints}
              onChangeMaxDataPoints={setMaxDataPoints}
            />
          </div>
        </div>
      </>
    </>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  contentContainer: css`
    display: flex;
    flex-direction: column;
  `,
  queryContainer: css`
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.weak};
    border-radius: 3px;
    margin-bottom: 18px;
  `,
  chartContainer: css`
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.weak};
    border-radius: 3px;
    height: calc(100vh - 400px);
  `,
  pageToolbar: css({
    backgroundColor: 'unset',
    padding: 'unset',
    marginBottom: theme.spacing(2),
  }),
});
