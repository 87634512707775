import React, { useCallback, useContext, useMemo } from 'react';

import { JsonParam, useQueryParam } from 'use-query-params';

import { DataQueryWithExpression } from 'types';

// Query Context is used to store the query and update it from the QueryEditor
// The source of truth for the query params is the url, and changes to the query
// will result in changes to the url.

export interface QueryContextState {
  queryParams?: DataQueryWithExpression;
  updateQuery: (query?: DataQueryWithExpression) => void;
  clearQuery: () => void;
  isEmpty: boolean;
}

export const QueryContext = React.createContext<QueryContextState>({
  queryParams: undefined,
  updateQuery: () => undefined,
  clearQuery: () => undefined,
  isEmpty: true,
});

interface QueryContextProviderProps {
  children: React.ReactNode;
}

export const QueryContextProvider = ({ children }: QueryContextProviderProps) => {
  const emptyQueryParams = useMemo(() => ({ refId: '' }), []);

  const [queryParams = emptyQueryParams, setQueryParams] = useQueryParam<DataQueryWithExpression | undefined>(
    'query_params',
    JsonParam
  );

  const updateQuery = useCallback(
    (updatedQuery?: DataQueryWithExpression) => {
      if (updatedQuery === undefined) {
        setQueryParams(emptyQueryParams);
      } else {
        // avoid updating the query if it hasn't changed to prevent un-needed
        // entries being added to the browser's url history
        if (JSON.stringify(updatedQuery) !== JSON.stringify(queryParams)) {
          // Need to delay updating the query params by one render to avoid race when going from View to Edit page
          setTimeout(() => {
            setQueryParams(updatedQuery);
          }, 0);
        }
      }
    },
    [emptyQueryParams, setQueryParams, queryParams]
  );

  const isEmpty = queryParams === undefined || JSON.stringify(queryParams) === JSON.stringify(emptyQueryParams);

  const clearQuery = () => setQueryParams(emptyQueryParams);

  // @ts-ignore
  // We don't support instant queries in the UI and loads of query editors break if it's set,
  // which sometimes happens after being redirected from the PanelMenu or ExploreToolbar extension points.
  delete queryParams.instant;

  return (
    <QueryContext.Provider value={{ queryParams, updateQuery, clearQuery, isEmpty }}>{children}</QueryContext.Provider>
  );
};

export const useQueryContext = () => {
  const { queryParams, updateQuery, clearQuery, isEmpty } = useContext(QueryContext);
  return { queryParams, updateQuery, clearQuery, isEmpty };
};
