import {
  DateTime,
  ExploreUrlState,
  rangeUtil,
  RawTimeRange,
  serializeStateToUrlParam,
  toURLRange,
} from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';

export function getPrometheusExploreUrl(datasourceUid: string, queries: string[], range?: RawTimeRange): string {
  const urlState: ExploreUrlState = {
    datasource: datasourceUid,
    queries: queries.map((query, i) => {
      return { refId: `${i}`, expr: query };
    }),
    range: toURLRange(range ?? { from: 'now-1h', to: 'now' }),
  };

  const param = encodeURIComponent(serializeStateToUrlParam(urlState));

  return `/explore?left=${param}`;
}

export function getLokiExploreUrl(datasourceUid: string, query: string, range?: RawTimeRange): string {
  const logUrlState: ExploreUrlState = {
    datasource: datasourceUid,
    queries: [{ refId: 'A', expr: query }],
    range: toURLRange(range ?? { from: 'now-1h', to: 'now' }),
  };

  const param = encodeURIComponent(serializeStateToUrlParam(logUrlState));

  return `/explore?left=${param}`;
}

export function getTempoExploreUrl(datasourceUid: string, traceId: string, range?: RawTimeRange): string {
  const traceUrlState: ExploreUrlState = {
    datasource: datasourceUid,
    queries: [{ refId: 'A', query: traceId, queryType: 'traceql' }],
    range: toURLRange(range ?? { from: 'now-1h', to: 'now' }),
  };

  const param = encodeURIComponent(serializeStateToUrlParam(traceUrlState));

  return `/explore?left=${param}`;
}

// Reference: https://github.com/grafana/pyroscope/blob/main/public/app/constants/profile-metrics.json
export enum ProfileType {
  CPUProfile = 'process_cpu:cpu:nanoseconds:cpu:nanoseconds',
  MemoryProfile = 'memory:inuse_space:bytes:space:bytes',
}

// Example: https://ops.grafana-ops.net/a/grafana-pyroscope-app/single?from=1717162173&until=1717163973&query=process_cpu%3Acpu%3Ananoseconds%3Acpu%3Ananoseconds%7Bservice_name%3D%22ebpf%2Fincident%2Fexporter%22%2Ccluster%3D%22prod-us-central-0%22%2Cnamespace%3D%22incident%22%2Ccontainer%3D%22exporter%22%2Cpod%3D%22exporter-689b559759-2dxx7%22%7D&maxNodes=16384&
interface PyroscopeSingleUrlState {
  from: number;
  until: number;
  query: string;
}

export function getPyroscopeExploreUrl(selector: string, profileType: ProfileType, rawRange?: RawTimeRange): string {
  const range = rangeUtil.convertRawToRange(rawRange ?? { from: 'now-1h', to: 'now' });
  const pyroscopeUrlState: PyroscopeSingleUrlState = {
    from: range.from.unix(),
    until: range.to.unix(),
    query: `${profileType}${selector}`,
  };
  const rawParam = `from=${pyroscopeUrlState.from}&until=${pyroscopeUrlState.until}&query=${pyroscopeUrlState.query}`;
  const param = encodeURIComponent(rawParam);
  return `/a/grafana-pyroscope-app/single?${param}`;
}

// Kubernetes monitoring app URLs.

interface BaseKubernetesOptions {
  from: DateTime;
  to: DateTime;
  prometheusDatasourceUid: string;
  lokiDatasourceUid?: string;
  cluster: string;
}

const WORKLOAD_KINDS = ['deployment', 'statefulset', 'daemonset', 'job', 'cronjob'] as const;
type WorkloadKind = (typeof WORKLOAD_KINDS)[number];

interface HasCluster {
  cluster: string;
}

interface HasNamespace {
  namespace: string;
}

export type KubernetesMonitoringNodeOptions = BaseKubernetesOptions &
  HasCluster & {
    node: string;
  };

export type KubernetesMonitoringWorkloadOptions = BaseKubernetesOptions &
  HasCluster &
  HasNamespace & {
    workloadKind: WorkloadKind;
    workload: string;
  };

export type KubernetesMonitoringPodOptions = KubernetesMonitoringWorkloadOptions & {
  pod: string;
};

export type KubernetesMonitoringContainerOptions = KubernetesMonitoringPodOptions & {
  container: string;
};

export type KubernetesMonitoringOptions =
  | KubernetesMonitoringNodeOptions
  | KubernetesMonitoringWorkloadOptions
  | KubernetesMonitoringPodOptions
  | KubernetesMonitoringContainerOptions;

export function isWorkloadKind(w?: string): w is WorkloadKind {
  if (!w) {
    return false;
  }
  const l = w.toLowerCase();
  return WORKLOAD_KINDS.includes(l as WorkloadKind);
}

// Get a URL to the Kubernetes monitoring app for a node or workload.
//
// URLs look like this:
// Nodes:
// /a/grafana-k8s-app/navigation/nodes/{cluster}/{node}?promName={prometheus UID}&lokiName=${loki UID}
// Workloads:
// /a/grafana-k8s-app/navigation/namespace/{cluster}/{namespace}/{workloadKind}/{workload}/{pod}?promName={prometheus UID}&lokiName={loki UID}
export function getKubernetesMonitoringUrl(options: KubernetesMonitoringOptions): string {
  const promDatasource = getDataSourceSrv().getInstanceSettings(options.prometheusDatasourceUid);
  const lokiDatasource =
    options.lokiDatasourceUid !== undefined
      ? getDataSourceSrv().getInstanceSettings(options.lokiDatasourceUid)
      : undefined;
  const baseUrl = '/a/grafana-k8s-app/navigation';
  const params = new URLSearchParams({
    from: options.from.toISOString(),
    to: options.to.toISOString(),
  });
  if (promDatasource !== undefined) {
    params.set('promName', promDatasource.name);
  }
  if (lokiDatasource !== undefined) {
    params.set('lokiName', lokiDatasource.name);
  }
  if ('node' in options) {
    const { cluster, node } = options;
    return `${baseUrl}/nodes/${cluster}/${node}?${params}`;
  }
  if ('container' in options) {
    const { cluster, container, namespace, pod, workload, workloadKind } = options;
    return `${baseUrl}/namespace/${cluster}/${namespace}/${workloadKind}/${workload}/${pod}/${container}?${params}`;
  }
  if ('pod' in options) {
    const { cluster, namespace, pod, workload, workloadKind } = options;
    return `${baseUrl}/namespace/${cluster}/${namespace}/${workloadKind}/${workload}/${pod}?${params}`;
  }
  // No container or pod, must be a workload.
  const { cluster, namespace, workload, workloadKind } = options;
  return `${baseUrl}/namespace/${cluster}/${namespace}/${workloadKind}/${workload}?${params}`;
}
