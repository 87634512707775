/* eslint-disable camelcase */
import { DataSourceInstanceSettings, Field } from '@grafana/data';

import { DataQueryWithExpression } from 'types';

// core parts to build types with
interface OutlierTimeRange {
  startTime: string;
  endTime: string;
  interval: number;
}
interface OutlierQuery {
  queryParams: DataQueryWithExpression;
  algorithm: string;
  sensitivity: number;
  config?: DBScanConfig;
  datasource: DataSourceInstanceSettings;
}

export interface OutlierDetails {
  id: string;
  name: string;
  description?: string;
  metric: string;
  errorState?: string;
}

export interface DBScanConfig {
  epsilon: number;
}

interface OutlierContent {
  grafanaUrl: string;
  datasourceType: string;
  datasourceId: number;
  datasourceUid: string;
  queryParams: DataQueryWithExpression;
  interval: number;
  algorithm: {
    name: string;
    sensitivity: number;
    config?: DBScanConfig;
  };
}

export enum OutlierResponseType {
  Label = 'label',
  Binary = 'binary',
}

interface OutlierResponse {
  responseType: OutlierResponseType;
}

// Types for labelling intervals of outlying values in a dataframe
export type OutlierIntervals = number[]; //of form [ts_start,ts_end,ts_start,ts_end....]
export interface DataFrameOutlierIntervals {
  // {series_index: [ts_start,ts_end,ts_start,ts_end....]}
  [index: number]: OutlierIntervals;
}
export interface OutlierField<T> extends Field<T, T[]> {
  outlierIntervals?: OutlierIntervals;
  index?: number;
}

export type Band = [Array<number | undefined>, Array<number | undefined>]; // 2 series: min & max

export interface OutlierResults {
  normalBand: Band;
  outlierIntervals: DataFrameOutlierIntervals;
}

// RUNNING OUTLIER QUERIES

// required params for building a payload to run an outlier query
export type OutlierParams = OutlierQuery & OutlierResponse & OutlierTimeRange;

// payload for running an outlier query
export interface RunOutlierPayload {
  data: {
    attributes: {
      grafana_url: string;
      datasource_type: string;
      datasource_id: number;
      datasource_uid: string;
      query_params: DataQueryWithExpression;
      start_end_attributes: {
        start: string;
        end: string;
        interval: number;
      };
      response_type: OutlierResponseType;
      algorithm: {
        name: string;
        sensitivity: number;
        config?: DBScanConfig;
      };
    };
  };
}

// SAVING AN OUTLIER

// required params for building a payload to save an outlier
export type OutlierParamsToSaveOutlier = OutlierDetails & OutlierQuery;

// payload for saving an outlier
export type SaveOutlierPayload = OutlierContent & OutlierDetails;

export interface SaveOutlierResponse {}

// Outlier is the base object used when getting a specific outlier that has been saved
// or when pulling in a list of Outliers as an array
export type Outlier = OutlierContent &
  OutlierDetails &
  OutlierTimeRange & {
    created: string;
    modified: string;
  };
