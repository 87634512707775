import React, { FC } from 'react';
import { useQuery } from 'react-query';

import { adminApiGet } from 'api/core.api';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Job, TrainingHistoryItem } from 'types';

import { ShowTrainingHistoryItem } from './ShowTrainingHistoryItem';

export const JobTrainingHistory: FC<{ job: Job }> = ({ job }) => {
  const { isLoading, isError, data, error } = useQuery<TrainingHistoryItem[]>(
    ['metric_job', job.id, 'trainings'],
    async () => {
      const res = await adminApiGet(`/manage/api/v1/jobs/${job.id}/trainings`);
      if (!res.ok) {
        throw res.data;
      }
      if (res.data?.data != null) {
        return (res.data as { data: TrainingHistoryItem[] }).data;
      }
      return [];
    }
  );

  if (isLoading || data == null) {
    return <LoadingIndicator />;
  }

  if (isError) {
    console.error('Error:', error);
    return <div>Problem loading training history</div>;
  }

  return (
    <aside>
      {data.map((item, index) => (
        <ShowTrainingHistoryItem item={item} key={index} />
      ))}
    </aside>
  );
};
