import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, LoadingBar, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { AnalysisData, InvestigationPreview, LabelStats } from 'types';

const PreviewPanel = ({
  isLoading,
  showResults,
  previewResults,
}: {
  isLoading: boolean;
  showResults: boolean;
  previewResults: InvestigationPreview | undefined;
}) => {
  const styles = useStyles2(getStyles);

  return (
    <aside>
      <div className={styles.previewHeader}>
        Investigation preview
        <Tooltip content={'A preview of the analyses Sift will perform based on the inputs provided.'}>
          <div className={styles.info}>
            <Icon tabIndex={0} name="info-circle" size="sm" />
          </div>
        </Tooltip>
      </div>
      {showResults ? (
        <PreviewPanelInternal isLoading={isLoading} previewResults={previewResults} />
      ) : (
        <div className={styles.previewWrapper}>
          <div className={styles.block}>
            Complete the form above to view a preview of the analyses Sift will perform.
          </div>
        </div>
      )}
    </aside>
  );
};

const PreviewPanelInternal = ({
  isLoading,
  previewResults,
}: {
  isLoading: boolean;
  previewResults: InvestigationPreview | undefined;
}) => {
  const styles = useStyles2(getStyles);
  const pendingAnalyses = previewResults?.analyses.filter((analysis) => analysis.status === 'pending');
  const skippedAnalyses = previewResults?.analyses.filter((analysis) => analysis.status === 'skipped');
  const labelDetails = previewResults?.labels;

  if (isLoading) {
    return (
      <div className={styles.previewWrapper}>
        <div className={styles.block}>
          <LoadingBar width={100} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.previewWrapper}>
      {!isLoading && previewResults?.errors !== undefined && previewResults?.errors.length > 0 ? (
        <PreviewPanelErrors errors={previewResults.errors} />
      ) : null}
      {!isLoading && previewResults?.errors !== undefined && previewResults?.warnings.length > 0 ? (
        <PreviewPanelWarnings warnings={previewResults.warnings} />
      ) : null}
      {!isLoading && pendingAnalyses !== undefined && pendingAnalyses.length > 0 ? (
        <PreviewPanelWillRun analyses={pendingAnalyses} />
      ) : null}
      {!isLoading && skippedAnalyses !== undefined && skippedAnalyses.length > 0 ? (
        <PreviewPanelSkipped analyses={skippedAnalyses} />
      ) : null}
      {!isLoading && labelDetails !== undefined ? <PreviewPanelLabelStatus labelDetails={labelDetails} /> : null}
    </div>
  );
};

const PreviewPanelErrors = ({ errors }: { errors: string[] }) => {
  const styles = useStyles2(getStyles);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={styles.block}>
      <div className={expanded ? styles.expandedHeader : styles.blockHeader} onClick={() => setExpanded(!expanded)}>
        <Icon className={styles.errorIcon} size="lg" name="exclamation-triangle" />
        <div>{errors.length === 1 ? '1 error' : `${errors.length} errors`} requires attention</div>
        <Icon className={styles.angleIcon} name={expanded ? 'angle-down' : 'angle-up'} />
      </div>
      {expanded
        ? errors.map((error, index) => (
            <div className={styles.analysisRow} key={`errors-${index}`}>
              {error}
            </div>
          ))
        : null}
    </div>
  );
};

const PreviewPanelWarnings = ({ warnings }: { warnings: string[] }) => {
  const styles = useStyles2(getStyles);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={styles.block}>
      <div className={expanded ? styles.expandedHeader : styles.blockHeader} onClick={() => setExpanded(!expanded)}>
        <Icon className={styles.warningIcon} size="lg" name="exclamation-triangle" />
        <div>{warnings.length === 1 ? '1 warning' : `${warnings.length} warnings`} require attention</div>
        <Icon className={styles.angleIcon} name={expanded ? 'angle-down' : 'angle-up'} />
      </div>
      {expanded
        ? warnings.map((warning, index) => (
            <div className={styles.analysisRow} key={`warnings-${index}`}>
              {warning}
            </div>
          ))
        : null}
    </div>
  );
};

const PreviewPanelWillRun = ({ analyses }: { analyses: AnalysisData[] }) => {
  const styles = useStyles2(getStyles);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={styles.block}>
      <div className={expanded ? styles.expandedHeader : styles.blockHeader} onClick={() => setExpanded(!expanded)}>
        <Icon className={styles.runningIcon} size="lg" name="check-circle" />
        <div>{analyses.length === 1 ? '1 analysis' : `${analyses.length} analyses`} will be run</div>
        <Icon className={styles.angleIcon} name={expanded ? 'angle-down' : 'angle-up'} />
      </div>
      {expanded
        ? analyses.map((analysis, index) => (
            <div className={styles.analysisRow} key={`running-${index}`}>
              <div>{analysis.name}</div>
              <div className={styles.analysisMessage}></div>
            </div>
          ))
        : null}
    </div>
  );
};

const PreviewPanelSkipped = ({ analyses }: { analyses: AnalysisData[] }) => {
  const styles = useStyles2(getStyles);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={styles.block}>
      <div className={expanded ? styles.expandedHeader : styles.blockHeader} onClick={() => setExpanded(!expanded)}>
        <Icon className={styles.skippedIcon} size="lg" name="check-circle" />
        <div>
          {analyses.length === 1 ? '1 analysis' : `${analyses.length} analyses`} will be skipped for the following
          reasons
        </div>
        <Icon className={styles.angleIcon} name={expanded ? 'angle-down' : 'angle-up'} />
      </div>
      {expanded
        ? analyses.map((analysis, index) => (
            <div className={styles.analysisRow} key={`skipped-${index}`}>
              <div>{analysis.name}</div>
              <div className={styles.analysisMessage}>{analysis.result.message}</div>
            </div>
          ))
        : null}
    </div>
  );
};

const PreviewPanelLabelStatus = ({ labelDetails }: { labelDetails: Record<string, LabelStats> }) => {
  const styles = useStyles2(getStyles);
  const [expanded, setExpanded] = React.useState(false);

  const labels = Object.keys(labelDetails);

  const labelsUsed = labels.filter((l) => labelDetails[l].included === true);
  const labelsSkipped = labels.filter((l) => labelDetails[l].included !== true);

  return (
    <div className={styles.block}>
      <div className={expanded ? styles.expandedHeader : styles.blockHeader} onClick={() => setExpanded(!expanded)}>
        {labelsSkipped.length > 0 ? (
          <Icon className={styles.skippedIcon} size="lg" name="exclamation-triangle" />
        ) : (
          <Icon className={styles.runningIcon} size="lg" name="check-circle" />
        )}
        <div>
          Labels: {labelsUsed.length} used, {labelsSkipped.length} skipped
        </div>
        <Icon className={styles.angleIcon} name={expanded ? 'angle-down' : 'angle-up'} />
      </div>
      {expanded ? (
        <>
          {labelsUsed.length > 0 ? (
            <div className={styles.analysisRow}>
              <div>Labels used</div>
              <div className={styles.analysisMessage}>{labelsUsed.join(', ')}</div>
            </div>
          ) : null}
          {labelsSkipped.length > 0 ? (
            <div className={styles.analysisRow}>
              <div>Labels skipped</div>
              <div className={styles.analysisMessage}>{labelsSkipped.join(', ')}</div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    previewHeader: css`
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 6px;
    `,
    previewWrapper: css``,
    block: css`
      background-color: ${theme.colors.background.secondary};
      padding: 20px;
      margin-bottom: 4px;
    `,
    blockHeader: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
    `,
    expandedHeader: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 20px;
    `,
    analysisRow: css`
      display: flex;
      padding: 4px 4px 4px 40px;
      align-items: top;
    `,
    analysisMessage: css`
      color: ${theme.colors.text.secondary};
      width: 200px;
      font-size: 11px;
      margin-left: auto;
    `,
    angleIcon: css`
      margin-left: auto;
    `,
    errorIcon: css`
      stroke: ${theme.colors.error.border};
      margin-right: 12px;
    `,
    warningIcon: css`
      stroke: ${theme.colors.warning.border};
      margin-right: 12px;
    `,
    runningIcon: css`
      stroke: ${theme.colors.success.border};
      margin-right: 12px;
    `,
    skippedIcon: css`
      margin-right: 12px;
    `,
    info: css`
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 5px;
      justify-content: space-between;
    `,
  };
};

export { PreviewPanel, PreviewPanelInternal };
