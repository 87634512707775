import React, { Children } from 'react';

import { FieldType, GrafanaTheme2, PanelData } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { OutlierField } from 'api/types';
import { Sparkline } from 'components/Sparkline';
import { getLineColor, getOutlierColors, SparkRange } from 'utils';

interface SparklineContainerProps {
  alignedData?: PanelData;
  sparkRange: SparkRange;
  selectedIndex: number | undefined;
  onSeriesSelection: (seriesIndex: number | undefined) => void;
}

const sparkSize = {
  tiny: {
    height: 24,
    width: 48,
    margin: 8,
  },
  small: {
    height: 36,
    width: 72,
    margin: 8,
  },
  medium: {
    height: 48,
    width: 96,
    margin: 12,
  },
  large: {
    height: 62,
    width: 124,
    margin: 12,
  },
};

export function OutlierSparklines({
  alignedData,
  sparkRange,
  selectedIndex,
  onSeriesSelection,
}: SparklineContainerProps): JSX.Element {
  const { styles, isDark } = useStyles2((t) => ({ isDark: t.isDark, styles: getStyles(t) }));
  const colors = getOutlierColors(isDark);

  if (alignedData?.series?.[0] === undefined) {
    return <></>;
  }

  const size =
    alignedData.series.length < 41
      ? sparkSize.large
      : alignedData.series.length < 61
      ? sparkSize.medium
      : alignedData.series.length < 81
      ? sparkSize.small
      : sparkSize.tiny;

  return (
    <div className={styles.container}>
      {Children.toArray(
        [...alignedData.series[0].fields]
          .filter((f) => f.type !== FieldType.time)
          .reverse()
          .map((field: OutlierField<number>) => {
            const isOutlierBool = field.labels?.isOutlier === 'True';
            return (
              <div
                className={styles.sparkBox}
                key={`sparkline${field.index}`}
                style={{ width: size.width, margin: size.margin }}
              >
                <Sparkline
                  data={field}
                  height={size.height}
                  width={size.width}
                  fieldName={field?.labels?.__name__ ?? ''}
                  minY={sparkRange.min!}
                  maxY={sparkRange.max!}
                  lineColor={getLineColor(colors, isOutlierBool, selectedIndex === field.index)}
                  selectedIndex={field.index}
                  isSelected={selectedIndex === field.index}
                  onClick={onSeriesSelection}
                />
              </div>
            );
          })
      )}
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      flex-wrap: wrap;
      width: '100%';
      margin-top: 18px;
    `,
    group: css`
      padding: 10px;
    `,
    headerBar: css`
      width: 100%;
      display: flex;
      margin-bottom: 6px;
    `,
    header: css`
      font-weight: bold;
      align-self: center;
    `,
    sparkBox: css`
      margin: 1px;
    `,
    sparkLabel: css`
      margin: 8px 8px 8px 8px;
      font-size: 11px;
    `,
    sparkOutlier: css`
      margin: 0px 4px 4px 0px;
    `,
    selectedLabel: css`
      border-radius: 10px;
      border: solid 1px #3d71aa;
      background-color: #3d71aa;
      padding: 2px 6px;
      margin-left: 8px;
      color: #ffffff;
      cursor: pointer;
    `,
    label: css`
      border-radius: 10px;
      border: solid 1px ${theme.colors.background.secondary};
      background-color: rgba(90, 90, 90, 0.15);
      padding: 2px 6px;
      margin-left: 8px;
      cursor: pointer;
    `,
  };
};
