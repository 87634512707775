import { DatasourceConfig, Investigation, Label, LabelInput, MatchType } from 'types';

// Finds a label matcher in the investigation's datasource inputs, overall inputs, or request data.
//
// If the datasource is specified, it will look for the label matcher in that datasource's inputs.
// If not, or if the datasource doesn't have inputs, it will look for the label matcher in the investigation's overall inputs.
// If there are no inputs, it will look for the label matcher in the investigation's request data.
function findLabelMatcher(
  investigation: Investigation,
  name: string,
  datasource?: keyof DatasourceConfig
): Label | undefined {
  const inputs =
    (datasource !== undefined ? investigation.datasources[datasource]?.inputs : undefined) ??
    investigation.inputs ??
    Object.entries(investigation.requestData.labels).map(([name, value]) => ({
      type: 'label',
      label: { name, value, type: MatchType.Equal },
    }));
  const input = inputs.find((input) => input.type === 'label' && input.label.name === name) as LabelInput | undefined;
  return input?.label;
}

// Adds a space before each capital letter, except for HTTP
const getAnalysisDisplayTitle = (name?: string, title?: string) => {
  if (title && title !== '') {
    return title;
  }
  if (!name) {
    return 'Sift Analysis';
  }
  return (
    name
      // don't want HTTP to be broken up with spaces
      .replace('HTTP', 'Http')
      .replace(/([A-Z])/g, ' $1')
      .replace('Http', 'HTTP')
      .trim()
  );
};

// Parses input to a type, or returns undefined if the input is empty.
//
// Useful for form field values which often contain empty strings rather
// than `undefined` if the user doesn't enter a value, but should be
// considered as undefined values instead.
function parseInput<T>(input: string | undefined, fn: (input: string) => T): T | undefined {
  if (input === undefined || input === '') {
    return undefined;
  }
  return fn(input);
}

// Parses input to a number, or returns undefined if the input is empty.
//
// Useful for form field values which often contain empty strings rather
// than `undefined` if the user doesn't enter a value, but should be
// considered as undefined values instead.
const parseInputToInt = (input: string) => parseInput<number>(input, (input) => parseInt(input, 10));

// Parses input to a float, or returns undefined if the input is empty.
const parseInputToFloat = (input: string) => parseInput<number>(input, (input) => parseFloat(input));

// Parses input to a string, or returns undefined if the input is empty.
//
// This isn't really doing any parsing, since it just passes the string
// through if it's non-empty, but it's a simple way of reusing the
// logic in `parseInput`.
//
// Useful for form field values which often contain empty strings rather
// than `undefined` if the user doesn't enter a value, but should be
// considered as undefined values instead.
const parseInputToString = (input: string) => parseInput<string>(input, (input) => input);

export {
  getAnalysisDisplayTitle,
  findLabelMatcher,
  parseInput,
  parseInputToInt,
  parseInputToFloat,
  parseInputToString,
};
