// Re-export the `contextSrv` inside a getter function so it's easier to mock.
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
const getContextSrv: () => any = () => require('grafana/app/core/core').contextSrv;

export function useIsEditor(): boolean {
  return getContextSrv().isEditor === true;
}

export function useIsAdmin(): boolean {
  return getContextSrv().hasRole('Admin') === true;
}
