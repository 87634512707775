import React, { useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, Form, Input } from '@grafana/ui';

import { useCreateSiftConfig, useUpdateSiftConfig } from 'api';
import { useSiftConfigDatasources } from 'hooks/useSupportedDatasources';
import {
  CheckConfig,
  CheckConfigWithDatasource,
  CheckFormProps,
  correlatedSeriesConfig,
  correlatedSeriesConfigSchema,
} from 'types';
import { getAnalysisDisplayTitle } from 'utils/utils.sift';

import { ConditionsField, convertConditions, convertIf } from './Components/ConditionsField';
import { DataSourceSelector } from './Components/DataSourceSelector';
import { LabelWithHelpText } from './Components/LabelWithHelpText';

function CorrelatedSeriesForm({
  check,
  defaultConfig,
  onClose,
}: CheckFormProps<correlatedSeriesConfig>): JSX.Element | null {
  const { mutateAsync: createConfig } = useCreateSiftConfig();
  const { mutateAsync: updateConfig } = useUpdateSiftConfig();
  const { prometheusFilter } = useSiftConfigDatasources();
  const [ifConditions, setIfConditions] = useState(convertIf(check.if));

  const handleWithCreate = async (updatedCheck: CheckConfig<correlatedSeriesConfig>) => {
    if (updatedCheck.id === undefined) {
      createConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    } else {
      updateConfig(updatedCheck).then(() => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Sift Configuration Saved', `${updatedCheck.title} has been saved.`],
        });
      });
    }
  };

  const defaultValues: CheckConfigWithDatasource<correlatedSeriesConfig> = {
    name: check.name,
    title: getAnalysisDisplayTitle(check.name, check.title),
    disabled: false,
    config: {
      ...check.config,
      prometheusDatasourceUid: '',
    },
    autoDetectDatasource: (check.config?.prometheusDatasourceUid ?? '') === '',
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ config, disabled, autoDetectDatasource, title }) => {
        const saveData: CheckConfig<correlatedSeriesConfig> = {
          id: check.id ?? undefined,
          name: check.name,
          title,
          disabled,
          if: convertConditions(ifConditions),
          config: {
            ...config,
            prometheusDatasourceUid: autoDetectDatasource ? '' : config.prometheusDatasourceUid,
          },
        };

        handleWithCreate(saveData);
        onClose();
      }}
    >
      {({ register, errors, formState, setValue, watch }) => {
        const prometheusDatasourceUid = watch('config.prometheusDatasourceUid');
        const autoDetectDatasource = watch('autoDetectDatasource');
        return (
          <>
            <Field label="Check Title">
              <Input {...register('title')} />
            </Field>

            <Field
              label={<LabelWithHelpText label="Limit" checkName="correlatedSeries" fieldName="limit" />}
              invalid={!!errors.config?.limit}
              error="must be between 1 and 20"
            >
              <Input
                type="number"
                {...register('config.limit', {
                  min: Number(correlatedSeriesConfigSchema.shape.limit.minValue),
                  max: Number(correlatedSeriesConfigSchema.shape.limit.maxValue),
                })}
                placeholder={(defaultConfig?.limit ?? '').toString()}
              />
            </Field>

            <Field
              label={
                <LabelWithHelpText label="Look behind factor" checkName="correlatedSeries" fieldName="lookBehind" />
              }
              invalid={!!errors.config?.lookBehindFactor}
              error="must be between 0 and 3"
            >
              <Input
                type="number"
                step="0.1"
                {...register('config.lookBehindFactor', {
                  min: Number(correlatedSeriesConfigSchema.shape.limit.minValue),
                  max: Number(correlatedSeriesConfigSchema.shape.limit.minValue),
                })}
                placeholder={(defaultConfig?.lookBehindFactor ?? '').toString()}
              />
            </Field>

            <Field
              label={<LabelWithHelpText label="Timeout (seconds)" checkName="correlatedSeries" fieldName="timeout" />}
              invalid={!!errors.config?.timeoutSeconds}
              error="must be between 10 and 120"
            >
              <Input
                type="number"
                {...register('config.timeoutSeconds', {
                  min: Number(correlatedSeriesConfigSchema.shape.timeoutSeconds.minValue),
                  max: Number(correlatedSeriesConfigSchema.shape.timeoutSeconds.maxValue),
                })}
                placeholder={(defaultConfig?.timeoutSeconds ?? '').toString()}
              />
            </Field>

            <DataSourceSelector
              title="Prometheus Datasource"
              dsType="prometheus"
              dsUid={prometheusDatasourceUid}
              autoDetectDatasource={autoDetectDatasource}
              filter={prometheusFilter}
              register={register}
              setValue={setValue}
              valueKey={'config.prometheusDatasourceUid' as const}
            />

            <ConditionsField conditions={ifConditions} onChange={setIfConditions} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" disabled={formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export { CorrelatedSeriesForm };
