import { Analysis, CheckStage } from 'types';

import { useFeature } from './useFeature';

const alwaysShowChecks = ['PublicPreview', 'GeneralAvailability'] as const;

// Return the stages of checks that should be shown given the current feature flags.
export const useCheckStages = (): CheckStage[] => [
  ...alwaysShowChecks,
  ...(useFeature('SIFTExperimental') ? (['Experimental'] as const) : []),
  ...(useFeature('SIFTDeprecated') ? (['Deprecated'] as const) : []),
  ...(useFeature('SIFTDummy') ? (['Dummy'] as const) : []),
];

// Return the names of all checks that should be shown for the given analyses.
export const useChecks = (analyses: Analysis[]): string[] => {
  const stages = useCheckStages();
  return analyses.filter((analysis) => stages.includes(analysis.stage)).map((analysis) => analysis.name);
};
