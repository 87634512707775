import React from 'react';
import { Control, Controller, FieldError, FieldPath, FieldValues, PathValue } from 'react-hook-form';

import { FieldValidationMessage, InlineField, Input, VerticalGroup } from '@grafana/ui';

import { validateNumber } from './validators';

interface InlineNumberFieldProps<R extends FieldValues, N extends FieldPath<R> = FieldPath<R>> {
  error?: FieldError;
  control: Control<R>;
  name: N;
  label?: string;
  tooltip?: string;
  required?: boolean;
  minimum?: number;
  maximum?: number;
  defaultValue?: PathValue<R, N>;
  integerOnly?: boolean;
  validate?: (tv: number) => string | undefined;
}

export function InlineNumberField<R extends FieldValues, N extends FieldPath<R> = FieldPath<R>>({
  error,
  control,
  name,
  label = '',
  tooltip,
  required = true,
  minimum,
  maximum,
  defaultValue,
  integerOnly = false,
  validate,
}: InlineNumberFieldProps<R, N>): JSX.Element | null {
  return (
    <InlineField grow labelWidth={35} label={label} tooltip={tooltip} required={required}>
      <VerticalGroup>
        <Controller
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: required ? 'required' : undefined,
            validate: (tv) => {
              const numCheck = validateNumber(tv as number | string, required, integerOnly, minimum, maximum);
              if (numCheck !== undefined) {
                return numCheck;
              }
              if (validate !== undefined) {
                return validate(Number(tv));
              } else {
                return undefined;
              }
            },
          }}
          name={name}
          render={({ field }) => {
            return (
              <Input
                {...field}
                value={field.value}
                invalid={error != null}
                placeholder={defaultValue == null ? undefined : String(defaultValue)}
                onBlur={(e) => {
                  const res = parseFloat(e.currentTarget.value);
                  field.onChange(isNaN(res) ? undefined : res);
                }}
              />
            );
          }}
        />
        {error == null ? null : <FieldValidationMessage>{error.message ?? ''}</FieldValidationMessage>}
      </VerticalGroup>
    </InlineField>
  );
}
