import React, { FC } from 'react';

import { Icon } from '@grafana/ui';

import { css } from '@emotion/css';

interface DurationDisplayProps {
  startTime: string;
  endTime: string;
}

const getTimeDisplay = (durationInMinutes: number): string => {
  if (Number.isNaN(durationInMinutes)) {
    return '0m';
  }
  if (durationInMinutes < 60) {
    return `${durationInMinutes}m`;
  }
  if (durationInMinutes < 1440) {
    return `${Math.floor(durationInMinutes / 60)}h`;
  }
  return `${Math.floor(durationInMinutes / 1440)}d`;
};

export const DurationDisplay: FC<DurationDisplayProps> = ({ startTime, endTime }) => {
  const duration = new Date(endTime).getTime() - new Date(startTime).getTime();
  const durationInMinutes = Math.floor(duration / 1000 / 60);

  return (
    <div className={styles.duration}>
      <Icon className={styles.iconSpacing} name="clock-nine" />
      {getTimeDisplay(durationInMinutes)}
    </div>
  );
};

const styles = {
  duration: css`
    display: flex;
    align-items: center;
    flex-gap: 8px;
    min-width: 58px;
  `,

  iconSpacing: css`
    margin-right: 2px;
  `,
};
