/*
Trimmed Harrell-Davis quantile estimator based on the highest density interval of the given width
https://arxiv.org/pdf/2111.11776.pdf

This is a TypeScript port of the Python implementation, see trimmed_quantile.py for more documentation
*/
import distBeta from '@stdlib/stats-base-dists-beta';
import { zero } from 'brent-zero-generator';

type NumericOrUndefined = number | undefined;

export const getBetaHDI = (alpha: number, beta: number, width: number): number[] => {
  const eps = 1e-9;
  if (alpha < 1 + eps && beta < 1 + eps) {
    // Degenerate case
    return [NaN, NaN];
  } else if (alpha < 1 + eps && beta > 1) {
    // Left border case
    return [0, width];
  } else if (alpha > 1 && beta < 1 + eps) {
    // Right border case
    return [1 - width, 1];
  }
  if (width > 1 - eps) {
    return [0, 1];
  }

  // Middle case
  const mode = (alpha - 1) / (alpha + beta - 2);

  const pdf = (x: number) => {
    return distBeta.pdf(x, alpha, beta);
  };

  const left = zero((x: number) => pdf(x) - pdf(x + width), Math.max(0, mode - width), Math.min(mode, 1 - width));
  const right = left + width;
  return [left, right];
};

export const thdquantile = (xIn: NumericOrUndefined[], q: number, ignoreNaN = false, sort = true): number => {
  // x: input array
  // q: quantile (e.g. 0.5 for median)
  // ignoreNaN: if true, NaNs and undefineds are ignored (default: false)
  // sort: assumes the `xIn` array is already sorted (default: true)

  let x = xIn.map((v) => (v === undefined ? NaN : v));
  if (ignoreNaN) {
    x = x.filter((v) => Number.isFinite(v));
  }
  // sort while handling undefined
  if (sort) {
    x.sort((a, b) => a - b);
  }

  const width = 1 / Math.sqrt(x.length);
  const n = x.length;
  if (n === 0) {
    return NaN;
  } else if (n === 1) {
    return x[0] === undefined ? NaN : x[0];
  }

  const alpha = (n + 1) * q;
  const beta = (n + 1) * (1 - q);
  const hdi = getBetaHDI(alpha, beta, width);
  const hdiCDFLeft = distBeta.cdf(hdi[0], alpha, beta);
  const hdiCDFRight = distBeta.cdf(hdi[1], alpha, beta);

  const cdf = (xs: number[]) => {
    const result: number[] = new Array(xs.length);
    for (let i = 0; i < xs.length; i++) {
      result[i] = Math.min(
        Math.max((distBeta.cdf(xs[i], alpha, beta) - hdiCDFLeft) / (hdiCDFRight - hdiCDFLeft), 0),
        1
      );
    }
    return result;
  };

  const indexLeft = Math.floor(hdi[0] * n);
  const indexRight = Math.ceil(hdi[1] * n);
  const cdfs = cdf(Array.from({ length: indexRight - indexLeft + 1 }, (_, i) => indexLeft + i).map((x) => x / n));

  const w = new Array(cdfs.length - 1);
  for (let i = 0; i < cdfs.length - 1; i++) {
    w[i] = cdfs[i + 1] - cdfs[i];
  }
  let result = 0;
  for (let i = indexLeft; i < indexRight; i++) {
    // handle undefined and check if index i exists in
    const x_i = x[i];
    if (x_i === undefined) {
      continue;
    }
    result += x_i * w[i - indexLeft];
  }
  return result;
};

export const thdmedian = (x: NumericOrUndefined[], ignoreNaN = false, sort = true): number => {
  return thdquantile(x, 0.5, ignoreNaN, sort);
};

export const thdnanmedian = (x: NumericOrUndefined[], sort = true): number => {
  return thdquantile(x, 0.5, true, sort);
};
