import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { DataFrame, PanelData } from '@grafana/data';

// Helper hook to limit the # of series rendered on initial render for performance
export function useCapLimit(
  inputData: PanelData | undefined,
  capLimit = 20, // maximum number of series to show initially
  samplingFunction = (a: PanelData, b: number): DataFrame[] => {
    return a.series.slice(0, b);
  }
): [PanelData | undefined, boolean, number, Dispatch<SetStateAction<boolean>>] {
  const [shouldCap, setShouldCap] = useState(true);
  const originalCount = inputData?.series.length ?? 0;

  const [didCap, cappedData] = useMemo(() => {
    if (inputData == null) {
      return [false, undefined];
    }
    const capped = inputData.series.length > capLimit && shouldCap;

    const series = capped ? samplingFunction(inputData, capLimit) : inputData.series;
    return [capped, { ...inputData, series }];
  }, [inputData, shouldCap, capLimit, samplingFunction]);

  return [cappedData, didCap, originalCount, setShouldCap];
}
