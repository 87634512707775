import React, { useEffect, useRef } from 'react';

import { DataSourceInstanceSettings, PanelData, TimeRange } from '@grafana/data';

import { QueryEditor } from 'components/QueryEditor';
import { DataQueryWithExpression } from 'types';

export interface InfluxDBQueryEditorProps {
  data: PanelData | undefined;
  datasource?: DataSourceInstanceSettings;
  onRunQuery: (query: DataQueryWithExpression) => void;
  onChange: (query: DataQueryWithExpression) => void;
  query: DataQueryWithExpression;
  timeRange: TimeRange;
}

export function InfluxQueryEditor({
  data,
  datasource: datasourceSettings,
  query,
  onRunQuery,
  onChange,
  timeRange,
}: InfluxDBQueryEditorProps): JSX.Element {
  const userQueryRef = useRef<DataQueryWithExpression | undefined>({
    ...query,
    expr: query.expr ?? '',
  });

  useEffect(() => {
    userQueryRef.current = {
      ...query,
    };
  }, [query]);

  if (userQueryRef.current === undefined) {
    return <></>;
  }

  return (
    <QueryEditor
      data={data}
      name={datasourceSettings?.name}
      timeRange={timeRange}
      query={userQueryRef.current}
      onRunQuery={() => {
        setTimeout(() => {
          if (userQueryRef.current !== undefined) {
            onRunQuery(userQueryRef.current);
          }
        }, 100);
      }}
      onChange={(q) => {
        userQueryRef.current = q;
        onChange(q);
      }}
    />
  );
}
