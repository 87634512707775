import React from 'react';

import { Alert, VerticalGroup } from '@grafana/ui';

interface Props {
  datasourceType: string;
}

export function QueryEditorNotices({ datasourceType }: Props): JSX.Element {
  switch (datasourceType) {
    case 'elasticsearch':
      return (
        <Alert severity="info" title="Note on Elasticsearch queries">
          <VerticalGroup>
            <div>
              The 'Interval' set in Date Histogram aggregations will be ignored in favour of the training model's
              'Training Interval' (default: 5 minutes).
            </div>
            <div>
              For representative query previews, please ensure that the 'Interval' in Date Histograms matches the
              configured 'Training Interval'.
            </div>
          </VerticalGroup>
        </Alert>
      );
  }
  return <></>;
}
