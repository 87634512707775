import React, { useCallback, useRef } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { CappedDataDisclaimer } from 'components/CappedDataDisclaimer';
import { FORECAST_RENDER_CAP_LIMIT } from 'consts';
import { useCapLimit } from 'hooks/useCapLimit';

import { JobGraphProps } from './JobGraph.types';
import { JobGraphContent } from './JobGraphContent';

export const JobGraph: React.FC<JobGraphProps> = ({ data, maxDataPoints, onChangeMaxDataPoints, ...rest }) => {
  const styles = useStyles2(getStyles);
  const detectMaxDataPoints = useDetectMaxDataPoint(maxDataPoints, onChangeMaxDataPoints);

  // Limit the # of series rendered on initial render for performance
  const capLimit = FORECAST_RENDER_CAP_LIMIT; // same limit as Grafana Explore
  const [cappedData, didCap, originalCount, setShouldCap] = useCapLimit(data, capLimit);

  return (
    <>
      {didCap && <CappedDataDisclaimer originalCount={originalCount} capLimit={capLimit} setShouldCap={setShouldCap} />}
      <div className={styles.container}>
        <AutoSizer>
          {({ width, height }: Size) => {
            if (width === undefined || width < 3 || height === undefined || height < 3) {
              return <div>Window is too small to render graph, please increase size.</div>;
            }

            detectMaxDataPoints(width);

            return (
              <div style={{ width, height }}>
                <JobGraphContent
                  {...(rest as Omit<JobGraphProps, 'maxDataPoints' | 'onChangeMaxDataPoints'>)}
                  data={cappedData}
                  width={width}
                  height={height}
                />
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};

// TODO: add a debouce to this function to prevent multiple when changing screen size.
const useDetectMaxDataPoint = (maxDataPoints: number, onChange: (maxDataPoints: number) => void) => {
  const previous = useRef<number>(maxDataPoints);

  return useCallback(
    (width: number) => {
      if (width === previous.current) {
        return;
      }

      previous.current = width;
      onChange(width);
    },
    [onChange]
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      flex: 1 1 0;
      width: 100%;
      padding: ${theme.spacing(2)};
    `,
  };
}
