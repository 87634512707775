import React, { FC } from 'react';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { Badge, Icon, LinkButton, Spinner, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import Card from 'components/Card';
import { DurationDisplay } from 'components/DurationDisplay';
import { PLUGIN_ROOT } from 'consts';
import { useCheckStages } from 'hooks';
import { AnalysisCounts, Investigation } from 'types';

import { Source } from '../../Components/Source';
import { LabelTooltip } from './LabelTooltip';

interface InvestigationListItemProps {
  item: Investigation;
  onlyShowInteresting: boolean;
}

export const InvestigationListItem: FC<InvestigationListItemProps> = ({ item, onlyShowInteresting }) => {
  const checkStages = useCheckStages();
  const startTime = new Date(item.requestData.start);
  const endTime = new Date(item.requestData.end);

  const viewInvestigation = (id: string) => {
    locationService.push({
      pathname: `${PLUGIN_ROOT}/investigations/${id}`,
      state: { returnTo: locationService.getLocation() },
    });
  };

  const counts = checkStages
    .map((s) => item.analyses.countsByStage[s])
    .reduce(
      (acc: AnalysisCounts, state) => ({
        total: (acc.total += state?.total ?? 0),
        pending: (acc.pending += state?.pending ?? 0),
        running: (acc.running += state?.running ?? 0),
        succeeded: (acc.succeeded += state?.succeeded ?? 0),
        failed: (acc.failed += state?.failed ?? 0),
        interesting: (acc.interesting += state?.interesting ?? 0),
        skipped: (acc.skipped += state?.skipped ?? 0),
      }),
      {
        total: 0,
        pending: 0,
        running: 0,
        succeeded: 0,
        failed: 0,
        interesting: 0,
        skipped: 0,
      }
    );

  if (onlyShowInteresting && counts.interesting === 0) {
    return null;
  }

  return (
    <>
      <Card height="subtle" variant="secondary-gray" className={styles.card} onClick={() => viewInvestigation(item.id)}>
        <div className={styles.investigation}>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.details}>
            <div>
              <Status
                counts={counts}
                investigationStatus={item.status}
                investigationFailureReason={item.failureReason}
                {...item.analyses}
              />
            </div>
            <div>|</div>
            <div className={styles.timestamp}>
              {startTime.toLocaleString()} - {endTime.toLocaleString()}
            </div>
            <div>|</div>
            <DurationDisplay startTime={item.requestData.start} endTime={item.requestData.end} />
            <div>|</div>
            <Source source={item.requestData.investigationSource} iconOnly />
            <LabelTooltip item={item} />
          </div>
        </div>
      </Card>
    </>
  );
};

interface StatusProps {
  counts: AnalysisCounts;
  investigationStatus: Investigation['status'];
  investigationFailureReason: Investigation['failureReason'];
}

const getIconAndClassName = (
  investigationStatus: Investigation['status'],
  investigationFailureReason: Investigation['failureReason'],
  counts: AnalysisCounts
): { icon: JSX.Element; className: keyof ReturnType<typeof getStyles> } => {
  if (investigationStatus === 'pending' || counts.running > 0) {
    return { icon: <Spinner key="spinner" />, className: 'spin' };
  }
  let icon: IconName;
  let tooltip: string;
  let className: keyof ReturnType<typeof getStyles>;
  if (counts.interesting > 0) {
    icon = 'bolt';
    tooltip = 'Found interesting results.';
    className = 'interesting';
  } else if (counts.failed > 0) {
    icon = 'info-circle';
    tooltip = 'Some analyses failed.';
    className = 'unsuccessful';
  } else if (investigationStatus === 'failed') {
    icon = 'exclamation-triangle';
    tooltip = 'Investigation failed. ' + investigationFailureReason;
    className = 'unsuccessful';
  } else {
    icon = 'check';
    tooltip = 'No interesting results found.';
    className = 'passed';
  }
  return {
    icon: (
      <Tooltip content={tooltip}>
        <Icon name={icon} />
      </Tooltip>
    ),
    className,
  };
};

function Status({ counts, investigationStatus, investigationFailureReason }: StatusProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const { icon, className } = getIconAndClassName(investigationStatus, investigationFailureReason, counts);
  const message =
    investigationStatus === 'failed'
      ? 'Failed'
      : investigationStatus === 'pending' || counts.running > 0
      ? 'In progress'
      : counts.interesting > 0
      ? `${counts.interesting} interesting results`
      : '';
  return (
    <div className={styles.status}>
      <span className={styles[className]}>{icon}</span>
      {message}
    </div>
  );
}

export function PlaceholderInvestigationListItem(): JSX.Element {
  return (
    <Card height="flat" className={styles.card}>
      <header className={styles.heading}>
        <h5>Loading</h5>
      </header>

      <aside className={styles.labels}>
        <div className={styles.statusWithMessage}>
          <Badge text="loading..." color="purple" />
          <Spinner />
        </div>
      </aside>

      <aside className={styles.status}>Loading...</aside>

      <div className={styles.actions}>
        <div className={styles.primary}>
          <LinkButton
            key="view"
            variant="secondary"
            href={`${PLUGIN_ROOT}/investigations/`}
            disabled
            className={styles.disabled}
          >
            View
          </LinkButton>
        </div>
      </div>
    </Card>
  );
}

const styles = {
  list: css`
    list-style-position: inside;
  `,

  empty: css``,

  card: css`
    display: flex;
    padding: 12px 12px 12px 20px;
    cursor: pointer;
  `,

  statusWithMessage: css`
    display: flex;
    align-items: center;
    gap: 12px;
    flex-flow: row nowrap;
  `,

  status: css`
    grid-area: status;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    font-size: 12px;
    opacity: 0.7;

    > span:not(:last-child):after {
      content: '|';
      padding-left: 6px;
    }
  `,

  heading: css`
    grid-area: header;
    > h5 {
      font-size: 14px;
      font-weight: 500;
    }
    > div {
      opacity: 0.8;
    }
  `,

  investigation: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,

  title: css`
    display: flex;
    widht: 100%;
    align-items: flex-start;
    font-size: 16px;
  `,

  details: css`
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 14px;
    font-size: 12px;
  `,

  timestamp: css`
    width: auto;
  `,

  labels: css`
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 4px 6px;
    cursor: default;
  `,

  actions: css`
    display: flex;
    align-items: center;
    padding-left: 20px;
  `,

  secondary: css`
    display: flex;
    flex-flow: row nowrap;
    gap: 3px;
  `,
  primary: css``,

  disabled: css`
    opacity: 0.6;
    cursor: not-allowed;
  `,

  iconSpacing: css`
    margin-right: 2px;
  `,
};

const getStyles = (theme: GrafanaTheme2) => ({
  spin: css``,
  passed: css`
    stroke: ${theme.colors.success.main};
  `,
  interesting: css`
    stroke: #ff8833;
    fill: #ff8833;
  `,
  unsuccessful: css`
    stroke: ${theme.colors.error.main};
  `,
  status: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
});
