import { QueryObserverResult, useQuery } from 'react-query';

import { config } from '@grafana/runtime';

import { Clue, Input, InvestigationPreview, NewInvestigation, ResponseWrapper, UseQueryOptions } from 'types';

import { adminApiPost, isErrorResponse } from './core.api';
import { getSiftPreview } from './endpoints/sift';

export async function cluesToInputs({ clues }: { clues: Clue[] }): Promise<Input[]> {
  const url = '/sift/api/v1/clues-to-inputs';
  const headers = { 'x-grafana-url': config.appUrl };
  const response = await adminApiPost<ResponseWrapper<Input[]>>(url, { headers, data: { clues } });
  if (isErrorResponse(response)) {
    throw new Error(response.data.error);
  }
  return response.data.data;
}

/*
 * Fetches the preview of the investigation based on the current configuration.
 */
export function useSiftPreview(
  investigation: NewInvestigation,
  options: UseQueryOptions = {}
): QueryObserverResult<InvestigationPreview, Error> {
  return useQuery<any, Error>(['sift-preview'], async () => getSiftPreview(investigation), {
    retry: false,
    refetchOnMount: false,
    cacheTime: 0,
    ...options,
  });
}
