import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsyncFn';

import { ScopedVars } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

export function useInterpolatedQuery(query: DataQuery, scopedVars?: ScopedVars): AsyncState<DataQuery> {
  return useAsync(async () => {
    const ds = await getDataSourceSrv().get(query.datasource);

    if (!ds.interpolateVariablesInQueries) {
      return query;
    }

    const [interpolated] = ds.interpolateVariablesInQueries([query], scopedVars ?? {});
    return interpolated;
  }, [query, scopedVars]);
}
