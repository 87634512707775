import React, { FC } from 'react';

import { Spinner } from '@grafana/ui';

import { cx } from '@emotion/css';

import { useUtilityStyles } from 'utils';

interface Props {
  text?: string;
}

export const LoadingIndicator: FC<Props> = ({ text = 'Loading...' }) => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.flex, s.justifyCenter)}>
      <div className={s.marginRightSm}>{text}</div>
      <Spinner />
    </div>
  );
};
