import { AlertStateCounts, RuleInstance } from 'types';

export function getAlertStateCounts(alertRules: RuleInstance[]): AlertStateCounts {
  return alertRules.reduce(
    (counts: AlertStateCounts, rule: RuleInstance) => {
      if (rule.health === 'err' || rule.health === 'error') {
        counts.error += 1;
      }
      counts[rule.state] += 1;
      counts['total'] += 1;
      return counts;
    },
    { firing: 0, error: 0, pending: 0, inactive: 0, total: 0 }
  );
}

export function alertStateCountsEq(a: AlertStateCounts, b: AlertStateCounts): boolean {
  return (
    a.error === b.error &&
    a.pending === b.pending &&
    a.total === b.total &&
    a.firing === b.firing &&
    a.inactive === b.inactive
  );
}
