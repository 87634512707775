import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

import {
  DataFrame,
  dataFrameFromJSON,
  DataFrameJSON,
  DateTime,
  dateTime,
  LoadingState,
  PanelData,
  ScopedVars,
  TimeRange,
} from '@grafana/data';
import { FetchResponse, getDataSourceSrv } from '@grafana/runtime';
import { DataQuery, TimeZone } from '@grafana/schema';

import { adminApiPost, getOutlierPayload } from 'api';
import { OutlierField, OutlierIntervals, OutlierResponseType } from 'api/types/outlier.types';

import { getQueryCustomParams } from '../../utils';
import { isBackendError } from '../ErrorAlert/guards';

type OutlierOptions = {
  query?: DataQuery;
  timeRange: TimeRange;
  timeZone: TimeZone;
  scopedVars?: ScopedVars;
  algorithm: string;
  sensitivity: number;
};

type QueryResult = {
  status: number;
  frames: DataFrameJSON[];
};

const interval = 60;

export function useOutlier(opts: OutlierOptions): AsyncState<PanelData | undefined> {
  const { query, timeRange, scopedVars, timeZone, algorithm, sensitivity } = opts;

  return useAsync(async (): Promise<PanelData | undefined> => {
    try {
      if (!query) {
        return undefined;
      }

      const datasource = getDataSourceSrv().getInstanceSettings(query.datasource);

      if (!datasource) {
        throw new Error('Could not find datasource');
      }

      const payloadOptions = {
        queryParams: getQueryCustomParams(query),
        algorithm: algorithm,
        sensitivity: sensitivity,
        startTime: formatAsUTC(timeRange.from),
        endTime: formatAsUTC(timeRange.to),
        interval: interval,
        datasource: datasource,
      };

      const labelResponse = await adminApiPost('/proxy/api/v1/outlier', {
        data: getOutlierPayload({
          ...payloadOptions,
          responseType: OutlierResponseType.Label,
        }),
      });

      if (isErrorResponse(labelResponse) || isAlgorithmErrorResponse(labelResponse)) {
        const message = labelResponse.data.data.results.A?.error ?? 'Failed to detect outlier, please try later';
        throw new Error(message);
      }

      const series = mapToSeries(query.refId, labelResponse.data.data.results);

      if (series.length <= 0) {
        return mapToPanelData({
          series,
          timeRange,
          timeZone,
          query: query,
          scopedVars,
        });
      }

      // generate list of series that have outliers
      const outlierSeries = findSerieIndexesWithOutliers(series);

      if (outlierSeries.length <= 0) {
        return mapToPanelData({
          series,
          timeRange,
          timeZone,
          query: query,
          scopedVars,
        });
      }

      const binaryResponse = await adminApiPost('/proxy/api/v1/outlier', {
        data: getOutlierPayload({
          ...payloadOptions,
          responseType: OutlierResponseType.Binary,
        }),
      });

      for (const seriesIndex of outlierSeries) {
        const seriesFrames = (binaryResponse.data.data.results.A ?? { frames: [] }).frames;
        const theSeries = seriesFrames !== undefined ? seriesFrames[seriesIndex] : { data: { values: [] } };
        const seriesValues = theSeries?.data?.values ?? [];
        const timestamps = seriesValues[0] ?? [];
        const values = seriesValues[1] ?? [];

        const outlierIntervals: OutlierIntervals = [];
        let previousOutlierState = 0; // assume start non-outlier, as only want to find outlier intervals

        for (let j = 0; j < timestamps.length; j++) {
          const ts = timestamps[j] as number;
          const outlierState = values[j] as number; // 0=normal, 1=outlier

          if (outlierState !== previousOutlierState) {
            // something changed, so take note of timestamp where change happened
            outlierIntervals.push(ts);
            previousOutlierState = outlierState;
          }
        }

        // Attach the OutlierIntervals to the Field as an additional property
        const field = series[seriesIndex].fields[1] as OutlierField<number>;
        field.outlierIntervals = outlierIntervals;
      }

      return mapToPanelData({
        series,
        timeRange,
        timeZone,
        query: query,
        scopedVars,
      });
    } catch (error) {
      if (isBackendError(error)) {
        error.isHandled = true;
      }
      throw error;
    }
  }, [timeRange, query, scopedVars, timeZone, algorithm, sensitivity]);
}

function formatAsUTC(date: DateTime): string {
  return dateTime(date).utc().format('YYYY-MM-DDTHH:mm:ss');
}

function mapToSeries(refId: string, results: Record<string, QueryResult> | undefined): DataFrame[] {
  const frames = results?.[refId].frames ?? [];
  return frames.map((frame) => {
    return dataFrameFromJSON(frame);
  });
}

function isErrorResponse(response: FetchResponse): boolean {
  return response.status < 200 || response.status >= 300;
}

function isAlgorithmErrorResponse(response: FetchResponse): boolean {
  return !!response.data.data.results.A?.error;
}

type PanelDataMapperOpts = {
  series: DataFrame[];
  timeRange: TimeRange;
  timeZone: TimeZone;
  query: DataQuery;
  scopedVars: ScopedVars | undefined;
};

function mapToPanelData(opts: PanelDataMapperOpts): PanelData {
  const { series, timeRange, timeZone, query, scopedVars } = opts;

  return {
    state: LoadingState.Done,
    series: series,
    timeRange: timeRange,
    request: {
      app: 'ML',
      startTime: Date.now(),
      timezone: timeZone,
      range: timeRange,
      intervalMs: interval * 1000,
      requestId: '0',
      interval: String(interval),
      scopedVars: scopedVars ?? {},
      targets: [query],
    },
  };
}

function findSerieIndexesWithOutliers(series: DataFrame[]): number[] {
  return series.reduce((indexes: number[], frame, index) => {
    if (frame.fields?.[1]?.labels?.isOutlier === 'True') {
      indexes.push(index);
    }
    return indexes;
  }, []);
}
