import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppEvents, DataSourceInstanceSettings, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Alert, Badge, PageToolbar, ToolbarButton, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { isEqual } from 'lodash';

import { useAllModels } from 'api';
import { JobGraph } from 'components/JobGraph';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { TabView } from 'components/TabView/TabView';
import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { DEFAULT_NEW_JOB_FORM, PLUGIN_ROOT } from 'consts';
import { useConfirmModal, useQueryResult } from 'hooks';
import { useQueryContext } from 'hooks/useQueryContext';
import { useTimeRange } from 'hooks/useTimeRange';
import { AlgorithmEditorTab } from 'projects/Forecasting/AlgorithmEditorTab';
import { AttachHoliday } from 'projects/Forecasting/AttachHoliday';
import { QueryEditorTab } from 'projects/Forecasting/QueryEditorTab';
import { DataQueryWithExpression, FullFormModel, TenantInfo } from 'types';
import { defaultCreateJobTimeRange, onHandler, useSelectedModel } from 'utils';

import { errorReason, errorString } from '../../../utils/errorHandling';
import { getTabs, TabProps } from '../../../utils/tabs';
import { useForecastingState } from '../useForecastingState';
import { CreateJobModal } from './CreateJobModal';

export const CreateJobContent: React.FC<{
  initialDataSource: DataSourceInstanceSettings;
  tenantInfo: TenantInfo;
}> = ({ initialDataSource, tenantInfo }) => {
  const styles = useStyles2(getStyles);
  const {
    data: forecastState,
    setInitialState,
    setDatasource,
    setQueryChangedSinceLastRun: setQueryDirty,
    setQueryValid,
    setQueryError,
    setQueryInProgress,
    setShowCreateModal,
    setParamsDirty,
    setParamsValid,
    setParameters,
    setQuery,
  } = useForecastingState();
  const navigate = useNavigate();
  const tabs = getTabs(QueryEditorTab, AlgorithmEditorTab, AttachHoliday);
  const { data: models, isLoading, isError } = useAllModels();
  const [holidays, setHolidays] = useState<string[]>([]);
  const [selectedModelId, setSelectedModelId] = useSelectedModel(models);
  const { queryParams, updateQuery } = useQueryContext();

  const [maxDataPoints, onChangeMaxDataPoints] = useState<number>(100);
  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultCreateJobTimeRange()
  );

  useEffect(() => {
    setInitialState(initialDataSource);
  }, [setInitialState, initialDataSource]);

  const finalQuery = useMemo(() => (queryParams === undefined ? [] : [queryParams]), [queryParams]);

  const [data, isRefreshing, runQuery, cancelQuery] = useQueryResult(
    finalQuery,
    maxDataPoints,
    timeRange,
    timeZone,
    forecastState.datasource?.uid ?? ''
  );

  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [showErrorBadge, setShowErrorBadge] = useState<boolean>(false);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    if (data?.state === 'Error' || data?.state === 'Done') {
      // the two query-completed states
      // Request is erroneous either due to invalid query, or no data being returned
      const requestError = errorReason(data, tenantInfo.maxSeriesPerJob);
      if (requestError !== null) {
        const error = errorString(requestError);
        setQueryError(error);
        if (
          requestError?.kind === 'HasDuplicateSeries' ||
          requestError?.kind === 'TooManySeries' ||
          requestError?.kind === 'InvalidQuery'
        ) {
          getAppEvents().publish({ type: AppEvents.alertError.name, payload: [`Error with query: ${error}`] });
        }
        if (!firstRun) {
          // first run is with default query, it will fail, but want to hide the error
          setShowErrorBadge(true);
        }
      } else {
        setQueryValid();
        setShowErrorBadge(false);
        if (firstRun) {
          setFirstRun(false);
        }
      }
    } else {
      // the loading state
      setQueryInProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.state, data]);

  useEffect(() => {
    // hide the error badge when the user changes the query
    setShowErrorBadge(false);
  }, [forecastState.dirty]);

  const [confirmAbandon, ConfirmAbandon] = useConfirmModal(
    async () => {
      navigate(`${PLUGIN_ROOT}/metric-forecast`, { replace: true });
    },
    { skipConfirm: !forecastState.dirty }
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || models == null) {
    return <Alert title="Error loading">Error while loading ML models</Alert>;
  }

  return (
    <>
      <ConfirmAbandon icon="exclamation-triangle" title="Unsaved changes" actionText="Yes">
        <div>Are you sure you want to discard changes to this forecast?</div>
      </ConfirmAbandon>
      {forecastState.datasource !== undefined ? (
        <CreateJobModal
          isOpen={forecastState.showCreateModal}
          queryParams={queryParams}
          datasource={forecastState.datasource}
          modelForm={forecastState.modelForm}
          onDismiss={() => setShowCreateModal(false)}
          attachedHolidays={holidays}
        />
      ) : null}

      <div>
        <PageToolbar className={styles.pageToolbar} data-element="forecasting-create-buttons">
          {showErrorBadge ? (
            <Badge color="red" icon="exclamation-circle" text="Error" tooltip={forecastState.queryErrorMessage} />
          ) : null}
          <ToolbarButton
            form="algorithm-form"
            disabled={!forecastState.isValid || !forecastState.dirty}
            variant="primary"
            title={
              !forecastState.isValid
                ? `To continue: ${forecastState.errorMessages.join('. ')}`
                : 'Save and go back to list of forecasts'
            }
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            Save
          </ToolbarButton>
          <ToolbarTimeRange
            timeRange={timeRange}
            isRefreshing={isRefreshing}
            timeZone={timeZone}
            onRefresh={runQuery}
            onChangeTimeRange={onChangeTimeRange}
            onChangeTimeZone={onChangeTimeZone}
            onZoomTimeRange={onZoomTimeRange}
          />
          <ToolbarButton id="Cancel" onClick={onHandler(confirmAbandon)} title="Undo all changes">
            Cancel
          </ToolbarButton>
        </PageToolbar>
        <div className={styles.contentContainer}>
          <div className={styles.queryContainer}>
            <TabView<TabProps>
              tabs={tabs}
              tabProps={{
                selectedModelId,
                setSelectedModelId,
                models,
                defaultValues: DEFAULT_NEW_JOB_FORM,
                datasource: forecastState.datasource,
                setParamsDirty,
                setParamsValid,
                onUpdateDatasource: (datasource: DataSourceInstanceSettings) => {
                  setDatasource(datasource);
                },
                onRunQuery: (query: DataQueryWithExpression) => {
                  // Don't update the query if we're using Elasticsearch.
                  // The Elasticsearch query editor calls onRunQuery whenever the
                  // query input is updated, not just when a query is run. This can
                  // result in an infinite loop where various components are trying
                  // to rerender each other.
                  // TODO: Get this fixed in the Elasticsearch query editor.
                  // See https://github.com/grafana/machine-learning/issues/3692.
                  if (forecastState.datasource?.type !== 'elasticsearch') {
                    setQuery(query);
                  }
                  updateQuery(query);
                },
                onQueryChange: (query: DataQueryWithExpression) => {
                  const changedSinceLastRun = !isEqual(query, queryParams);
                  setQueryDirty(changedSinceLastRun);
                },
                onSave: (modelForm: FullFormModel) => {
                  setParameters(modelForm.parameters);
                },
                timeRange,
                setSelectedHolidays: setHolidays,
                preSelectedHolidayIds: holidays,
                holidays,
              }}
            />
          </div>
          <div className={styles.chartContainer}>
            <JobGraph
              data={data}
              timeZone={timeZone}
              timeRange={timeRange}
              isRefreshing={isRefreshing}
              onChangeTimeRange={onChangeTimeRange}
              onRunQuery={runQuery}
              onCancelQuery={cancelQuery}
              maxDataPoints={maxDataPoints}
              onChangeMaxDataPoints={onChangeMaxDataPoints}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  contentContainer: css`
    display: flex;
    flex-direction: column;
  `,
  queryContainer: css`
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.weak};
    border-radius: 3px;
    margin-bottom: 18px;
  `,
  chartContainer: css`
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.weak};
    border-radius: 3px;
    height: calc(100vh - 400px);
  `,
  pageToolbar: css({
    backgroundColor: 'unset',
    padding: 'unset',
    marginBottom: theme.spacing(2),
  }),
});
