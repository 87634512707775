import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Badge, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { DurationDisplay } from 'components/DurationDisplay';
import { LabelTooltip } from 'projects/Sift/Tabs/List/LabelTooltip';
import { Investigation } from 'types';
import { formatDateTime } from 'utils';

import { Source } from '../Components/Source';

interface InvestigationMetadataProps {
  investigation: Investigation;
}

export function InvestigationMetadata({ investigation }: InvestigationMetadataProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const startTime = new Date(investigation.requestData.start);
  const endTime = new Date(investigation.requestData.end);

  return (
    <div className={styles.metaLayout}>
      <Badge
        color={investigation.status === 'finished' ? 'green' : 'blue'}
        text={investigation.status === 'finished' ? 'Completed' : 'In progress'}
      />
      <LabelTooltip item={investigation} />
      <div className={styles.timeLayout}>
        <div className={styles.fieldLabel}>Time range:</div>
        <div>
          {formatDateTime(startTime)} - {formatDateTime(endTime)}
        </div>
      </div>
      <DurationDisplay startTime={investigation.requestData.start} endTime={investigation.requestData.end} />
      <Source source={investigation.requestData.investigationSource} />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  metaLayout: css`
    display: flex;
    gap: 16px;
    margin-bottom: 10px;
  `,
  outer: css`
    display: flex;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.weak};
    padding: 18px;
    margin-bottom: 16px;
    border-radius: 2px;
  `,
  left: css`
    flex-direction: left;
    margin: auto;
  `,
  right: css`
    flex-direction: right;
    margin: auto;
  `,
  timeLayout: css`
    display: flex;
    align-self: center;
    gap: 8px;
  `,
  fieldLabel: css`
    color: ${theme.colors.text.secondary};
  `,
});
