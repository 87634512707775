import React, { useState } from 'react';

import { dateTime, GrafanaTheme2, TimeRange } from '@grafana/data';
import { Button, Field, FieldValidationMessage, Icon, Input, TimeRangeInput, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { Labels, LabelValidation } from 'grafana-ml-common/components/Labels';

import { getSiftPreview } from 'api/endpoints/sift';
import { useFeature } from 'hooks';
import useRudderStack, { RudderstackEvents } from 'hooks/useRudderstack';
import { createInvestigationRequestFromFormModel } from 'utils';
import { queryClient } from 'utils/queryClient';

import { DurationDisplay } from '../../components/DurationDisplay';
import { Services, ServiceValidation } from '../../components/ServicesForm';
import {
  InvestigationFormModel,
  InvestigationPreview,
  InvestigationSourceType,
  Label,
  NewInvestigation,
  Service,
} from '../../types';
import { PreviewPanel } from './Components/PreviewPanel';

export const defaultNewInvestigationForm = (): InvestigationFormModel => {
  const end = new Date();
  // 30 minutes, for consistency with the backend defaults.
  const start = new Date(end.getTime() - 30 * 60 * 1000);
  return {
    name: '',
    start: start.toISOString(),
    end: end.toISOString(),

    labels: [
      { name: 'cluster', value: '' },
      { name: 'namespace', value: '' },
    ],

    services: [],
    // Hidden on the form but sometimes populated by clues provided by extensions;
    // see overrideInputs in CreateInvestigation.tsx.
    queries: [],

    investigationSource: {
      type: InvestigationSourceType.ML,
      id: '',
      url: '',
    },
  };
};

interface RunSiftFormProps {
  defaults?: InvestigationFormModel;
  onSubmit: (form: NewInvestigation) => void;
  onClose?: () => void;
}

export default function RunSiftForm({ defaults, onClose, onSubmit }: RunSiftFormProps): React.ReactElement | null {
  const styles = useStyles2(getStyles);
  const trackRudderStackEvent = useRudderStack();
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [previewResults, setPreviewResults] = useState<InvestigationPreview | undefined>(undefined);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [formData, setFormData] = useState<InvestigationFormModel>(defaults ?? defaultNewInvestigationForm());
  const showPreviewFeature = useFeature('SIFTPreview');

  const setChangingDone = () => {
    setIsChanging(false);
    setIsPreviewLoading(false);
    setPreviewResults(undefined);
    queryClient.cancelQueries({ queryKey: ['sift-preview'] });
  };

  const onFieldChange = (field: string, value: string | TimeRange | Label[] | Service[]) => {
    // time range is broken into start/end strings
    if (field === 'timeRange') {
      setFormData({
        ...formData,
        start: (value as TimeRange).from.toISOString(),
        end: (value as TimeRange).to.toISOString(),
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const startInvestigation = (form: InvestigationFormModel) => {
    const labelValidation = getLabelValidation(form.labels, undefined);
    const serviceValidation = getServiceValidation(form.services ?? []);
    if (
      labelValidation.valid === true &&
      formData.name !== '' &&
      formData.labels.length > 0 &&
      serviceValidation.valid === true
    ) {
      trackRudderStackEvent(RudderstackEvents.CreateInvestigation, {
        labelsEdited: JSON.stringify(defaults?.labels) !== JSON.stringify(form.labels),
        labels: form.labels.length,
        labelsSuggested: defaults?.labels.length,
        timeRangeEdited: defaults?.start !== form.start || defaults?.end !== form.end,
        investigationSourceType: form.investigationSource.type,
        services: form.services?.length,
      });
      onSubmit(createInvestigationRequestFromFormModel(form));
    } else {
      setShowErrors(true);
    }
  };

  const getPreviewValidation = (labels: Label[], preview: InvestigationPreview | undefined) =>
    labels.reduce((result, item) => {
      const message =
        preview?.labels[item.name]?.included === false ? 'Label will not be used in the investigation' : '';

      return [...result, message];
    }, [] as string[]);

  // validate if labels meet requirements
  const getLabelValidation = (labels: Label[], preview: InvestigationPreview | undefined) =>
    labels.reduce(
      (result, item, i) => {
        const message =
          preview?.labels[item.name]?.included === false
            ? 'Label will not be used in the investigation'
            : labels.filter(({ name }, j) => name === item.name && i !== j).length > 0
            ? 'Duplicate labels are not allowed'
            : item.name === ''
            ? 'Label names cannot be empty'
            : item.value === ''
            ? 'Label value cannot be empty'
            : '';

        return {
          valid: result.valid === false ? false : message === '',
          messages: [...result.messages, message],
        };
      },
      { valid: true, messages: [] } as LabelValidation
    );

  // validate if service inputs meet requirements
  const getServiceValidation = (services: Service[]) =>
    services.reduce(
      (result, item, i) => {
        const message =
          services.filter(({ name, namespace }, j) => name === item.name && namespace === item.namespace && i !== j)
            .length > 0
            ? 'Duplicate services are not allowed'
            : item.name === ''
            ? 'Service name cannot be empty'
            : '';

        return {
          valid: result.valid === false ? false : message === '',
          messages: [...result.messages, message],
        };
      },
      { valid: true, messages: [] } as ServiceValidation
    );

  const timeRangeValue: TimeRange = {
    from: dateTime(formData.start),
    to: dateTime(formData.end),
    raw: { from: formData.start, to: formData.end },
  };

  // showPreviewFeature
  const labels = formData.labels.reduce<Record<string, string>>((acc, label) => {
    acc[label.name] = label.value;
    return acc;
  }, {});

  // has empty label?
  const emptyLabel =
    formData.labels.filter((v) => v.value === '' || v.value === undefined || v.value === null).length > 0;

  // at least one label is required for the preview
  const hasLabel =
    formData.labels.filter((v) => v.value !== '' && v.value !== undefined && v.value !== null).length > 0;

  const newInvestigation: NewInvestigation = {
    name: 'Sift Investigation Preview',
    requestData: {
      labels,
      start: formData.start,
      end: formData.end,
      investigationSource: {
        type: InvestigationSourceType.ML,
        id: '',
        url: '',
      },
    },
  };

  // if changes are done, and we have labels, lets run the preview call here
  if (
    showPreviewFeature &&
    isPreviewLoading === false &&
    !isChanging &&
    !emptyLabel &&
    hasLabel &&
    previewResults === undefined
  ) {
    setIsPreviewLoading(true);
    queryClient
      .fetchQuery('sift-preview', () => getSiftPreview(newInvestigation))
      .then((result) => {
        setPreviewResults(result);
        setIsPreviewLoading(false);
      });
  }

  return (
    <form id="investigation-form" className={styles.form} data-element="sift-create-investigation-form">
      <Field label="Investigation name">
        <Input
          name="investigation-name"
          value={formData.name}
          onKeyDownCapture={() => {
            setIsChanging(true);
          }}
          onBlur={() => {
            setChangingDone();
          }}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onFieldChange('name', e.currentTarget.value)}
          autoFocus={true}
        />
      </Field>
      {showErrors && formData.name === '' ? (
        <FieldValidationMessage className={styles.error}>Name field cannot be empty</FieldValidationMessage>
      ) : null}
      <Field label="Labels">
        <Labels
          validation={getLabelValidation(formData.labels, previewResults)}
          previewMessage={getPreviewValidation(formData.labels, previewResults)}
          showErrors={showErrors}
          values={formData.labels}
          onChange={(labels) => {
            onFieldChange('labels', labels);
          }}
          onStartChange={() => {
            setIsChanging(true);
          }}
          onEndChange={() => {
            setChangingDone();
          }}
        />
      </Field>
      {showErrors && formData.labels.length === 0 && (
        <FieldValidationMessage className={styles.error}>At least one label must be provided</FieldValidationMessage>
      )}
      <Field
        label={
          <div className={styles.label}>
            Services
            <Tooltip content={'Investigate specific services using OpenTelemetry data (optional)'}>
              <div className={styles.info}>
                <Icon tabIndex={0} name="info-circle" size="sm" />
              </div>
            </Tooltip>
          </div>
        }
      >
        <Services
          validation={getServiceValidation(formData.services ?? [])}
          showErrors={showErrors}
          values={formData.services ?? []}
          onChange={(services) => {
            onFieldChange('services', services);
          }}
          onStartChange={() => {
            setIsChanging(true);
          }}
          onEndChange={() => {
            setChangingDone();
          }}
        />
      </Field>
      <Field label="Time range">
        <>
          <TimeRangeInput
            key="timerange"
            value={timeRangeValue}
            onChange={(range) => {
              onFieldChange('timeRange', range);
            }}
          />
          <div className={styles.notes}>
            Selected investigation time range:
            <DurationDisplay startTime={formData.start} endTime={formData.end} />
          </div>
        </>
      </Field>

      {showPreviewFeature ? (
        <PreviewPanel
          isLoading={isPreviewLoading}
          showResults={!isChanging && !emptyLabel && hasLabel}
          previewResults={previewResults}
        />
      ) : null}
      <div className={styles.buttons} data-element="sift-form-buttons">
        <Button
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            startInvestigation(formData);
          }}
        >
          Create
        </Button>
      </div>
    </form>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    form: css`
      min-height: 500px;
    `,
    optional: css`
      background-color: ${theme.colors.background.secondary};
      padding: 20px;
    `,
    notes: css`
      display: flex;
      gap: 8px;
      padding-top: 8px;
      color: ${theme.colors.text.secondary};
      font-size: ${theme.typography.bodySmall.fontSize};
    `,
    buttons: css`
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      padding: 20px 0px;
    `,
    optionButton: css`
      margin-left: -10px;
    `,
    optionField: css`
      margin: 10px 0px;
    `,
    error: css`
      width: 100%;
      margin-bottom: 10px;
    `,
    info: css`
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 5px;
      justify-content: space-between;
    `,
    label: css`
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
    `,
    previewWrapper: css`
      background-color: ${theme.colors.background.secondary};
      padding: 20px;
    `,
  };
};
