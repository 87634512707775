import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useSiftConfig } from 'api/queries';
import { useCheckStages } from 'hooks';
import { ConfigModalState } from 'types';

import { ConfigurationItem } from './ConfigurationItem';
import { ConfigurationModal } from './ConfigurationModal';

function Configuration(): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const { data: checkList } = useSiftConfig();
  const [modalState, setModalState] = useState<ConfigModalState | undefined>(undefined);
  const stages = useCheckStages();

  const checkNames = Object.keys(checkList ?? {});

  if (checkList === undefined || checkNames.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.viewContainer}>
        {checkNames.map((checkName, i) => {
          const checkItem = checkList[checkName as keyof typeof checkList];
          const { instances: checks, defaultConfig, stage } = checkItem;
          // Only show checks with a stage that should show for the current
          // feature flags.
          const showCheck = stages.includes(stage);

          if (showCheck === true) {
            return (
              <ConfigurationItem
                name={checkName}
                key={`${checkName}-${i}`}
                checkInstances={checks}
                onEdit={({ check }) => {
                  // When editing, use the existing check's config.
                  setModalState({ show: true, check, defaultConfig });
                }}
                onAdd={() => {
                  setModalState({
                    show: true,
                    // When adding a new check we want to start off with an
                    // empty config object so that the user can only update
                    // the fields they care about.
                    check: { name: checkName, disabled: false, config: {} },
                    defaultConfig,
                  });
                }}
              />
            );
          }
          return null;
        })}
      </div>
      <ConfigurationModal
        show={modalState?.show ?? false}
        check={modalState?.check}
        defaultConfig={modalState?.defaultConfig ?? {}}
        onClose={() => {
          setModalState(undefined);
        }}
      />
    </>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    viewContainer: css``,
    configBox: css`
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 5px;
      margin-bottom: 16px;
    `,
    configBoxHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: ${theme.colors.background.secondary};
      padding: 16px;
    `,
    configBoxBody: css`
      display: flex;
      padding: 16px;
      align-items: center;
    `,
    checkName: css``,
    controls: css`
      display: flex;
      margin-left: auto;
      gap: 8px;
    `,
    buttonIcon: css`
      margin-right: 4px;
    `,
  };
};

export { Configuration };
