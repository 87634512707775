import React, { FC } from 'react';

import { AppPluginMeta, PluginConfigPageProps } from '@grafana/data';
import { Button, Legend } from '@grafana/ui';

import { disablePlugin } from 'api/setup.api';
import { useIsAdmin } from 'hooks';
import { MlAppSettings } from 'types';
import { onHandler } from 'utils';

export const AppConfig: FC<PluginConfigPageProps<AppPluginMeta<MlAppSettings>>> = ({ plugin }) => {
  const isAdmin = useIsAdmin();
  const handleClick = () => disablePlugin();

  const initialized = plugin.meta?.jsonData?.initialized;
  const enabled = initialized !== null && initialized === true;

  return (
    <div className="gf-form-group">
      <Legend>Enable / Disable</Legend>
      {enabled ? (
        <>
          <p>The plugin is currently enabled.</p>
          <p>Disabling the plugin will require an admin to re-initialize it.</p>
          <div>
            <Button variant="destructive" disabled={!isAdmin} onClick={onHandler(handleClick)}>
              Disable plugin
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
